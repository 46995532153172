import React, { useCallback, useEffect, useMemo, useState } from "react";
import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import MuiContainer, { ContainerProps } from "@mui/material/Container";

import { useHistory } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import usePageViewEvent from "hooks/tracking/usePageViewEvent";
import useLogPricingToGTM from "hooks/tracking/useLogPricingToGTM";

import {
  selectIsTestUser,
  selectPolicyDetailsFinishedLoading,
  selectQuotePaymentProcess,
} from "features/quote/slice/selectors";
import { goToPolicyThunk } from "features/quote/slice/thunks/steps";

import Offer from "../Offer";
import PaymentModal from "./PaymentModal/styled";
import PaymentForm from "./PaymentForm";
import Failure from "./Failure";
import { CLASS_PAYMENT_MODAL_CONTENT, PAYMENT_PAGE_NAME } from "./constants";
import TestUserPaymentModal from "./TestUserPaymentModal";

const QuotePaymentContainer = styled((props: ContainerProps) => (
  <MuiContainer {...props} />
))`
  display: flex;
  flex-direction: column;
  padding: 0 6.4rem;
`;

const Payment = () => {
  usePageViewEvent({ pageName: PAYMENT_PAGE_NAME });
  useLogPricingToGTM();

  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const paymentProcess = useAppSelector(selectQuotePaymentProcess);
  const policyDetailsFinishedLoading = useAppSelector(
    selectPolicyDetailsFinishedLoading
  );
  const isTestUser = useAppSelector(selectIsTestUser);

  const modalContent = useMemo(() => {
    if (isTestUser) {
      return <TestUserPaymentModal />;
    }

    return paymentProcess.error ? <Failure /> : <PaymentForm />;
  }, [paymentProcess, isTestUser]);

  const handleCloseModal = useCallback(() => {
    if (paymentProcess.inProcess || paymentProcess.success) return;
    setIsModalOpen(false);
    history.goBack();
  }, [history, paymentProcess]);

  useEffect(() => {
    if (paymentProcess.success && policyDetailsFinishedLoading) {
      dispatch(goToPolicyThunk());
    }
  }, [dispatch, paymentProcess, policyDetailsFinishedLoading]);

  return (
    <QuotePaymentContainer>
      <Offer />
      <PaymentModal open={isModalOpen} onClose={handleCloseModal}>
        <Box className={CLASS_PAYMENT_MODAL_CONTENT}>{modalContent}</Box>
      </PaymentModal>
    </QuotePaymentContainer>
  );
};

export default Payment;
