import React, { useEffect, useMemo, useState } from "react";
import { Typography, Box } from "@mui/material";

import { useTranslation } from "react-i18next";
import { TRANSLATION } from "i18n";

import { mapToIndexedCoverages } from "utils/collections";
import { getAdultTravelers } from "utils/QuoteTravelers";
import { useAppSelector } from "hooks/redux-hooks";

import { CoverageItem } from "types/quote-types";
import { CoverageType } from "types/quote-types/Coverage";

import StepMessage from "common-components/display-utils/StepMessage";
import AdditionalCoverage from "../AdditionalCoverage";
import { selectQuoteDTO } from "../../../../../slice/selectors";

import {
  AdditionalCoverageSectionProps,
  SECONDARY_TITLE_KEY,
  TITLE_KEY,
  TRANSLATION_KEY_PREFIX,
} from "./constants";
import AdditionalCoverageSectionContainer from "./styled";

const AdditionalCoverageSection: React.FC<AdditionalCoverageSectionProps> =
  () => {
    const { t } = useTranslation(TRANSLATION, {
      keyPrefix: TRANSLATION_KEY_PREFIX,
    });

    const [coverages, setCoverages] = useState<CoverageItem[]>([]);
    const quoteDto = useAppSelector(selectQuoteDTO);

    useEffect(() => {
      if (quoteDto) {
        const filtered = mapToIndexedCoverages(quoteDto.coverages).filter(
          ({ coverage }) => !coverage.isBase
        );
        setCoverages(filtered);
      }
    }, [quoteDto]);

    const coverageElements = useMemo(
      () =>
        coverages.map(({ coverage, coverageIndex }) =>
          coverage.coverageType === CoverageType.car &&
          getAdultTravelers(quoteDto!.travellers).length === 0 ? null : (
            <AdditionalCoverage
              key={`coverage-${coverage.title}`}
              coverage={coverage}
              coverageIndex={coverageIndex}
            />
          )
        ),
      [coverages, quoteDto]
    );

    return (
      <AdditionalCoverageSectionContainer className="additional-coverages">
        <StepMessage
          className="additional-coverages-title"
          message={t(TITLE_KEY)}
        />
        <Typography
          component="h3"
          className="additional-coverages-secondary-title"
        >
          {t(SECONDARY_TITLE_KEY)}
        </Typography>
        <Box className="coverages-container">{coverageElements}</Box>
      </AdditionalCoverageSectionContainer>
    );
  };

export default AdditionalCoverageSection;
