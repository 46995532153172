import React, { useCallback, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";

import { useTranslation } from "react-i18next";
import { TRANSLATION } from "i18n";

import { GooglePlace, ResidencyAddress } from "types/quote-types/Locations";
import { Nullable } from "types/general";

import FillDetailsContainer from "common-components/display-utils/FillDetailsContainer";
import FormField from "common-components/display-utils/FormField/FormField";
import TextFieldWrapper from "common-components/controllers/TextFieldWrapper";
import FayeBirthDateField from "common-components/controllers/FayeBirthDateField";
import {
  CLASS_RESIDENCY_FIELD_ADDRESS,
  CLASS_RESIDENCY_FIELD_APARTMENT,
} from "../common-components/ResidencyFields/constants";
import ResidencyFields from "../common-components/ResidencyFields";
import FullAddress from "../FullAddress";

import useInput from "hooks/controllers/useInput";
import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import useNextQuoteStep from "hooks/flow/useNextQuoteStep";
import useBirthDateField from "hooks/controllers/useBirthDateField";
import usePageViewEvent from "hooks/tracking/usePageViewEvent";
import useGooglePlacesMuiAutoComplete from "hooks/controllers/useGooglePlacesMuiAutoComplete";

import {
  capitalize,
  isEmailTextValid,
  isNonEmptyText,
  limitStringLength,
} from "utils/texts";
import { convertDateToISO } from "utils";
import { getGooglePlaceDetailsForRouteResidency } from "utils/residency";

import {
  selectDateOfBirth,
  selectEmail,
  selectName,
  selectResidency,
} from "features/quote/slice/selectors";
import { setSoloTravelerInformationThunk } from "features/quote/slice/thunks/steps";
import { StepName } from "features/quote/routes/url-map";

import SoloTravelerContainer from "./styled";
import {
  ASK_EMAIL_PLACEHOLDER,
  ASK_RESIDENCY_PLACEHOLDER,
  PAGE_NAME_SOLO_TRAVELER,
  PersonalInformationProps,
  TITLE,
  TRANSLATION_KEY_PREFIX,
} from "./constants";
import {
  APARTMENT_STRING_MAXIMUM_CHARACTERS,
  GOOGLE_PLACES_RESIDENCY_ADDRESS_SEARCH_FILTER,
} from "../../../../../../constants";
import {
  getResidencyDetails,
  isSelectedResidencyStreetAddress,
} from "../../../../../../utils/residency";

const SoloTraveler: React.FC<PersonalInformationProps> = () => {
  usePageViewEvent({ pageName: PAGE_NAME_SOLO_TRAVELER });

  const dispatch = useAppDispatch();
  const { t } = useTranslation(TRANSLATION, {
    keyPrefix: TRANSLATION_KEY_PREFIX,
  });
  const [fullAddress, setFullAddress] = useState<{
    shown: boolean;
    residencyAddress?: ResidencyAddress;
  }>({
    shown: false,
  });
  const nextQuoteStep: StepName = useNextQuoteStep();

  const { firstName } = useAppSelector(selectName);
  const dateOfBirth = useAppSelector(selectDateOfBirth);
  const residency = useAppSelector(selectResidency);
  const email = useAppSelector(selectEmail);

  const {
    value: userEmail,
    valid: userEmailValid,
    onChange: onUserEmailChange,
    onFocus: onUserEmailFocus,
    onBlur: onUserEmailBlur,
    hasError: userEmailHasError,
  } = useInput<string>(email, isEmailTextValid);

  const {
    selectedOption: selectedResidency,
    valid: selectedResidencyValid,
    onOptionChange: onChangeSelectedResidency,
    onFocus: onResidencyFocus,
    onBlur: onResidencyBlur,
    onTypedTextChange,
    options: placesCollection,
    isAutoCompleteOpen,
    hasError: residencyHasError,
  } = useGooglePlacesMuiAutoComplete(
    residency?.googleId
      ? [
          {
            googleId: residency.googleId,
            label: residency.label!,
          },
        ]
      : [],
    GOOGLE_PLACES_RESIDENCY_ADDRESS_SEARCH_FILTER,
    false
  );
  const {
    value: selectedDateOfBirth,
    valid: dateOfBirthValid,
    focused: isDateOfBirthFocused,
    onFieldFocus: onFieldDateFocus,
    onDateFieldChange: onDateOfBirthFieldChange,
    hasError: dateHasError,
    onDateFieldBlur,
    month,
    day,
    year,
  } = useBirthDateField(dateOfBirth);

  const {
    value: apartment,
    onChange: onChangeApartmentText,
    hasError: apartmentHasError,
  } = useInput<string>(
    residency?.apartment ?? "",
    isNonEmptyText,
    limitStringLength(APARTMENT_STRING_MAXIMUM_CHARACTERS)
  );

  const handleStagesButtonClick = useCallback(
    async (fullAddressFormResidency?: ResidencyAddress) => {
      if (dateOfBirthValid && userEmailValid && selectedResidencyValid) {
        let residencyDetails: ResidencyAddress;
        const sameResidencyGoogleId =
          residency?.googleId === (selectedResidency as GooglePlace)?.googleId;

        if (Boolean(fullAddressFormResidency)) {
          residencyDetails = fullAddressFormResidency!;
          residencyDetails = {
            ...residencyDetails,
            ...getGooglePlaceDetailsForRouteResidency(residencyDetails),
          };
        } else if (
          residency &&
          residency?.googleId === (selectedResidency as GooglePlace)?.googleId
        ) {
          residencyDetails = residency;
        } else {
          residencyDetails = await getResidencyDetails(
            (selectedResidency as GooglePlace)!,
            apartment
          );
        }

        const isAddress =
          Boolean(fullAddressFormResidency) ||
          isSelectedResidencyStreetAddress(
            selectedResidency as Nullable<GooglePlace>
          ) ||
          sameResidencyGoogleId;

        if (isAddress) {
          dispatch(
            setSoloTravelerInformationThunk({
              nextQuoteStep,
              dateOfBirth: convertDateToISO(selectedDateOfBirth!),
              email: userEmail.trim(),
              residency: residencyDetails,
            })
          );
        } else {
          setFullAddress(() => ({
            residencyAddress: residencyDetails,
            shown: true,
          }));
        }
      }
    },
    [
      dispatch,
      userEmail,
      selectedDateOfBirth,
      selectedResidency,
      dateOfBirthValid,
      selectedResidencyValid,
      userEmailValid,
      residency,
      nextQuoteStep,
      apartment,
    ]
  );

  return fullAddress.shown ? (
    <FullAddress
      onClickNext={handleStagesButtonClick}
      residencyAddress={fullAddress.residencyAddress!}
    />
  ) : (
    <SoloTravelerContainer>
      <FillDetailsContainer
        title={t(TITLE, { name: capitalize(firstName) })}
        onStepsButtonClick={handleStagesButtonClick}
        isStepsButtonDisabled={
          !userEmailValid || !dateOfBirthValid || !selectedResidencyValid
        }
      >
        <FormField hasError={dateHasError}>
          <FayeBirthDateField
            showError={dateHasError}
            isFocused={isDateOfBirthFocused}
            autoFocus
            onFieldFocus={onFieldDateFocus}
            onFieldBlur={onDateFieldBlur}
            onDateFieldChange={onDateOfBirthFieldChange}
            month={month}
            day={day}
            year={year}
          />
        </FormField>
        <FormField
          onBlur={onUserEmailBlur}
          onFocus={onUserEmailFocus}
          hasError={userEmailHasError}
        >
          <TextFieldWrapper
            className="details-input"
            label={t(ASK_EMAIL_PLACEHOLDER)}
            value={userEmail}
            onChange={onUserEmailChange}
            type="email"
          />
        </FormField>
        <ResidencyFields>
          <FormField
            onFocus={onResidencyFocus}
            onBlur={onResidencyBlur}
            hasError={residencyHasError}
            className={CLASS_RESIDENCY_FIELD_ADDRESS}
          >
            <Autocomplete
              open={isAutoCompleteOpen}
              disablePortal
              fullWidth
              value={(selectedResidency ?? null) as Nullable<GooglePlace>}
              options={placesCollection}
              filterOptions={(suggestions) => suggestions} // suppose to help MUI Autocomplete re-render issue
              onChange={onChangeSelectedResidency}
              isOptionEqualToValue={(option, value) =>
                option.googleId === value.googleId
              }
              renderInput={(params) => (
                <TextFieldWrapper
                  onChange={onTypedTextChange}
                  {...params}
                  placeholder={t(ASK_RESIDENCY_PLACEHOLDER)}
                  submitCallback={handleStagesButtonClick}
                />
              )}
            />
          </FormField>
          <FormField
            hasError={apartmentHasError}
            className={CLASS_RESIDENCY_FIELD_APARTMENT}
          >
            <TextFieldWrapper
              submitCallback={handleStagesButtonClick}
              label={"Apt #"}
              value={apartment}
              onChange={onChangeApartmentText}
            />
          </FormField>
        </ResidencyFields>
      </FillDetailsContainer>
    </SoloTravelerContainer>
  );
};

export default SoloTraveler;
