import React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";

import { WithThemeProps } from "types/general";
import { ThemeType } from "theme";
import AppImage1 from "assets/images/download-app/Front-iPhone.png";
import AppImage2 from "assets/images/download-app/Rear-iPhone.png";
import {
  CLASS_APP_IMAGES_CONTAINER,
  CLASS_BUBBLE_ALERTS,
  CLASS_BUBBLE_ASSISTANCE,
  CLASS_BUBBLE_CLAIMS_PROCESSING,
  CLASS_MAIN_APP_IMAGE,
  CLASS_SECONDARY_APP_IMAGE,
} from "./constants";
import { CLASS_BUBBLE_CONTENT } from "../Bubble/constants";

const AppSuggestionsContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box {...props} />
))`
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.2s cubic-bezier(0, 0, 0.2, 1);
  transform: none;
  display: flex;
  margin: auto;
  max-height: 900px;


  & > .${CLASS_APP_IMAGES_CONTAINER} {
    position: relative;
    width: 100%;
    height: clamp(${({ theme: { spacing } }: ThemeType) =>
      `${spacing(60)}, 56vw, ${spacing(82)}`});
    transition: transform 0.2s cubic-bezier(0, 0, 0.2, 1);
  }

  & .${CLASS_MAIN_APP_IMAGE}, .${CLASS_SECONDARY_APP_IMAGE} {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    bottom: 0;
    background-size: cover;
    background-position: 50%;
    transition: width 0.2s cubic-bezier(0, 0, 0.2, 1), height 0.2s cubic-bezier(0, 0, 0.2, 1);
  }

  & .${CLASS_MAIN_APP_IMAGE} {
    right: 44%;
    aspect-ratio: 329 / 694;
    width: clamp(${({ theme: { spacing } }: ThemeType) =>
      `${spacing(24)}, 26vw, ${spacing(41.25)}`});
    background-image: url("${AppImage1}");
    z-index: 1;

    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      width: clamp(${({ theme: { spacing } }: ThemeType) =>
        `${spacing(19.5)}, 26vw, ${spacing(24)}`});
    }
  }

  & .${CLASS_SECONDARY_APP_IMAGE} {
    left: 48%;
    aspect-ratio: 298 / 628;
    width: clamp(${({ theme: { spacing } }: ThemeType) =>
      `${spacing(22)}, 24vw, ${spacing(37.25)}`});
    background-image: url("${AppImage2}");
    z-index: 0;

    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      width: clamp(${({ theme: { spacing } }: ThemeType) =>
        `${spacing(17.5)}, 24vw, ${spacing(22)}`});
    }
  }

  &
  .${CLASS_BUBBLE_ASSISTANCE},
  .${CLASS_BUBBLE_ALERTS},
  .${CLASS_BUBBLE_CLAIMS_PROCESSING} {
    position: absolute;
    z-index: 2;

  }

  & .${CLASS_BUBBLE_ASSISTANCE} {
    bottom: 88%;
    left: clamp(40%, 20vw, 58%);
    width: clamp(${({ theme: { spacing } }: ThemeType) =>
      `${spacing(12.25)}, 14vw, ${spacing(23.75)}`});
    height: clamp(${({ theme: { spacing } }: ThemeType) =>
      `${spacing(12.25)}, 14vw, ${spacing(23.75)}`});

    padding: clamp(${({ theme: { spacing } }: ThemeType) =>
      `${spacing(1)}, 2vw, ${spacing(3)}`});

    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      padding: clamp(${({ theme: { spacing } }: ThemeType) =>
        `${spacing(1)}, 1vw, ${spacing(3)}`});
    }


    & .${CLASS_BUBBLE_CONTENT} {
      transform: rotate(11.16deg);
    }

  }

  & .${CLASS_BUBBLE_ALERTS} {
    top: 36%;
    right: clamp(52%, 24vw, 90%);
    width: clamp(${({ theme: { spacing } }: ThemeType) =>
      `${spacing(10.5)}, 14vw, ${spacing(22.5)}`});
    height: clamp(${({ theme: { spacing } }: ThemeType) =>
      `${spacing(10.5)}, 14vw, ${spacing(22.5)}`});

    & .${CLASS_BUBBLE_CONTENT} {
      transform: rotate(-6.26deg);
    }

  }

  & .${CLASS_BUBBLE_CLAIMS_PROCESSING} {
    top: 80%;
    left: clamp(20%, 24vw, 60%);
    width: clamp(${({ theme: { spacing } }: ThemeType) =>
      `${spacing(10.75)}, 14vw, ${spacing(21.5)}`});
    height: clamp(${({ theme: { spacing } }: ThemeType) =>
      `${spacing(10.75)}, 14vw, ${spacing(21.5)}`});

    & .${CLASS_BUBBLE_CONTENT} {
      transform: rotate(10.75deg);
    }

  }
}
`;

export default AppSuggestionsContainer;
