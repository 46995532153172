import States from "assets/json-files/states_titlecase.json";

export const LEAD_LOCATION_COMING_SOON = "state closed";

export const TRANSLATION_KEY_PREFIX = "coming soon";
export const MAIN_MESSAGE_KEY = "main message";
export const SECONDARY_MESSAGE_KEY = "secondary message";
export const MESSAGE_NOTIFY_ME_KEY = "message notify me";
export const BUTTON_BACK_TO_HOMEPAGE_TEXT_KEY = "back to homepage";
export const CLASS_CONTAINER = "coming-soon-container";
export const CLASS_TITLE = "title";
export const CLASS_SECONDARY_MESSAGE = "secondary-message";
export const CLASS_NOTIFY_ME = "notify-me";
export const CLASS_BUTTON_BACK_TO_HOMEPAGE = "button-back-to-homepage";

export interface ComingSoonProps {}

type State = { label: string; value: string };
export const getStateName = (stateInitials?: string) =>
  (States as State[]).find(
    ({ value }) => value.toUpperCase() === stateInitials?.toUpperCase()
  )?.label;
