import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  Method,
} from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const REFRESH_TOKEN_URL = `https://securetoken.googleapis.com/v1/token?key=${process.env.REACT_APP_FIREBASE_API_KEY}`;

const axiosInstanceCreator = (
  configs: Partial<AxiosRequestConfig>
): AxiosInstance => {
  return axios.create(configs);
};

export const axiosInstance = axiosInstanceCreator({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosRefreshTokenInstance = axiosInstanceCreator({
  baseURL: REFRESH_TOKEN_URL,
});

class ApiWrapper {
  Authorization = "";

  put = async (path: string, options?: AxiosRequestConfig) => {
    const { data, ...rest } = { data: {}, ...options };
    return await axiosInstance.put(path, data, rest);
  };

  post = async (path: string, options?: AxiosRequestConfig) => {
    const { data, ...rest } = { data: {}, ...options };
    return await axiosInstance.post(path, data, rest);
  };

  get = async (path: string, options?: AxiosRequestConfig) => {
    const { data, ...rest } = { data: {}, ...options };
    return await axiosInstance.get(path, { ...rest, data });
  };

  async request<T>(path: string, method: Method, options?: AxiosRequestConfig) {
    if (options?.headers?.Authorization && !this.Authorization) {
      this.Authorization = options.headers.Authorization;
    }
    const response: AxiosResponse<T> = await // @ts-ignore
    this[method.toLowerCase()](path, options);
    return response.data;
  }
}

export default new ApiWrapper();
