export const TRANSLATION_KEY_PREFIX = "quote.steps.offer.section quote";
export const FOR_TRAVELLERS_KEY = "for_travellers";

export const CLASS_CONTAINER = "offer-trip-description-container";
export const CLASS_TRIP_DESCRIPTION = "offer-trip-description-text";
export const CLASS_DESTINATION = "destination";
export const CLASS_DESTINATION_CONTAINER = "destination-container";
export const CLASS_DATE = "date";
export const CLASS_FOR_TRAVELLERS = "for-travellers";

export interface TripShortDescriptionProps {}
