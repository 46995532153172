import React from "react";
import { styled } from "@mui/system";
import Collapse, { CollapseProps } from "@mui/material/Collapse";

import { WithThemeProps } from "types/general";
import { ThemeType } from "theme";
import {
  CLASS_COVERAGES,
  CLASS_PANEL_COLLAPSE,
  CLASS_PAY_BUTTON,
  CLASS_QUOTE_PANEL,
  CLASS_TRAVELERS,
  CLASS_TRIP_DESTINATIONS_AND_DATES,
} from "./constants";

const QuotePanelContainer = styled((props: WithThemeProps & CollapseProps) => (
  <Collapse {...props} />
))`
  &.${CLASS_QUOTE_PANEL} {
    z-index: 1;
    position: fixed;
    bottom: 0;
    width: 100vw;
    background-color: ${({ theme }) => theme.palette.p_white.full};
    transition: height 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      background-color 0.2s cubic-bezier(0, 0, 0.2, 1);

    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      background-color: ${({ theme }: ThemeType) =>
        theme.palette.background.default};

      &.mobile-summary-expanded {
        background-color: ${({ theme }) => theme.palette.p_white.full};
      }
    }

    & .${CLASS_PANEL_COLLAPSE} {
      max-width: ${({ theme: { spacing } }: ThemeType) => spacing(180)};
      margin: 0 auto;
      padding: ${({ theme: { spacing } }: ThemeType) =>
        `${spacing(3)} ${spacing(10)}`};
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      line-height: 28px;
      gap: ${({ theme: { spacing } }: ThemeType) => spacing(5)};

      & > .${CLASS_TRIP_DESTINATIONS_AND_DATES} {
        font-weight: 600;
      }

      ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
        padding: ${({ theme: { spacing } }: ThemeType) => spacing(2)};
        gap: unset;
        display: flex;
        flex-direction: column;
        align-items: unset;

        & > .${CLASS_PAY_BUTTON} {
          width: unset;
          grid-area: unset;
        }

        & .mobile-summary-collapse .MuiCollapse-wrapperInner {
          display: grid;
          grid-template-rows: 0.5fr 0.5fr;
          grid-template-columns: 1fr 2fr;
          grid-template-areas:
            "${CLASS_TRIP_DESTINATIONS_AND_DATES} ${CLASS_TRIP_DESTINATIONS_AND_DATES}"
            "         ${CLASS_TRAVELERS}                  ${CLASS_COVERAGES}          ";

          & .${CLASS_TRIP_DESTINATIONS_AND_DATES} {
            font-size: 16px;
            justify-self: flex-start;
            grid-area: ${CLASS_TRIP_DESTINATIONS_AND_DATES};
          }

          & .${CLASS_TRAVELERS}, .${CLASS_COVERAGES} {
            font-weight: 400;
            font-size: 14px;
            justify-self: flex-start;
            margin-bottom: ${({ theme: { spacing } }: ThemeType) => spacing(1)};
          }

          & .${CLASS_TRAVELERS} {
            grid-area: ${CLASS_TRAVELERS};
          }

          & .${CLASS_COVERAGES} {
            grid-area: ${CLASS_COVERAGES};
          }
        }
      }
    }
  }
`;

export default QuotePanelContainer;
