import React from "react";
import { StepMessageProps } from "./constants";
import StepMessageContainer from "./styled";
import { handleTextWithNewLines } from "../../../utils/translations";

const StepMessage: React.FC<StepMessageProps> = ({ message, className }) => {
  return (
    <StepMessageContainer className={className}>
      {handleTextWithNewLines(message)}
    </StepMessageContainer>
  );
};

export default StepMessage;
