import React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";

import { WithThemeProps } from "types";
import { ThemeType } from "theme";

import { STATE_AUTOCOMPLETE_TEXT_FIELD } from "./constants";

const FullAddressContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box {...props} />
))`
  & .${STATE_AUTOCOMPLETE_TEXT_FIELD} {
    & input {
      padding: ${({ theme: { spacing } }: ThemeType) =>
        spacing(4, 1.75, 2)} !important;
    }
  }
`;

export default FullAddressContainer;
