import { useCallback, useEffect, useMemo, useState } from "react";
import DateRangeChipType from "../../types/quote-types/DateRangeChip";
import { getOverlappedIntervalIndexes } from "../../utils/intervals";
import { CarDateDto } from "../../types/quote-types";

const getValidatedChips = (
  chipCollection: DateRangeChipType[]
): DateRangeChipType[] => {
  const overlappingRangeIndexes: number[] =
    getOverlappedIntervalIndexes(chipCollection);
  const validatedChips = [...chipCollection];
  validatedChips.forEach(
    (chip, index) =>
      (chip.isOverlapping = overlappingRangeIndexes.includes(index))
  );

  return validatedChips;
};

const useDateRangeCollection = (initialValue: CarDateDto[]) => {
  const [chipCollection, setChipCollection] = useState<DateRangeChipType[]>([]);

  const hasOverlappingRanges = useMemo(
    () => !!chipCollection.find((chip) => chip.isOverlapping),
    [chipCollection]
  );

  const addChip = useCallback(
    (chip: DateRangeChipType) => {
      const chipAlreadyInCollection = chipCollection.find(
        (chipInCollection) =>
          chipInCollection.startDate === chip.startDate &&
          chipInCollection.endDate === chip.endDate
      );

      if (!chipAlreadyInCollection) {
        const newCollection = [...chipCollection, chip];
        const validatedChips = getValidatedChips(newCollection);
        setChipCollection(validatedChips);
      }
    },
    [chipCollection]
  );
  const onDeleteChip = useCallback(
    (chipIndex: number) => {
      const newCollection = [
        ...chipCollection.slice(0, chipIndex),
        ...chipCollection.slice(chipIndex + 1),
      ];
      const validatedChips = getValidatedChips(newCollection);
      setChipCollection(validatedChips);
    },
    [chipCollection]
  );

  useEffect(
    () => {
      const updatedValue: DateRangeChipType[] = initialValue.map(
        ({ startDate, endDate }: CarDateDto) => ({
          startDate: startDate,
          endDate: endDate,
        })
      );
      setChipCollection(updatedValue);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    chipCollection,
    addChip,
    onDeleteChip,
    hasOverlappingRanges,
  };
};

export default useDateRangeCollection;
