import { useCallback, useEffect } from "react";

import { useLocation } from "react-router-dom";
import { isValid, parseISO } from "date-fns";
import queryString from "query-string";

import { Nullable } from "types";
import { UtmDto } from "types/utm";

import { convertDateToISO } from "utils";
import { isAdvisorUtms, UTM_NAMES_COLLECTION } from "utils/utm";
import { useAppDispatch } from "hooks/redux-hooks";
import { getCustomerId, loggerUserLogin } from "tracking/logger";

import {
  datesAndDestinationGivenThunk,
  getAdvisorDetails,
} from "features/quote/slice/thunks/general";
import { setUtmCollection } from "features/quote/slice";

//?destination=ChIJF9BC6RdBe0gRycIykheyLbU&startDate=2022-03-20&endDate=2022-03-22
//?utm_source=111source111&utm_medium=111medium111&utm_campaign=111campaign111&utm_term=111term111&utm_content=111content111

const DESTINATION_PARAM_NAME = "destination";
const START_DATE_PARAM_NAME = "startDate";
const END_DATE_PARAM_NAME = "endDate";

export const useUrlParamsParse = () => {
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const parsed = queryString.parse(search);

  const parseParams = useCallback(async () => {
    const parsedParams = Object.keys(parsed);
    if (parsedParams.length) {
      let dates: { tripStartDate: string; tripEndDate: string } | undefined;

      const startDate = parsed?.[START_DATE_PARAM_NAME] as string;
      const endDate = parsed?.[END_DATE_PARAM_NAME] as string;
      const destinationGoogleId = parsed?.[DESTINATION_PARAM_NAME] as string;

      const utmCollection: UtmDto[] = UTM_NAMES_COLLECTION.reduce(
        (acc: UtmDto[], currUtm) => {
          const utmValue = (parsed?.[currUtm] || "") as string;
          const utmDto: Nullable<UtmDto> = utmValue
            ? { key: currUtm, value: utmValue }
            : null;
          return utmDto ? [...acc, utmDto] : acc;
        },
        []
      );

      if (isAdvisorUtms(utmCollection)) {
        dispatch(getAdvisorDetails({ utms: utmCollection }));
      }

      if (destinationGoogleId || (startDate && endDate)) {
        const startDateAsDate = parseISO(startDate);
        const endDateAsDate = parseISO(endDate);

        if (isValid(startDateAsDate) && isValid(endDateAsDate)) {
          dates = {
            tripStartDate: convertDateToISO(startDateAsDate),
            tripEndDate: convertDateToISO(endDateAsDate),
          };
        }
      }

      if (utmCollection.length) {
        dispatch(setUtmCollection(utmCollection));
      }

      if (Boolean(dates) || Boolean(destinationGoogleId)) {
        dispatch(datesAndDestinationGivenThunk({ dates, destinationGoogleId }));
      }
    }
  }, [parsed, dispatch]);

  useEffect(() => {
    const useEmailForLogin = Boolean(
      (parsed?.quote_hash as string) && (parsed?.email as string)
    );

    const loginData = useEmailForLogin
      ? { email: parsed?.email as string }
      : {
          customerId: getCustomerId(parsed?.customer_id as string),
        };

    loggerUserLogin(loginData);
  }, [parsed?.customer_id, parsed?.quote_hash, parsed?.email]);

  useEffect(() => {
    parseParams().then();
  }, [parseParams]);
};
