import React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";

import { WithThemeProps } from "types";
import { CLASS_QUOTE_OFFER } from "./constants";
import { ThemeType } from "../../../../../theme";

const QuoteOfferContainer = styled((props: BoxProps & WithThemeProps) => (
  <Box {...props} />
))`
  &.${CLASS_QUOTE_OFFER} {
    width: 100%;
    height: fit-content;
    padding-bottom: ${({ theme: { spacing } }) => spacing(24)};

    margin: 0 auto;
    color: ${({ theme }) => theme.palette.primary.main};
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(8)};

    ${({ theme }: ThemeType) => theme.breakpoints.down("tablet")} {
      gap: ${({ theme }) => theme.spacing(7)};
    }
  }
`;

export default QuoteOfferContainer;
