import { createAsyncThunk } from "@reduxjs/toolkit";
import { push } from "connected-react-router";
import { differenceInDays, parseISO } from "date-fns";

import { RootState } from "types/redux-types";
import { Name, Traveler } from "types/quote-types";
import {
  DestinationGoogleData,
  PlaceDetails,
  ResidencyAddress,
} from "types/quote-types/Locations";

import {
  calcAgeByDateOfBirth,
  fillEmptyTravelersByAmount,
  nonAdultTravelersCount,
  TravelersCountOption,
} from "utils/QuoteTravelers";
import {
  logEvent,
  loggerUserModify,
  setUserAttributeList,
  setUserAttributes,
} from "tracking/logger";

import EventName from "tracking/mixpanel/EventName";
import { TrackerPropertyName } from "tracking/mixpanel/PropertyNames";

import {
  selectDateOfBirth,
  selectEmail,
  selectTravelers,
} from "features/quote/slice/selectors";
import {
  setCurrentTravelerIndex,
  setTravelersCountOption,
} from "features/travelers/slice";
import { selectCurrentTravelerIndex } from "features/travelers/selectors";

import { STEP_TO_URL_MAP, StepName } from "features/quote/routes/url-map";
import { QUOTE_URL } from "routes/routes-collection";

import {
  addEmptyTravelerAtIndex,
  clearEmptyTravelers,
  resetSavedQuoteIds,
  setAdditionalTravelerInformation,
  setDates,
  setDestinationGooglePlaceResults,
  setDestinations,
  setMainTravelerPersonalInformation,
  setName,
  setOfferLoaderShown,
  setPersonalInformation,
  setResidency,
  setTravellers,
} from "../index";
import { omit } from "lodash-es";
import { UNSUPPORTED_STATES } from "../../../../constants";
import { isoDateWithoutHour } from "../../../../utils";

export const setNameThunk = createAsyncThunk<
  void,
  {
    nextQuoteStep: StepName;
    firstName: string;
    lastName: string;
  },
  { state: RootState }
>(
  "quote/setNameThunk",
  ({ firstName, lastName, nextQuoteStep }, { dispatch }) => {
    dispatch(setName({ firstName, lastName }));
    setUserAttributes({
      [TrackerPropertyName.FirstName]: firstName,
      [TrackerPropertyName.LastName]: lastName,
    });
    logEvent(EventName.SettingName, {
      [TrackerPropertyName.FirstName]: firstName,
      [TrackerPropertyName.LastName]: lastName,
    });
    dispatch(push(`${QUOTE_URL}${STEP_TO_URL_MAP[nextQuoteStep]}`));
    return;
  }
);

export const setDestinationThunk = createAsyncThunk<
  void,
  {
    destinationGooglePlaceResult: DestinationGoogleData[];
    nextQuoteStep: StepName;
    destinations: PlaceDetails[];
  },
  { state: RootState }
>(
  "quote/setPlannedTripThunk",
  (
    { destinations, nextQuoteStep, destinationGooglePlaceResult },
    { dispatch }
  ) => {
    dispatch(setDestinationGooglePlaceResults(destinationGooglePlaceResult));
    dispatch(setDestinations({ destinations }));

    const destinationsNames = destinations.map(
      (destination) => destination.label
    );

    setUserAttributeList(TrackerPropertyName.Destinations, destinationsNames);
    logEvent(EventName.SettingDestination, {
      [TrackerPropertyName.Destinations]: destinationsNames.join(" | "),
      [TrackerPropertyName.IsMultipleDestinations]: destinations.length > 0,
    });
    dispatch(push(`${QUOTE_URL}${STEP_TO_URL_MAP[nextQuoteStep]}`));
    return;
  }
);

export const setDatesThunk = createAsyncThunk<
  void,
  {
    nextQuoteStep: StepName;
    dates: {
      tripStartDate: string;
      tripEndDate: string;
    };
  },
  { state: RootState }
>("quote/setPlannedTripThunk", ({ dates, nextQuoteStep }, { dispatch }) => {
  dispatch(setDates({ dates }));

  const eventData = {
    [TrackerPropertyName.StartDate]: isoDateWithoutHour(dates.tripStartDate),
    [TrackerPropertyName.EndDate]: isoDateWithoutHour(dates.tripEndDate),
    [TrackerPropertyName.Days]: Math.abs(
      differenceInDays(
        parseISO(dates.tripEndDate),
        parseISO(dates.tripStartDate)
      )
    ),
  };

  setUserAttributes(eventData);
  logEvent(EventName.SettingDates, eventData);

  dispatch(push(`${QUOTE_URL}${STEP_TO_URL_MAP[nextQuoteStep]}`));
  return;
});

export const setTravellersCountOptionThunk = createAsyncThunk<
  void,
  {
    nextQuoteStep: StepName;
    travellersCountOption: TravelersCountOption;
  },
  { state: RootState }
>(
  "quote/setTravellersThunk",
  ({ travellersCountOption, nextQuoteStep }, { dispatch, getState }) => {
    const travelers = selectTravelers(getState());
    const updatedTravelersCollection = fillEmptyTravelersByAmount({
      countOption: travellersCountOption,
      currTravelers: travelers,
    });
    dispatch(setTravelersCountOption(travellersCountOption));
    logEvent(EventName.SettingTravelersCount, {
      [TrackerPropertyName.TravelersCount]: travellersCountOption,
    });

    dispatch(setTravellers(updatedTravelersCollection));
    dispatch(push(`${QUOTE_URL}${STEP_TO_URL_MAP[nextQuoteStep]}`));
    return;
  }
);

export const setSoloTravelerInformationThunk = createAsyncThunk<
  void,
  {
    nextQuoteStep: StepName;
    dateOfBirth: string;
    residency: ResidencyAddress;
    email: string;
  },
  { state: RootState }
>(
  "quote/setSoloTravelerInformationThunk",
  ({ dateOfBirth, residency, email, nextQuoteStep }, { dispatch }) => {
    dispatch(setPersonalInformation({ residency, dateOfBirth, email }));

    setUserAttributes({
      [TrackerPropertyName.DateOfBirth]: isoDateWithoutHour(dateOfBirth),
      [TrackerPropertyName.Address]: residency.label!,
      [TrackerPropertyName.Email]: email,
    });
    loggerUserModify({ email });
    logEvent(EventName.SettingMainTravelersDetails, {
      [TrackerPropertyName.DateOfBirth]: isoDateWithoutHour(dateOfBirth),
      [TrackerPropertyName.Age]: calcAgeByDateOfBirth(dateOfBirth),
      [TrackerPropertyName.UsedFullAddressForm]: Boolean(!residency.googleId),
      [TrackerPropertyName.Address]: residency.label,
      [TrackerPropertyName.Email]: email,
    });

    dispatch(setOfferLoaderShown(true));
    if (residency.areaLevel1) {
      if (UNSUPPORTED_STATES.includes(residency.areaLevel1)) {
        dispatch(push(`${QUOTE_URL}${STEP_TO_URL_MAP[StepName.ComingSoon]}`));
      } else {
        dispatch(push(`${QUOTE_URL}${STEP_TO_URL_MAP[nextQuoteStep]}`));
      }
    }
    return;
  }
);

export const setMainTravelerPersonalInfoThunk = createAsyncThunk<
  void,
  {
    nextQuoteStep: StepName;
    dateOfBirth: string;
    email: string;
  },
  { state: RootState }
>(
  "quote/setFirstTravelerPersonalInfoThunk",
  ({ dateOfBirth, email, nextQuoteStep }, { dispatch }) => {
    dispatch(setMainTravelerPersonalInformation({ dateOfBirth, email }));

    setUserAttributes({
      [TrackerPropertyName.DateOfBirth]: isoDateWithoutHour(dateOfBirth),
      [TrackerPropertyName.Email]: email,
    });
    loggerUserModify({ email });

    dispatch(push(`${QUOTE_URL}${STEP_TO_URL_MAP[nextQuoteStep]}`));
    return;
  }
);

export const setMainTravelerResidencyThunk = createAsyncThunk<
  void,
  {
    nextQuoteStep: StepName;
    residency: ResidencyAddress;
  },
  { state: RootState }
>(
  "quote/setFirstTravelerResidencyThunk",
  ({ residency, nextQuoteStep }, { dispatch, getState }) => {
    const email = selectEmail(getState());
    const dateOfBirth = selectDateOfBirth(getState());

    const nextTravelerIndex = 1;
    dispatch(setResidency({ residency }));

    setUserAttributes({
      [TrackerPropertyName.DateOfBirth]:
        dateOfBirth && isoDateWithoutHour(dateOfBirth),
      [TrackerPropertyName.Address]: residency.label!,
      [TrackerPropertyName.Email]: email,
    });
    logEvent(EventName.SettingMainTravelersDetails, {
      [TrackerPropertyName.DateOfBirth]:
        dateOfBirth && isoDateWithoutHour(dateOfBirth),
      [TrackerPropertyName.Age]: calcAgeByDateOfBirth(dateOfBirth!),
      [TrackerPropertyName.UsedFullAddressForm]: Boolean(!residency.googleId),
      [TrackerPropertyName.Address]: residency.label,
      [TrackerPropertyName.Email]: email,
    });

    if (residency.areaLevel1) {
      if (UNSUPPORTED_STATES.includes(residency.areaLevel1)) {
        dispatch(push(`${QUOTE_URL}${STEP_TO_URL_MAP[StepName.ComingSoon]}`));
      } else {
        dispatch(
          push(
            `${QUOTE_URL}${STEP_TO_URL_MAP[nextQuoteStep]}/${nextTravelerIndex}`
          )
        );
      }
    }
    return;
  }
);

export const setAdditionalTravelerInfoThunk = createAsyncThunk<
  void,
  {
    nextQuoteStep: StepName;
    name: Name;
    dateOfBirth: string;
    email: string;
    addTraveler: boolean;
  },
  { state: RootState }
>(
  "quote/setAdditionalTravelerInfoThunk",
  (
    { dateOfBirth, name, email, nextQuoteStep, addTraveler },
    { dispatch, getState }
  ) => {
    const travelerIndex = selectCurrentTravelerIndex(getState());
    const travelers = selectTravelers(getState());
    const nextTravelerIndex = travelerIndex + 1;

    dispatch(
      setAdditionalTravelerInformation({
        name,
        dateOfBirth,
        email,
        travelerIndex,
      })
    );
    if (addTraveler) {
      dispatch(addEmptyTravelerAtIndex(nextTravelerIndex));
    }
    if (nextQuoteStep === StepName.AdditionalTravelerInfo) {
      dispatch(setCurrentTravelerIndex(nextTravelerIndex));
      dispatch(
        push(
          `${QUOTE_URL}${
            STEP_TO_URL_MAP[StepName.AdditionalTravelerInfo]
          }/${nextTravelerIndex}`
        )
      );
    } else {
      const additionalTravelersDOBs = [
        ...(travelers.slice(1, -1) as Traveler[]).map(
          (traveler: Traveler) => traveler?.dateOfBirth
        ),
        dateOfBirth,
      ];
      const additionalTravelersEmails = [
        ...(travelers.slice(1, -1) as Traveler[]).map(
          (traveler: Traveler) => traveler.email
        ),
        email,
      ];

      const eventData = {
        [TrackerPropertyName.TotalTravelersCount]: travelers.length,
        [TrackerPropertyName.NonAdultTravelersCount]: nonAdultTravelersCount(
          additionalTravelersDOBs as string[]
        ),
        [TrackerPropertyName.Ages]: additionalTravelersDOBs
          .map(calcAgeByDateOfBirth)
          .join(" | "),
        [TrackerPropertyName.Emails]: additionalTravelersEmails.join(" | "),
      };

      setUserAttributes(
        omit(eventData, TrackerPropertyName.TotalTravelersCount)
      );
      logEvent(EventName.SettingAdditionalTravelersDetails, eventData);

      dispatch(clearEmptyTravelers());
      dispatch(setOfferLoaderShown(true));
      dispatch(push(`${QUOTE_URL}${STEP_TO_URL_MAP[nextQuoteStep]}`));
    }
    return;
  }
);

export const goToPaymentThunk = createAsyncThunk<
  void,
  undefined,
  { state: RootState }
>("quote/goToPaymentThunk", (_, { dispatch }) => {
  dispatch(resetSavedQuoteIds());
  dispatch(push(`${QUOTE_URL}${STEP_TO_URL_MAP[StepName.Payment]}`));
  return;
});

export const goToPolicyThunk = createAsyncThunk<
  void,
  undefined,
  { state: RootState }
>("quote/goToPolicyThunk", (_, { dispatch }) => {
  dispatch(push(`${QUOTE_URL}${STEP_TO_URL_MAP[StepName.Policy]}`));
  return;
});
