import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { NoInfer } from "@reduxjs/toolkit/dist/tsHelpers";

import {
  Coverage,
  ProcessStatus,
  QuoteDTO,
  QuotePaymentResponse,
  Traveler,
} from "types/quote-types";
import { IQuoteState } from "./IQuoteState";

import {
  calcQuotePrice,
  getPolicyDetails,
  getPolicyPDFs,
  getPolicyReceipts,
  getQuote,
  getQuoteFromHash,
  payWithCreditCard,
  saveQuote,
} from "./thunks/quote";
import { isTravelerAdult } from "utils/QuoteTravelers";
import { convertDateToISO } from "utils";
import { getAdvisorDetails } from "./thunks/general";
import { initialState } from "./initialState";

type ExtraReducers = (
  builder: ActionReducerMapBuilder<NoInfer<IQuoteState>>
) => void;

export const extraReducers: ExtraReducers = (builder) => {
  builder.addCase(getQuote.pending, (state: IQuoteState) => {
    state.getQuoteProcessStatus = ProcessStatus.IN_PROCESS;
  });
  builder.addCase(
    getQuote.fulfilled,
    (state: IQuoteState, { payload }: { payload: QuoteDTO }) => {
      let travellers: Traveler[] = state.travellers.map(
        (traveler) => traveler!
      );
      const coverages: Coverage[] = payload.coverages.reduce(
        (accumulator: Coverage[], current: Coverage) => {
          let isSelected: boolean;
          if (current.isBase) {
            isSelected = current.isSelected;
          } else {
            isSelected = Boolean(
              state.quoteDTO?.coverages.find(
                (coverage) => coverage.coverageType === current.coverageType
              )?.isSelected
            );
          }

          const coverageWithUpdatedSelectionValue: Coverage = {
            ...current,
            isSelected: Boolean(isSelected),
          };
          return [...accumulator, coverageWithUpdatedSelectionValue];
        },
        []
      );
      const quoteEditableData: Partial<QuoteDTO> = {
        coverages,
        tripCost: payload.tripCost,
        carDatesDto: state.quoteDTO?.carDatesDto ?? [],
      };

      if (
        state.getQuoteProcessStatus === ProcessStatus.IN_PROCESS &&
        isTravelerAdult(travellers[0]) &&
        !travellers[0].isDriver
      ) {
        travellers[0].isDriver = true;
      }

      state.getQuoteProcessStatus = ProcessStatus.SUCCEED;
      state.quoteDTO = { ...payload, travellers, ...quoteEditableData };
      state.calculationProcess.status = ProcessStatus.REQUESTED;
      state.defaultTripCost = payload.tripCost;
    }
  );

  builder.addCase(
    getQuoteFromHash.fulfilled,
    (state: IQuoteState, { payload: { quote, destinationsGoogleData } }) => {
      state.destinationsGoogleData = destinationsGoogleData;

      state.tripStartDate = convertDateToISO(new Date(quote.tripStartDate));
      state.tripEndDate = convertDateToISO(new Date(quote.tripEndDate));
      state.destinations = quote.destinations;
      state.travellers = quote.travellers;
      state.residency = quote.residency;
      state.utms = quote.utms;

      const firstTraveler = quote.travellers[0];
      state.dateOfBirth = firstTraveler.dateOfBirth;
      state.name = {
        firstName: firstTraveler.firstName,
        lastName: firstTraveler.lastName,
      };
      state.quoteDTO = quote;
    }
  );
  builder.addCase(saveQuote.pending, (state: IQuoteState) => {
    state.savedQuoteIds = initialState.savedQuoteIds;
  });
  builder.addCase(saveQuote.fulfilled, (state: IQuoteState, { payload }) => {
    state.savedQuoteIds = payload;
  });
  builder.addCase(
    calcQuotePrice.fulfilled,
    (state: IQuoteState, { payload }) => {
      state.pricing = payload.prices;
      state.coveragesPrices = payload.coveragesPrice;
      state.calculationProcess.status = ProcessStatus.SUCCEED;
      state.isTestUser = Boolean(payload.isTest);
    }
  );
  builder.addCase(calcQuotePrice.pending, (state: IQuoteState) => {
    state.calculationProcess.status = ProcessStatus.IN_PROCESS;
    state.calculationProcess.error = null;
    state.pricing = [];
  });
  builder.addCase(payWithCreditCard.pending, (state: IQuoteState) => {
    state.paymentProcess.inProcess = true;
  });
  builder.addCase(
    payWithCreditCard.rejected,
    (state: IQuoteState, { error }) => {
      state.paymentProcess.inProcess = false;
      state.paymentProcess.error = { message: error.message! };
    }
  );
  builder.addCase(
    payWithCreditCard.fulfilled,
    (state: IQuoteState, { payload }: { payload: QuotePaymentResponse }) => {
      state.paymentProcess.inProcess = false;
      if (payload === 200) {
        state.paymentProcess.success = true;
      }
    }
  );
  builder.addCase(
    getPolicyPDFs.fulfilled,
    (state: IQuoteState, { payload }: { payload: string[] }) => {
      state.policyPdfUrls = payload;
    }
  );
  builder.addCase(
    getPolicyReceipts.fulfilled,
    (
      state: IQuoteState,
      { payload }: { payload: { description: string; receipt: string }[] }
    ) => {
      state.policyReceiptUrls = payload;
    }
  );
  builder.addCase(getPolicyDetails.pending, (state: IQuoteState) => {
    state.finishedLoadingPolicyDetails = false;
  });
  builder.addCase(
    getPolicyDetails.fulfilled,
    (state: IQuoteState, { payload: { quoteDTO } }) => {
      state.finishedLoadingPolicyDetails = true;
      state.quoteDTO = quoteDTO;
    }
  );
  builder.addCase(getAdvisorDetails.fulfilled, (state, { payload }) => {
    if (payload) {
      state.advisorEmail = payload;
    }
  });
};
