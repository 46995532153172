import { createSlice } from "@reduxjs/toolkit";

import { Nullable } from "types/general";
import {
  Name,
  Traveler,
  ProcessStatus,
  DateRangeChipType,
} from "types/quote-types";
import {
  PlaceDetails,
  DestinationGoogleData,
  ResidencyAddress,
} from "types/quote-types/Locations";
import { UtmDto } from "types/utm";

import { initialState } from "./initialState";
import { extraReducers } from "./extraRedcuers";

export const quoteSlice = createSlice({
  name: "quote",
  initialState,
  reducers: {
    setName: (
      state,
      { payload: { firstName, lastName } }: { payload: Name }
    ) => {
      state.name = { firstName, lastName };
      state.travellers[0] = {
        ...state.travellers[0]!,
        firstName,
        lastName,
      };
    },

    setDestinationGooglePlaceResults: (
      state,
      {
        payload: destinationGooglePlaceResult,
      }: { payload: DestinationGoogleData[] }
    ) => {
      state.destinationsGoogleData = destinationGooglePlaceResult;
    },

    setDates: (
      state,
      {
        payload: { dates },
      }: {
        payload: {
          dates: {
            tripStartDate: string;
            tripEndDate: string;
          };
        };
      }
    ) => {
      state.tripStartDate = dates.tripStartDate;
      state.tripEndDate = dates.tripEndDate;
    },

    setDestinations: (
      state,
      {
        payload: { destinations },
      }: {
        payload: {
          destinations: PlaceDetails[];
        };
      }
    ) => {
      state.destinations = destinations;
    },

    setDatesAndDestinationAreKnown: (
      state,
      {
        payload,
      }: {
        payload: boolean;
      }
    ) => {
      state.datesAndOrDestinationAreKnown = payload;
    },

    setPersonalInformation: (
      state,
      {
        payload: { dateOfBirth, email, residency },
      }: {
        payload: {
          dateOfBirth: string;
          residency: ResidencyAddress;
          email: string;
        };
      }
    ) => {
      const { firstName, lastName, mainTraveller } = state.travellers[0]!;
      state.dateOfBirth = dateOfBirth;
      state.travellers[0] = {
        ...state.travellers[0]!,
        firstName,
        lastName,
        mainTraveller,
        dateOfBirth,
        email,
      };
      state.email = email;
      state.residency = residency;
    },

    setMainTravelerPersonalInformation: (
      state,
      {
        payload: { dateOfBirth, email },
      }: {
        payload: {
          dateOfBirth: string;
          email: string;
        };
      }
    ) => {
      state.dateOfBirth = dateOfBirth;
      state.travellers[0] = {
        ...state.travellers[0]!,
        dateOfBirth,
        email,
      };
      state.email = email;
    },

    setTravellers: (state, { payload }: { payload: Nullable<Traveler>[] }) => {
      state.travellers = payload;
    },

    updateQuoteCoverageIsSelected: (
      state,
      {
        payload: { isSelected, coverageIndex },
      }: { payload: { isSelected: boolean; coverageIndex: number } }
    ) => {
      state.quoteDTO!.coverages[coverageIndex].isSelected = isSelected;
      state.calculationProcess.status = ProcessStatus.REQUESTED;
    },

    setPaymentErrorMessage: (state, { payload }: { payload: string }) => {
      state.paymentProcess.error = { message: payload };
    },

    setPaymentErrorEmpty: (state) => {
      state.paymentProcess.error = initialState.paymentProcess.error;
    },

    resetPaymentProcessStatus: (state) => {
      state.paymentProcess = initialState.paymentProcess;
    },

    resetSavedQuoteIds: (state) => {
      state.savedQuoteIds = initialState.savedQuoteIds;
    },

    setResidency: (
      state,
      { payload: { residency } }: { payload: { residency: ResidencyAddress } }
    ) => {
      state.residency = residency;
    },

    setAdditionalTravelerInformation: (
      state,
      {
        payload: { dateOfBirth, email, travelerIndex, name },
      }: {
        payload: {
          dateOfBirth: string;
          email: string;
          name: Name;
          travelerIndex: number;
        };
      }
    ) => {
      const { firstName, lastName } = name;
      const newTravelers = [...state.travellers];
      newTravelers[travelerIndex] = {
        dateOfBirth,
        email,
        mainTraveller: false,
        firstName,
        lastName,
        isDriver: Boolean(state.travellers[travelerIndex]?.isDriver),
      };
      state.travellers = newTravelers;
    },

    addEmptyTravelerAtIndex: (
      state,
      { payload: index }: { payload: number }
    ) => {
      const newTravelers = [...state.travellers];
      newTravelers[index] = null;
      state.travellers = newTravelers;
    },

    toggleTravelerAsDriver: (
      state,
      { payload: index }: { payload: number }
    ) => {
      const currentTravelerAtIndex = state.travellers[index]!;
      state.travellers[index]!.isDriver = !currentTravelerAtIndex.isDriver;
      const currentQuoteTravelerAtIndex = state.quoteDTO!.travellers[index];
      state.quoteDTO!.travellers[index]!.isDriver =
        !currentQuoteTravelerAtIndex.isDriver;
      state.calculationProcess.status = ProcessStatus.REQUESTED;
    },
    updateCarDatesDto: (
      state,
      { payload }: { payload: DateRangeChipType[] }
    ) => {
      state.quoteDTO!.carDatesDto = payload
        .filter(({ isOverlapping }: DateRangeChipType) => !isOverlapping)
        .map(({ startDate, endDate }: DateRangeChipType) => ({
          startDate: startDate,
          endDate: endDate,
        }));
      state.calculationProcess.status = ProcessStatus.REQUESTED;
    },
    updateTripCost: (state, { payload }: { payload: number }) => {
      if (state.quoteDTO) {
        state.quoteDTO.tripCost = payload;
        state.calculationProcess.status = ProcessStatus.REQUESTED;
      }
    },
    clearEmptyTravelers: (state) => {
      state.travellers = state.travellers.filter(
        (traveler) => traveler !== null
      );
    },
    setOfferLoaderShown: (state, { payload }: { payload: boolean }) => {
      state.offerLoaderShown = payload;
    },
    setDirectOfferDetailsFromUrlParams: (
      state,
      { payload: { hash, email } }: { payload: { hash: string; email: string } }
    ) => {
      state.hash = hash;
      state.email = email;
      state.isDirectOffer = true;
    },
    setUtmCollection: (state, { payload }: { payload: UtmDto[] }) => {
      state.utms = payload;
    },
  },
  extraReducers,
});

export const {
  setName,
  setResidency,
  setTravellers,
  setPersonalInformation,
  updateQuoteCoverageIsSelected,
  setPaymentErrorEmpty,
  setMainTravelerPersonalInformation,
  setAdditionalTravelerInformation,
  resetPaymentProcessStatus,
  addEmptyTravelerAtIndex,
  toggleTravelerAsDriver,
  updateCarDatesDto,
  clearEmptyTravelers,
  setDestinationGooglePlaceResults,
  setDates,
  setDestinations,
  setDatesAndDestinationAreKnown,
  updateTripCost,
  setOfferLoaderShown,
  setDirectOfferDetailsFromUrlParams,
  setUtmCollection,
  setPaymentErrorMessage,
  resetSavedQuoteIds,
} = quoteSlice.actions;

export default quoteSlice.reducer;
