import {
  GooglePlace,
  GoogleResidencyAddressResult,
  ResidencyAddress,
} from "../types/quote-types";
import { GooglePlaceDetailsResponse, Nullable } from "../types";
import {
  COMPONENT_TYPE_AREA_LEVEL1,
  COMPONENT_TYPE_COUNTRY,
  COMPONENT_TYPE_LOCALITY,
  COMPONENT_TYPE_STREET,
  COMPONENT_TYPE_STREET_NUMBER,
  COMPONENT_TYPE_SUB_LOCALITY,
  COMPONENT_TYPE_ZIP_CODE,
} from "../constants";
import { getDetails } from "use-places-autocomplete";
import { isStreetAddress } from "./Places";

const residencyAddressComponentsInitValue: GoogleResidencyAddressResult = {
  street: "",
  streetNumber: "",
  country: "",
  locality: "",
  zipCode: "",
  areaLevel1: "",
};

export const getResidencyDetailsFromGooglePlace = (
  placeDetailsResponse: GooglePlaceDetailsResponse,
  id: string,
  apartment: string
): ResidencyAddress => {
  const addressComponents =
    placeDetailsResponse.address_components?.reduce(
      (details: GoogleResidencyAddressResult, currentComponent) => {
        const componentTypes = currentComponent.types;
        switch (true) {
          case componentTypes.indexOf(COMPONENT_TYPE_COUNTRY) !== -1:
            details.country = currentComponent.short_name;
            break;
          case componentTypes.indexOf(COMPONENT_TYPE_LOCALITY) !== -1:
            details.locality = currentComponent.short_name;
            break;
          case componentTypes.indexOf(COMPONENT_TYPE_SUB_LOCALITY) !== -1:
            details.locality = currentComponent.short_name;
            break;
          case componentTypes.indexOf(COMPONENT_TYPE_AREA_LEVEL1) !== -1:
            details.areaLevel1 = currentComponent.short_name;
            break;
          case componentTypes.indexOf(COMPONENT_TYPE_ZIP_CODE) !== -1:
            details.zipCode = currentComponent.long_name;
            break;
          case componentTypes.indexOf(COMPONENT_TYPE_STREET) !== -1:
            details.street = currentComponent.long_name;
            break;
          case componentTypes.indexOf(COMPONENT_TYPE_STREET_NUMBER) !== -1:
            details.streetNumber = currentComponent.long_name;
            break;
        }
        return details;
      },
      { ...residencyAddressComponentsInitValue }
    ) ?? residencyAddressComponentsInitValue;

  const { zipCode, country, locality, areaLevel1, street, streetNumber } =
    addressComponents;

  return {
    zipCode,
    country,
    locality,
    areaLevel1,
    streetAndNumber: streetNumber ? `${streetNumber} ${street}`.trim() : "",
    apartment,
    googleId: id,
    label: placeDetailsResponse.formatted_address!,
  };
};

export const getResidencyDetails = async (
  place: GooglePlace,
  apartment: string
): Promise<ResidencyAddress> => {
  const details = await getDetails({
    placeId: place.googleId,
    fields: ["address_components", "formatted_address"],
  });
  const detailsResponse = details as GooglePlaceDetailsResponse;
  return getResidencyDetailsFromGooglePlace(
    detailsResponse,
    place.googleId,
    apartment
  );
};

export const isSelectedResidencyStreetAddress = (
  selectedResidency: Nullable<GooglePlace>
) =>
  Boolean(selectedResidency) &&
  isStreetAddress(selectedResidency as GooglePlace);

export const getGooglePlaceDetailsForRouteResidency = ({
  streetAndNumber,
  locality,
  areaLevel1,
  country,
}: ResidencyAddress): GooglePlace => ({
  googleId: "",
  label: `${streetAndNumber}, ${locality}, ${areaLevel1}, ${country}`,
});
