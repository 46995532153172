import { useState, useCallback, useEffect, useMemo } from "react";
import { AutoCompleteOption } from "../../types/quote-types";
import { Nullable } from "../../types/general";
import { findItemCollection } from "../../utils/collections";

const valueValidation = (value: Nullable<AutoCompleteOption>) => Boolean(value);

const useAutoComplete = (
  initialValue: Nullable<string>,
  optionsCollection: AutoCompleteOption[]
) => {
  const [selectedOption, setSelectedOption] =
    useState<Nullable<AutoCompleteOption>>(null);
  const [valid, setValid] = useState<boolean>();
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const hasError = useMemo(
    () => valid === false && !isFocused,
    [valid, isFocused]
  );

  const validate = useCallback((newValue) => {
    const isValid = valueValidation(newValue);
    setValid(isValid);
  }, []);

  const handleChange = useCallback(
    (event: any, newValue: Nullable<AutoCompleteOption>) => {
      const selectedLocation =
        newValue === null
          ? null
          : findItemCollection(optionsCollection, newValue.value);
      setSelectedOption(selectedLocation);
      validate(selectedLocation);
    },
    [optionsCollection, validate]
  );

  const onFocus = useCallback(() => {
    if (!isFocused) {
      setIsFocused(true);
    }
    if (!valid) {
      setValid(undefined);
    }
  }, [isFocused, valid]);

  const onBlur = useCallback(() => {
    if (isFocused) {
      setIsFocused(false);
    }
  }, [isFocused]);

  useEffect(() => {
    setSelectedOption(
      findItemCollection(optionsCollection, initialValue) ?? null
    );
    if (initialValue) {
      validate(initialValue);
    }
  }, [initialValue, optionsCollection, validate]);

  return {
    value: selectedOption,
    onChange: handleChange,
    valid,
    onFocus,
    onBlur,
    hasError,
  };
};

export default useAutoComplete;
