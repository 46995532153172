import React from "react";

import { useTranslation } from "react-i18next";
import { TRANSLATION } from "i18n";

import DisclaimerLink from "./DisclaimerLink";

import DisclaimerContainer from "./styled";
import {
  CLASS_DISCLAIMER_CONTAINER,
  DisclaimerProps,
  FAQ_URL,
  injectElementsToArray,
  injectElementToParagraph,
  KEY_DISCLAIMER,
  KEY_FAQ_LINK,
  KEY_POLICY_WORDING_LINK,
  POLICY_WORDING_URL_DOMESTIC,
  POLICY_WORDING_URL_INTERNATIONAL,
  StringOrElement,
  TRANSLATION_KEY_PREFIX,
} from "./constants";
import { useAppSelector } from "../../../../../../hooks/redux-hooks";
import { selectIsDomesticTraveling } from "../../../../slice/selectors";

const Disclaimer: React.FC<DisclaimerProps> = () => {
  const isDomestic = useAppSelector(selectIsDomesticTraveling);

  const { t } = useTranslation(TRANSLATION, {
    keyPrefix: TRANSLATION_KEY_PREFIX,
  });

  const paragraphWithPolicyWordingLink: StringOrElement[] =
    injectElementToParagraph({
      sourceString: t(KEY_DISCLAIMER, {
        policy_wording_link_name: t(KEY_POLICY_WORDING_LINK),
        faq_link_name: t(KEY_FAQ_LINK),
      }),
      linkName: t(KEY_POLICY_WORDING_LINK),
      element: (
        <DisclaimerLink
          key={KEY_POLICY_WORDING_LINK}
          name={t(KEY_POLICY_WORDING_LINK)}
          href={
            isDomestic
              ? POLICY_WORDING_URL_DOMESTIC
              : POLICY_WORDING_URL_INTERNATIONAL
          }
        />
      ),
    });

  const paragraphWithLinks = injectElementsToArray({
    sourceArray: paragraphWithPolicyWordingLink,
    linkName: t(KEY_FAQ_LINK),
    element: (
      <DisclaimerLink
        key={KEY_FAQ_LINK}
        name={t(KEY_FAQ_LINK)}
        href={FAQ_URL}
      />
    ),
  });

  return (
    <DisclaimerContainer className={CLASS_DISCLAIMER_CONTAINER}>
      {paragraphWithLinks}
    </DisclaimerContainer>
  );
};

export default Disclaimer;
