import { createAsyncThunk } from "@reduxjs/toolkit";
import { signInWithCustomToken, UserCredential } from "firebase/auth";
import { getAuthInstance } from "../../../../integrations/firebase";
import TokenService from "../../../../utils/TokenService";
import { AppUserAuthData, AppUserData } from "../../../../types/AuthTypes";

const buildAppUserData = ({
  accessToken,
  refreshToken,
  expirationTime,
}: AppUserAuthData): AppUserData => ({
  authData: {
    accessToken,
    refreshToken,
    expirationTime,
  },
});

export const signInUsingCustomToken = createAsyncThunk<AppUserData, string, {}>(
  "authentication/signInUsingCustomToken",
  async (customToken: string) => {
    const auth = getAuthInstance();
    const response: UserCredential = await signInWithCustomToken(
      auth!,
      customToken
    );
    const { refreshToken } = response.user;
    const { expirationTime } = await response.user.getIdTokenResult();
    const accessToken = await response.user.getIdToken();

    const userAppData = buildAppUserData({
      accessToken,
      refreshToken,
      expirationTime: new Date(expirationTime).getTime(),
    });
    TokenService.setUser(userAppData);
    return userAppData;
  }
);
