import { Dict } from "types";
import { UtmDto, UtmTypes } from "types/utm";

const UTM_MEDIUM_ADVISOR_OPTIONS = ["bd-traveladvisors"];

export const UtmTypeToNameDict: Dict<UtmTypes, string> = {
  [UtmTypes.UtmSource]: "utm_source",
  [UtmTypes.UtmMedium]: "utm_medium",
  [UtmTypes.UtmCampaign]: "utm_campaign",
  [UtmTypes.UtmTerm]: "utm_term",
  [UtmTypes.UtmContent]: "utm_content",
};

export const UTM_NAMES_COLLECTION = Object.values(UtmTypeToNameDict);

export const isAdvisorUtms = (utms: UtmDto[]): boolean => {
  const foundMedium = utms.find((utm) => {
    const isMedium = utm.key === UtmTypeToNameDict[UtmTypes.UtmMedium];
    const isValueAdvisors = UTM_MEDIUM_ADVISOR_OPTIONS.includes(utm.value);
    return isMedium && isValueAdvisors;
  });
  const foundAdvisorId = utms.find((utm) => {
    return (
      utm.key === UtmTypeToNameDict[UtmTypes.UtmCampaign] && Boolean(utm.value)
    );
  });

  return Boolean(foundMedium && foundAdvisorId);
};
