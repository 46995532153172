import { useEffect, useState } from "react";

import { useAppSelector } from "hooks/redux-hooks";
import { Nullable } from "types";

import { getGTMInstanceDataLayer } from "tracking/google-tag-manager";

import { selectTotalPrice } from "features/quote/slice/selectors";

const useLogPricingToGTM = () => {
  const [price, setPrice] = useState<Nullable<string>>(null);
  const totalPrice = useAppSelector(selectTotalPrice);

  useEffect(() => {
    const updatedPrice = totalPrice || null;
    if (price !== updatedPrice && updatedPrice !== null) {
      const dataLayer = getGTMInstanceDataLayer();
      dataLayer.push({ price: Number(updatedPrice) });
    }
    setPrice(updatedPrice);
  }, [totalPrice, price]);
};

export default useLogPricingToGTM;
