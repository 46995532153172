import { InputBaseComponentProps, TextFieldProps } from "@mui/material";
import { WithThemeProps } from "types";

export const CLASS_TEXT_FIELD_CONTAINER = "text-field-container";

export type TextFieldWrapperProps = {
  submitCallback?: () => void;
  autoFocus?: boolean;
  topLabel?: string;
};

export const textFieldValueLength = (
  value: unknown,
  inputProps?: InputBaseComponentProps
) => (inputProps?.value ?? (value as string) ?? "").length;

export type TextFieldContainerProps = TextFieldProps &
  WithThemeProps & { hint?: string };
