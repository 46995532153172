import React from "react";
import { DisclaimerLinkProps } from "./constants";

const DisclaimerLink: React.FC<DisclaimerLinkProps> = ({ name, href }) => (
  <a href={href} target="_blank" rel="noreferrer">
    {name}
  </a>
);

export default DisclaimerLink;
