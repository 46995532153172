import React, { useCallback, useMemo } from "react";
import { Typography, Box } from "@mui/material";

import { Nullable } from "types";
import { CoverageType } from "types/quote-types/Coverage";

import { updateQuoteCoverageIsSelected } from "features/quote/slice";
import { selectCoveragesPrices } from "features/quote/slice/selectors";

import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";

import ToggleSwitch from "common-components/controllers/ToggleSwitch";
import CarCollapse from "../../CarCoverageComponents/CarCollapse";

import Price from "./Price";
import { AdditionalCoverageSectionProps } from "./constants";
import AdditionalCoverageContainer from "./styled";

const AdditionalCoverage: React.FC<AdditionalCoverageSectionProps> = ({
  coverage,
  coverageIndex,
}) => {
  const dispatch = useAppDispatch();

  const coveragesPrices = useAppSelector(selectCoveragesPrices);
  const isCarCoverage = coverage.coverageType === CoverageType.car;

  const price: Nullable<string> = useMemo(() => {
    const priceResult = coveragesPrices[coverage.coverageType];
    return priceResult === undefined ? null : Number(priceResult).toFixed(2);
  }, [coverage, coveragesPrices]);

  const description: JSX.Element[] = coverage.description
    .split("\\n")
    .map((part, index) => (
      <React.Fragment key={`text-part-${index}`}>
        {index > 0 && <br />}
        <Typography component="span">{part}</Typography>
      </React.Fragment>
    ));

  const handleCheckedChange = useCallback(() => {
    dispatch(
      updateQuoteCoverageIsSelected({
        coverageIndex,
        isSelected: !coverage.isSelected,
      })
    );
  }, [coverage.isSelected, coverageIndex, dispatch]);

  return (
    <AdditionalCoverageContainer className="additional-coverage">
      <Box className="coverage">
        <Box className="details">
          <Typography component="h3" className="title">
            {coverage.title}
          </Typography>
          <Typography component="p" className="description">
            {description}
          </Typography>
        </Box>
        {price && (
          <Box className="price">
            <Typography className="addition" component="div">
              <Price priceSuffix={isCarCoverage ? "/day" : ""} price={price} />
            </Typography>
            {isCarCoverage && (
              <Typography className="per-unit" component="div">
                per driver
              </Typography>
            )}
          </Box>
        )}
        <ToggleSwitch
          className="toggle"
          checked={coverage.isSelected}
          onChange={handleCheckedChange}
        />
      </Box>
      {isCarCoverage && <CarCollapse isCarAdditionOn={coverage.isSelected} />}
    </AdditionalCoverageContainer>
  );
};

export default AdditionalCoverage;
