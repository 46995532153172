export const TRANSLATION_KEY_PREFIX = "quote.steps.dates";
export const TITLE_TRANSLATION_KEY = "title";
export const TITLE_MULTIPLE_DESTINATIONS_TRANSLATION_KEY =
  "title multiple destinations";
export const SECONDARY_TITLE_TRANSLATION_KEY = "secondary title";
export const FIELD_FROM_TRANSLATION_KEY = "field from";
export const FIELD_UNTIL_TRANSLATION_KEY = "field until";

export const DATE_RANGE_BOXES_CLASS = "date-range-boxes";
export const DATES_CONTAINER_CLASS = "dates-container-class";

export const PAGE_NAME_DATES = "Dates";

export interface DatesProps {}
