import React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";

import { useTranslation } from "react-i18next";
import { TRANSLATION } from "i18n";

import { WithThemeProps } from "types/general";

import { useAppSelector } from "hooks/redux-hooks";
import {
  selectHasTripCancellationCoverage,
  selectIsDomesticTraveling,
} from "features/quote/slice/selectors";
import { parseExpandedDetails } from "utils/coverage-expanded-details";

import {
  BASE_COVERAGE,
  ExpandedDetailsComponentProps,
  NON_MEDICAL_EMERGENCY_EVACUATION,
  TRANSLATION_KEY_PREFIX,
  TRIP_CANCELLATION,
} from "./constants";
import MoreDetailsItemElement from "./MoreDetailsItemElement";

const YourTripExpandedDetailsContainer = styled(
  (props: WithThemeProps & BoxProps) => <Box {...props} />
)``;

const YourTripExpandedDetails: React.FC<ExpandedDetailsComponentProps> = ({
  className,
  coverageName,
}) => {
  const { t } = useTranslation(TRANSLATION, {
    keyPrefix: TRANSLATION_KEY_PREFIX,
  });

  const isDomestic = useAppSelector(selectIsDomesticTraveling);
  const hasTripCancellationCoverage = useAppSelector(
    selectHasTripCancellationCoverage
  );

  if (!coverageName) return null;

  const expandedDetailsItemsBase = parseExpandedDetails(
    t(`${coverageName}.${BASE_COVERAGE}`)
  );
  const expandedDetailsItemsTripCancellation = parseExpandedDetails(
    t(`${coverageName}.${TRIP_CANCELLATION}`)
  );
  const expandedDetailsItemsNonMedicalEmergencyEvacuation =
    parseExpandedDetails(
      t(`${coverageName}.${NON_MEDICAL_EMERGENCY_EVACUATION}`)
    );

  return (
    <YourTripExpandedDetailsContainer className={className}>
      {hasTripCancellationCoverage &&
        expandedDetailsItemsTripCancellation.map((item) => (
          <MoreDetailsItemElement key={item.title} item={item} />
        ))}
      {expandedDetailsItemsBase.map((item) => (
        <MoreDetailsItemElement key={item.title} item={item} />
      ))}
      {!isDomestic &&
        expandedDetailsItemsNonMedicalEmergencyEvacuation.map((item) => (
          <MoreDetailsItemElement key={item.title} item={item} />
        ))}
    </YourTripExpandedDetailsContainer>
  );
};

export default YourTripExpandedDetails;
