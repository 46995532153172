import { useCallback, useEffect, useMemo, useState } from "react";
import { Nullable } from "../../types/general";
import { isPast, isValid, parseISO } from "date-fns";
import { DatePart } from "../../types/quote-types";

type DateParts = { month: string; day: string; year: string };

const isoDateRegex = /(\d{4})-(\d{2})-(\d{2})T.*/;
const regexYearGroupIndex = 1;
const regexMonthGroupIndex = 2;
const regexRegexGroupIndex = 3;

const getDateFromStrings = (dateParts: Nullable<DateParts>): Nullable<Date> =>
  dateParts
    ? new Date(+dateParts.year, +dateParts.month - 1, +dateParts.day)
    : null;

const dateMonthRegex = /^([1-9]|0[1-9]|1[0-2])$/;
const dateDayRegex = /^([1-9]|0[1-9]|1\d|2\d|3[01])$/;
const dateYearRegex = /^(19\d{2}|20\d{2})$/;

const useBirthDateField = (initialValue: Nullable<string>) => {
  const [yearValue, setYearValue] = useState<string>(
    initialValue?.match(isoDateRegex)?.[regexYearGroupIndex] ?? ""
  );
  const [monthValue, setMonthValue] = useState<string>(
    initialValue?.match(isoDateRegex)?.[regexMonthGroupIndex] ?? ""
  );
  const [dayValue, setDayValue] = useState<string>(
    initialValue?.match(isoDateRegex)?.[regexRegexGroupIndex] ?? ""
  );
  const [value, setValue] = useState<Nullable<Date>>(
    initialValue === null ? initialValue : parseISO(initialValue ?? undefined)
  );
  const [valid, setValid] = useState<boolean>();

  const [isMonthFocused, setIsMonthFocused] = useState<boolean>(false);
  const [isDayFocused, setIsDayFocused] = useState<boolean>(false);
  const [isYearFocused, setIsYearFocused] = useState<boolean>(false);

  const isFocused = useMemo(() => {
    return isMonthFocused || isDayFocused || isYearFocused;
  }, [isMonthFocused, isDayFocused, isYearFocused]);

  const hasError = useMemo(() => {
    return valid === false && !isFocused;
  }, [valid, isFocused]);

  const validate = useCallback((dateParts: DateParts) => {
    const isValidMonth = Boolean(dateParts.month.match(dateMonthRegex));
    const isValidDay = Boolean(dateParts.day.match(dateDayRegex));
    const isValidYear = Boolean(dateParts.year.match(dateYearRegex));
    const dateFromParts = getDateFromStrings(dateParts);
    const isValidDate =
      !!dateFromParts && isValid(dateFromParts) && isPast(dateFromParts);
    setValid(isValidMonth && isValidDay && isValidYear && isValidDate);
  }, []);

  const onFieldFocus = useCallback((fieldFocused: DatePart) => {
    switch (fieldFocused) {
      case DatePart.Month:
        setIsMonthFocused(true);
        break;
      case DatePart.Day:
        setIsDayFocused(true);
        break;
      case DatePart.Year:
        setIsYearFocused(true);
        break;
    }
  }, []);

  const onDateFieldBlur = useCallback(
    (fieldBlurred: DatePart) => {
      switch (fieldBlurred) {
        case DatePart.Day:
          setIsDayFocused(false);
          if (dayValue.length === 1) {
            setDayValue(`0${dayValue}`);
          }
          break;
        case DatePart.Month:
          setIsMonthFocused(false);
          if (monthValue.length === 1) {
            setMonthValue(`0${monthValue}`);
          }
          break;
        case DatePart.Year:
          setIsYearFocused(false);
          break;
      }
    },
    [dayValue, monthValue]
  );

  const handleDateFieldChange = useCallback(
    (datePart: DatePart, value: string) => {
      const dateParts: DateParts = {
        month: monthValue,
        day: dayValue,
        year: yearValue,
      };
      switch (datePart) {
        case DatePart.Day:
          dateParts.day = value;
          setDayValue(value);
          break;
        case DatePart.Month:
          dateParts.month = value;
          setMonthValue(value);
          break;
        case DatePart.Year:
          dateParts.year = value;
          setYearValue(value);
          break;
      }
      setValue(getDateFromStrings(dateParts)!);
      validate(dateParts);
    },
    [validate, yearValue, dayValue, monthValue]
  );

  useEffect(() => {
    if (initialValue) {
      const initialValueMatches = initialValue?.match(isoDateRegex);
      if (initialValueMatches) {
        const [, year, month, day] = initialValueMatches!;
        validate({ year, month: month, day });
      }
    }
  }, [validate, initialValue]);

  return {
    value,
    onDateFieldChange: handleDateFieldChange,
    valid,
    focused: true,
    hasError,
    onFieldFocus,
    onDateFieldBlur,
    month: monthValue,
    day: dayValue,
    year: yearValue,
  };
};

export default useBirthDateField;
