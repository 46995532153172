import React from "react";
import { Typography } from "@mui/material";
import classnames from "classnames";
import BubbleContainer from "./styled";
import {
  BubbleProps,
  CLASS_BUBBLE_COMMENT,
  CLASS_BUBBLE_CONTAINER,
  CLASS_BUBBLE_CONTENT,
  CLASS_BUBBLE_TITLE,
} from "./constants";
import Box from "@mui/material/Box";

const Bubble: React.FC<BubbleProps> = ({ title, comment, className = "" }) => {
  return (
    <BubbleContainer className={classnames(CLASS_BUBBLE_CONTAINER, className)}>
      <Box className={CLASS_BUBBLE_CONTENT}>
        <Typography className={CLASS_BUBBLE_TITLE} component={"h3"}>
          {title}
        </Typography>
        {comment && (
          <Typography className={CLASS_BUBBLE_COMMENT}>{comment}</Typography>
        )}
      </Box>
    </BubbleContainer>
  );
};

export default Bubble;
