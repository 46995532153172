import React from "react";
import { css, styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";

import { WithThemeProps } from "types/general";
import FayeTheme, { ThemeType, TypeFayeTheme } from "theme";

import {
  CLASS_FORM_FIELDS_SECTION,
  CLASS_FORM_FIELDS_SECTION_UNITS_WIDTH,
} from "../../../constants";
import {
  CLASS_BLANK,
  CLASS_DETAILS,
  CLASS_DETAILS_FIELDS,
  CLASS_DETAILS_TITLE,
  CLASS_FILL_DETAILS_CONTAINER,
  CLASS_FILL_DETAILS_MESSAGE,
  CLASS_FORM_BUTTONS_CONTAINER,
  CLASS_SECONDARY_TITLE,
  CLASS_STEP_BUTTON,
} from "./constants";
import { isIOS } from "react-device-detect";

const fayeTheme = FayeTheme as TypeFayeTheme;

const fixedButtonsContainer = css`
  position: fixed;
  left: 0;
  bottom: ${fayeTheme.spacing(4)};
`;

const FillDetailsContainer = styled((props: BoxProps & WithThemeProps) => (
  <Box {...props} />
))`
  &.${CLASS_FILL_DETAILS_CONTAINER} {
    height: 100%;
    padding-bottom: ${({ theme }) => theme.spacing(10)};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: ${({ theme }) => theme.spacing(54)};
    margin: 0 auto;

    ${({ theme }: ThemeType) => theme.breakpoints.down("sm")} {
      max-width: ${({ theme }: ThemeType) => theme.spacing(43)};
    }

    & .${CLASS_FORM_FIELDS_SECTION} {
      width: ${({ theme: { spacing } }: ThemeType) =>
        spacing(CLASS_FORM_FIELDS_SECTION_UNITS_WIDTH)};
    }

    & > .${CLASS_DETAILS} {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      & .${CLASS_BLANK} {
        height: ${({ theme }) => theme.spacing(6.25)};
      }

      & > .${CLASS_DETAILS_TITLE} {
        display: flex;
        flex-direction: column;

        & > .${CLASS_FILL_DETAILS_MESSAGE} {
          margin-top: ${({ theme }) => theme.spacing(2.5)};
        }

        & .${CLASS_SECONDARY_TITLE} {
          font-weight: 500;
          font-size: 20px;
          line-height: 28px;
        }
      }

      & > .${CLASS_DETAILS_FIELDS} {
        width: 100%;
        margin-top: ${({ theme: { spacing } }: ThemeType) => spacing(5.5)};
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: ${({ theme: { spacing } }: ThemeType) => spacing(3)};

        & .MuiFormControl-root {
          & > div {
            display: flex;
            justify-content: center;
          }
        }
      }
    }

    & .details-input {
      display: flex;
      flex-direction: column;
      align-items: stretch;

      font-weight: 500;
      font-size: 18px;
      line-height: 28px;

      & .date-range-picker {
        width: 100%;
      }

      & .MuiAutocomplete-root {
        & .MuiOutlinedInput-root {
          padding: 0;
        }

        & .MuiAutocomplete-endAdornment {
          & .MuiButtonBase-root {
            &:last-of-type {
              display: none;
            }
          }
        }
      }

      & .MuiOutlinedInput-root {
        padding: 0;

        & .MuiAutocomplete-input {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }

    & .${CLASS_FORM_BUTTONS_CONTAINER} {
      width: 100%;
      display: flex;
      justify-content: center;
      gap: ${({ theme: { spacing } }: ThemeType) => spacing(2.5)};
      padding: ${({ theme: { spacing } }: ThemeType) => spacing(0, 1)};

      & .${CLASS_STEP_BUTTON} {
        margin-top: ${({ theme: { spacing } }: ThemeType) => spacing(2.5)};
      }

      ${isIOS && fixedButtonsContainer}
    }
  }
`;

export default FillDetailsContainer;
