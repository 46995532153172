import React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";

import { WithThemeProps } from "types";
import { ThemeType } from "theme";
import {
  CLASS_MOBILE_TRIP_COST_CARD,
  SUGGESTION_LABEL,
  TRIP_DETAILS_CARD,
  TRIP_DETAILS_CONTAINER,
} from "./constants";
import {
  SHOW_PANEL_INTERSECTION_TARGET,
  SHOW_PRICE_INTERSECTION_TARGET,
} from "../constants";

const TripDetailsContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box {...props} />
))`
  &.${TRIP_DETAILS_CONTAINER} {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${({ theme: { spacing } }: ThemeType) => spacing(1)};
    margin-top: ${({ theme }) => theme.spacing(12)};
    flex-wrap: wrap;

    & .${TRIP_DETAILS_CARD} {
      justify-content: unset;
      align-items: unset;
    }

    & .${SUGGESTION_LABEL} {
      font-size: 18px;
      font-weight: 500;
      line-height: 177%;
      text-align: center;
    }

    & .${CLASS_MOBILE_TRIP_COST_CARD} {
      min-height: ${({ theme: { spacing } }: ThemeType) => spacing(18)};
    }

    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      & .${SHOW_PANEL_INTERSECTION_TARGET} {
        position: absolute;
        bottom: ${({ theme }) => theme.spacing(100)};
        height: ${({ theme }) => theme.spacing(6)};
        z-index: -1;
      }

      & .${SHOW_PRICE_INTERSECTION_TARGET} {
        position: absolute;
        bottom: ${({ theme }) => theme.spacing(15)};
        height: ${({ theme }) => theme.spacing(6)};
        z-index: -1;
      }
    }

    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      width: 100%;
      margin-top: 0;
      flex-direction: column;
    }
  }
`;

export default TripDetailsContainer;
