export const CLASS_BUBBLE_CONTAINER = "bubble-container";
export const CLASS_BUBBLE_CONTENT = "bubble-content";
export const CLASS_BUBBLE_TITLE = "bubble-title";
export const CLASS_BUBBLE_COMMENT = "bubble-comment";

export interface BubbleProps {
  title: string;
  comment?: string;
  className?: string;
}
