import React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";

import { WithThemeProps } from "types/general";
import { ThemeType } from "theme";

const AdditionalCoverageSectionContainer = styled(
  (props: BoxProps & WithThemeProps) => <Box {...props} />
)`
  &.additional-coverages {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    & .additional-coverages-title,
    .additional-coverages-secondary-title {
      padding: ${({ theme: { spacing } }: ThemeType) => spacing(0, 1)};
      text-align: center;
    }

    & .additional-coverages-secondary-title {
      margin-top: ${({ theme: { spacing } }: ThemeType) => spacing(1.5)};
      font-size: 24px;

      ${({ theme }: ThemeType) => theme.breakpoints.down("sm")} {
        font-size: 16px;
        line-height: 21px;
      }
    }

    & .coverages-container {
      margin-top: ${({ theme }) => theme.spacing(7)};
      display: flex;
      flex-direction: column;
      gap: ${({ theme }) => theme.spacing(1.5)};
      width: ${({ theme }) => theme.spacing(106)};

      ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
        width: 100%;
      }
    }
  }
`;

export default AdditionalCoverageSectionContainer;
