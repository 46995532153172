import {
  format,
  getMonth,
  getYear,
  parseISO,
  addDays,
  getTime,
  getDaysInMonth,
  setHours,
  setMinutes,
  setSeconds,
  setMilliseconds,
  isAfter,
} from "date-fns";
import { Nullable } from "types/general";
import { enUS } from "date-fns/locale";
import { MIN_DATE } from "../constants";

export const MAX_DAYS_FROM_TODAY = 730;
export const MAX_DAYS_FROM_TRIP_START_DATE = 180;

export const US_LOCALE: Locale = {
  ...enUS,
  options: { ...enUS.options, weekStartsOn: 0 },
};

export const dateMonthRegex = /^(0|0[1-9]|[1-9]|1[0-2])?$/;
export const dateDayRegex = /^(0|0[1-9]|[1-9]|1\d|2\d|3[01])?$/;
export const dateYearRegex = /^(1|19|19\d{1,2}|2|20|20\d{1,2})?$/;
export const dateTextRegex = /^\d{2}\/\d{2}\/\d{4}$/;

export const isValid = (value: string, validationRegex: RegExp) => {
  const match = value.match(validationRegex);
  return Boolean(match);
};

export const isDayInMonth =
  (month: string, year: string) => (dayValue: string) => {
    const testYear = year.length === 4 && isYearValid(year) ? +year : 2000;
    const testMonth = month && isMonthValid(month) ? +month - 1 : 0;
    return !(+dayValue > getDaysInMonth(new Date(testYear, testMonth)));
  };

export const isMonthValid = (month: string) => isValid(month, dateMonthRegex);
export const isDayValid =
  (isDayInMonth: (day: string) => boolean) => (day: string) => {
    const isDayRegexOK = isValid(day, dateDayRegex);
    const isDayInMonthOK = isDayInMonth(day);
    return isDayInMonthOK && isDayRegexOK;
  };
export const isYearValid = (year: string) => isValid(year, dateYearRegex);

export const isDateStringValid = (dateText: string) => {
  if (!isValid(dateText, dateTextRegex)) {
    return false;
  } else {
    const [month, day, year] = dateText.split("/");
    const monthValid = isMonthValid(month);
    const yearValid = isYearValid(year);
    const dayValid = isDayValid(isDayInMonth(month, year))(day);
    return monthValid && yearValid && dayValid;
  }
};

export const getDefaultCalendarMonth = (tripStartDateObject: Date) => {
  const month = getMonth(tripStartDateObject);
  const year = getYear(tripStartDateObject);
  return new Date(year, month);
};

export const getDatesRangeString = (
  startDate: Date | undefined,
  endDate: Date | undefined
) => {
  if (!startDate || !endDate) {
    return "";
  } else {
    const startMonth = format(new Date(startDate), "MMM");
    const endMonth = format(new Date(endDate), "MMM");
    const startYear = format(new Date(startDate), "yyyy");
    const endYear = format(new Date(endDate), "yyyy");
    const startDay = format(new Date(startDate), "d");
    const endDay = format(new Date(endDate), "d");
    switch (true) {
      case startYear === endYear && startMonth !== endMonth:
        return `${startMonth} ${startDay} - ${endMonth} ${endDay}, ${endYear}`;
      case startYear === endYear && startMonth === endMonth:
        return `${startMonth} ${startDay} - ${endDay}, ${endYear}`;
      default:
        return `${startMonth} ${startDay}, ${startYear} - ${endMonth} ${endDay}, ${endYear}`;
    }
  }
};

export const rangeInitialValue = (initialValue?: Nullable<string>) =>
  initialValue !== null ? parseISO(initialValue!) : initialValue;

export const getDateMidnight = (date: Date): Date =>
  setHours(setMinutes(setSeconds(setMilliseconds(date, 0), 0), 0), 0);

export const getTodayMidnight = (): Date => getDateMidnight(new Date());

const getMaxEndDateFromToday = () => {
  const todayMidnight: Date = getTodayMidnight();
  return addDays(todayMidnight, MAX_DAYS_FROM_TODAY);
};

/***
 *
 * Example 1:
 * Today         = Day 1   ----> maxEndDateFromToday     = Day 730
 * TripStartDate = Day 201 ----> maxEndDateFromStartDate = Day 380
 * Then Max End Date should be Day 380
 *
 * Example 2:
 * Today         = Day 1   ----> maxEndDateFromToday     = Day 730
 * TripStartDate = Day 701 ----> maxEndDateFromStartDate = Day 880
 * Then Max End Date should be Day 730
 * In both cases the result is min(maxEndDateFromToday, maxEndDateFromStartDate)
 *
 * */
export const getMaxEndDateAccordingToStartDate = (
  startDate: Nullable<Date>
): Date => {
  const maxEndDateFromToday = getMaxEndDateFromToday();
  const maxEndDateFromStartDate = startDate
    ? addDays(startDate, MAX_DAYS_FROM_TRIP_START_DATE)
    : maxEndDateFromToday;

  return new Date(
    Math.min(getTime(maxEndDateFromToday), getTime(maxEndDateFromStartDate))
  );
};

export const getMinDate = (): Date => {
  const minDateMidnight = getDateMidnight(MIN_DATE);
  const todayMidnight = getTodayMidnight();

  return isAfter(minDateMidnight, todayMidnight)
    ? minDateMidnight
    : todayMidnight;
};
