import { createSlice } from "@reduxjs/toolkit";

import { Nullable } from "types/general";

import { TravelersCountOption } from "utils/QuoteTravelers";
import { loadState } from "store/session-persisted-state";

interface ITravelersSlice {
  travelersCountOption: Nullable<TravelersCountOption>;
  currentTravelerIndex: number;
}

const initialState: ITravelersSlice = loadState()?.travelers ?? {
  travelersCountOption: null,
  /* first traveler index */
  currentTravelerIndex: 0,
};

const travelersSlice = createSlice({
  name: "travelers",
  initialState,
  reducers: {
    setCurrentTravelerIndex: (state, { payload }: { payload: number }) => {
      state.currentTravelerIndex = payload;
    },
    setTravelersCountOption: (
      state,
      { payload }: { payload: TravelersCountOption }
    ) => {
      state.travelersCountOption = payload;
    },
  },
});
export const { setTravelersCountOption, setCurrentTravelerIndex } =
  travelersSlice.actions;

export default travelersSlice.reducer;
