export enum CoverageType {
  medicalCoverage = "medicalCoverage",
  tripInterruption = "tripInterruption",
  tripCancellation = "tripCancellation",
  car = "car",
  cancelForAnyReason = "cancelForAnyReason",
  baggageExtraCare = "baggageExtraCare",
  pet = "pet",
}

export type CoverageExpandedDetailsItem = {
  title: string;
  description: string;
};
