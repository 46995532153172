import { Window } from "types";

export const MAPS_LOADED_CALLBACK_NAME = "initGoogleMaps";

const GOOGLE_PLACES_API_KEY = process.env.REACT_APP_GOOGLE_PLACES_API_KEY;
const scriptUrl = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_PLACES_API_KEY}&libraries=places&callback=${MAPS_LOADED_CALLBACK_NAME}`;
const GOOGLE_MAPS_SCRIPT_ID = "googleMaps";

(window as Window)[MAPS_LOADED_CALLBACK_NAME] = () => {};

let googleMapsLoadPromise: Promise<any>;

const loadGoogleMapsMaps = (callback: () => void) => {
  if (!googleMapsLoadPromise) {
    googleMapsLoadPromise = new Promise((resolve) => {
      const existingScript = document.getElementById(GOOGLE_MAPS_SCRIPT_ID);

      if (!existingScript) {
        const script = document.createElement("script");
        script.src = scriptUrl;
        script.id = GOOGLE_MAPS_SCRIPT_ID;
        document.body.appendChild(script);
        script.onload = () => {
          if (callback) callback();
          resolve(true);
        };
      }

      if (existingScript && callback) {
        callback();
        resolve(true);
      }
    });
  }
  return googleMapsLoadPromise;
};
export default loadGoogleMapsMaps;
