import React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";

import { WithThemeProps } from "types/general";
import { ThemeType } from "theme";

const AdditionalCoverageContainer = styled(
  (props: BoxProps & WithThemeProps) => <Box {...props} />
)`
  &.additional-coverage {
    background-color: ${({ theme }) => theme.palette.p_white.full};
    box-shadow: none;
    border-radius: 4px;
    display: flex;
    flex-direction: column;

    & > .coverage {
      height: ${({ theme: { spacing } }) => spacing(15)};
      padding: ${({ theme: { spacing } }) => spacing(0, 5.5)};
      display: grid;
      grid-template-columns: 1fr ${({ theme: { spacing } }) =>
          spacing(13, 7.25)};
      grid-template-rows: 1fr;
      align-items: center;
      grid-template-areas: "details price toggle";

      ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
        height: fit-content;
        padding: ${({ theme: { spacing } }) => spacing(2, 2.75)};
        grid-template-rows: 1fr ${({ theme: { spacing } }) => spacing(5)};
        grid-row-gap: ${({ theme: { spacing } }) => spacing(1.75)};
        grid-template-columns: 1fr ${({ theme: { spacing } }) => spacing(4.5)};
        grid-template-areas:
          "details details"
          "price toggle";

        & > .price {
          justify-self: start;
        }
      }

      & > .details {
        grid-area: details;

        & > .title {
          font-weight: 600;
          font-size: 24px;
          line-height: 30px;
          margin: 0;

          ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
            font-size: 16px;
            line-height: 35px;
          }
        }

        & > .description {
          font-weight: 300;
          font-size: 18px;
          line-height: 20px;

          ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
            font-size: 13px;
            line-height: 19px;
          }

          & * {
            font-size: inherit;
            line-height: inherit;
          }

          max-width: ${({ theme }) => theme.spacing(60.5)};
          color: ${({ theme: { palette } }: ThemeType) =>
            palette.p_grey.textDark};
        }
      }

      & > .price {
        grid-area: price;
        align-self: center;

        display: flex;
        flex-direction: column;
        align-items: center;

        & > .addition {
          font-size: 18px;
          font-weight: 600;
        }

        & > .per-unit {
          font-size: 14px;
          line-height: 20px;

          ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
            font-size: 12px;
          }
        }
      }

      & > .toggle {
        grid-area: toggle;
        align-self: center;
      }
    }
  }
`;

export default AdditionalCoverageContainer;
