import React, { useCallback, useMemo } from "react";
import { useTheme } from "@mui/material";

import { useTranslation } from "react-i18next";
import { TRANSLATION } from "i18n";

import { convertDateToISO } from "utils";
import { rangeInitialValue } from "utils/dates";

import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import useDateRangePicker from "hooks/controllers/useDateRangePicker/useDateRangePicker";
import useNextQuoteStep from "hooks/flow/useNextQuoteStep";
import usePageViewEvent from "hooks/tracking/usePageViewEvent";

import { destinationNameForDisplay } from "utils/destinations";

import FormField from "common-components/display-utils/FormField/FormField";
import FillDetails from "common-components/display-utils/FillDetailsContainer";

import { StepName } from "features/quote/routes/url-map";
import {
  selectDestinationGoogleData,
  selectTripEndDate,
  selectTripStartDate,
} from "features/quote/slice/selectors";
import { setDatesThunk } from "features/quote/slice/thunks/steps";

import DateRangePickerWrapper from "./DateRangePickerWrapper";

import {
  DATES_CONTAINER_CLASS,
  DatesProps,
  FIELD_FROM_TRANSLATION_KEY,
  FIELD_UNTIL_TRANSLATION_KEY,
  PAGE_NAME_DATES,
  SECONDARY_TITLE_TRANSLATION_KEY,
  TITLE_MULTIPLE_DESTINATIONS_TRANSLATION_KEY,
  TITLE_TRANSLATION_KEY,
  TRANSLATION_KEY_PREFIX,
} from "./constants";
import DatesContainer from "./styled";

const Dates: React.FC<DatesProps> = () => {
  usePageViewEvent({ pageName: PAGE_NAME_DATES });

  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(TRANSLATION, {
    keyPrefix: TRANSLATION_KEY_PREFIX,
  });

  const nextQuoteStep: StepName = useNextQuoteStep();
  const startDate = useAppSelector(selectTripStartDate);
  const endDate = useAppSelector(selectTripEndDate);
  const destinationGooglePlaceResult = useAppSelector(
    selectDestinationGoogleData
  );

  const {
    value: dateRange,
    valid: isDateRangeValid,
    onChange: onChangeDateRange,
    onBlur: onDateRangeBlur,
    onFocus: onDateRangeFocus,
    hasError: dateRangeHasError,
    maxEndDate: maxEndDateAccordingToStartDate,
    minStartDate: dateRangeMinStartDate,
    errorType,
  } = useDateRangePicker([
    rangeInitialValue(startDate),
    rangeInitialValue(endDate),
  ]);

  const title = useMemo(
    () =>
      destinationGooglePlaceResult?.length === 1
        ? t(TITLE_TRANSLATION_KEY, {
            destination: destinationNameForDisplay(
              destinationGooglePlaceResult![0]
            ),
          })
        : t(TITLE_MULTIPLE_DESTINATIONS_TRANSLATION_KEY),
    [destinationGooglePlaceResult, t]
  );

  const handleStepsButtonClick = useCallback(async () => {
    if (isDateRangeValid && dateRange[0] && dateRange[1]) {
      dispatch(
        setDatesThunk({
          nextQuoteStep,
          dates: {
            tripStartDate: convertDateToISO(dateRange[0]),
            tripEndDate: convertDateToISO(dateRange[1]),
          },
        })
      );
    }
  }, [dispatch, nextQuoteStep, dateRange, isDateRangeValid]);

  return (
    <DatesContainer className={DATES_CONTAINER_CLASS}>
      <FillDetails
        title={title}
        secondaryTitle={t(SECONDARY_TITLE_TRANSLATION_KEY)}
        onStepsButtonClick={handleStepsButtonClick}
        isStepsButtonDisabled={!isDateRangeValid}
      >
        <FormField
          hasError={dateRangeHasError}
          onBlur={onDateRangeBlur}
          onFocus={onDateRangeFocus}
        >
          <DateRangePickerWrapper
            showError={dateRangeHasError}
            errorType={errorType}
            submitCallback={handleStepsButtonClick}
            desktopModeMediaQuery={theme.breakpoints.up("md")}
            autoFocus={true}
            className="date-range-picker"
            inputFormat="MM/dd/yyyy"
            value={dateRange!}
            onChange={onChangeDateRange}
            startText={t(FIELD_FROM_TRANSLATION_KEY)}
            endText={t(FIELD_UNTIL_TRANSLATION_KEY)}
            maxDate={maxEndDateAccordingToStartDate}
            minDate={dateRangeMinStartDate}
            showToolbar={false}
          />
        </FormField>
      </FillDetails>
    </DatesContainer>
  );
};

export default Dates;
