import React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";

import { WithThemeProps } from "types/general";
import { ThemeType } from "theme";

import {
  CLASS_BUTTON_CHANGE_COST,
  CLASS_PRICE,
  CLASS_ESTIMATED_TRIP_COST,
  CLASSNAME_TRIP_COST_CONTROLLER_CONTAINER,
  CLASSNAME_TRIP_COST_CONTROLLER,
  CLASS_TRIP_COST_DESCRIPTION,
} from "./constants";

const TripCostControllerContainer = styled(
  (props: WithThemeProps & BoxProps) => <Box {...props} />
)`
  &.${CLASSNAME_TRIP_COST_CONTROLLER_CONTAINER} {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: ${({ theme: { spacing } }: ThemeType) => spacing(36)};

    & > .${CLASSNAME_TRIP_COST_CONTROLLER} {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: ${({ theme: { spacing } }: ThemeType) => spacing(4)};
    }

    & .${CLASS_BUTTON_CHANGE_COST} {
      background-color: ${({ theme: { palette } }: ThemeType) =>
        palette.primaryLight};
      color: ${({ theme: { palette } }: ThemeType) => palette.primary.main};
      width: ${({ theme: { spacing } }: ThemeType) => spacing(3)};
      height: ${({ theme: { spacing } }: ThemeType) => spacing(3)};
      min-width: unset;
      display: flex;
      padding: 0;
      box-shadow: none;

      &.Mui-disabled {
        background-color: ${({ theme: { palette } }: ThemeType) =>
          palette.disabled.main};
        color: ${({ theme: { palette } }: ThemeType) =>
          palette.p_grey.textDark};
      }

      & > div {
        font-weight: 600;
        margin: auto;
        font-size: 24px;
        height: 24px;
        line-height: 20px;
      }
    }

    & .${CLASS_ESTIMATED_TRIP_COST} {
      font-weight: 600;
      font-size: 18px;

      ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
        font-size: 16px;
      }
    }

    & > .${CLASS_TRIP_COST_DESCRIPTION} {
      font-size: 14px;
      text-align: center;

      ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
        font-size: 12px;
      }
    }

    & .${CLASS_PRICE} {
      font-weight: 600;
      font-size: 24px;
      line-height: 135%;
    }
  }
`;

export default TripCostControllerContainer;
