import { Nullable } from "../types/general";
import { AppUserAuthData, AppUserData } from "../types/AuthTypes";

class TokenService {
  getLocalRefreshToken() {
    const user = this.getUser();
    return user?.authData?.refreshToken;
  }

  getLocalAccessToken() {
    const user = this.getUser();
    return user?.authData?.accessToken;
  }

  updateLocalAccessToken = ({
    accessToken,
    refreshToken,
    expirationTime,
  }: AppUserAuthData) => {
    let user = this.getUser();
    if (user) {
      user.authData.accessToken = accessToken;
      user.authData.refreshToken = refreshToken;
      user.authData.expirationTime = expirationTime;
    } else {
      user = { authData: { accessToken, refreshToken, expirationTime } };
    }
    localStorage.setItem("user", JSON.stringify(user));
  };

  getUser() {
    return JSON.parse(localStorage.getItem("user")!) as Nullable<AppUserData>;
  }

  setUser(user: AppUserData) {
    localStorage.setItem("user", JSON.stringify(user));
  }
}

export default new TokenService();
