import { styled } from "@mui/system";
import { WithThemeProps } from "../../../../../../../types";
import Collapse, { CollapseProps } from "@mui/material/Collapse";
import React from "react";
import {
  CLASS_CARD_TITLE,
  CLASS_PER_PERSON,
  CARD_MORE_DETAILS_CONTAINER,
  CLASS_ITEMS_CONTAINER,
} from "./constants";
import { ThemeType } from "../../../../../../../theme";

const MoreDetailsContainer = styled((props: WithThemeProps & CollapseProps) => (
  <Collapse {...props} />
))`
  &.${CARD_MORE_DETAILS_CONTAINER} {
    background-color: ${({ theme }: ThemeType) => theme.palette.p_white.full};
    width: ${({ theme: { spacing } }) =>
      `clamp(${spacing(39)}, 30vw, ${spacing(45)})`};

    & .MuiCollapse-wrapper {
      display: flex;

      & .MuiCollapse-wrapperInner {
        margin: ${({ theme: { spacing } }: ThemeType) => spacing(5, 4.5, 3.5)};
        display: flex;
        flex-direction: column;
        gap: ${({ theme: { spacing } }: ThemeType) => spacing(5)};
      }
    }

    & .${CLASS_CARD_TITLE} {
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
    }

    & .${CLASS_PER_PERSON} {
      font-size: 20px;
    }

    & .${CLASS_ITEMS_CONTAINER} {
      display: flex;
      flex-direction: column;
      gap: ${({ theme: { spacing } }: ThemeType) => spacing(3)};
    }
  }
`;

export default MoreDetailsContainer;
