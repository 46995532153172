import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Box, TextFieldProps } from "@mui/material";

import TextFieldWrapper from "../../TextFieldWrapper";
import { DateFieldProps, notDigitRegex } from "./constants";

const DateField: React.FC<TextFieldProps & DateFieldProps> = ({
  length,
  inputRef,
  onFieldFocus,
  placeholder,
  validationCallback,
  previousFieldRef,
  nextFieldRef,
  handleChangeValue,
  className,
  fieldValue,
  datePart,
  onFieldBlur,
  fieldLabel,
  autoFocus = false,
}) => {
  const [canGoBack, setCanGoBack] = useState<boolean>(true);
  const [shouldSkip, setShouldSkip] = useState<boolean>(false);
  const fieldWrapperRef = useRef<HTMLDivElement>();

  const dateFieldID = `date-field-${fieldLabel}`;

  const hasError = useMemo(() => {
    const isValid = validationCallback(fieldValue);
    return !isValid;
  }, [fieldValue, validationCallback]);

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.code === "Backspace" && fieldValue?.length === 0) {
        if (previousFieldRef?.current && canGoBack) {
          previousFieldRef?.current?.focus();
        } else {
          setCanGoBack(true);
        }
      }
    },
    [previousFieldRef, fieldValue, canGoBack]
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const eventValue = event?.target.value
        .slice(0, length)
        .replace(notDigitRegex, "");
      setShouldSkip(eventValue.length === 2);
      handleChangeValue(eventValue);
      if (eventValue.length > 0) {
        setCanGoBack(false);
      }
    },
    [length, handleChangeValue]
  );

  const handleFieldBlur = useCallback(() => {
    setShouldSkip(false);
    onFieldBlur(datePart);
  }, [datePart, onFieldBlur]);

  const handleFieldFocus = useCallback(() => {
    onFieldFocus(datePart);
  }, [datePart, onFieldFocus]);

  useLayoutEffect(() => {
    if (
      shouldSkip &&
      nextFieldRef?.current &&
      validationCallback(fieldValue) &&
      fieldValue.length === length
    ) {
      nextFieldRef.current?.focus();
    }
  }, [shouldSkip, fieldValue, validationCallback, nextFieldRef, length]);

  useLayoutEffect(() => {
    if (autoFocus) {
      (
        inputRef as React.MutableRefObject<HTMLInputElement | undefined>
      )?.current?.focus();
    }
  }, [autoFocus, inputRef]);

  return (
    <Box ref={fieldWrapperRef}>
      <TextFieldWrapper
        id={dateFieldID}
        onBlur={handleFieldBlur}
        onFocus={handleFieldFocus}
        className={className}
        inputRef={inputRef}
        value={fieldValue}
        label={placeholder}
        onChange={handleChange}
        onKeyUp={handleKeyPress}
        error={hasError}
        type="tel"
        topLabel={fieldLabel}
      />
    </Box>
  );
};
export default DateField;
