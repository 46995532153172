import { ThemeType } from "../../../theme";

export const AVATAR_EXTRA_SMALL = "extra-small";
export const AVATAR_SMALL = "small";
export const AVATAR_MEDIUM = "medium";

export type SizeType =
  | typeof AVATAR_EXTRA_SMALL
  | typeof AVATAR_SMALL
  | typeof AVATAR_MEDIUM;

export interface AgentAvatarProps {
  size?: SizeType;
  className?: string;
}

export const getAvatarSize = ({
  size,
  theme: { spacing },
}: ThemeType & { size: SizeType }) =>
  size === AVATAR_EXTRA_SMALL
    ? spacing(3.75)
    : size === AVATAR_SMALL
    ? spacing(6)
    : spacing(6.25);
