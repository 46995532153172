import React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";

import classnames from "classnames";

import { ThemeType } from "theme";
import { WithThemeProps } from "types/general";

import { CLASS_PAYMENT_MODAL_CONTENT } from "../constants";

const FailureModalContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box
    {...props}
    className={classnames(CLASS_PAYMENT_MODAL_CONTENT, props.className)}
  />
))`
  &.payment-failed {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  & > .something-wrong {
    margin-top: ${({ theme: { spacing } }: ThemeType) => spacing(3)};
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
  }

  & > .card-not-charged {
    margin-top: ${({ theme: { spacing } }: ThemeType) => spacing(2)};
    display: flex;
    font-size: 18px;
    line-height: 27px;
  }

  & > .message {
    margin-top: ${({ theme: { spacing } }: ThemeType) => spacing(2)};
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: ${({ theme: { palette } }: ThemeType) => palette.p_grey.text};
    padding: ${({ theme: { spacing } }: ThemeType) => spacing(5.5, 0)};
  }

  & > .button-try-again {
    margin-top: ${({ theme: { spacing } }: ThemeType) => spacing(0.5)};
    width: ${({ theme: { spacing } }: ThemeType) => spacing(38)};
    text-transform: none;
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
    padding: ${({ theme: { spacing } }: ThemeType) => spacing(2, 0)};

    & > .MuiSvgIcon-root {
      margin-right: ${({ theme: { spacing } }: ThemeType) => spacing(1)};
      font-size: ${({ theme: { spacing } }: ThemeType) => spacing(3.5)};
    }

    & > .download-link {
      display: none;
    }
  }
`;

export default FailureModalContainer;
