import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "./styles/index.css";
import "./i18n";

import App from "./App";
import { store } from "store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import setupInterceptors from "./utils/ApiWrapper/SetupInterceptors";
import { ThemeProvider } from "@mui/material/styles";
import FayeTheme from "./theme";
import { ConnectedRouter } from "connected-react-router";
import history from "./utils/history";

const app = (
  <Provider store={store}>
    <ThemeProvider theme={FayeTheme}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </ThemeProvider>
  </Provider>
);

Sentry.init({
  dsn: "https://cd5b4bc47b8d4eb7a96bcae543dffb92@o954259.ingest.sentry.io/6125155",
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [
        "localhost",
        "empanda-dev-core.zennerplatform.com",
        "dev-api.fayehq.com",
        "stg-api.fayehq.com",
        "api.withfaye.com",
      ],
    }),
  ],
  environment: process.env.REACT_APP_BITBUCKET_BRANCH,
  release: "empanda@1.0.2",
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>{app}</React.StrictMode>,
  document.getElementById("root")
);

setupInterceptors(store);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
