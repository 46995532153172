import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
import {
  PaletteOptions as DefaultPaletteOptions,
  Palette as DefaultPalette,
} from "@mui/material/styles";

const primary = "#4726D1";
const secondary = "#E2FBFF";

const PaletteExtension = {
  disabled: {
    main: "#C4C4C4",
    contrastText: "#888888",
  },
  p_grey: {
    background: "#B1ACBF",
    border: "#BFBFBF",
    text: "#787878",
    textDark: "#4F4F4F",
    bubbleMessage: "#E5E5E5",
    light: "#DADADA",
  },
  primaryLight: "#ECD8FF",
  p_white: {
    full: "#FFFFFF",
  },
  p_pink: {
    primary: "#F178B6",
  },
  shadow: {
    main: "#bf7dff",
  },
};

// A custom theme for this app
const FayeTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      tablet: 1024,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    text: {
      primary,
    },
    background: { default: "#F6EDFF" },
    primary: {
      main: primary,
      contrastText: "#FFFFFF",
      light: "#F6EDFF",
      hovered: "#6642FB",
    },
    secondary: {
      main: secondary,
      light: "#CAF0F4",
    },
    error: {
      main: red.A400,
    },
    ...PaletteExtension,
  },
  typography: {
    fontFamily: [
      "TWK Lausanne",
      "utopia-std-display",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

const { palette, ...others } = FayeTheme;

const CustomTheme = {
  ...others,
  palette: {
    ...palette,
    ...PaletteExtension,
  },
};

/**
 * For stopping typescript errors for adding custom fields to Mui Theme
 */
declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    tablet: true; // adds the `tablet` breakpoint
  }

  interface PaletteColor {
    hovered?: string;
  }

  interface SimplePaletteColorOptions {
    hovered?: string;
  }

  interface Palette {
    // someField: Palette["primary"];
    disabled: DefaultPalette["primary"];
    p_grey: {
      background: string;
      border: string;
      text: string;
      textDark: string;
      bubbleMessage: string;
      light: string;
    };
    primaryLight: string;
    p_white: {
      full: string;
    };
    p_pink: {
      primary: string;
    };
    shadow: {
      main: string;
    };
  }

  interface PaletteOptions {
    // someField: PaletteOptions["primary"];
    disabled: DefaultPaletteOptions["primary"];
    p_grey: {
      background: string;
      border: string;
      text: string;
      textDark: string;
      bubbleMessage: string;
      light: string;
    };
    primaryLight: string;
    p_white: {
      full: string;
    };
    p_pink: {
      primary: string;
    };
    shadow: {
      main: string;
    };
  }
}
export type TypeFayeTheme = typeof CustomTheme;
export type ThemeType = { theme: TypeFayeTheme };
export default FayeTheme;
