import React from "react";
import { CLASS_STEP_HINT_MESSAGE, StepHintMessageProps } from "./constants";
import StepHintMessageContainer from "./styled";

const StepHint: React.FC<StepHintMessageProps> = ({ text }) => {
  return (
    <StepHintMessageContainer className={CLASS_STEP_HINT_MESSAGE}>
      {text}
    </StepHintMessageContainer>
  );
};

export default StepHint;
