import React, { useCallback } from "react";
import { ReactComponent as Logo } from "../../../assets/svg/logo.svg";
import { NOT_FOUND_404_URL } from "../../../routes/routes-collection";
import { GATSBY_URL } from "../../../constants";
import { useLocation } from "react-router-dom";
import { Link, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import packageJson from "../../../../package.json";
import HeaderContainer from "./styled";

const Header = () => {
  const location = useLocation();
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleTooltipOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleVersionAlertTriggerClick = useCallback(
    (event: React.MouseEvent) => {
      if (event.detail === 3) {
        handleTooltipOpen();
      }
    },
    [handleTooltipOpen]
  );

  if (location.pathname === NOT_FOUND_404_URL) {
    return null;
  }

  return (
    <HeaderContainer>
      <Box className="header-content">
        <Link href={GATSBY_URL} id="logo-link">
          <Logo />
        </Link>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          title={packageJson.version}
        >
          <Box
            id="version-alert-trigger"
            onClick={handleVersionAlertTriggerClick}
          />
        </Tooltip>
      </Box>
    </HeaderContainer>
  );
};

export default Header;
