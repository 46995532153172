import React, { useCallback, useEffect } from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";

import classnames from "classnames";

import { WithThemeProps } from "types/general";
import { ThemeType } from "theme";

import {
  AndroidBottomSpacingProps,
  CLASS_HELPER_HIDDEN,
  HEIGHT_UNITS_AUTOCOMPLETE_MAX_LINES,
} from "./constants";
import { HEADER_UNITS_HEIGHT_MOBILE } from "../../../../../../constants";

const AndroidInputPlacingHelperEmptySpace = styled(
  (props: WithThemeProps & BoxProps) => <Box {...props} />
)`
  height: ${({ theme }: ThemeType) =>
    theme.spacing(HEIGHT_UNITS_AUTOCOMPLETE_MAX_LINES)};

  &.${CLASS_HELPER_HIDDEN} {
    transition: height 400ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    height: 0;
  }
`;

const AndroidInputPlacingHelper: React.FC<AndroidBottomSpacingProps> = ({
  inputRef,
  inputShouldBeUp,
}) => {
  const scrollToInput = useCallback(() => {
    if (inputRef?.current) {
      const bounding = inputRef?.current?.getBoundingClientRect();
      const yPositionToScroll =
        bounding.top - 20 - HEADER_UNITS_HEIGHT_MOBILE * 8;
      window.scrollTo({
        top: yPositionToScroll,
        behavior: "smooth",
      });
    }
  }, [inputRef]);

  useEffect(() => {
    inputShouldBeUp
      ? scrollToInput()
      : window.scrollTo({
          top: window.innerHeight,
          behavior: "smooth",
        });
  }, [inputShouldBeUp, scrollToInput]);

  return (
    <AndroidInputPlacingHelperEmptySpace
      className={classnames({
        [CLASS_HELPER_HIDDEN]: !inputShouldBeUp,
      })}
    />
  );
};

export default AndroidInputPlacingHelper;
