import { initializeApp, FirebaseOptions, FirebaseApp } from "firebase/app";
import {
  Auth,
  getAuth,
  ActionCodeSettings,
  sendSignInLinkToEmail,
} from "firebase/auth";
import { getAnalytics, Analytics } from "firebase/analytics";
import { Nullable } from "../types/general";

let appInstance: FirebaseApp;
let analytics: Analytics;
let auth: Nullable<Auth>;

const firebaseConfig: FirebaseOptions = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const actionCodeSettings: ActionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  // url: "https://www.example.com/finishSignUp?cartId=1234",
  url: "http://localhost:3000/verify?",
  handleCodeInApp: true,
};

export const getFirebaseInstance = () => {
  if (typeof window !== "undefined") {
    if (appInstance) return appInstance;
    appInstance = initializeApp(firebaseConfig);
    return appInstance;
  }
  return null;
};

export const getAuthInstance = () => {
  if (typeof appInstance !== "undefined") {
    if (auth) return auth;
    auth = getAuth();
    return auth;
  }
  return null;
};

export const getAnalyticsInstance = () => {
  if (typeof appInstance !== "undefined") {
    if (analytics) return analytics;
    analytics = getAnalytics(appInstance);
    return analytics;
  }
  return null;
};

export const sendUserSignInEmail = (email: string) => {
  auth = getAuthInstance();
  if (auth) {
    sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        console.log("SUCCESS");
        window.localStorage.setItem("emailForSignIn", email);
        // ...
      })
      .catch((error) => {
        console.error("FAILURE", error);
      });
  }
};
