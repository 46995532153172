import React, { Dispatch, SetStateAction } from "react";
import { PaymentRequest } from "@stripe/stripe-js/types/stripe-js/payment-request";
import { Nullable } from "../../../../types";

interface IPaymentRequestContext {
  paymentRequestInstance: Nullable<PaymentRequest>;
  setPaymentRequestInstance: Dispatch<SetStateAction<Nullable<PaymentRequest>>>;
}

const PaymentRequestContext = React.createContext<IPaymentRequestContext>({
  paymentRequestInstance: null,
  setPaymentRequestInstance: () => {},
});

export default PaymentRequestContext;
