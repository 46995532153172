import { StepName } from "../../../features/quote/routes/url-map";
import { Dict } from "../../../types/general";

export interface StepsButtonProps {
  disabled?: boolean;
  loading?: boolean;
  translationVariables?: {
    price?: string;
  };
  buttonContentOverrideText?: string;

  onClick?(): void;
}

export type StageNameKey =
  | StepName.Name
  | StepName.Destination
  | StepName.Dates
  | StepName.TravelersCount
  | StepName.SoloTravelerInfo
  | StepName.MainTravelerPersonalInfo
  | StepName.MainTravelerResidency
  | StepName.AdditionalTravelerInfo
  | StepName.Offer;

export const BUTTON_TEXT_BY_STEP: Dict<StageNameKey, string> = {
  [StepName.Name]: "step name",
  [StepName.Destination]: "step destination",
  [StepName.Dates]: "step dates",
  [StepName.TravelersCount]: "step travelers",
  [StepName.SoloTravelerInfo]: "step solo traveler info",
  [StepName.MainTravelerPersonalInfo]: "step main traveler personal info",
  [StepName.MainTravelerResidency]: "step main traveler residency",
  [StepName.AdditionalTravelerInfo]: "step additional traveler info",
  [StepName.Offer]: "step offer",
};

export type WithDisabledColorProp = { disabled: boolean };
