import { GooglePlace } from "types/quote-types/Locations";
import { GooglePlaceDetailsResponse, Nullable } from "types/general";
import { getDetails, Suggestion } from "use-places-autocomplete";
import {
  COMPONENT_TYPE_PREMISE,
  COMPONENT_TYPE_STREET_ADDRESS,
} from "../constants";

export const getGoogleData = async (
  place: GooglePlace
): Promise<GooglePlaceDetailsResponse> => {
  const result = await getDetails({
    placeId: place.googleId,
    fields: ["address_components", "formatted_address", "id"],
  });
  return result as GooglePlaceDetailsResponse;
};

export const isStreetAddress = (selectedPlace: GooglePlace): boolean =>
  selectedPlace?.types
    ? selectedPlace.types.includes(COMPONENT_TYPE_STREET_ADDRESS) ||
      selectedPlace.types.includes(COMPONENT_TYPE_PREMISE)
    : false;

export const googlePlaceCollectionsAreEqual = (
  collectionA: Nullable<GooglePlace>[],
  collectionB: Nullable<GooglePlace>[]
): boolean => {
  let equalResult: boolean;

  if (collectionA.length !== collectionB.length) {
    equalResult = false;
  } else {
    equalResult = collectionA.reduce(
      (acc: boolean, curr: Nullable<GooglePlace>, index: number) =>
        acc && curr?.googleId === collectionB[index]?.googleId,
      true
    );
  }

  return equalResult;
};

export const mapToGooglePlace = ({
  place_id,
  description,
  types,
  terms,
}: Suggestion): GooglePlace => ({
  googleId: place_id,
  label: description,
  types,
  terms,
});
