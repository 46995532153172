import React, { useLayoutEffect, useRef } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

import { parseISO } from "date-fns";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "i18n";

import { useAppSelector } from "hooks/redux-hooks";

import { getDatesRangeString } from "utils/dates";
import { convertDateToISO } from "utils";

import {
  selectDestinationGoogleData,
  selectTravelers,
  selectTripEndDate,
  selectTripStartDate,
} from "features/quote/slice/selectors";

import OfferTripShortDescriptionContainer from "./styled";
import {
  CLASS_CONTAINER,
  CLASS_DATE,
  CLASS_DESTINATION,
  CLASS_DESTINATION_CONTAINER,
  CLASS_FOR_TRAVELLERS,
  CLASS_TRIP_DESCRIPTION,
  FOR_TRAVELLERS_KEY,
  TRANSLATION_KEY_PREFIX,
  TripShortDescriptionProps,
} from "./constants";
import { destinationNameForDisplay } from "../../../utils/destinations";
import { resizeText } from "../../../utils/texts";

const TripShortDescription: React.FC<TripShortDescriptionProps> = () => {
  const { t } = useTranslation(TRANSLATION, {
    keyPrefix: TRANSLATION_KEY_PREFIX,
  });

  const destinationTextRef = useRef<HTMLDivElement>();

  const destinationGooglePlaceResults = useAppSelector(
    selectDestinationGoogleData
  );
  const travellers = useAppSelector(selectTravelers);
  const startDate = useAppSelector(selectTripStartDate);
  const endDate = useAppSelector(selectTripEndDate);
  const destinationName = destinationGooglePlaceResults
    ? destinationGooglePlaceResults
        .map((destinationGooglePlaceResult) =>
          destinationNameForDisplay(destinationGooglePlaceResult)
        )
        .join(", ")
    : "";

  useLayoutEffect(() => {
    resizeText({
      element: destinationTextRef.current as Element,
      maxSize: 36,
      minSize: 24,
    });
  }, []);

  return (
    <OfferTripShortDescriptionContainer className={CLASS_CONTAINER}>
      <Box className={CLASS_TRIP_DESCRIPTION}>
        <Box>
          <Box className={CLASS_DESTINATION_CONTAINER}>
            <Box ref={destinationTextRef} className={CLASS_DESTINATION}>
              {destinationName}
            </Box>
          </Box>
          <Typography component="div" className={CLASS_DATE}>
            {getDatesRangeString(
              parseISO(startDate ?? convertDateToISO(new Date())),
              parseISO(endDate ?? convertDateToISO(new Date()))
            )}
          </Typography>
        </Box>
        <Typography component="div" className={CLASS_FOR_TRAVELLERS}>
          {t(FOR_TRAVELLERS_KEY, { count: travellers.length })}
        </Typography>
      </Box>
    </OfferTripShortDescriptionContainer>
  );
};

export default TripShortDescription;
