import { styled } from "@mui/system";
import { WithThemeProps } from "../../../../../../../../types/general";
import Chip, { ChipProps } from "@mui/material/Chip";
import { ThemeType } from "../../../../../../../../theme";
import React from "react";

const StyledChip = styled(
  ({
    isOverlapping,
    ...props
  }: WithThemeProps & ChipProps & { isOverlapping: boolean }) => (
    <Chip {...props} />
  )
)`
  margin: ${({ theme: { spacing } }: ThemeType) => spacing(0.5)};
  background-color: ${({
    isOverlapping,
    theme: { palette },
  }: ThemeType & { isOverlapping: boolean }) =>
    isOverlapping ? palette.error.main : undefined};
  color: ${({
    isOverlapping,
    theme: { palette },
  }: ThemeType & { isOverlapping: boolean }) =>
    isOverlapping ? palette.p_white.full : undefined};
`;

export default StyledChip;
