import { useCallback, useContext, useEffect } from "react";
import PaymentRequestContext from "../../features/quote/components/contexts/PaymentRequestContext";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { Nullable } from "../../types";
import { CanMakePaymentResult } from "@stripe/stripe-js/types/stripe-js/payment-request";
import {
  PAYMENT_COUNTRY,
  PAYMENT_CURRENCY,
} from "../../features/quote/components/steps/Payment/PaymentForm/CheckoutPaymentRequestButton/constants";
import { useAppSelector } from "../redux-hooks";
import { selectTotalPrice } from "../../features/quote/slice/selectors";
import { PAYMENT_LABEL } from "../../constants";

const usePaymentRequest = () => {
  const { paymentRequestInstance, setPaymentRequestInstance } = useContext(
    PaymentRequestContext
  );

  const totalPrice = useAppSelector(selectTotalPrice);
  const stripe = useStripe();
  const elements = useElements();

  const getPaymentRequestInstance = useCallback(async () => {
    if (!stripe || !elements || Number(totalPrice) === 0) {
      return;
    }

    const paymentRequest = stripe.paymentRequest({
      country: PAYMENT_COUNTRY,
      currency: PAYMENT_CURRENCY,
      requestPayerEmail: true,
      requestPayerName: true,
      total: {
        label: PAYMENT_LABEL,
        // Cents
        amount: Math.floor(Number(totalPrice) * 100),
      },
    });

    // Check the availability of the Payment Request API.
    const canMakePaymentResult: Nullable<CanMakePaymentResult> =
      await paymentRequest.canMakePayment();
    if (canMakePaymentResult) {
      setPaymentRequestInstance(paymentRequest);
    }
  }, [stripe, elements, totalPrice, setPaymentRequestInstance]);

  useEffect(() => {
    void getPaymentRequestInstance().catch();
  }, [getPaymentRequestInstance]);

  return paymentRequestInstance;
};

export default usePaymentRequest;
