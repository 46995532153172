import React, { Dispatch, SetStateAction } from "react";
import { DateRange } from "@mui/lab/DateRangePicker";
import DateRangeChipType from "../../../../../../../../types/quote-types/DateRangeChip";

interface IDateRangeContext {
  range?: DateRange<Date>;
  setRange?: Dispatch<SetStateAction<DateRange<Date>>>;
  chipCollection?: DateRangeChipType[];
  addChip?: (chip: DateRangeChipType) => void;
  onDeleteChip?: (chipIndex: number) => void;
  hasOverlappingRanges?: boolean;
}

const DateRangeContext = React.createContext<IDateRangeContext>({});

export default DateRangeContext;
