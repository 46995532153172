import React, { useCallback, useContext, useEffect } from "react";

import {
  PaymentRequestButtonElement,
  useStripe,
} from "@stripe/react-stripe-js";

import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";

import {
  CheckoutPaymentRequestButtonProps,
  PAYMENT_OPTIONS_DIVIDER,
  PAYMENT_REQUEST_ON_GOT_PAYMENT_METHOD,
  WALLET_PAY_CONTAINER,
} from "./constants";
import CheckoutPaymentRequestButtonContainer from "./styled";
import { Divider } from "@mui/material";
import {
  payWithPaymentMethod,
  saveQuote,
} from "../../../../../slice/thunks/quote";
import PaymentRequestContext from "../../../../contexts/PaymentRequestContext";
import { selectTotalPrice } from "../../../../../slice/selectors";
import {PAYMENT_LABEL} from "../../../../../../../constants";

const CheckoutPaymentRequestButton: React.FC<CheckoutPaymentRequestButtonProps> =
  () => {
    const dispatch = useAppDispatch();
    const stripe = useStripe();
    const totalPrice = useAppSelector(selectTotalPrice);

    const { paymentRequestInstance } = useContext(PaymentRequestContext);

    const generatePaymentRequest = useCallback(() => {
      if (!paymentRequestInstance || !stripe) {
        return;
      }

      paymentRequestInstance.on(
        PAYMENT_REQUEST_ON_GOT_PAYMENT_METHOD,
        async (e) => {
          await dispatch(saveQuote());

          dispatch(
            payWithPaymentMethod({
              paymentRequestPaymentMethodEvent: e,
              stripeInstance: stripe,
            })
          );
        }
      );
    }, [stripe, dispatch, paymentRequestInstance]);

    useEffect(() => {
      generatePaymentRequest();
    }, [generatePaymentRequest]);

    paymentRequestInstance?.update({
      total: {
        amount: Math.floor(Number(totalPrice) * 100),
        label: PAYMENT_LABEL,
      },
    });

    return (
      <CheckoutPaymentRequestButtonContainer className={WALLET_PAY_CONTAINER}>
        {paymentRequestInstance && (
          <>
            <PaymentRequestButtonElement
              options={{ paymentRequest: paymentRequestInstance }}
            />
            <Divider className={PAYMENT_OPTIONS_DIVIDER}>or</Divider>
          </>
        )}
      </CheckoutPaymentRequestButtonContainer>
    );
  };

export default CheckoutPaymentRequestButton;
