import { styled } from "@mui/system";
import { WithThemeProps } from "../../../../../../../types/general";
import Box, { BoxProps } from "@mui/material/Box";
import React from "react";

const MainTravelerResidencyContainer = styled(
  (props: WithThemeProps & BoxProps) => <Box {...props} />
)``;

export default MainTravelerResidencyContainer;
