import { styled } from "@mui/system";
import { WithThemeProps } from "types/general";
import Box, { BoxProps } from "@mui/material/Box";
import React from "react";
import { ThemeType } from "../../../theme";

const CoverageImageContainer = styled(
  (props: WithThemeProps & BoxProps & { src: string }) => <Box {...props} />
)`
  display: flex;
  align-items: flex-end;
  justify-content: center;

  background-image: url("${({ src }) => src}");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0;

  aspect-ratio: 1 / 1;
  min-height: ${({ theme: { spacing } }: ThemeType) => spacing(25)};

  ${({ theme }: ThemeType) => theme.breakpoints.down("tablet")} {
    min-height: ${({ theme: { spacing } }: ThemeType) => spacing(16)};
    background-position-y: center;
  }
`;

export default CoverageImageContainer;
