import React from "react";
import { getDatesRangeString } from "../../../../../../../../utils/dates";
import { parseISO } from "date-fns";
import StyledChip from "./styled";
import { DateRangeChipProps } from "./constants";

const DateRangeChip: React.FC<DateRangeChipProps> = ({
  range,
  chipIndex,
  onDeleteChip,
}) => {
  const handleDelete = () => {
    onDeleteChip(chipIndex);
  };

  return (
    <StyledChip
      isOverlapping={Boolean(range.isOverlapping)}
      label={getDatesRangeString(
        parseISO(range.startDate!),
        parseISO(range.endDate!)
      )}
      variant="outlined"
      onDelete={handleDelete}
    />
  );
};

export default DateRangeChip;
