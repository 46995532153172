import { TypeFayeTheme } from "../../../../../../theme";
import { StripeElementStyle } from "@stripe/stripe-js";

export interface ContentFormProps {}

export const TRANSLATION_KEY_PREFIX = "quote.steps.payment";
export const CARD_NUMBER_KEY = "card number";
export const CARD_EXPIRATION_DATE_KEY = "card expiration date";
export const CARD_CVC_KEY = "card cvc";
export const STRIPE_TOKEN_ERROR = "failure.stripe token error";

export const getPaymentElementStyle = (
  theme: TypeFayeTheme
): StripeElementStyle => ({
  base: {
    fontSize: "14px",
    fontFamily: "TWK Lausanne",
    letterSpacing: "1px",
    fontStyle: "normal",
    fontWeight: 400,
    color: theme.palette.primary.main,
  },
  empty: {
    "::placeholder": {
      fontSize: "14px",
      fontFamily: "TWK Lausanne",
      letterSpacing: "1px",
      fontStyle: "normal",
      fontWeight: 300,
      color: theme.palette.p_grey.border,
    },
  },
});
