import React from "react";
import { styled } from "@mui/system";
import Button, { ButtonProps } from "@mui/material/Button";

import { WithThemeProps } from "types";
import { ThemeType } from "theme";

const FinePrintButtonContainer = styled(
  (props: WithThemeProps & ButtonProps) => (
    <Button {...props} disableRipple={true} />
  )
)`
  &.MuiButton-root {
    width: 100%;
    cursor: pointer;
    font-size: 18px;
    line-height: 23px;
    padding-top: ${({ theme: { spacing } }: ThemeType) => spacing(3)};
    padding-bottom: ${({ theme: { spacing } }: ThemeType) => spacing(2.5)};
    text-transform: none;

    &:hover {
      background-color: unset;
    }
  }
`;

export default FinePrintButtonContainer;
