import React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";

import { WithThemeProps } from "types";
import { ThemeType } from "theme";
import {
  CLASS_ITEM_DESCRIPTION,
  CLASS_ITEM_TITLE,
  CLASS_MORE_DETAILS_ITEM_ELEMENT,
} from "./constants";

const MoreDetailsItemElementContainer = styled(
  (props: WithThemeProps & BoxProps) => <Box {...props} />
)`
  &.${CLASS_MORE_DETAILS_ITEM_ELEMENT} {
    color: ${({ theme: { palette } }: ThemeType) => palette.p_grey.textDark};

    & > .${CLASS_ITEM_TITLE} {
      font-weight: 600;
    }

    & > .${CLASS_ITEM_TITLE}, .${CLASS_ITEM_DESCRIPTION} {
      font-size: 14px;
    }
  }
`;
export default MoreDetailsItemElementContainer;
