import { Nullable } from "../../../../../../../types/general";
import { Traveler } from "../../../../../../../types/quote-types";

export const TRANSLATION_KEY_PREFIX = "quote.steps.personal info";
export const TITLE = "multiple travelers info.title";
export const ASK_EMAIL_PLACEHOLDER = "ask email placeholder optional";
export const FIRST_NAME_PLACEHOLDER_TRANSLATION_KEY = "first name placeholder";
export const LAST_NAME_PLACEHOLDER_TRANSLATION_KEY = "last name placeholder";
export const SECOND_TRAVELER_ORDINAL_KEY = "traveler ordinal.second";
export const THIRD_TRAVELER_ORDINAL_KEY = "traveler ordinal.third";
export const OTHER_TRAVELER_ORDINAL_KEY = "traveler ordinal.other";

export const CLASS_DETAILS_INPUT_NAME = "details-input-name";
export const CLASS_TRAVELER_ORDINAL = "traveler-ordinal";
export const SECTION_FIRST_NAME = "section-first-name";
export const SECTION_LAST_NAME = "section-last-name";
export const SECTION_DATE_OF_BIRTH = "section-dob";
export const SECTION_EMAIL = "section-email";
export const CLASS_ADD_TRAVELLER_BUTTON = "add-traveller-button";

export const TRAVELER_ORDINAL_BY_COUNT = (count: number) =>
  count === 2
    ? SECOND_TRAVELER_ORDINAL_KEY
    : count === 3
    ? THIRD_TRAVELER_ORDINAL_KEY
    : OTHER_TRAVELER_ORDINAL_KEY;

export interface AdditionalTravelerFormProps {
  currentTraveler: Nullable<Traveler>;
  currentTravelerIndex: number;

  onStepsButtonClick({
    firstName,
    lastName,
    selectedDateOfBirth,
    userEmail,
    isDateOfBirthValid,
    isUserEmailValid,
    addTraveler,
  }: {
    firstName: string;
    lastName: string;
    selectedDateOfBirth: Nullable<Date>;
    userEmail: string;
    isDateOfBirthValid?: boolean;
    isUserEmailValid?: boolean;
    addTraveler?: boolean;
  }): void;
}
