import React from "react";

export const HEIGHT_UNITS_AUTOCOMPLETE_MAX_LINES = 32;

export interface AndroidBottomSpacingProps {
  inputRef: React.MutableRefObject<HTMLInputElement | undefined>;
  inputShouldBeUp: boolean;
}

export const CLASS_HELPER_HIDDEN = "helper-hidden";
