import { RootState } from "../../types/redux-types";
import { createSelector } from "@reduxjs/toolkit";
import { selectTravelers } from "../quote/slice/selectors";
import { TravelersCountOption } from "../../utils/QuoteTravelers";

export const selectTravelersCountOption = ({ travelers }: RootState) =>
  travelers.travelersCountOption;
export const selectCurrentTravelerIndex = ({ travelers }: RootState) =>
  travelers.currentTravelerIndex;
export const selectRemainingTravelers = createSelector(
  selectCurrentTravelerIndex,
  selectTravelers,
  (travelerIndex, travelers) => {
    return travelers.length - 1 - travelerIndex;
  }
);
export const selectShouldContinueToNextTraveler = createSelector(
  selectCurrentTravelerIndex,
  selectTravelers,
  selectTravelersCountOption,
  (travelerIndex, travelers, travelersCountOption) => {
    return (
      travelersCountOption === TravelersCountOption.Bunch &&
      (travelerIndex + 1 === 2 ||
        (travelerIndex + 1 >= 3 && Boolean(travelers[travelerIndex + 1])))
    );
  }
);
