import React from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../hooks/redux-hooks";
import { ReactComponent as FailureSign } from "../../../../../../assets/svg/payment-failure.svg";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { useCallback } from "react";
import { setPaymentErrorEmpty } from "../../../../slice";
import { selectQuotePaymentProcess } from "../../../../slice/selectors";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "../../../../../../i18n";
import {
  BUTTON_TEXT_POLICY,
  FailureProps,
  SECONDARY_TITLE_KEY,
  TITLE_KEY,
  TRANSLATION_KEY_PREFIX,
} from "./constants";
import FailureModalContainer from "./styled";

const Failure: React.FC<FailureProps> = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(TRANSLATION, {
    keyPrefix: TRANSLATION_KEY_PREFIX,
  });
  const paymentProcess = useAppSelector(selectQuotePaymentProcess);

  const handleDownloadPolicyClick = useCallback(() => {
    dispatch(setPaymentErrorEmpty());
  }, [dispatch]);

  return (
    <FailureModalContainer className="payment-failed">
      <FailureSign />
      <Typography component="h2" className="something-wrong">
        {t(TITLE_KEY)}
      </Typography>
      <Typography component="div" className="card-not-charged">
        {t(SECONDARY_TITLE_KEY)}
      </Typography>
      <Typography component="p" className="message">
        {paymentProcess?.error?.message}
      </Typography>
      <Button
        className="button-try-again"
        variant="contained"
        onClick={handleDownloadPolicyClick}
      >
        {t(BUTTON_TEXT_POLICY)}
      </Button>
    </FailureModalContainer>
  );
};

export default Failure;
