import React, { useCallback, useEffect } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../hooks/redux-hooks";
import { selectTravelers } from "../../../../slice/selectors";
import { useParams } from "react-router";
import { Traveler } from "../../../../../../types/quote-types";
import { Nullable } from "../../../../../../types/general";
import { setAdditionalTravelerInfoThunk } from "../../../../slice/thunks/steps";
import { StepName } from "../../../../routes/url-map";
import useNextQuoteStep from "../../../../../../hooks/flow/useNextQuoteStep";
import { convertDateToISO } from "../../../../../../utils";
import AdditionalTravelerForm from "./AdditionalTravelerForm";
import { setCurrentTravelerIndex } from "../../../../../travelers/slice";
import {
    CLASS_ADDITIONAL_TRAVELER_CONTAINER, PAGE_NAME_ADDITIONAL_TRAVELER,
    PersonalInformationProps,
} from "./constants";
import AdditionalTravelerContainer from "./styled";
import usePageViewEvent from "../../../../../../hooks/tracking/usePageViewEvent";

const AdditionalTraveler: React.FC<PersonalInformationProps> = () => {
  usePageViewEvent({ pageName: PAGE_NAME_ADDITIONAL_TRAVELER });

  const dispatch = useAppDispatch();
  const { index: travelerIndex } = useParams<{ index: string }>();
  const nextQuoteStep: StepName = useNextQuoteStep();
  const travelers = useAppSelector(selectTravelers);
  const currentTraveler: Nullable<Traveler> = travelers[+travelerIndex];

  const handleStepsButtonClick = useCallback(
    async ({
      firstName,
      lastName,
      selectedDateOfBirth,
      userEmail,
      isDateOfBirthValid,
      isUserEmailValid,
      addTraveler,
    }: {
      firstName: string;
      lastName: string;
      selectedDateOfBirth: Nullable<Date>;
      userEmail: string;
      isDateOfBirthValid?: boolean;
      isUserEmailValid?: boolean;
      addTraveler: boolean;
    }) => {
      if (isDateOfBirthValid && isUserEmailValid) {
        dispatch(
          setAdditionalTravelerInfoThunk({
            name: {
              firstName,
              lastName,
            },
            nextQuoteStep: addTraveler
              ? StepName.AdditionalTravelerInfo
              : nextQuoteStep,
            dateOfBirth: convertDateToISO(selectedDateOfBirth!),
            email: userEmail || "",
            addTraveler,
          })
        );
      }
    },
    [dispatch, nextQuoteStep]
  );

  useEffect(() => {
    dispatch(setCurrentTravelerIndex(+travelerIndex));
  }, [dispatch, travelerIndex]);

  return (
    <AdditionalTravelerContainer
      className={CLASS_ADDITIONAL_TRAVELER_CONTAINER}
    >
      <AdditionalTravelerForm
        key={`traveler-index-${+travelerIndex}-name-${
          currentTraveler?.firstName
        }`}
        currentTraveler={currentTraveler}
        currentTravelerIndex={+travelerIndex}
        onStepsButtonClick={handleStepsButtonClick}
      />
    </AdditionalTravelerContainer>
  );
};

export default AdditionalTraveler;
