import React, { useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import { LocalizationProvider, StaticDateRangePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { TextField, useMediaQuery, useTheme } from "@mui/material";
import { DateRange } from "@mui/lab/DateRangePicker";
import { updateCarDatesDto } from "../../../../../../slice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../hooks/redux-hooks";
import {
  selectTripEndDate,
  selectTripStartDate,
} from "../../../../../../slice/selectors";
import { parseISO } from "date-fns";
import { convertDateToISO } from "../../../../../../../../utils";
import DateRangeContext from "../DateRangeContext/DateRangeContext";
import {
  getDefaultCalendarMonth,
  US_LOCALE,
} from "../../../../../../../../utils/dates";
import classnames from "classnames";
import CarDrivingRangesPickerContainer from "./styled";
import {
  CAR_DRIVING_RANGES_PICKER,
  CAR_DRIVING_RANGES_PICKER_CONTAINER,
  CarDrivingRangesPickerProps,
} from "./constants";

const CarDrivingRangesPicker: React.FC<CarDrivingRangesPickerProps> = ({
  className,
  isCarAdditionOn,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { range, setRange, chipCollection, addChip } =
    useContext(DateRangeContext);
  const dispatch = useAppDispatch();

  const tripStartDate = useAppSelector(selectTripStartDate);
  const tripEndDate = useAppSelector(selectTripEndDate);

  const handleChange = React.useCallback(
    (newValue: DateRange<Date>) => {
      if (newValue[0] && newValue[1]) {
        addChip!({
          startDate: convertDateToISO(newValue[0]!),
          endDate: convertDateToISO(newValue[1]!),
        });
        setRange!([null, null]);
      } else {
        setRange!(newValue);
      }
    },
    [addChip, setRange]
  );

  const updateCarDates = React.useCallback(() => {
    if (isCarAdditionOn) {
      dispatch(updateCarDatesDto(chipCollection!));
    }
  }, [dispatch, chipCollection, isCarAdditionOn]);

  useEffect(() => {
    updateCarDates();
  }, [chipCollection, updateCarDates]);

  return (
    <CarDrivingRangesPickerContainer
      className={classnames(CAR_DRIVING_RANGES_PICKER_CONTAINER, className)}
    >
      <Box>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={US_LOCALE}>
          <StaticDateRangePicker
            reduceAnimations
            className={CAR_DRIVING_RANGES_PICKER}
            calendars={1}
            minDate={parseISO(tripStartDate!)}
            maxDate={parseISO(tripEndDate!)}
            displayStaticWrapperAs={isMobile ? "mobile" : "desktop"}
            showToolbar={false}
            defaultCalendarMonth={getDefaultCalendarMonth(
              parseISO(tripStartDate!)
            )}
            value={range!}
            onChange={handleChange}
            renderInput={(startProps, endProps) => (
              <React.Fragment>
                <TextField {...startProps} />
                <Box sx={{ mx: 2 }}> to </Box>
                <TextField {...endProps} />
              </React.Fragment>
            )}
          />
        </LocalizationProvider>
      </Box>
    </CarDrivingRangesPickerContainer>
  );
};

export default CarDrivingRangesPicker;
