import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import Autocomplete from "@mui/material/Autocomplete";

import classnames from "classnames";

import { useTranslation } from "react-i18next";
import { TRANSLATION } from "i18n";

import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import useNextQuoteStep from "hooks/flow/useNextQuoteStep";
import useInput from "hooks/controllers/useInput";
import useGooglePlacesMuiAutoComplete from "hooks/controllers/useGooglePlacesMuiAutoComplete";
import usePageViewEvent from "hooks/tracking/usePageViewEvent";

import { isNonEmptyText, limitStringLength } from "utils/texts";
import { getGooglePlaceDetailsForRouteResidency } from "utils/residency";

import { selectName, selectResidency } from "features/quote/slice/selectors";
import { setMainTravelerResidencyThunk } from "features/quote/slice/thunks/steps";
import { StepName } from "features/quote/routes/url-map";
import { setCurrentTravelerIndex } from "features/travelers/slice";

import FormField from "common-components/display-utils/FormField/FormField";
import FillDetailsContainer from "common-components/display-utils/FillDetailsContainer";
import TextFieldWrapper from "common-components/controllers/TextFieldWrapper";
import StepTabs from "common-components/indicators/StepTabs";
import ResidencyFields from "../../common-components/ResidencyFields";
import {
  CLASS_RESIDENCY_FIELD_ADDRESS,
  CLASS_RESIDENCY_FIELD_APARTMENT,
} from "../../common-components/ResidencyFields/constants";
import FullAddress from "../../FullAddress";

import { GooglePlace, ResidencyAddress } from "types/quote-types/Locations";
import { Nullable } from "types/general";

import MainTravelerResidencyContainer from "./styled";
import {
  ASK_RESIDENCY_PLACEHOLDER,
  MainTravelerResidencyProps,
  PAGE_NAME_MAIN_TRAVELER_RESIDENCY,
  SECONDARY_TITLE_KEY,
  TITLE_KEY,
  TRANSLATION_KEY_PREFIX,
} from "./constants";
import {
  APARTMENT_STRING_MAXIMUM_CHARACTERS,
  CLASS_FORM_FIELDS_SECTION,
  GOOGLE_PLACES_RESIDENCY_ADDRESS_SEARCH_FILTER,
} from "../../../../../../../constants";
import {
  getResidencyDetails,
  isSelectedResidencyStreetAddress,
} from "../../../../../../../utils/residency";

const MainTravelerResidency: React.FC<MainTravelerResidencyProps> = () => {
  usePageViewEvent({ pageName: PAGE_NAME_MAIN_TRAVELER_RESIDENCY });

  const [mounted, setMounted] = useState<boolean>(false);
  const [fullAddress, setFullAddress] = useState<{
    shown: boolean;
    residencyAddress?: ResidencyAddress;
  }>({
    shown: false,
  });

  const residencyRef = useRef<HTMLInputElement>();
  const dispatch = useAppDispatch();
  const nextQuoteStep: StepName = useNextQuoteStep();
  const { firstName } = useAppSelector(selectName);
  const residency = useAppSelector(selectResidency);

  const { t } = useTranslation(TRANSLATION, {
    keyPrefix: TRANSLATION_KEY_PREFIX,
  });

  const {
    selectedOption: selectedResidency,
    valid: selectedResidencyValid,
    onOptionChange: onChangeSelectedResidency,
    onFocus: onResidencyFocus,
    onBlur: onResidencyBlur,
    onTypedTextChange,
    options: placesCollection,
    isAutoCompleteOpen,
    hasError: residencyHasError,
  } = useGooglePlacesMuiAutoComplete(
    residency?.googleId
      ? [
          {
            googleId: residency.googleId,
            label: residency.label!,
          },
        ]
      : [],
    GOOGLE_PLACES_RESIDENCY_ADDRESS_SEARCH_FILTER,
    false
  );

  const {
    value: apartment,
    onChange: onChangeApartmentText,
    hasError: apartmentHasError,
  } = useInput<string>(
    residency?.apartment ?? "",
    isNonEmptyText,
    limitStringLength(APARTMENT_STRING_MAXIMUM_CHARACTERS)
  );

  const handleStagesButtonClick = useCallback(
    async (fullAddressFormResidency?: ResidencyAddress) => {
      if (selectedResidencyValid) {
        let residencyDetails: ResidencyAddress;

        if (Boolean(fullAddressFormResidency)) {
          residencyDetails = fullAddressFormResidency!;
          residencyDetails = {
            ...residencyDetails,
            ...getGooglePlaceDetailsForRouteResidency(residencyDetails),
          };
        } else if (
          residency &&
          residency?.googleId ===
            (selectedResidency as Nullable<GooglePlace>)?.googleId
        ) {
          residencyDetails = residency;
        } else {
          residencyDetails = await getResidencyDetails(
            (selectedResidency as Nullable<GooglePlace>)!,
            apartment
          );
        }

        const sameResidencyGoogleId =
          residency?.googleId ===
          (selectedResidency as Nullable<GooglePlace>)?.googleId;

        const isAddress =
          Boolean(fullAddressFormResidency) ||
          isSelectedResidencyStreetAddress(
            selectedResidency as Nullable<GooglePlace>
          ) ||
          sameResidencyGoogleId;

        if (isAddress) {
          dispatch(
            setMainTravelerResidencyThunk({
              nextQuoteStep,
              residency: residencyDetails,
            })
          );
        } else {
          setFullAddress(() => ({
            residencyAddress: residencyDetails,
            shown: true,
          }));
        }
      }
    },
    [
      dispatch,
      selectedResidency,
      apartment,
      residency,
      selectedResidencyValid,
      nextQuoteStep,
    ]
  );

  useLayoutEffect(() => {
    if (residencyRef.current && mounted) {
      residencyRef.current?.focus();
    }
  }, [mounted]);

  useEffect(() => {
    dispatch(setCurrentTravelerIndex(0));
  }, [dispatch]);

  useEffect(() => {
    setMounted(true);

    return () => {
      setMounted(false);
    };
  }, []);

  return fullAddress.shown ? (
    <FullAddress
      onClickNext={handleStagesButtonClick}
      residencyAddress={fullAddress.residencyAddress!}
    />
  ) : (
    <MainTravelerResidencyContainer>
      <FillDetailsContainer
        title={`${t(TITLE_KEY)}`}
        secondaryTitle={t(SECONDARY_TITLE_KEY, { name: firstName })}
        subtitleWidget={<StepTabs tabsCount={2} selectedIndex={1} />}
        isStepsButtonDisabled={!selectedResidencyValid}
        onStepsButtonClick={handleStagesButtonClick}
      >
        <ResidencyFields>
          <FormField
            onFocus={onResidencyFocus}
            onBlur={onResidencyBlur}
            hasError={residencyHasError}
            className={classnames(
              CLASS_FORM_FIELDS_SECTION,
              CLASS_RESIDENCY_FIELD_ADDRESS
            )}
          >
            <Autocomplete
              open={isAutoCompleteOpen}
              disablePortal
              fullWidth
              value={(selectedResidency ?? null) as Nullable<GooglePlace>}
              options={placesCollection}
              filterOptions={(suggestions) => suggestions} // suppose to help MUI Autocomplete re-render issue
              onChange={onChangeSelectedResidency}
              isOptionEqualToValue={(option, value) =>
                option.googleId === value.googleId
              }
              renderInput={(params) => (
                <TextFieldWrapper
                  {...params}
                  onChange={onTypedTextChange}
                  inputRef={residencyRef}
                  placeholder={t(ASK_RESIDENCY_PLACEHOLDER)}
                  submitCallback={handleStagesButtonClick}
                />
              )}
            />
          </FormField>
          <FormField
            hasError={apartmentHasError}
            className={CLASS_RESIDENCY_FIELD_APARTMENT}
          >
            <TextFieldWrapper
              submitCallback={handleStagesButtonClick}
              label={"Apt #"}
              value={apartment}
              onChange={onChangeApartmentText}
            />
          </FormField>
        </ResidencyFields>
      </FillDetailsContainer>
    </MainTravelerResidencyContainer>
  );
};

export default MainTravelerResidency;
