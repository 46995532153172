import { Nullable } from "../general";
import { SerializedError } from "@reduxjs/toolkit/dist/createAsyncThunk";

export enum ProcessStatus {
  REQUESTED = "REQUESTED",
  IN_PROCESS = "IN_PROCESS",
  SUCCEED = "SUCCEED",
  ERROR = "ERROR",
  INITIAL_STATUS = "INITIAL_STATUS",
}

export interface CalcQuoteProcesses {
  status: ProcessStatus;
  error: Nullable<Error | SerializedError>;
}

export interface PaymentQuoteProcesses {
  inProcess: Nullable<boolean>;
  success: Nullable<boolean>;
  error: Nullable<{ message: string }>;
}
