import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "types/redux-types";
import { CoverageType } from "types/quote-types";

import { calcTotalPrice } from "utils/Pricings";

import { UNITED_STATES } from "../../../../constants";

export const selectQuoteDTO = (state: RootState) => state.quote.quoteDTO;
export const selectName = (state: RootState) => state.quote.name;
export const selectDateOfBirth = (state: RootState) => state.quote.dateOfBirth;
export const selectTripStartDate = (state: RootState) =>
  state.quote.tripStartDate;
export const selectTripEndDate = (state: RootState) => state.quote.tripEndDate;
export const selectDestinations = (state: RootState) =>
  state.quote.destinations;
export const selectResidency = (state: RootState) => state.quote.residency;
export const selectTravelers = (state: RootState) => state.quote.travellers;
export const selectEmail = (state: RootState) => state.quote.email;
export const selectPricing = (state: RootState) => state.quote.pricing;
export const selectPolicyPdfUrls = (state: RootState) =>
  state.quote.policyPdfUrls;
export const selectPolicyReceiptUrls = (state: RootState) =>
  state.quote.policyReceiptUrls;
export const selectCalculationProcess = (state: RootState) =>
  state.quote.calculationProcess;
export const selectQuotePaymentProcess = (state: RootState) =>
  state.quote.paymentProcess;
export const selectSavedQuoteIds = (state: RootState) =>
  state.quote.savedQuoteIds;
export const selectCoveragesPrices = (state: RootState) =>
  state.quote.coveragesPrices;
export const selectPolicyDetailsFinishedLoading = (state: RootState) =>
  state.quote.finishedLoadingPolicyDetails;
export const selectDatesAndDestinationAreKnown = (state: RootState) =>
  state.quote.datesAndOrDestinationAreKnown;
export const selectDestinationGoogleData = (state: RootState) =>
  state.quote.destinationsGoogleData;
export const selectOfferLoaderShown = (state: RootState) =>
  state.quote.offerLoaderShown;
export const selectQuoteCodeHash = (state: RootState) =>
  state.quote.quoteDTO?.policyCodeHash;
export const selectIsDirectOffer = (state: RootState) =>
  state.quote.isDirectOffer;
export const selectIsTestUser = (state: RootState) => state.quote.isTestUser;
export const selectTripCost = (state: RootState) =>
  state.quote.quoteDTO?.tripCost;

export const selectIsDomesticTraveling = createSelector(
  selectDestinations,
  (destinations) =>
    destinations.reduce((answer, currentDestination) => {
      return (
        answer && currentDestination?.country?.toLowerCase() === UNITED_STATES
      );
    }, true)
);

export const selectHasTripCancellationCoverage = createSelector(
  selectQuoteDTO,
  (quoteDto) =>
    quoteDto?.coverages?.find(
      (coverage) =>
        coverage.coverageType === CoverageType.tripCancellation &&
        coverage.isSelected
    ) !== undefined
);

export const selectTotalPrice = createSelector(selectPricing, (pricing) =>
  calcTotalPrice(pricing)
);

export const selectSavedQuoteId = createSelector(
  selectSavedQuoteIds,
  (savedQuoteIds) => Boolean(savedQuoteIds?.[0])
);
