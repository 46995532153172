import { Dict, Nullable } from "types/general";
import { CoverageType } from "types/quote-types/Coverage";

import MedicalCoverage from "assets/images/coverage-types/MedicalCoverage.png";
import TripCoverage from "assets/images/coverage-types/TripCoverage.png";
import BelongingsCoverage from "assets/images/coverage-types/BelongingsCoverage.png";

export const coverageImages: Dict<CoverageType, Nullable<string>> = {
  [CoverageType.medicalCoverage]: MedicalCoverage,
  [CoverageType.baggageExtraCare]: BelongingsCoverage,
  [CoverageType.tripCancellation]: TripCoverage,
  [CoverageType.tripInterruption]: TripCoverage,
  [CoverageType.car]: null,
  [CoverageType.cancelForAnyReason]: null,
  [CoverageType.pet]: null,
};
