import { IQuoteState } from "./IQuoteState";
import { ProcessStatus } from "types/quote-types";

import { loadState } from "../../../store/session-persisted-state";
import { STEP_TO_URL_MAP, StepName } from "../routes/url-map";
import { QUOTE_URL } from "../../../routes/routes-collection";

const startedFromOfferPage =
  window.location.pathname === `${QUOTE_URL}${STEP_TO_URL_MAP[StepName.Offer]}`;

export const initialState: IQuoteState = loadState()?.quote ?? {
  name: {
    firstName: "",
    lastName: "",
  },
  dateOfBirth: null,
  effectiveStartDate: new Date().toISOString(),
  effectiveEndDate: new Date().toISOString(),
  tripStartDate: null,
  tripEndDate: null,
  destinations: [],
  residency: null,
  travellers: [
    {
      firstName: "",
      lastName: "",
      mainTraveller: true,
      dateOfBirth: "",
      email: "",
      isDriver: false,
    },
  ],
  isDirectOffer: false,
  email: "",
  hash: "",
  quoteDTO: null,
  pricing: [],
  calculationProcess: {
    status: ProcessStatus.INITIAL_STATUS,
    error: null,
  },
  paymentProcess: {
    inProcess: null,
    success: null,
    error: null,
  },
  coveragesPrices: {},
  datesAndOrDestinationAreKnown: false,
  destinationsGoogleData: undefined,
  getQuoteProcessStatus: ProcessStatus.INITIAL_STATUS,
  savedQuoteIds: [],
  offerLoaderShown: false,
  utms: [],
  isTestUser: false,
};

initialState.offerLoaderShown = startedFromOfferPage;
initialState.getQuoteProcessStatus = ProcessStatus.INITIAL_STATUS;
initialState.paymentProcess = {
  inProcess: null,
  success: null,
  error: null,
};
initialState.savedQuoteIds = [];
