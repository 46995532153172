import React from "react";
import { styled } from "@mui/system";
import AppBar, { AppBarProps } from "@mui/material/AppBar";

import { WithThemeProps } from "types/general";
import { ThemeType } from "theme";
import {
  HEADER_UNITS_HEIGHT_DESKTOP,
  HEADER_UNITS_HEIGHT_MOBILE,
} from "../../../constants";

const HeaderContainer = styled((props: AppBarProps & WithThemeProps) => (
  <AppBar {...props} />
))`
  width: 100vw;
  height: ${({ theme }) => theme.spacing(HEADER_UNITS_HEIGHT_DESKTOP)};
  background-color: ${({ theme }: ThemeType) =>
    theme.palette.background.default};
  box-shadow: none;
  left: 0;
  right: 0;

  ${({ theme }: ThemeType) => theme.breakpoints.down("sm")} {
    height: ${({ theme }) => theme.spacing(HEADER_UNITS_HEIGHT_MOBILE)};
  }

  & > .header-content {
    padding-top: ${({ theme }) => theme.spacing(2.25)};
    padding-right: ${({ theme: { spacing } }) =>
      `clamp(${spacing(2)}, 5vw, ${spacing(10)})`};
    padding-left: ${({ theme: { spacing } }) =>
      `clamp(${spacing(2)}, 5vw, ${spacing(10)})`};

    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    max-width: ${({ theme }) => theme.spacing(180)};
    margin: 0 auto;

    & > #logo-link {
      height: ${({ theme }) => theme.spacing(5.5)};
      width: ${({ theme }) => theme.spacing(11.25)};

      ${({ theme }: ThemeType) => theme.breakpoints.down("sm")} {
        height: ${({ theme }) => theme.spacing(4.5)};
        width: ${({ theme }) => theme.spacing(9.25)};
      }

      & svg {
        height: 100%;
        width: 100%;
      }
    }

    & > #version-alert-trigger {
      height: 100%;
      width: ${({ theme }) => theme.spacing(10)};
    }
  }
`;

export default HeaderContainer;
