import React from "react";
import { styled } from "@mui/system";
import { Button, ButtonProps } from "@mui/material";

import { WithThemeProps } from "types/general";
import { ThemeType } from "theme";

import { WithDisabledColorProp } from "./constants";

const ButtonContainer = styled(
  (props: ButtonProps & WithThemeProps & WithDisabledColorProp) => (
    <Button disableRipple={true} {...props} />
  )
)`
  height: ${({ theme }) => theme.spacing(6)};
  width: ${({ theme }) => theme.spacing(25)};
  text-transform: none;
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  background-color: ${({
    theme,
    disabled,
  }: ThemeType & WithDisabledColorProp) =>
    disabled ? theme.palette.disabled.main : undefined};
  box-shadow: none;

  &:hover {
    box-shadow: none;
    background-color: ${({
      theme: { palette },
      disabled,
    }: ThemeType & WithDisabledColorProp) =>
      disabled ? palette.disabled.main : palette.primary.hovered};
  }
`;
export default ButtonContainer;
