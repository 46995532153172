export enum UtmTypes {
  UtmSource = "UtmSource",
  UtmMedium = "UtmMedium",
  UtmCampaign = "UtmCampaign",
  UtmTerm = "UtmTerm",
  UtmContent = "UtmContent",
}

export interface UtmDto {
  key: string;
  value: string;
}
