import React, { useCallback, useLayoutEffect, useRef } from "react";

import { isAndroid, isIOS } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "i18n";

import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import useInput from "hooks/controllers/useInput";
import useNextQuoteStep from "hooks/flow/useNextQuoteStep";
import usePageViewEvent from "hooks/tracking/usePageViewEvent";

import FillDetails from "common-components/display-utils/FillDetailsContainer";
import FormField from "common-components/display-utils/FormField/FormField";
import TextFieldWrapper from "common-components/controllers/TextFieldWrapper";
import { capitalize, isNameValid } from "utils/texts";

import {
  selectDatesAndDestinationAreKnown,
  selectName,
} from "features/quote/slice/selectors";
import { StepName } from "features/quote/routes/url-map";
import { setNameThunk } from "features/quote/slice/thunks/steps";

import {
  CLASS_NAME_STEP_CONTAINER,
  FIRST_NAME_PLACEHOLDER_TRANSLATION_KEY,
  LAST_NAME_PLACEHOLDER_TRANSLATION_KEY,
  TITLE_TRANSLATION_KEY,
  TITLE_DESTINATION_AND_DATES_KNOWN_TRANSLATION_KEY,
  TRANSLATION_KEY_PREFIX,
  CLASS_DETAILS_INPUT_NAME,
  PAGE_NAME_FILL_NAME,
} from "./constants";
import Container from "./styled";

const Name = () => {
  usePageViewEvent({ pageName: PAGE_NAME_FILL_NAME });

  const firstNameRef = useRef<HTMLInputElement>();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(TRANSLATION, {
    keyPrefix: TRANSLATION_KEY_PREFIX,
  });
  const nextQuoteStep: StepName = useNextQuoteStep();

  const { firstName: first, lastName: last } = useAppSelector(selectName);
  const datesAndOrDestinationAreKnown = useAppSelector(
    selectDatesAndDestinationAreKnown
  );

  const {
    value: firstName,
    onChange: onChangeFirst,
    valid: isFirstNameValid,
    onFocus: onFirstNameFocus,
    onBlur: onFirstNameBlur,
    hasError: firstNameHasError,
  } = useInput<string>(first, isNameValid, capitalize);
  const {
    value: lastName,
    onChange: onChangeLast,
    valid: isLastNameValid,
    onFocus: onLastNameFocus,
    onBlur: onLastNameBlur,
    hasError: lastNameHasError,
  } = useInput<string>(last, isNameValid, capitalize);

  const handleStepsButtonClick = useCallback(() => {
    if (isFirstNameValid && isLastNameValid) {
      dispatch(
        setNameThunk({
          firstName: firstName as string,
          lastName: lastName as string,
          nextQuoteStep,
        })
      );
    }
  }, [
    dispatch,
    firstName,
    lastName,
    isFirstNameValid,
    isLastNameValid,
    nextQuoteStep,
  ]);

  useLayoutEffect(() => {
    if (!(isAndroid || isIOS) && firstNameRef.current) {
      firstNameRef.current?.focus();
    }
  }, []);

  return (
    <Container className={CLASS_NAME_STEP_CONTAINER}>
      <FillDetails
        title={t(
          datesAndOrDestinationAreKnown
            ? TITLE_DESTINATION_AND_DATES_KNOWN_TRANSLATION_KEY
            : TITLE_TRANSLATION_KEY
        )}
        onStepsButtonClick={handleStepsButtonClick}
        isStepsButtonDisabled={!isFirstNameValid || !isLastNameValid}
      >
        <FormField
          className={CLASS_DETAILS_INPUT_NAME}
          onFocus={onFirstNameFocus}
          onBlur={onFirstNameBlur}
          hasError={firstNameHasError}
        >
          <TextFieldWrapper
            inputRef={firstNameRef}
            label={t(FIRST_NAME_PLACEHOLDER_TRANSLATION_KEY)}
            value={firstName}
            onChange={onChangeFirst}
            submitCallback={handleStepsButtonClick}
          />
        </FormField>
        <FormField
          className={CLASS_DETAILS_INPUT_NAME}
          onFocus={onLastNameFocus}
          onBlur={onLastNameBlur}
          hasError={lastNameHasError}
        >
          <TextFieldWrapper
            label={t(LAST_NAME_PLACEHOLDER_TRANSLATION_KEY)}
            value={lastName}
            onChange={onChangeLast}
            submitCallback={handleStepsButtonClick}
          />
        </FormField>
      </FillDetails>
    </Container>
  );
};

export default Name;
