import React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";

import { WithThemeProps } from "types";
import { ThemeType } from "theme";

import {
  CLASS_BUTTON_BACK_TO_HOMEPAGE,
  CLASS_CONTAINER,
  CLASS_NOTIFY_ME,
  CLASS_SECONDARY_MESSAGE,
  CLASS_TITLE,
} from "./constants";
import {
  HEADER_UNITS_HEIGHT_DESKTOP,
  HEADER_UNITS_HEIGHT_MOBILE,
} from "../../../constants";

export const UncheckedIcon = styled((props: WithThemeProps & BoxProps) => (
  <Box {...props} />
))`
  background-color: ${({ theme }: ThemeType) => theme.palette.p_white.full};
  border: ${({ theme }: ThemeType) =>
    `${theme.spacing(0.125)} solid ${theme.palette.p_grey.border}`};
  height: ${({ theme: { spacing } }: ThemeType) => spacing(2.75)};
  width: ${({ theme: { spacing } }: ThemeType) => spacing(2.75)};
  border-radius: ${({ theme: { spacing } }: ThemeType) => spacing(0.25)};
`;
const ComingSoonContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box {...props} />
))`
  &.${CLASS_CONTAINER} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${({ theme: { spacing } }: ThemeType) => spacing(6)};
    transform: translateY(
      ${({ theme: { spacing } }: ThemeType) =>
        spacing(-HEADER_UNITS_HEIGHT_DESKTOP)}
    );

    ${({ theme }: ThemeType) => theme.breakpoints.down("sm")} {
      transform: translateY(
        ${({ theme: { spacing } }: ThemeType) =>
          spacing(-HEADER_UNITS_HEIGHT_MOBILE)}
      );
    }

    & > .${CLASS_TITLE} {
      font-size: 50px;
      line-height: 58px;
      font-family: "utopia-std-display";
    }

    & > .${CLASS_SECONDARY_MESSAGE} {
      color: ${({ theme: { palette } }: ThemeType) => palette.p_grey.textDark};
      font-size: 18px;
      line-height: 125.5%;
    }

    & > .${CLASS_NOTIFY_ME} {
      user-select: none;
      margin: 0;
      padding: ${({ theme: { spacing } }: ThemeType) => spacing(2, 2.5)};
      padding-right: clamp(
        ${({ theme: { spacing } }: ThemeType) =>
          `${spacing(3)}, 30vw, ${spacing(18)}`}
      );
      background-color: ${({ theme }: ThemeType) => theme.palette.p_white.full};
      border: ${({ theme }: ThemeType) =>
        `${theme.spacing(0.125)} solid ${theme.palette.p_grey.border}`};
      border-radius: ${({ theme: { spacing } }: ThemeType) => spacing(0.5)};

      & .MuiCheckbox-root {
        &:not(.Mui-checked) {
          padding: ${({ theme: { spacing } }: ThemeType) => spacing(0.5)};
        }

        padding: 0;
      }

      & .MuiSvgIcon-root {
        border-radius: ${({ theme: { spacing } }: ThemeType) => spacing(0.25)};
        height: ${({ theme: { spacing } }: ThemeType) => spacing(3.75)};
        width: ${({ theme: { spacing } }: ThemeType) => spacing(3.75)};
      }

      & > .MuiFormControlLabel-label {
        margin-left: ${({ theme: { spacing } }: ThemeType) => spacing(1)};
        color: ${({ theme: { palette } }: ThemeType) => palette.p_grey.text};
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
      }
    }

    & > .${CLASS_BUTTON_BACK_TO_HOMEPAGE} {
      text-transform: none;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      width: ${({ theme: { spacing } }: ThemeType) => spacing(31.5)};
      height: ${({ theme: { spacing } }: ThemeType) => spacing(6)};
    }
  }
`;
export default ComingSoonContainer;
