import { CssFontSource, CustomFontSource, Stripe } from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { Nullable } from "../../../../../../types";

export interface StripeElementsContainerProps {}

let stripeInstance: Promise<Nullable<Stripe>>;

export const getStripeInstance = () => {
  if (!stripeInstance) {
    stripeInstance = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);
  }
  return stripeInstance;
};

export const fonts: Array<CssFontSource | CustomFontSource> = [
  {
    family: "TWK Lausanne",
    src: "url(https://assets.withfaye.com/fonts/TWKLausanne/TWKLausanne-400.woff)",
    weight: "400",
  },
  {
    family: "TWK Lausanne",
    src: "url(https://assets.withfaye.com/fonts/TWKLausanne/TWKLausanne-300.woff)",
    weight: "300",
  },
];
