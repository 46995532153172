import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { LocalizationProvider } from "@mui/lab";
import DateRangePicker, {
  DateRangePickerProps,
} from "@mui/lab/DateRangePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Box from "@mui/material/Box";
import { Popper } from "@mui/material";

import classnames from "classnames";

import { useTranslation } from "react-i18next";
import { TRANSLATION } from "i18n";

import TextFieldWrapper from "common-components/controllers/TextFieldWrapper";
import ErrorMessage from "common-components/indicators/ErrorMessage";
import { CLASS_INVALID_DATE_ERROR_MESSAGE } from "common-components/controllers/FayeBirthDateField/constants";

import {
  MAX_DAYS_FROM_TODAY,
  MAX_DAYS_FROM_TRIP_START_DATE,
  US_LOCALE,
} from "utils/dates";
import {
  DateRangeError,
  DateRangeErrorDictionaryKeyByType,
  TRANSLATION_KEY_PREFIX,
} from "hooks/controllers/useDateRangePicker/constants";

const DATE_RANGE_BOXES_CLASS = "date-range-boxes";

enum MountPhases {
  UNMOUNTED = "UNMOUNTED",
  MOUNTED = "MOUNTED",
  MOUNTED_AND_MODIFIED = "MOUNTED_AND_MODIFIED",
}

const DateRangePickerWrapper = React.forwardRef<
  HTMLDivElement,
  Omit<DateRangePickerProps<Date>, "renderInput"> & {
    submitCallback: () => void;
    errorType: DateRangeError;
    showError: boolean;
  }
>(
  (
    {
      errorType,
      showError,
      submitCallback,
      className,
      desktopModeMediaQuery,
      value,
      onChange,
      startText,
      endText,
      maxDate,
      minDate,
      showToolbar,
    },
    ref
  ) => {
    const { t } = useTranslation(TRANSLATION, {
      keyPrefix: TRANSLATION_KEY_PREFIX,
    });

    const refStartDate = useRef<HTMLInputElement>();
    const refTextFieldsContainer = React.useRef<HTMLDivElement>();

    const [mounted, setMounted] = useState<MountPhases>(MountPhases.UNMOUNTED);

    const [openedByClick, setOpenedByClick] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const [rangeSelected, setRangeSelected] = useState<boolean>(false);
    const [isTextFieldFocused, setIsTextFieldFocused] =
      useState<boolean>(false);

    const handleRangePickerAccept = useCallback(() => {
      setRangeSelected(true);
    }, []);
    const handleRangePickerClose = useCallback(() => {
      setRangeSelected(false);
      if (!isTextFieldFocused) {
        setOpen(false);
        setOpenedByClick(false);
      }
    }, [isTextFieldFocused]);
    const handleRangePickerTryOpen = useCallback(() => {
      if (
        !rangeSelected &&
        openedByClick &&
        mounted === MountPhases.MOUNTED_AND_MODIFIED
      ) {
        setOpen(true);
      }
    }, [rangeSelected, mounted, openedByClick]);

    const handleTextFieldFocus = useCallback(() => {
      if (mounted === MountPhases.MOUNTED_AND_MODIFIED) {
        setIsTextFieldFocused(true);
      }
    }, [mounted]);
    const handleTextFieldBlur = useCallback(() => {
      setIsTextFieldFocused(false);
    }, []);
    const handleTextFieldClick = useCallback(() => {
      setOpen(true);
      setOpenedByClick(true);
    }, []);

    useLayoutEffect(() => {
      if (mounted === MountPhases.MOUNTED) {
        if (refStartDate.current) refStartDate.current?.focus();
        setMounted(MountPhases.MOUNTED_AND_MODIFIED);
      }
    }, [mounted]);

    useEffect(() => {
      setMounted(MountPhases.MOUNTED);

      return () => {
        setMounted(MountPhases.UNMOUNTED);
      };
    }, []);

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={US_LOCALE}>
        <DateRangePicker
          showToolbar={showToolbar}
          maxDate={maxDate}
          minDate={minDate}
          ref={ref}
          disablePast
          className={className}
          desktopModeMediaQuery={desktopModeMediaQuery}
          open={open}
          onAccept={handleRangePickerAccept}
          onClose={handleRangePickerClose}
          onOpen={handleRangePickerTryOpen}
          value={value}
          onChange={onChange}
          PopperProps={{
            modifiers: [
              {
                name: "flip",
                enabled: false,
                options: {
                  altBoundary: true,
                  rootBoundary: "document",
                  padding: 8,
                },
              },
            ],
          }}
          renderInput={(startProps, endProps) => (
            <Box
              className={DATE_RANGE_BOXES_CLASS}
              ref={refTextFieldsContainer}
            >
              <TextFieldWrapper
                {...startProps}
                submitCallback={submitCallback}
                inputRef={refStartDate}
                onFocus={handleTextFieldFocus}
                onBlur={handleTextFieldBlur}
                onClick={handleTextFieldClick}
                autoComplete="off"
                value={
                  startProps.inputProps?.value
                    ? startProps.inputProps?.value
                    : startProps.focused &&
                      (startProps.inputProps?.value || " ")
                }
                label=""
                topLabel={startText as string}
              />
              <TextFieldWrapper
                {...endProps}
                submitCallback={submitCallback}
                onFocus={handleTextFieldFocus}
                onBlur={handleTextFieldBlur}
                onClick={handleTextFieldClick}
                autoComplete="off"
                value={
                  endProps.inputProps?.value
                    ? endProps.inputProps?.value
                    : endProps.focused && (endProps.inputProps?.value || " ")
                }
                label=""
                topLabel={endText as string}
              />
              <Popper
                open={showError}
                anchorEl={refTextFieldsContainer.current}
              >
                <ErrorMessage
                  className={classnames(
                    CLASS_INVALID_DATE_ERROR_MESSAGE,
                    "Mui-error"
                  )}
                >
                  {t(DateRangeErrorDictionaryKeyByType[errorType], {
                    maxDate: MAX_DAYS_FROM_TODAY,
                    maxDuration: MAX_DAYS_FROM_TRIP_START_DATE,
                  })}
                </ErrorMessage>
              </Popper>
            </Box>
          )}
        />
      </LocalizationProvider>
    );
  }
);

export default DateRangePickerWrapper;
