import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "../../../../../../../i18n";
import StepMessage from "../../../../../../../common-components/display-utils/StepMessage";
import BaseCoverage from "../BaseCoverage";
import { useAppSelector } from "../../../../../../../hooks/redux-hooks";
import { selectQuoteDTO } from "../../../../../slice/selectors";
import {
  getCoveragesArrayByIndexes,
  nonDefaultCoveragesFilter,
} from "utils/coverage-collections";
import { Coverage } from "types/quote-types/quote.dto";
import { Collapse, Typography, useMediaQuery, useTheme } from "@mui/material";
import BaseCoverageCarousel from "../BaseCoverageCarousel";
import {
  BaseCoverageSectionProps,
  SECONDARY_TITLE_KEY,
  TITLE_KEY,
  TRANSLATION_KEY_PREFIX,
} from "./constants";
import BaseCoverageSectionContainer from "./styled";
import ShowBaseCoverageExpandedDetails from "../../ShowBaseCoverageExpandedDetails";
import CardMoreDetails from "../CardMoreDetails";
import Disclaimer from "../Disclaimer";

const BaseCoverageSection: React.FC<BaseCoverageSectionProps> = () => {
  const theme = useTheme();
  const isTabletAndBelow = useMediaQuery(theme.breakpoints.down("tablet"));

  const { t } = useTranslation(TRANSLATION, {
    keyPrefix: TRANSLATION_KEY_PREFIX,
  });

  const [baseCoverages, setBaseCoverages] = useState<Coverage[]>([]);
  const [showBaseCoverageExpandedDetails, setShowBaseCoverageExpandedDetails] =
    useState(false);

  const quoteDto = useAppSelector(selectQuoteDTO);

  useEffect(() => {
    if (quoteDto) {
      const filtered: Coverage[] = quoteDto.coverages
        .filter(nonDefaultCoveragesFilter)
        .filter((coverage) => coverage.isBase);
      setBaseCoverages(getCoveragesArrayByIndexes(filtered));
    }
  }, [quoteDto]);

  const coverageElements = useMemo(
    () =>
      baseCoverages.map((coverage) => (
        <BaseCoverage key={`coverage-${coverage.title}`} coverage={coverage} />
      )),
    [baseCoverages]
  );

  const expandedDetailsElements = useMemo(
    () =>
      baseCoverages.map((coverage) => (
        <CardMoreDetails
          key={`coverage-${coverage.title}`}
          coverageType={coverage.coverageType}
        />
      )),
    [baseCoverages]
  );

  return (
    <ShowBaseCoverageExpandedDetails.Provider
      value={{
        expanded: showBaseCoverageExpandedDetails,
        setExpanded: setShowBaseCoverageExpandedDetails,
      }}
    >
      <BaseCoverageSectionContainer className="base-coverages">
        <StepMessage message={t(TITLE_KEY)} className="base-coverages-title" />
        <Typography className="base-coverages-secondary-title">
          {t(SECONDARY_TITLE_KEY)}
        </Typography>
        {!isTabletAndBelow && (
          <>
            <Box className="coverages-container">{coverageElements}</Box>
            <Box className="coverages-expanded-details-container">
              {expandedDetailsElements}
            </Box>
          </>
        )}
        {isTabletAndBelow && <BaseCoverageCarousel coverages={baseCoverages} />}
      </BaseCoverageSectionContainer>
      <Collapse in={showBaseCoverageExpandedDetails}>
        <Disclaimer />
      </Collapse>
    </ShowBaseCoverageExpandedDetails.Provider>
  );
};

export default React.memo(BaseCoverageSection);
