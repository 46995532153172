import { useEffect } from "react";
import { StepName, STEP_TO_URL_MAP } from "../../features/quote/routes/url-map";
import { useAppDispatch, useAppSelector } from "../redux-hooks";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import queryString from "query-string";
import { QUOTE_URL } from "../../routes/routes-collection";
import {
  setDirectOfferDetailsFromUrlParams,
  setOfferLoaderShown,
} from "../../features/quote/slice";
import { selectQuoteDTO } from "../../features/quote/slice/selectors";

const useRedirectToQuoteSteps = (): void => {
  const dispatch = useAppDispatch();
  const { path } = useRouteMatch();
  const { search, pathname } = useLocation();
  const parsed = queryString.parse(search);
  const history = useHistory();
  const quoteDto = useAppSelector(selectQuoteDTO);

  const redirectToPrerequisiteStep = () => {
    if (!quoteDto) {
      history.replace(`${path}${STEP_TO_URL_MAP[StepName.Name]}`);
      return;
    }

    switch (pathname) {
      case `${QUOTE_URL}${STEP_TO_URL_MAP[StepName.Destination]}`:
      case `${QUOTE_URL}${STEP_TO_URL_MAP[StepName.Dates]}`:
      case `${QUOTE_URL}${STEP_TO_URL_MAP[StepName.TravelersCount]}`:
      case `${QUOTE_URL}${STEP_TO_URL_MAP[StepName.SoloTravelerInfo]}`:
      case `${QUOTE_URL}${STEP_TO_URL_MAP[StepName.MainTravelerPersonalInfo]}`:
      case `${QUOTE_URL}${STEP_TO_URL_MAP[StepName.MainTravelerResidency]}`:
      case `${QUOTE_URL}${STEP_TO_URL_MAP[StepName.AdditionalTravelerInfo]}`:
        history.replace(`${path}${STEP_TO_URL_MAP[StepName.Name]}`);
        break;
      case `${QUOTE_URL}${STEP_TO_URL_MAP[StepName.Payment]}`:
        history.push(`${path}${STEP_TO_URL_MAP[StepName.Offer]}`);
        break;
      default:
        break;
    }
  };

  useEffect(
    () => {
      if (
        parsed?.quote_hash &&
        parsed?.email &&
        pathname === `${QUOTE_URL}${STEP_TO_URL_MAP[StepName.Offer]}`
      ) {
        dispatch(
          setDirectOfferDetailsFromUrlParams({
            hash: parsed.quote_hash as string,
            email: parsed.email as string,
          })
        );
        dispatch(setOfferLoaderShown(true));
      } else {
        redirectToPrerequisiteStep();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};
export default useRedirectToQuoteSteps;
