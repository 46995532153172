import React from "react";
import { styled } from "@mui/system";
import FormControl, { FormControlProps } from "@mui/material/FormControl";

import { WithThemeProps } from "types/general";
import { ThemeType } from "theme";

import {
  CLASS_RESIDENCY_FIELD_ADDRESS,
  CLASS_RESIDENCY_FIELD_APARTMENT,
  CLASS_RESIDENCY_FIELDS_CONTAINER,
} from "./constants";

export const ResidencyFieldsContainer = styled(
  (props: WithThemeProps & FormControlProps) => (
    <FormControl {...props} fullWidth />
  )
)`
  &.${CLASS_RESIDENCY_FIELDS_CONTAINER} {
    display: flex;
    flex-direction: row;
    gap: ${({ theme: { spacing } }: ThemeType) => spacing(2)};

    & .${CLASS_RESIDENCY_FIELD_APARTMENT} {
      display: inline-block !important;
      flex-shrink: 0;
      width: ${({ theme: { spacing } }: ThemeType) => spacing(11.5)};

      ${({ theme }: ThemeType) => theme.breakpoints.down("sm")} {
        flex-basis: ${({ theme: { spacing } }: ThemeType) => spacing(10.5)};
      }
    }

    & .${CLASS_RESIDENCY_FIELD_ADDRESS} {
      display: inline-block !important;
      flex-grow: 1;

      & input {
        padding-right: ${({ theme: { spacing } }: ThemeType) => spacing(4)};
      }
    }
  }
`;
