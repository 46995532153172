import React from "react";
import AgentPhoto from "../../../assets/images/daniel.png";
import { AgentAvatarProps } from "./constants";
import AgentAvatarContainer from "./styled";

const AgentAvatar: React.FC<AgentAvatarProps> = ({ size = "medium" }) => {
  return <AgentAvatarContainer size={size} src={AgentPhoto} />;
};

export default AgentAvatar;
