import React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";

import { WithThemeProps } from "types/general";
import { ThemeType } from "theme";
import {
  BUTTON_CONTAINER,
  SHOW_PANEL_INTERSECTION_TARGET,
  QUOTE_SECTION,
  QUOTE_SECTION_TITLE,
  SECTION_IMAGE,
} from "./constants";

const QuoteSectionContainer = styled((props: BoxProps & WithThemeProps) => (
  <Box {...props} />
))`
  &.${QUOTE_SECTION} {
    position: relative;
    margin-top: ${({ theme }) => theme.spacing(10)};
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    z-index: 1;

    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      margin-top: 0;
    }

    & > .${SECTION_IMAGE} {
      & > img {
        aspect-ratio: 479 / 609;
        object-fit: cover;
        width: 100%;
      }

      width: ${({ theme: { spacing } }: ThemeType) => spacing(29)};
      height: ${({ theme: { spacing } }: ThemeType) => spacing(34)};
      z-index: 1;

      ${({ theme }: ThemeType) => theme.breakpoints.up("md")} {
        position: absolute;
        top: ${({ theme: { spacing } }: ThemeType) => spacing(7)};
        left: ${({ theme: { spacing } }: ThemeType) => spacing(-5)};
        z-index: -1;
        width: ${({ theme: { spacing } }: ThemeType) =>
          `clamp(${spacing(29)}, 44vw, ${spacing(62)})`};
        height: unset;
      }
    }


    & > .${QUOTE_SECTION_TITLE} {
      font-family: "utopia-std-display";
      font-size: clamp(52px, 6.5vw, 80px);
      line-height: 120%;
      text-align: center;
      max-width: ${({ theme: { spacing } }: ThemeType) => spacing(120)};
    }

    & > .${BUTTON_CONTAINER} {
      position: relative;
      margin-top: ${({ theme: { spacing } }: ThemeType) => spacing(13)};
      width: ${({ theme: { spacing } }: ThemeType) => spacing(43)};
      height: ${({ theme: { spacing } }: ThemeType) => spacing(7)};
      transition: width 0.2s cubic-bezier(0, 0, 0.2, 1), margin-top 0.2s cubic-bezier(0, 0, 0.2, 1);


      & > .${SHOW_PANEL_INTERSECTION_TARGET} {
        position: absolute;
        bottom: ${({ theme }) => theme.spacing(15)};
        height: ${({ theme }) => theme.spacing(6)};
        z-index: -1;
      }

      & > button {
        height: 100%;
        width: 100%;
      }

      ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
        width: 100%;
        margin-top: ${({ theme: { spacing } }: ThemeType) => spacing(7.5)};
      }
    }
  }


}
`;

export default QuoteSectionContainer;
