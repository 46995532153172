import { getUnixTime, parseISO } from "date-fns";
import DateRangeChipType from "../types/quote-types/DateRangeChip";

const mapDateRangeChipsIntervals = (
  dateRangeChips: DateRangeChipType[]
): [number, number][] =>
  dateRangeChips.map((dateRangeChip) => [
    getUnixTime(parseISO(dateRangeChip.startDate)),
    getUnixTime(parseISO(dateRangeChip.endDate)),
  ]);

const merge = (intervals: [number, number][]) => {
  if (intervals.length < 2) return intervals;

  intervals.sort((a, b) => a[0] - b[0]);

  const result = [];
  let previous = intervals[0];

  for (let i = 1; i < intervals.length; i += 1) {
    if (previous[1] >= intervals[i][0]) {
      previous = [previous[0], Math.max(previous[1], intervals[i][1])];
    } else {
      result.push(previous);
      previous = intervals[i];
    }
  }

  result.push(previous);

  return result;
};

export const getOverlappedIntervalIndexes = (
  dateRangeChips: DateRangeChipType[]
): number[] => {
  if (dateRangeChips.length === 1) {
    return [];
  }
  const mapped = mapDateRangeChipsIntervals(dateRangeChips);
  const result: number[] = [];
  const merged: [number, number][] = merge([...mapped]);

  for (let i = 0; i < mapped.length; i++) {
    const intervalToFind = mapped[i];
    const intervalIncludedInMergeResult = !!merged.find((interval) => {
      return (
        interval[0] === intervalToFind[0] && interval[1] === intervalToFind[1]
      );
    });

    if (!intervalIncludedInMergeResult) {
      result.push(i);
    }
  }
  return result;
};
