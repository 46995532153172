import React, { useCallback, useEffect, useState } from "react";
import {
  StepName,
  URL_TO_STEP_MAP,
} from "../../../features/quote/routes/url-map";
import { ButtonProps, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { getStepUrlFromPathname } from "../../../utils";
import {
  BUTTON_TEXT_BY_STEP,
  StageNameKey,
  StepsButtonProps,
} from "./constants";
import ButtonContainer from "./styled";

const StepsButton: React.FC<StepsButtonProps & ButtonProps> = ({
  onClick,
  disabled = false,
  translationVariables,
  loading,
  buttonContentOverrideText,
  className,
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "quote.steps button",
  });
  const location = useLocation();
  const [buttonByStage, setButtonByStage] = useState(
    BUTTON_TEXT_BY_STEP[StepName.Name]
  );

  const handleButtonClick = useCallback(() => {
    if (onClick) {
      onClick();
    }
    if (disabled) {
      return;
    }
  }, [onClick, disabled]);

  useEffect(() => {
    const currentStepPath = getStepUrlFromPathname(location.pathname);
    const currentStep = URL_TO_STEP_MAP[currentStepPath ?? ""] as StageNameKey;
    const updatedText = BUTTON_TEXT_BY_STEP[currentStep];
    setButtonByStage(updatedText);
  }, [location]);

  if (!buttonByStage) {
    return null;
  }

  let buttonContent: string | JSX.Element;
  if (loading) {
    buttonContent = <CircularProgress color="secondary" />;
  } else {
    buttonContent =
      buttonContentOverrideText || t(buttonByStage, translationVariables);
  }

  return (
    <ButtonContainer
      variant="contained"
      onClick={handleButtonClick}
      disabled={disabled}
      className={className}
    >
      {buttonContent}
    </ButtonContainer>
  );
};

export default React.memo(StepsButton);
