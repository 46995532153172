import React from "react";
import classnames from "classnames";
import Box from "@mui/material/Box";
import { FieldWithLabelProps } from "./constants";
import FieldWithLabelContainer from "./styled";

const FormField = React.forwardRef<HTMLDivElement, FieldWithLabelProps>(
  (
    {
      hint,
      additionalHint = "",
      hasError,
      children,
      onBlur,
      onFocus,
      className,
      fullWidth = true,
    },
    ref
  ) => (
    <FieldWithLabelContainer
      ref={ref}
      focused
      fullWidth={fullWidth}
      className={classnames("details-input", className)}
      color="primary"
      error={hasError}
      onBlur={onBlur}
      onFocus={onFocus}
    >
      {(hint || additionalHint) && (
        <Box className="hint-container">
          <label className="hint">{hint}</label>
          {additionalHint}
        </Box>
      )}
      {children}
    </FieldWithLabelContainer>
  )
);

export default FormField;
