import { ResidencyAddress } from "types/quote-types";

export const TRANSLATION_KEY_PREFIX = "quote.steps.personal info.real address";
export const TITLE_KEY = "title";
export const STREET_AND_NUMBER_KEY = "street and number";
export const CITY_KEY = "city";
export const STATE_KEY = "state";
export const APT_NUMBER_KEY = "apt number";
export const ZIP_CODE_KEY = "zip code";

export const STATE_AUTOCOMPLETE_TEXT_FIELD = "state-autocomplete-text-field";

export const PAGE_NAME_FULL_ADDRESS_FORM = "Full Address Form";

export interface FullAddressProps {
  residencyAddress: ResidencyAddress;

  onClickNext(residencyAddress: ResidencyAddress): void;
}
