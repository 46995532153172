export type ExpandedDetailsItem = { title: string; description: string };
const ITEMS_SEPARATOR = "<n>";
const ITEM_TITLE_DESCRIPTION_SEPARATOR = "<b>";

const mapExpandedDetailsItemTextsToInstance = (
  texts: string
): ExpandedDetailsItem => {
  const [title, description] = texts.split(ITEM_TITLE_DESCRIPTION_SEPARATOR);
  return { title, description };
};

export const parseExpandedDetails = (text: string): ExpandedDetailsItem[] =>
  text.split(ITEMS_SEPARATOR).map(mapExpandedDetailsItemTextsToInstance);
