import React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";

import { useTranslation } from "react-i18next";
import { TRANSLATION } from "i18n";

import { WithThemeProps } from "types/general";

import {
  BASE_COVERAGE,
  ExpandedDetailsComponentProps,
  TRANSLATION_KEY_PREFIX,
} from "./constants";
import { parseExpandedDetails } from "../../../../../../../utils/coverage-expanded-details";
import MoreDetailsItemElement from "./MoreDetailsItemElement";

const YourStuffExpandedDetailsContainer = styled(
  (props: WithThemeProps & BoxProps) => <Box {...props} />
)``;

const YourStuffExpandedDetails: React.FC<ExpandedDetailsComponentProps> = ({
  className,
  coverageName,
}) => {
  const { t } = useTranslation(TRANSLATION, {
    keyPrefix: TRANSLATION_KEY_PREFIX,
  });

  if (!coverageName) return null;

  const expandedDetailsItemsBase = parseExpandedDetails(
    t(`${coverageName}.${BASE_COVERAGE}`)
  );

  return (
    <YourStuffExpandedDetailsContainer className={className}>
      {expandedDetailsItemsBase.map((item) => (
        <MoreDetailsItemElement key={item.title} item={item} />
      ))}
    </YourStuffExpandedDetailsContainer>
  );
};

export default YourStuffExpandedDetails;
