import React from "react";

import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";

import { WithThemeProps } from "types";
import { ThemeType } from "theme";

import { CLASS_DOWNLOAD_DOCUMENTS } from "../constants";

const CLASS_MUI_SVG = "MuiSvgIcon-root";
const CLASS_MUI_BUTTON = "MuiButton-root";

const DownloadDocumentsContainer = styled(
  (props: WithThemeProps & BoxProps) => <Box {...props} />
)`
  &.${CLASS_DOWNLOAD_DOCUMENTS} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${({ theme: { spacing } }: ThemeType) => spacing(0.5)};

    & .${CLASS_MUI_BUTTON} {
      text-transform: none;
      font-size: 14px;
      line-height: 18px;
      font-weight: 300;
      justify-content: flex-start;
      white-space: nowrap;
      color: ${({ theme: { palette } }: ThemeType) => palette.primary.main};

      &:hover {
        background-color: unset;
        color: ${({ theme: { palette } }: ThemeType) =>
          palette.primary.hovered};
      }

      & .${CLASS_MUI_SVG} {
        margin-right: ${({ theme }) => theme.spacing(1.5)};
      }
    }
  }
`;

export default DownloadDocumentsContainer;
