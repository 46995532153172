import React, { useCallback } from "react";
import Autocomplete from "@mui/material/Autocomplete";

import { useTranslation } from "react-i18next";
import { TRANSLATION } from "i18n";

import States from "assets/json-files/states_titlecase.json";

import AutoCompletePopper from "common-components/display-utils/AutoCompletePopper";
import TextFieldWrapper from "common-components/controllers/TextFieldWrapper";
import FormField from "common-components/display-utils/FormField/FormField";
import FillDetails from "common-components/display-utils/FillDetailsContainer";
import ResidencyFields from "../common-components/ResidencyFields";
import {
  CLASS_RESIDENCY_FIELD_ADDRESS,
  CLASS_RESIDENCY_FIELD_APARTMENT,
} from "../common-components/ResidencyFields/constants";
import StateZipCodeFields from "./StateZipCodeFields";

import useInput from "hooks/controllers/useInput";
import useAutoComplete from "hooks/controllers/useAutoComplete";
import usePageViewEvent from "hooks/tracking/usePageViewEvent";

import {
  isMinimum2Characters,
  isNonEmptyText,
  isZipCodeValid,
  limitStringLength,
} from "utils/texts";

import { APARTMENT_STRING_MAXIMUM_CHARACTERS } from "../../../../../../constants";
import {
  APT_NUMBER_KEY,
  CITY_KEY,
  FullAddressProps,
  PAGE_NAME_FULL_ADDRESS_FORM,
  STATE_AUTOCOMPLETE_TEXT_FIELD,
  STATE_KEY,
  STREET_AND_NUMBER_KEY,
  TITLE_KEY,
  TRANSLATION_KEY_PREFIX,
  ZIP_CODE_KEY,
} from "./constants";
import FullAddressContainer from "./styled";

const FullAddress: React.FC<FullAddressProps> = ({
  residencyAddress,
  onClickNext,
}) => {
  usePageViewEvent({ pageName: PAGE_NAME_FULL_ADDRESS_FORM });

  const { t } = useTranslation(TRANSLATION, {
    keyPrefix: TRANSLATION_KEY_PREFIX,
  });

  const {
    value: streetAndNumber,
    onChange: onChangeStreetAndNumber,
    valid: streetAndNumberValid,
  } = useInput<string>(residencyAddress.streetAndNumber ?? "", isNonEmptyText);
  const { value: apartment, onChange: onChangeApartment } = useInput<string>(
    residencyAddress.apartment ?? "",
    isNonEmptyText,
    limitStringLength(APARTMENT_STRING_MAXIMUM_CHARACTERS)
  );
  const {
    value: city,
    onChange: onChangeCity,
    valid: cityValid,
  } = useInput<string>(residencyAddress.locality ?? "", isMinimum2Characters);
  const {
    value: zipCode,
    onChange: onChangeZipCode,
    valid: zipCodeValid,
  } = useInput<string>(residencyAddress.zipCode ?? "", isZipCodeValid);

  const {
    value: state,
    valid: stateValid,
    onChange: onChangeState,
  } = useAutoComplete(residencyAddress.areaLevel1 ?? null, States);

  const handleNextScreenClick = useCallback(async () => {
    if (streetAndNumberValid && cityValid && zipCodeValid && stateValid) {
      onClickNext({
        streetAndNumber,
        zipCode,
        apartment,
        locality: city,
        areaLevel1: state!.value,
        country: residencyAddress.country,
      });
    }
  }, [
    streetAndNumber,
    zipCode,
    apartment,
    city,
    state,
    streetAndNumberValid,
    cityValid,
    zipCodeValid,
    stateValid,
    residencyAddress,
    onClickNext,
  ]);

  return (
    <FullAddressContainer>
      <FillDetails
        title={t(TITLE_KEY)}
        onStepsButtonClick={handleNextScreenClick}
        isStepsButtonDisabled={
          !(streetAndNumberValid && cityValid && zipCodeValid && stateValid)
        }
      >
        <ResidencyFields>
          <FormField className={CLASS_RESIDENCY_FIELD_ADDRESS}>
            <TextFieldWrapper
              submitCallback={handleNextScreenClick}
              label={t(STREET_AND_NUMBER_KEY)}
              value={streetAndNumber}
              onChange={onChangeStreetAndNumber}
            />
          </FormField>
          <FormField className={CLASS_RESIDENCY_FIELD_APARTMENT}>
            <TextFieldWrapper
              submitCallback={handleNextScreenClick}
              label={t(APT_NUMBER_KEY)}
              value={apartment}
              onChange={onChangeApartment}
            />
          </FormField>
        </ResidencyFields>
        <FormField>
          <TextFieldWrapper
            submitCallback={handleNextScreenClick}
            label={t(CITY_KEY)}
            value={city}
            onChange={onChangeCity}
          />
        </FormField>
        <StateZipCodeFields>
          <FormField>
            <Autocomplete
              disablePortal
              fullWidth
              value={state}
              options={States}
              onChange={onChangeState}
              PopperComponent={AutoCompletePopper}
              renderInput={(params) => (
                <TextFieldWrapper
                  {...params}
                  className={STATE_AUTOCOMPLETE_TEXT_FIELD}
                  label={t(STATE_KEY)}
                  submitCallback={handleNextScreenClick}
                />
              )}
            />
          </FormField>
          <FormField>
            <TextFieldWrapper
              submitCallback={handleNextScreenClick}
              label={t(ZIP_CODE_KEY)}
              value={zipCode}
              onChange={onChangeZipCode}
            />
          </FormField>
        </StateZipCodeFields>
      </FillDetails>
    </FullAddressContainer>
  );
};

export default FullAddress;
