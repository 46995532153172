import { styled } from "@mui/system";
import { WithThemeProps } from "../../../../../../../../types/general";
import Chip, { ChipProps } from "@mui/material/Chip";
import { ThemeType } from "../../../../../../../../theme";
import React from "react";

const DriverChipContainer = styled(
  ({
    isDriver,
    ...props
  }: WithThemeProps & ChipProps & { isDriver: boolean }) => <Chip {...props} />
)`
  color: ${({
    theme: { palette },
    isDriver,
  }: ThemeType & { isDriver: boolean }) =>
    isDriver ? palette.primary.main : palette.p_grey.textDark};
  background-color: ${({
    theme: { palette },
    isDriver,
  }: ThemeType & { isDriver: boolean }) =>
    isDriver ? palette.primaryLight : palette.p_grey.light};

  &:hover {
    color: ${({
      theme: { palette },
      isDriver,
    }: ThemeType & { isDriver: boolean }) =>
      isDriver ? palette.primary.main : palette.p_grey.textDark};
    background-color: ${({
      theme: { palette },
      isDriver,
    }: ThemeType & { isDriver: boolean }) =>
      isDriver ? palette.primaryLight : palette.p_grey.light};
  }

  font-size: 13px;
  line-height: 150%;
`;

export default DriverChipContainer;
