import React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";

import { ThemeType } from "theme";
import { WithThemeProps } from "types";
import { CARD_CONTAINER } from "./constants";

const CardContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box {...props} />
))`
  &.${CARD_CONTAINER} {
    position: relative;
    background-color: ${({ theme: { palette } }: ThemeType) =>
      palette.p_white.full};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: ${({ theme: { spacing } }: ThemeType) => spacing(4, 3, 3)};
    border-radius: ${({ theme: { spacing } }: ThemeType) => spacing(0.5)};
    max-width: 100%;

    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      padding: ${({ theme: { spacing } }: ThemeType) => spacing(1.5, 2, 2)};
      min-height: ${({ theme: { spacing } }: ThemeType) => spacing(15)};
      width: 100%;
    }

    ${({ theme }: ThemeType) => theme.breakpoints.up("md")} {
      width: ${({ theme: { spacing } }: ThemeType) =>
        `clamp(${spacing(35)}, 30vw, ${spacing(41.5)})`};
      height: ${({ theme: { spacing } }: ThemeType) => spacing(25)};

      :not(:first-of-type, :last-of-type) {
        width: ${({ theme: { spacing } }: ThemeType) =>
          `clamp(${spacing(37.5)}, 30vw, ${spacing(41.5)})`};
      }
    }
  }
`;

export default CardContainer;
