import React, { useCallback, useEffect, useState } from "react";
import { Button, Checkbox, FormControlLabel, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";
import { TRANSLATION } from "i18n";

import { useAppSelector } from "hooks/redux-hooks";

import { logEvent } from "tracking/logger";
import EventName from "tracking/mixpanel/EventName";
import { TrackerPropertyName } from "tracking/mixpanel/PropertyNames";

import { selectEmail, selectResidency } from "features/quote/slice/selectors";

import { GATSBY_URL } from "../../../constants";
import {
  BUTTON_BACK_TO_HOMEPAGE_TEXT_KEY,
  CLASS_BUTTON_BACK_TO_HOMEPAGE,
  CLASS_CONTAINER,
  CLASS_NOTIFY_ME,
  CLASS_SECONDARY_MESSAGE,
  CLASS_TITLE,
  ComingSoonProps,
  getStateName,
  LEAD_LOCATION_COMING_SOON,
  MAIN_MESSAGE_KEY,
  MESSAGE_NOTIFY_ME_KEY,
  SECONDARY_MESSAGE_KEY,
  TRANSLATION_KEY_PREFIX,
} from "./constants";
import ComingSoonContainer, { UncheckedIcon } from "./styled";

const ComingSoon: React.FC<ComingSoonProps> = () => {
  const [checkedAtLeastOnce, setCheckedAtLeastOnce] = useState<boolean>(false);
  const [currentlyChecked, setCurrentlyChecked] = useState<boolean>(false);
  const email = useAppSelector(selectEmail);

  const handleToggleCheckbox = useCallback(() => {
    if (!currentlyChecked && !checkedAtLeastOnce) {
      setCheckedAtLeastOnce(true);
    }
    setCurrentlyChecked((prevChecked) => !prevChecked);
  }, [currentlyChecked, checkedAtLeastOnce]);

  useEffect(() => {
    if (currentlyChecked && checkedAtLeastOnce) {
      logEvent(EventName.LeadAdded, {
        [TrackerPropertyName.Location]: LEAD_LOCATION_COMING_SOON,
        [TrackerPropertyName.Email]: email,
      });
    }
  }, [currentlyChecked, checkedAtLeastOnce, email]);

  const { t } = useTranslation(TRANSLATION, {
    keyPrefix: TRANSLATION_KEY_PREFIX,
  });
  const stateInitials = useAppSelector(selectResidency)?.areaLevel1;
  return (
    <ComingSoonContainer className={CLASS_CONTAINER}>
      <Typography component="h1" className={CLASS_TITLE}>
        {t(MAIN_MESSAGE_KEY, { state: getStateName(stateInitials) })}
      </Typography>
      <Typography className={CLASS_SECONDARY_MESSAGE}>
        {t(SECONDARY_MESSAGE_KEY)}
      </Typography>
      <FormControlLabel
        control={
          <Checkbox
            onChange={handleToggleCheckbox}
            checked={currentlyChecked}
            icon={<UncheckedIcon />}
            disableRipple
          />
        }
        label={t(MESSAGE_NOTIFY_ME_KEY) as string}
        className={CLASS_NOTIFY_ME}
      />
      <Button
        variant="contained"
        className={CLASS_BUTTON_BACK_TO_HOMEPAGE}
        onClick={() => {
          window.location.replace(GATSBY_URL as string);
        }}
      >
        {t(BUTTON_BACK_TO_HOMEPAGE_TEXT_KEY)}
      </Button>
    </ComingSoonContainer>
  );
};
export default ComingSoon;
