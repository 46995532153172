import { styled } from "@mui/system";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { WithThemeProps } from "../../../types/general";
import React from "react";

const FooterContainer = styled((props: TypographyProps & WithThemeProps) => (
  <Typography component={"footer"} {...props} />
))`
  flex-shrink: 0;
  flex-grow: 0;
`;

export default FooterContainer;
