import React, { useEffect, useMemo } from "react";
import { useMediaQuery, useTheme } from "@mui/material";

import { useLocation } from "react-router-dom";
import { useInView } from "react-intersection-observer";

import { ProcessStatus } from "types/quote-types/QuoteProcesses";

import ShowQuotePanelContext from "./ShowQuotePanelContext";

import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import useLogPricingToGTM from "hooks/tracking/useLogPricingToGTM";

import { setUserAttributes } from "tracking/logger";

import QuoteSection from "./TopSection";
import BaseCoverageSection from "./BaseCoverages/BaseCoverageSection";
import AdditionalCoverageSection from "./AdditionalCoverages/AdditionalCoverageSection";
import QuotePanel from "./BottomPanel";

import { calcQuotePrice } from "features/quote/slice/thunks/quote";
import {
  selectCalculationProcess,
  selectOfferLoaderShown,
  selectQuoteCodeHash,
} from "features/quote/slice/selectors";
import { STEP_TO_URL_MAP, StepName } from "features/quote/routes/url-map";
import { QUOTE_URL } from "routes/routes-collection";

import {
  CLASS_QUOTE_OFFER,
  OfferProps,
  PAGE_NAME_OFFER_PAGE,
} from "./constants";
import QuoteOfferContainer from "./styled";
import Loader from "./Loader";
import Disclaimer from "./Disclaimer";
import usePageViewEvent from "../../../../../hooks/tracking/usePageViewEvent";
import { TrackerPropertyName } from "../../../../../tracking/mixpanel/PropertyNames";
import usePaymentRequest from "../../../../../hooks/stripe/usePaymentRequest";

const OfferWrapper: React.FC = () => {
  useLogPricingToGTM();
  usePaymentRequest();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { ref: showPanelToggleRef, inView: shouldHidePanel } = useInView();
  const { ref: showPriceToggleRef, inView: shouldHidePrice } = useInView();

  const calculationProcess = useAppSelector(selectCalculationProcess);
  const offerLoaderShown = useAppSelector(selectOfferLoaderShown);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (calculationProcess.status === ProcessStatus.REQUESTED) {
      dispatch(calcQuotePrice());
    }
  }, [dispatch, calculationProcess]);

  return (
    <ShowQuotePanelContext.Provider
      value={{
        showPanelToggleRef,
        shouldShowPanel: isMobile || !shouldHidePanel,
        showPriceToggleRef,
        shouldShowPrice: !isMobile || !shouldHidePrice,
      }}
    >
      <Loader />
      {!offerLoaderShown && <Offer />}
    </ShowQuotePanelContext.Provider>
  );
};

export const Offer: React.FC<OfferProps> = React.memo(() => {
  const { pathname } = useLocation();
  const isOfferPageUrl = useMemo(
    () => pathname === `${QUOTE_URL}${STEP_TO_URL_MAP[StepName.Offer]}`,
    [pathname]
  );
  const quoteCodeHash = useAppSelector(selectQuoteCodeHash);

  const additionalParams = useMemo(
    (): Record<string, string> =>
      quoteCodeHash && isOfferPageUrl
        ? { [TrackerPropertyName.QuoteHash]: quoteCodeHash }
        : {},
    [isOfferPageUrl, quoteCodeHash]
  );

  usePageViewEvent({
    pageName: PAGE_NAME_OFFER_PAGE,
    disable: !isOfferPageUrl,
    additionalParams,
  });

  useEffect(() => {
    quoteCodeHash &&
      setUserAttributes({ [TrackerPropertyName.QuoteHash]: quoteCodeHash });
  }, [quoteCodeHash]);

  return (
    <QuoteOfferContainer className={CLASS_QUOTE_OFFER}>
      <QuoteSection />
      <BaseCoverageSection />
      <AdditionalCoverageSection />
      <Disclaimer />
      <QuotePanel />
    </QuoteOfferContainer>
  );
});

export default OfferWrapper;
