import {
  AppRouteItem,
  NAME_URL,
  DESTINATION,
  DATES,
  TRAVELERS_COUNT_URL,
  SOLO_TRAVELER_INFO_URL,
  QUOTE_CONFIRMED_URL,
  QUOTE_OFFER_URL,
  QUOTE_PAYMENT_URL,
  PERSONAL_INFO_URL,
  RESIDENCY_URL,
  ADDITIONAL_TRAVELER_INFO_URL,
  COMING_SOON_URL,
} from "./routes-collection";
import { Dict } from "types/general";

export enum StepName {
  Name = "NAME",
  Destination = "DESTINATION",
  Dates = "DATES",
  TravelersCount = "TRAVELERS",
  SoloTravelerInfo = "SOLO_TRAVELER_INFO",
  MainTravelerPersonalInfo = "FIRST_TRAVELER_PERSONAL_INFO",
  MainTravelerResidency = "RESIDENCY",
  AdditionalTravelerInfo = "ADDITIONAL_TRAVELER_INFO",
  ComingSoon = "COMING_SOON",
  Offer = "OFFER",
  Payment = "PAYMENT",
  Policy = "POLICY",
}

export const STEP_TO_URL_MAP: Dict<StepName, AppRouteItem> = {
  [StepName.Name]: NAME_URL,
  [StepName.Destination]: DESTINATION,
  [StepName.Dates]: DATES,
  [StepName.TravelersCount]: TRAVELERS_COUNT_URL,
  [StepName.SoloTravelerInfo]: SOLO_TRAVELER_INFO_URL,
  [StepName.MainTravelerPersonalInfo]: PERSONAL_INFO_URL,
  [StepName.MainTravelerResidency]: RESIDENCY_URL,
  [StepName.AdditionalTravelerInfo]: ADDITIONAL_TRAVELER_INFO_URL,
  [StepName.ComingSoon]: COMING_SOON_URL,
  [StepName.Offer]: QUOTE_OFFER_URL,
  [StepName.Payment]: QUOTE_PAYMENT_URL,
  [StepName.Policy]: QUOTE_CONFIRMED_URL,
};

export const URL_TO_STEP_MAP: Dict<string, StepName> = {
  [STEP_TO_URL_MAP[StepName.Name].toString()]: StepName.Name,
  [STEP_TO_URL_MAP[StepName.Destination].toString()]: StepName.Destination,
  [STEP_TO_URL_MAP[StepName.Dates].toString()]: StepName.Dates,
  [STEP_TO_URL_MAP[StepName.TravelersCount].toString()]:
    StepName.TravelersCount,
  [STEP_TO_URL_MAP[StepName.SoloTravelerInfo].toString()]:
    StepName.SoloTravelerInfo,
  [STEP_TO_URL_MAP[StepName.MainTravelerPersonalInfo].toString()]:
    StepName.MainTravelerPersonalInfo,
  [STEP_TO_URL_MAP[StepName.MainTravelerResidency].toString()]:
    StepName.MainTravelerResidency,
  [STEP_TO_URL_MAP[StepName.AdditionalTravelerInfo].toString()]:
    StepName.AdditionalTravelerInfo,
  [STEP_TO_URL_MAP[StepName.Offer].toString()]: StepName.Offer,
  [STEP_TO_URL_MAP[StepName.Payment].toString()]: StepName.Payment,
  [STEP_TO_URL_MAP[StepName.Policy].toString()]: StepName.Policy,
};
