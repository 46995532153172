import React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";

import { WithThemeProps } from "types/general";
import { ThemeType } from "theme";

import {
  CLASS_DETAILS_FIELDS,
  CLASS_DETAILS_INPUT_NAME,
  CLASS_NAME_STEP_CONTAINER,
} from "./constants";

const Container = styled((props: BoxProps & WithThemeProps) => (
  <Box {...props} />
))`
  &.${CLASS_NAME_STEP_CONTAINER} {
    & .${CLASS_DETAILS_FIELDS} {
      gap: ${({ theme: { spacing } }: ThemeType) => spacing(2)};
      justify-content: space-between;

      & .${CLASS_DETAILS_INPUT_NAME} {
        flex: 1 1 0;
      }
    }
  }
`;

export default Container;
