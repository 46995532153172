import React from "react";
import { styled } from "@mui/system";
import Modal, { ModalProps } from "@mui/material/Modal";

import { WithThemeProps } from "types/general";
import { CLASS_PAYMENT_MODAL_CONTENT } from "../constants";

const PaymentModal = styled((props: ModalProps & WithThemeProps) => (
  <Modal {...props} />
))`
  & > .${CLASS_PAYMENT_MODAL_CONTENT} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    justify-self: center;
    background-color: ${({ theme: { palette } }) => palette.p_white.full};
    width: ${({ theme: { spacing } }) => spacing(50)};
    border-radius: ${({ theme: { spacing } }) => spacing(0.75)};
    padding: ${({ theme: { spacing } }) => spacing(6)};
    overflow-y: hidden;
  }
`;

export default PaymentModal;
