import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

import CoverageImage from "common-components/layout/CoverageImage";

import FinePrintExpandToggle from "./FinePrintExpandToggle";

import {
  BaseCoverageProps,
  CLASS_BASE_COVERAGE,
  CLASS_COVERAGE_DESCRIPTION,
  CLASS_COVERAGE_DETAILS_CONTAINER,
  CLASS_COVERAGE_DETAILS_CONTENT,
  CLASS_COVERAGE_IMAGE,
  CLASS_COVERAGE_TITLE,
} from "./constants";
import BaseCoverageContainer from "./styled";
import { CoverageType } from "../../../../../../../types/quote-types";
import CovidBadge from "./CovidBadge";

const BaseCoverage: React.FC<BaseCoverageProps> = ({ coverage }) => (
  <BaseCoverageContainer className={CLASS_BASE_COVERAGE}>
    <Box className={CLASS_COVERAGE_DETAILS_CONTAINER}>
      <Box className={CLASS_COVERAGE_DETAILS_CONTENT}>
        {coverage.coverageType === CoverageType.medicalCoverage && (
          <CovidBadge />
        )}
        <CoverageImage
          className={CLASS_COVERAGE_IMAGE}
          coverageType={coverage.coverageType}
        />
        <Typography className={CLASS_COVERAGE_TITLE}>
          {coverage.title}
        </Typography>
        <Typography component="p" className={CLASS_COVERAGE_DESCRIPTION}>
          {coverage.description}
        </Typography>
      </Box>
    </Box>
    <FinePrintExpandToggle coverage={coverage} />
  </BaseCoverageContainer>
);

export default BaseCoverage;
