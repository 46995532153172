import { Traveler } from "../types/quote-types";
import { Nullable } from "../types/general";
import { differenceInYears, parseISO, startOfToday } from "date-fns";

export enum TravelersCountOption {
  Solo = "solo",
  Couple = "couple",
  Bunch = "group",
}

type FillEmptyTravelersByAmountParams = {
  countOption: TravelersCountOption;
  currTravelers: Nullable<Traveler>[];
};

const ADULT_AGE = 18;

export const calcAgeByDateOfBirth = (dateOfBirth: string) =>
  differenceInYears(startOfToday(), parseISO(dateOfBirth));

export const isTravelerAdult = (traveler: Traveler) =>
  calcAgeByDateOfBirth(traveler.dateOfBirth) >= ADULT_AGE;

export const getAdultTravelers = (travelers: Traveler[]): Traveler[] =>
  travelers.filter((traveler: Traveler) => isTravelerAdult(traveler));

const getNonEmptyTravelers = (travelers: Nullable<Traveler>[]) =>
  travelers.filter((traveler) => traveler !== null);

export const fillEmptyTravelersByAmount = ({
  countOption,
  currTravelers,
}: FillEmptyTravelersByAmountParams): Nullable<Traveler>[] => {
  if (countOption === TravelersCountOption.Solo) {
    return currTravelers.slice(0, 1);
  } else if (countOption === TravelersCountOption.Couple) {
    const coupleArray = getNonEmptyTravelers(currTravelers.slice(0, 2));
    let remaining: Nullable<Traveler>[] = [];
    const remainingCount = 2 - coupleArray.length;
    if (remainingCount > 0) {
      remaining = new Array(remainingCount).fill(null);
    }
    return [...coupleArray, ...remaining];
  } else {
    const bunchArray = getNonEmptyTravelers(currTravelers);
    let remaining: Nullable<Traveler>[] = [];
    const remainingCount = 3 - bunchArray.length;
    if (remainingCount > 0) {
      remaining = new Array(remainingCount).fill(null);
    }
    return [...bunchArray, ...remaining];
  }
};

export const travelerIsDriver = (traveler: Nullable<Traveler>) =>
  Boolean(traveler?.isDriver);

export const driversAmount = (travelers: Nullable<Traveler>[]) =>
  travelers.filter(travelerIsDriver).length;

export const nonAdultTravelersCount = (travelersDOBs: string[]) =>
  travelersDOBs.reduce(
    (count, currTraveler) =>
      calcAgeByDateOfBirth(currTraveler) < ADULT_AGE ? count + 1 : count,
    0
  );
