export const CLASS_DETAILS_INPUT_NAME = "details-input-name";
export const CLASS_DETAILS_FIELDS = "details-fields";
export const CLASS_NAME_STEP_CONTAINER = "name-step-container";

export const TRANSLATION_KEY_PREFIX = "quote.steps.name";
export const TITLE_TRANSLATION_KEY = "title";
export const TITLE_DESTINATION_AND_DATES_KNOWN_TRANSLATION_KEY =
  "title destination and dates known";
export const FIRST_NAME_PLACEHOLDER_TRANSLATION_KEY = "first name placeholder";
export const LAST_NAME_PLACEHOLDER_TRANSLATION_KEY = "last name placeholder";

export const PAGE_NAME_FILL_NAME = "Fill Name";
