import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import {
  StripeElementsContainerProps,
  fonts,
  getStripeInstance,
} from "./constants";

const StripeElementsContainer: React.FC<StripeElementsContainerProps> = ({
  children,
}) => {
  return (
    <Elements stripe={getStripeInstance()} options={{ fonts }}>
      {children}
    </Elements>
  );
};

export default StripeElementsContainer;
