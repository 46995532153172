import IOSSwitch, { SwitchProps as IOSSwitchProps } from "@mui/material/Switch";
import { styled } from "@mui/system";

import { WithThemeProps } from "types/general";
import { ThemeType } from "theme";

const ToggleSwitch = styled((props: IOSSwitchProps & WithThemeProps) => (
  <IOSSwitch
    {...props}
    disableRipple
    focusVisibleClassName=".Mui-focusVisible"
  />
))`
  &.MuiSwitch-root {
    padding: 0;
    height: ${({ theme: { spacing } }) => spacing(4)};
    width: ${({ theme: { spacing } }) => spacing(6.5)};
    border-radius: ${({ theme: { spacing } }) => spacing(2.5)};

    & .MuiSwitch-track {
      background-color: ${({ theme }) => theme.palette.disabled.main};
    }

    & .MuiSwitch-switchBase {
      padding: 0;
      height: 100%;
      transition-duration: 300ms;
      left: ${({ theme: { spacing } }) => spacing(0.25)};

      &:hover {
        background-color: unset;
      }

      & .MuiSwitch-thumb {
        opacity: 1;
        width: ${({ theme: { spacing } }) => spacing(3.5)};
        height: ${({ theme: { spacing } }) => spacing(3.5)};
        background-color: ${({ theme: { palette } }) => palette.p_white.full};
        box-shadow: none;

        ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
          width: ${({ theme: { spacing } }) => spacing(2.25)};
          height: ${({ theme: { spacing } }) => spacing(2.25)};
        }
      }

      &.Mui-checked {
        & + .MuiSwitch-track {
          background-color: ${({ theme }) => theme.palette.p_pink.primary};
          opacity: 1;
          border: 0;
        }

        ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
          transform: translateX(${({ theme: { spacing } }) => spacing(1.75)});
        }
      }
    }

    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      height: ${({ theme: { spacing } }) => spacing(2.75)};
      width: ${({ theme: { spacing } }) => spacing(4.5)};
    }
  }
`;

export default ToggleSwitch;
