import { useEffect, useState } from "react";
import { STEP_TO_URL_MAP, StepName } from "../../features/quote/routes/url-map";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../redux-hooks";
import {
  selectShouldContinueToNextTraveler,
  selectRemainingTravelers,
} from "../../features/travelers/selectors";
import { TravelersCountOption } from "../../utils/QuoteTravelers";
import { getStepUrlFromPathname } from "../../utils";
import { selectDatesAndDestinationAreKnown } from "../../features/quote/slice/selectors";

const STEPS: StepName[] = [
  StepName.Name,
  StepName.Destination,
  StepName.Dates,
  StepName.TravelersCount,
  StepName.SoloTravelerInfo,
  StepName.MainTravelerPersonalInfo,
  StepName.MainTravelerResidency,
  StepName.AdditionalTravelerInfo,
  StepName.Offer,
  StepName.Payment,
  StepName.Policy,
];

const travelersCountInfoStepIndex = STEPS.findIndex(
  (step: StepName) => step === StepName.TravelersCount
);
const soloTravelerInfoStepIndex = STEPS.findIndex(
  (step: StepName) => step === StepName.SoloTravelerInfo
);
const mainTravelerPersonalInfoStepIndex = STEPS.findIndex(
  (step: StepName) => step === StepName.MainTravelerPersonalInfo
);
const additionalTravelerInfoStepIndex = STEPS.findIndex(
  (step: StepName) => step === StepName.AdditionalTravelerInfo
);
const offerStepIndex = STEPS.findIndex(
  (step: StepName) => step === StepName.Offer
);
const nameStepIndex = STEPS.findIndex(
  (step: StepName) => step === StepName.Name
);

const useNextQuoteStep = (
  travelerCountOption?: TravelersCountOption
): StepName => {
  const location = useLocation();
  const [nextStep, setNextStep] = useState<StepName>(StepName.Name);

  const travelersRemaining = useAppSelector(selectRemainingTravelers);
  const shouldContinueToNextTraveler = useAppSelector(
    selectShouldContinueToNextTraveler
  );
  const datesAndOrDestinationAreKnown = useAppSelector(
    selectDatesAndDestinationAreKnown
  );

  useEffect(() => {
    let nextStepFromList;
    const currentStepPath = getStepUrlFromPathname(location.pathname);
    const stepIndex = STEPS.findIndex(
      (step: StepName) => STEP_TO_URL_MAP[step] === currentStepPath
    );
    switch (true) {
      // Choose Next Step from Travelers page
      case stepIndex === travelersCountInfoStepIndex &&
        Boolean(travelerCountOption):
        nextStepFromList =
          travelerCountOption === TravelersCountOption.Solo
            ? STEPS[soloTravelerInfoStepIndex]
            : STEPS[mainTravelerPersonalInfoStepIndex];
        break;
      // If there is another traveler to fill, continue to the next one
      case stepIndex === additionalTravelerInfoStepIndex &&
        travelersRemaining > 0 &&
        shouldContinueToNextTraveler:
        nextStepFromList = STEPS[additionalTravelerInfoStepIndex];
        break;
      // If there is a solo traveler, continue to the Offer page
      case stepIndex === soloTravelerInfoStepIndex:
        nextStepFromList = STEPS[offerStepIndex];
        break;
      // If there is a solo traveler, continue to the Offer page
      case stepIndex === nameStepIndex && datesAndOrDestinationAreKnown:
        nextStepFromList = STEPS[travelersCountInfoStepIndex];
        break;
      // For all the rest cases, just go to the next one in the STEPS list
      default:
        nextStepFromList = STEPS[stepIndex + 1];
        break;
    }

    setNextStep(nextStepFromList);
  }, [
    location.pathname,
    travelersRemaining,
    shouldContinueToNextTraveler,
    travelerCountOption,
    datesAndOrDestinationAreKnown,
  ]);

  return nextStep;
};
export default useNextQuoteStep;
