import { styled } from "@mui/system";
import { WithThemeProps } from "../../../../../../../../types/general";
import Box, { BoxProps } from "@mui/material/Box";
import { ThemeType } from "../../../../../../../../theme";
import React from "react";
import {
  CLASS_DRIVERS_CHIPS_CONTAINER,
  CLASS_DRIVERS_CONTAINER,
  CLASS_DRIVERS_PICKER_TITLE,
} from "./constants";

const DriversPickerContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box {...props} />
))`
  &.${CLASS_DRIVERS_CONTAINER} {
    & > .${CLASS_DRIVERS_PICKER_TITLE} {
      color: ${({ theme }: ThemeType) => theme.palette.p_grey.textDark};
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
    }

    & > .${CLASS_DRIVERS_CHIPS_CONTAINER} {
      margin-top: ${({ theme: { spacing } }: ThemeType) => spacing(1)};
      display: flex;
      gap: ${({ theme: { spacing } }: ThemeType) => spacing(1)};
      flex-wrap: wrap;
    }
  }
`;

export default DriversPickerContainer;
