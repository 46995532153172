import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import TrapFocus from "@mui/material/Unstable_TrapFocus";

import { useTranslation } from "react-i18next";
import { TRANSLATION } from "i18n";

import { Nullable } from "types/general";

import FillDetails from "common-components/display-utils/FillDetailsContainer";

import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import useNextQuoteStep from "hooks/flow/useNextQuoteStep";
import usePageViewEvent from "hooks/tracking/usePageViewEvent";

import { destinationNameForDisplay } from "utils/destinations";
import { TravelersCountOption } from "utils/QuoteTravelers";
import { hasPressedEnter } from "utils";

import { StepName } from "../../../routes/url-map";
import { setTravellersCountOptionThunk } from "features/quote/slice/thunks/steps";
import { selectTravelersCountOption } from "features/travelers/selectors";
import { setCurrentTravelerIndex } from "features/travelers/slice";
import {
  selectDatesAndDestinationAreKnown,
  selectDestinationGoogleData,
  selectName,
} from "features/quote/slice/selectors";

import TravelersCountContainer from "./styled";
import {
  TITLE_TRANSLATION_KEY_VARIATION_WITH_DATES_MULTIPLE_DESTINATIONS,
  TITLE_TRANSLATION_KEY_VARIATION_WITH_DATES_SINGLE_DESTINATION,
  TITLE_TRANSLATION_KEY_VARIATION_REGULAR,
  TRANSLATION_KEY_PREFIX,
  PAGE_NAME_TRAVELERS_COUNT,
} from "./constants";

const TravelersCount = () => {
  usePageViewEvent({ pageName: PAGE_NAME_TRAVELERS_COUNT });

  const dispatch = useAppDispatch();
  const { t } = useTranslation(TRANSLATION, {
    keyPrefix: TRANSLATION_KEY_PREFIX,
  });
  const { firstName } = useAppSelector(selectName);
  const travelersCountOption = useAppSelector(selectTravelersCountOption);
  const [travellersOptionValue, setTravellersOptionValue] =
    useState<Nullable<TravelersCountOption>>(travelersCountOption);
  const nextQuoteStep: StepName = useNextQuoteStep(travellersOptionValue!);
  const datesAndOrDestinationAreKnown = useAppSelector(
    selectDatesAndDestinationAreKnown
  );
  const destinationGooglePlaceResults = useAppSelector(
    selectDestinationGoogleData
  );

  const title = useMemo(() => {
    const destinationName =
      destinationGooglePlaceResults && destinationGooglePlaceResults?.[0]
        ? destinationNameForDisplay(destinationGooglePlaceResults[0])
        : "";

    if (datesAndOrDestinationAreKnown) {
      return destinationGooglePlaceResults &&
        destinationGooglePlaceResults.length > 1
        ? t(TITLE_TRANSLATION_KEY_VARIATION_WITH_DATES_MULTIPLE_DESTINATIONS, {
            name: firstName,
          })
        : t(TITLE_TRANSLATION_KEY_VARIATION_WITH_DATES_SINGLE_DESTINATION, {
            destination: destinationName,
            name: firstName,
          });
    } else {
      return t(TITLE_TRANSLATION_KEY_VARIATION_REGULAR);
    }
  }, [
    t,
    datesAndOrDestinationAreKnown,
    destinationGooglePlaceResults,
    firstName,
  ]);

  const handleChangeTravelersOption = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value as TravelersCountOption;
      setTravellersOptionValue(newValue);
    },
    []
  );

  const handleStepsButtonClick = useCallback(() => {
    if (travellersOptionValue) {
      dispatch(
        setTravellersCountOptionThunk({
          travellersCountOption: travellersOptionValue,
          nextQuoteStep,
        })
      );
    }
  }, [dispatch, nextQuoteStep, travellersOptionValue]);

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent) => {
      if (hasPressedEnter(event) && Boolean(travellersOptionValue)) {
        handleStepsButtonClick();
      }
    },
    [handleStepsButtonClick, travellersOptionValue]
  );

  useEffect(() => {
    dispatch(setCurrentTravelerIndex(0));
  }, [dispatch]);

  return (
    <TravelersCountContainer>
      <FillDetails
        title={title}
        secondaryTitle={
          datesAndOrDestinationAreKnown
            ? t(TITLE_TRANSLATION_KEY_VARIATION_REGULAR)
            : undefined
        }
        onStepsButtonClick={handleStepsButtonClick}
        isStepsButtonDisabled={!travellersOptionValue}
      >
        <FormControl component="fieldset" fullWidth>
          <TrapFocus disableEnforceFocus open>
            <RadioGroup
              tabIndex={-1}
              onKeyPress={handleKeyPress}
              aria-label="travellers amount"
              name="radio-buttons-group"
              value={travellersOptionValue}
              onChange={handleChangeTravelersOption}
            >
              <FormControlLabel
                value={TravelersCountOption.Solo}
                control={<Radio />}
                label={t(TravelersCountOption.Solo) as string}
              />
              <FormControlLabel
                value={TravelersCountOption.Couple}
                control={<Radio />}
                label={t(TravelersCountOption.Couple) as string}
              />
              <FormControlLabel
                value={TravelersCountOption.Bunch}
                control={<Radio />}
                label={t(TravelersCountOption.Bunch) as string}
              />
            </RadioGroup>
          </TrapFocus>
        </FormControl>
      </FillDetails>
    </TravelersCountContainer>
  );
};

export default TravelersCount;
