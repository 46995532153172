import React, { useCallback, useContext, useEffect, useState } from "react";
import StepsButton from "../../../../../../common-components/display-utils/StepsButton";
import Box from "@mui/material/Box";
import {
  selectDestinationGoogleData,
  selectQuoteDTO,
  selectTotalPrice,
  selectTravelers,
  selectTripEndDate,
  selectTripStartDate,
} from "../../../../slice/selectors";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../hooks/redux-hooks";
import { getDatesRangeString } from "../../../../../../utils/dates";
import { parseISO } from "date-fns";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "../../../../../../i18n";
import { goToPaymentThunk } from "../../../../slice/thunks/steps";
import QuotePanelContainer from "./styled";
import {
  BASE_COVERAGE_ADDONS_KEY,
  BOTTOM_PANEL_PAYMENT_BUTTON_TRANSLATION_KEY,
  CLASS_COVERAGES,
  CLASS_PANEL_COLLAPSE,
  CLASS_PAY_BUTTON,
  CLASS_QUOTE_PANEL,
  CLASS_TRAVELERS,
  CLASS_TRIP_DESTINATIONS_AND_DATES,
  COVERAGES_WITH_ADDONS_KEY,
  QuotePanelProps,
  TRANSLATION_KEY_PREFIX,
  TRAVELLERS_COUNT_KEY,
} from "./constants";
import ShowQuotePanelContext from "../ShowQuotePanelContext";
import useAcceptOfferEvent, { PaymentButtonCTA } from "../useAcceptOfferEvent";
import { getLimitedMultipleDestinationsList } from "../../../../../../utils/destinations";
import { Collapse, useMediaQuery, useTheme } from "@mui/material";
import classnames from "classnames";

const QuotePanel: React.FC<QuotePanelProps> = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [collapseKey, setCollapseKey] = useState(Date.now());

  const { fireAcceptOfferEvent } = useAcceptOfferEvent();
  const { shouldShowPanel, shouldShowPrice } = useContext(
    ShowQuotePanelContext
  );
  const dispatch = useAppDispatch();
  const { t } = useTranslation(TRANSLATION, {
    keyPrefix: TRANSLATION_KEY_PREFIX,
  });
  const totalPrice = useAppSelector(selectTotalPrice);

  const startDate = useAppSelector(selectTripStartDate);
  const endDate = useAppSelector(selectTripEndDate);
  const travellers = useAppSelector(selectTravelers);
  const quoteDto = useAppSelector(selectQuoteDTO);
  const destinationGooglePlaceResults = useAppSelector(
    selectDestinationGoogleData
  );

  const tripDates =
    startDate && endDate
      ? getDatesRangeString(parseISO(startDate!), parseISO(endDate!))
      : "";

  const additionalCoveragesCount = quoteDto?.coverages?.filter(
    (coverage) => !coverage.isBase && coverage.isSelected
  ).length;

  const handleClickPay = useCallback(() => {
    fireAcceptOfferEvent(PaymentButtonCTA.BottomCTA);
    dispatch(goToPaymentThunk());
  }, [dispatch, fireAcceptOfferEvent]);

  const destinationsAndDates =
    destinationGooglePlaceResults && tripDates
      ? `${getLimitedMultipleDestinationsList(
          destinationGooglePlaceResults
        )}, ${tripDates}`
      : "";

  const offerSummary = (
    <>
      <Box className={CLASS_TRIP_DESTINATIONS_AND_DATES}>
        {destinationsAndDates}
      </Box>
      <Box className={CLASS_TRAVELERS}>
        {t(TRAVELLERS_COUNT_KEY, { count: travellers.length })}
      </Box>
      <Box className={CLASS_COVERAGES}>
        {t(
          additionalCoveragesCount
            ? COVERAGES_WITH_ADDONS_KEY
            : BASE_COVERAGE_ADDONS_KEY,
          { count: additionalCoveragesCount }
        )}
      </Box>
    </>
  );

  useEffect(() => {
    setCollapseKey(Date.now());
  }, [isMobile]);

  return (
    <QuotePanelContainer
      key={collapseKey}
      className={classnames(CLASS_QUOTE_PANEL, {
        "mobile-summary-expanded": isMobile && shouldShowPrice,
      })}
      in={shouldShowPanel}
    >
      <Box className={CLASS_PANEL_COLLAPSE}>
        {isMobile ? (
          <Collapse className="mobile-summary-collapse" in={shouldShowPrice}>
            {offerSummary}
          </Collapse>
        ) : (
          offerSummary
        )}
        <StepsButton
          onClick={handleClickPay}
          className={CLASS_PAY_BUTTON}
          loading={!Number(totalPrice)}
          buttonContentOverrideText={
            shouldShowPrice
              ? t(BOTTOM_PANEL_PAYMENT_BUTTON_TRANSLATION_KEY, {
                  price: totalPrice,
                })
              : undefined
          }
        />
      </Box>
    </QuotePanelContainer>
  );
};

export default React.memo(QuotePanel);
