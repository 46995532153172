import { styled } from "@mui/system";
import { WithThemeProps } from "../../../../../../../../types/general";
import Box, { BoxProps } from "@mui/material/Box";
import React from "react";
import {
  CAR_DRIVING_RANGES_PICKER,
  CAR_DRIVING_RANGES_PICKER_CONTAINER,
} from "./constants";

const CarDrivingRangesPickerContainer = styled(
  (props: WithThemeProps & BoxProps) => <Box {...props} />
)`
  &.${CAR_DRIVING_RANGES_PICKER_CONTAINER} {
    & .${CAR_DRIVING_RANGES_PICKER} {
      & .PrivatePickersSlideTransition-root {
        min-height: unset;

        & div[role="grid"] {
          position: static;

          & div[role="row"] {
            margin: 0;

            & .MuiDateRangePickerDay-root {
              & .MuiDateRangePickerDay-rangeIntervalPreview {
                line-height: 0;
                border-top: 0;
                border-bottom: 0;

                & .Mui-disabled {
                  visibility: hidden;
                  height: 0;
                }

                & .MuiPickersDay-today {
                  border: 0;
                }

                & .MuiPickersDay-hiddenDaySpacingFiller {
                  height: 0;
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default CarDrivingRangesPickerContainer;
