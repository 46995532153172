import React, { useCallback, useLayoutEffect, useMemo, useRef } from "react";
import {
  capitalize,
  isEmailTextValid,
  isNameValid,
} from "../../../../../../../utils/texts";
import FormField from "../../../../../../../common-components/display-utils/FormField/FormField";
import FillDetailsContainer from "../../../../../../../common-components/display-utils/FillDetailsContainer";
import useInput from "../../../../../../../hooks/controllers/useInput";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "../../../../../../../i18n";
import TextFieldWrapper from "../../../../../../../common-components/controllers/TextFieldWrapper";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Button, IconButton, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import { CLASS_FORM_FIELDS_SECTION } from "../../../../../../../constants";
import classnames from "classnames";
import FayeBirthDateField from "../../../../../../../common-components/controllers/FayeBirthDateField";
import useBirthDateField from "../../../../../../../hooks/controllers/useBirthDateField";
import {
  AdditionalTravelerFormProps,
  ASK_EMAIL_PLACEHOLDER,
  CLASS_ADD_TRAVELLER_BUTTON,
  CLASS_DETAILS_INPUT_NAME,
  CLASS_TRAVELER_ORDINAL,
  FIRST_NAME_PLACEHOLDER_TRANSLATION_KEY,
  LAST_NAME_PLACEHOLDER_TRANSLATION_KEY,
  SECTION_DATE_OF_BIRTH,
  SECTION_EMAIL,
  SECTION_FIRST_NAME,
  SECTION_LAST_NAME,
  TITLE,
  TRANSLATION_KEY_PREFIX,
  TRAVELER_ORDINAL_BY_COUNT,
} from "./constants";

const AdditionalTravelerForm: React.FC<AdditionalTravelerFormProps> = ({
  currentTraveler,
  currentTravelerIndex,
  onStepsButtonClick,
}) => {
  const history = useHistory();
  const firstNameRef = useRef<HTMLInputElement>();
  const { t } = useTranslation(TRANSLATION, {
    keyPrefix: TRANSLATION_KEY_PREFIX,
  });

  const {
    value: firstName,
    onChange: onChangeFirst,
    valid: isFirstNameValid,
    onFocus: onFirstNameFocus,
    onBlur: onFirstNameBlur,
    hasError: firstNameHasError,
  } = useInput<string>(
    currentTraveler?.firstName ?? "",
    isNameValid,
    capitalize
  );
  const {
    value: lastName,
    onChange: onChangeLast,
    valid: isLastNameValid,
    onFocus: onLastNameFocus,
    onBlur: onLastNameBlur,
    hasError: lastNameHasError,
  } = useInput<string>(
    currentTraveler?.lastName ?? "",
    isNameValid,
    capitalize
  );

  const {
    value: userEmail,
    valid: isUserEmailValid,
    onChange: onUserEmailChange,
    onFocus: onUserEmailFocus,
    onBlur: onUserEmailBlur,
    hasError: userEmailHasError,
  } = useInput<string>(currentTraveler?.email ?? "", isEmailTextValid);

  const {
    value: selectedDateOfBirth,
    valid: isDateOfBirthValid,
    focused: isDateOfBirthFocused,
    onFieldFocus: onFieldDateFocus,
    onDateFieldChange: onDateOfBirthFieldChange,
    hasError: dateHasError,
    onDateFieldBlur,
    month,
    day,
    year,
  } = useBirthDateField(currentTraveler?.dateOfBirth ?? null);

  const handlePreviousTravelerClick = useCallback(
    () => history.goBack(),
    [history]
  );

  const isAdditionalEmailValid = userEmail.length <= 0 || isUserEmailValid;

  const handleStepsButtonClick = useCallback(() => {
    onStepsButtonClick({
      firstName,
      lastName,
      selectedDateOfBirth,
      userEmail: userEmail.trim(),
      isDateOfBirthValid,
      isUserEmailValid: isAdditionalEmailValid,
      addTraveler: false,
    });
  }, [
    firstName,
    lastName,
    selectedDateOfBirth,
    userEmail,
    isDateOfBirthValid,
    isAdditionalEmailValid,
    onStepsButtonClick,
  ]);

  const handleAddTravelerClick = useCallback(() => {
    onStepsButtonClick({
      firstName,
      lastName,
      selectedDateOfBirth,
      userEmail: userEmail.trim(),
      isDateOfBirthValid,
      isUserEmailValid: isAdditionalEmailValid,
      addTraveler: true,
    });
  }, [
    firstName,
    lastName,
    selectedDateOfBirth,
    userEmail,
    isDateOfBirthValid,
    isAdditionalEmailValid,
    onStepsButtonClick,
  ]);

  const additionalButton = useMemo(
    () =>
      currentTravelerIndex < 2 ? undefined : (
        <Button
          className={CLASS_ADD_TRAVELLER_BUTTON}
          variant="outlined"
          disabled={
            !isAdditionalEmailValid ||
            !isDateOfBirthValid ||
            !isFirstNameValid ||
            !isLastNameValid
          }
          onClick={handleAddTravelerClick}
        >
          + Add Traveler
        </Button>
      ),
    [
      isAdditionalEmailValid,
      isDateOfBirthValid,
      isFirstNameValid,
      isLastNameValid,
      handleAddTravelerClick,
      currentTravelerIndex,
    ]
  );

  useLayoutEffect(
    () => {
      if (firstNameRef.current) {
        firstNameRef.current?.focus();
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [firstNameRef.current]
  );

  return (
    <FillDetailsContainer
      additionalButton={additionalButton}
      title={t(TITLE, { name: capitalize(firstName) })}
      onStepsButtonClick={handleStepsButtonClick}
      isStepsButtonDisabled={
        !isAdditionalEmailValid ||
        !isDateOfBirthValid ||
        !isFirstNameValid ||
        !isLastNameValid
      }
      subtitleWidget={
        <Box component="div" className={CLASS_TRAVELER_ORDINAL}>
          <IconButton
            color="primary"
            aria-label="go to previous traveler"
            onClick={handlePreviousTravelerClick}
            component="span"
          >
            <ChevronLeftIcon />
          </IconButton>
          <Typography component="span">
            {t(TRAVELER_ORDINAL_BY_COUNT(currentTravelerIndex + 1), {
              count: currentTravelerIndex + 1,
            })}
          </Typography>
          <span />
        </Box>
      }
    >
      <Box className={CLASS_FORM_FIELDS_SECTION}>
        <FormField
          onFocus={onFirstNameFocus}
          onBlur={onFirstNameBlur}
          hasError={firstNameHasError}
          className={classnames(CLASS_DETAILS_INPUT_NAME, SECTION_FIRST_NAME)}
        >
          <TextFieldWrapper
            inputRef={firstNameRef}
            label={t(FIRST_NAME_PLACEHOLDER_TRANSLATION_KEY)}
            value={firstName}
            onChange={onChangeFirst}
            submitCallback={handleStepsButtonClick}
          />
        </FormField>
        <FormField
          onFocus={onLastNameFocus}
          onBlur={onLastNameBlur}
          hasError={lastNameHasError}
          className={classnames(CLASS_DETAILS_INPUT_NAME, SECTION_LAST_NAME)}
        >
          <TextFieldWrapper
            label={t(LAST_NAME_PLACEHOLDER_TRANSLATION_KEY)}
            value={lastName}
            onChange={onChangeLast}
            submitCallback={handleStepsButtonClick}
          />
        </FormField>

        <FormField hasError={dateHasError} className={SECTION_DATE_OF_BIRTH}>
          <FayeBirthDateField
            showError={dateHasError}
            isFocused={isDateOfBirthFocused}
            onFieldFocus={onFieldDateFocus}
            onFieldBlur={onDateFieldBlur}
            onDateFieldChange={onDateOfBirthFieldChange}
            month={month}
            day={day}
            year={year}
          />
        </FormField>

        <FormField
          onBlur={onUserEmailBlur}
          onFocus={onUserEmailFocus}
          hasError={userEmailHasError}
          className={SECTION_EMAIL}
        >
          <TextFieldWrapper
            className="details-input"
            label={t(ASK_EMAIL_PLACEHOLDER)}
            value={userEmail}
            onChange={onUserEmailChange}
            submitCallback={handleStepsButtonClick}
            type="email"
          />
        </FormField>
      </Box>
    </FillDetailsContainer>
  );
};

export default AdditionalTravelerForm;
