import React from "react";
import Quote from "../features/quote/components";
import NotFound404 from "../common-components/indicators/404";
import { Redirect, Route, Switch } from "react-router-dom";
import { useUrlParamsParse } from "../hooks/util/useUrlParamsParse";
import { NOT_FOUND_404_URL, QUOTE_URL } from "./routes-collection";

const Routes = () => {
  useUrlParamsParse();

  return (
    <Switch>
      <Route path={NOT_FOUND_404_URL}>
        <NotFound404 />
      </Route>
      <Route path={QUOTE_URL}>
        <Quote />
      </Route>
      <Redirect to={QUOTE_URL} />
    </Switch>
  );
};

export default Routes;
