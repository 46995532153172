import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, Typography } from "@mui/material";

import { debounce } from "lodash-es";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "i18n";

import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import { formattedPrice } from "utils/texts";

import { selectQuoteDTO } from "features/quote/slice/selectors";
import { updateTripCost } from "features/quote/slice";

import TripCostControllerContainer from "./styled";
import {
  TRIP_COVERAGE_COST_STEP,
  TRIP_COVERAGE_MAX_COST_STEP,
  TRIP_COVERAGE_MIN_COST_STEP,
} from "../../../../../../constants";
import {
  CLASS_BUTTON_CHANGE_COST,
  CLASS_PRICE,
  CLASS_ESTIMATED_TRIP_COST,
  CLASSNAME_TRIP_COST_CONTROLLER_CONTAINER,
  TRIP_COST_CONTROLLER_TITLE,
  TRANSLATION_KEY_PREFIX,
  TripCoverageProps,
  UPDATE_REDUX_TRIP_COST_DEBOUNCE,
  CLASSNAME_TRIP_COST_CONTROLLER,
  TRIP_COST_CONTROLLER_DESCRIPTION,
  CLASS_TRIP_COST_DESCRIPTION,
} from "./constants";

const TripCoverage: React.FC<TripCoverageProps> = () => {
  const [tripCost, setTripCost] = useState<number>(TRIP_COVERAGE_COST_STEP);

  const dispatch = useAppDispatch();
  const { t } = useTranslation(TRANSLATION, {
    keyPrefix: TRANSLATION_KEY_PREFIX,
  });
  const quoteDto = useAppSelector(selectQuoteDTO);

  const debouncedUpdateTrip = useMemo(
    () =>
      debounce(
        (newTripCost: number) => dispatch(updateTripCost(newTripCost)),
        UPDATE_REDUX_TRIP_COST_DEBOUNCE
      ),
    [dispatch]
  );

  const handleClickPlus = useCallback(() => {
    if (tripCost < TRIP_COVERAGE_MAX_COST_STEP) {
      const newTripCost = tripCost + TRIP_COVERAGE_COST_STEP;
      setTripCost(newTripCost);
      debouncedUpdateTrip(newTripCost);
    }
  }, [tripCost, debouncedUpdateTrip]);

  const handleClickMinus = useCallback(() => {
    if (tripCost > TRIP_COVERAGE_COST_STEP) {
      const newTripCost = tripCost - TRIP_COVERAGE_COST_STEP;
      setTripCost(newTripCost);
      debouncedUpdateTrip(newTripCost);
    }
  }, [tripCost, debouncedUpdateTrip]);

  useEffect(() => {
    if (quoteDto?.tripCost) {
      setTripCost(quoteDto.tripCost);
    }
  }, [quoteDto?.tripCost]);

  return (
    <TripCostControllerContainer
      className={CLASSNAME_TRIP_COST_CONTROLLER_CONTAINER}
    >
      <Typography component={"span"} className={CLASS_ESTIMATED_TRIP_COST}>
        {t(TRIP_COST_CONTROLLER_TITLE)}
      </Typography>
      <Box className={CLASSNAME_TRIP_COST_CONTROLLER}>
        <Button
          variant="contained"
          onClick={handleClickMinus}
          className={CLASS_BUTTON_CHANGE_COST}
          disabled={tripCost <= TRIP_COVERAGE_MIN_COST_STEP}
        >
          <Typography component={"div"}>-</Typography>
        </Button>
        <Typography component={"span"} className={CLASS_PRICE}>
          {formattedPrice(tripCost).split(".")[0]}
        </Typography>
        <Button
          variant="contained"
          onClick={handleClickPlus}
          className={CLASS_BUTTON_CHANGE_COST}
          disabled={tripCost >= TRIP_COVERAGE_MAX_COST_STEP}
        >
          <Typography component="div">+</Typography>
        </Button>
      </Box>
      <Typography component={"span"} className={CLASS_TRIP_COST_DESCRIPTION}>
        {t(TRIP_COST_CONTROLLER_DESCRIPTION)}
      </Typography>
    </TripCostControllerContainer>
  );
};

export default TripCoverage;
