export const TRANSLATION_KEY_PREFIX = "download-app";
export const KEY_ASSISTANCE_TITLE = "assistance.title";
export const KEY_ASSISTANCE_COMMENT = "assistance.comment";
export const KEY_CLAIMS_PROCESSING = "quick claims processing.title";
export const KEY_REAL_TIME_ALERTS = "real time alerts.title";

export const CLASS_APP_IMAGES_CONTAINER = "app-images-container";
export const CLASS_MAIN_APP_IMAGE = "main-app-image";
export const CLASS_SECONDARY_APP_IMAGE = "secondary-app-image";
export const CLASS_BUBBLE_ASSISTANCE = "bubble-assistance";
export const CLASS_BUBBLE_CLAIMS_PROCESSING = "bubble-claims-processing";
export const CLASS_BUBBLE_ALERTS = "bubble-alerts";

export interface AppSuggestionsProps {}
