import React, { useCallback, useEffect, useMemo, useState } from "react";
import usePlacesAutocomplete from "use-places-autocomplete";

import { Nullable, SearchRestrictions } from "types/general";
import { GooglePlace } from "types/quote-types/Locations";

import { mapToGooglePlace } from "utils/Places";
import { MAPS_LOADED_CALLBACK_NAME } from "utils/dynamic-scripts/google-maps";

const OK_STATUS = "OK";

const valueValidation = (value: Nullable<GooglePlace>[]) =>
  Boolean(value.length);

const useGooglePlacesMuiAutoComplete = (
  initialValue: Nullable<GooglePlace>[],
  searchRestrictions: SearchRestrictions,
  isMultiple: boolean
) => {
  const [selectedPlaces, setSelectedPlaces] = useState<Nullable<GooglePlace>[]>(
    []
  );
  const [valid, setValid] = useState<boolean>();
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const {
    setValue,
    suggestions: { status, data },
    clearSuggestions,
    ready,
  } = usePlacesAutocomplete({
    callbackName: MAPS_LOADED_CALLBACK_NAME,
    requestOptions: searchRestrictions ? searchRestrictions : {},
    debounce: 500,
    cache: searchRestrictions ? false : undefined,
  });

  const options: GooglePlace[] = useMemo(() => {
    if (status === OK_STATUS) {
      const filteredData = searchRestrictions?.filterCallBack
        ? searchRestrictions?.filterCallBack(data)
        : data;

      return filteredData.map(mapToGooglePlace);
    } else {
      return [];
    }
  }, [status, data, searchRestrictions]);
  const isAutoCompleteOpen: boolean = useMemo(
    () => data.length > 0 && isFocused,
    [data, isFocused]
  );

  const hasError = useMemo(
    () => valid === false && !isFocused,
    [valid, isFocused]
  );

  const validate = useCallback((selectedPlace) => {
    const isValid = valueValidation(selectedPlace);
    setValid(isValid);
  }, []);

  const handleChangeSelectedPlace = useCallback(
    (event: any, newValue: Nullable<GooglePlace>[] | Nullable<GooglePlace>) => {
      const selectedLocation: Nullable<GooglePlace>[] = Array.isArray(newValue)
        ? newValue
        : [newValue];
      setSelectedPlaces(selectedLocation);
      validate(selectedLocation);
      clearSuggestions();
    },
    [validate, clearSuggestions]
  );

  const handleTypePlace = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      if (!newValue) {
        clearSuggestions();
      }
      setValue(newValue);
    },
    [setValue, clearSuggestions]
  );

  const onFocus = useCallback(() => {
    if (!isFocused) {
      setIsFocused(true);
    }
    if (!valid) {
      setValid(undefined);
    }
  }, [isFocused, valid]);

  const onBlur = useCallback(() => {
    if (isFocused) {
      setIsFocused(false);
    }
    clearSuggestions();
  }, [isFocused, clearSuggestions]);

  useEffect(() => {
    setValid(undefined);
  }, []);

  useEffect(() => {
    if (initialValue.length) {
      validate(initialValue);
      setSelectedPlaces(initialValue ?? []);
    }
    // eslint-disable-next-line
  }, [validate]);

  return {
    validate,
    valid,
    ready,
    onTypedTextChange: handleTypePlace,
    onOptionChange: handleChangeSelectedPlace,
    options,
    selectedOption: isMultiple ? selectedPlaces : selectedPlaces[0],
    isAutoCompleteOpen,
    onBlur,
    onFocus,
    hasError,
  };
};

export default useGooglePlacesMuiAutoComplete;
