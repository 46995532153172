import React, { useCallback, useEffect, useMemo } from "react";
import { LinearProgress, Typography } from "@mui/material";
import Box from "@mui/material/Box";

import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "i18n";

import StepMessage from "common-components/display-utils/StepMessage";
import AgentAvatar from "common-components/display-utils/AgentAvatar";
import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";

import useDummyProgress from "./useDummyProgress";

import { setOfferLoaderShown } from "features/quote/slice";
import {
  calcQuotePrice,
  getQuote,
  getQuoteFromHash,
} from "features/quote/slice/thunks/quote";
import {
  selectIsDirectOffer,
  selectOfferLoaderShown,
} from "features/quote/slice/selectors";
import { STEP_TO_URL_MAP, StepName } from "features/quote/routes/url-map";
import { QUOTE_URL } from "routes/routes-collection";

import LoaderContainer from "./styled";
import {
  TRANSLATION_KEY_PREFIX,
  CALCULATING_KEY,
  LOADER_CONTAINER,
  TITLE_KEY,
  LoadingProps,
  CLASS_LOADER_CONTENT,
  CLASS_MESSAGE_WE_BUILD_YOUR_POLICY,
  CLASS_CALCULATING_POLICY,
  getRandomTimeRangeMaximumProgressPerWord,
  TITLE_RETRIEVING_OFFER_KEY,
  PAGE_NAME_OB_LOADER,
  LOADER_SHOWN,
  LOADER_HIDDEN,
} from "./constants";
import usePageViewEvent from "../../../../../../hooks/tracking/usePageViewEvent";
import classnames from "classnames";

const Loader: React.FC<LoadingProps> = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { t } = useTranslation(TRANSLATION, {
    keyPrefix: TRANSLATION_KEY_PREFIX,
  });

  const offerLoaderShown = useAppSelector(selectOfferLoaderShown);
  const isDirectOffer = useAppSelector(selectIsDirectOffer);

  usePageViewEvent({
    pageName: PAGE_NAME_OB_LOADER,
    disable: !offerLoaderShown,
  });

  const { progress, setProgress, progressFinished } = useDummyProgress(
    getRandomTimeRangeMaximumProgressPerWord(t(TITLE_KEY))
  );

  const getQuoteAndCalculate = useCallback(async () => {
    const getOfferAction = isDirectOffer ? getQuoteFromHash : getQuote;
    await dispatch(getOfferAction());
    setProgress((prevProgress) => prevProgress + 10);
    await dispatch(calcQuotePrice());
    setProgress((prevProgress) => prevProgress + 10);
  }, [dispatch, setProgress, isDirectOffer]);

  const showLoader = useMemo(
    () => offerLoaderShown && !progressFinished,
    [offerLoaderShown, progressFinished]
  );

  useEffect(() => {
    if (
      offerLoaderShown &&
      pathname === `${QUOTE_URL}${STEP_TO_URL_MAP[StepName.Offer]}`
    ) {
      getQuoteAndCalculate().then();
    }
  }, [offerLoaderShown, pathname, getQuoteAndCalculate]);

  useEffect(() => {
    if (progressFinished) {
      dispatch(setOfferLoaderShown(false));
    }
  }, [progressFinished, dispatch]);

  return (
    <LoaderContainer
      className={classnames(LOADER_CONTAINER, {
        [LOADER_SHOWN]: showLoader,
        [LOADER_HIDDEN]: !showLoader,
      })}
    >
      <Box className={CLASS_LOADER_CONTENT}>
        <AgentAvatar size="medium" />
        <StepMessage
          message={t(isDirectOffer ? TITLE_RETRIEVING_OFFER_KEY : TITLE_KEY)}
          className={CLASS_MESSAGE_WE_BUILD_YOUR_POLICY}
        />
        <Typography component="label" className={CLASS_CALCULATING_POLICY}>
          {t(CALCULATING_KEY)}
        </Typography>
        {showLoader && (
          <LinearProgress variant="determinate" value={progress} />
        )}
      </Box>
    </LoaderContainer>
  );
};

export default Loader;
