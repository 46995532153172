import React from "react";
import Box from "@mui/material/Box";
import StepMessage from "../StepMessage";
import { Typography } from "@mui/material";
import FillDetailsContainer from "./styled";
import {
  CLASS_BLANK,
  CLASS_DETAILS,
  CLASS_DETAILS_FIELDS,
  CLASS_DETAILS_TITLE,
  CLASS_FILL_DETAILS_CONTAINER,
  CLASS_FILL_DETAILS_MESSAGE,
  CLASS_FORM_BUTTONS_CONTAINER,
  CLASS_SECONDARY_TITLE,
  CLASS_STEP_BUTTON,
} from "./constants";
import AgentAvatar from "../AgentAvatar";
import StepHint from "../StepHint";
import StepsButton from "../StepsButton";
import { isAndroid, isIOS } from "react-device-detect";

interface FillDetailsProps {
  title: string;
  secondaryTitle?: string;
  subtitleWidget?: JSX.Element;
  additionalButton?: JSX.Element;
  hint?: string;

  onStepsButtonClick(): void;

  isStepsButtonDisabled?: boolean;
}

const FillDetails: React.FC<FillDetailsProps> = ({
  children,
  isStepsButtonDisabled,
  title,
  onStepsButtonClick,
  secondaryTitle,
  hint,
  additionalButton,
  subtitleWidget,
}) => {
  return (
    <FillDetailsContainer className={CLASS_FILL_DETAILS_CONTAINER}>
      <Box className={CLASS_DETAILS}>
        {!(isIOS || isAndroid) && <Box className={CLASS_BLANK} />}
        <Box className={CLASS_DETAILS_TITLE}>
          <AgentAvatar size="medium" />
          <StepMessage message={title} className={CLASS_FILL_DETAILS_MESSAGE} />
          {hint && <StepHint text={hint} />}
          {secondaryTitle && (
            <Typography component="div" className={CLASS_SECONDARY_TITLE}>
              {secondaryTitle}
            </Typography>
          )}
          {Boolean(subtitleWidget) && (
            <Typography component="div" className={CLASS_SECONDARY_TITLE}>
              {subtitleWidget}
            </Typography>
          )}
        </Box>
        <Box className={CLASS_DETAILS_FIELDS}>{children}</Box>
      </Box>
      <Box className={CLASS_FORM_BUTTONS_CONTAINER}>
        {additionalButton}
        <StepsButton
          onClick={onStepsButtonClick}
          disabled={isStepsButtonDisabled}
          className={CLASS_STEP_BUTTON}
        />
      </Box>
    </FillDetailsContainer>
  );
};

export default FillDetails;
