import React from "react";
import Box from "@mui/material/Box";
import { isIOS, isAndroid } from "react-device-detect";
import { ReactComponent as GooglePlay } from "../../../assets/svg/GooglePlay.svg";
import { ReactComponent as AppStore } from "../../../assets/svg/AppStore.svg";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "../../../i18n";
import { Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import classnames from "classnames";
import AppSuggestions from "./AppSuggestions";
import {
  CLASS_APP_SUGGESTIONS,
  CLASS_DOWNLOAD_APP,
  CLASS_DOWNLOAD_APP_LINK,
  CLASS_LINK_ANDROID,
  CLASS_LINK_IOS,
  CLASS_MAIN_TITLE,
  CLASS_SECONDARY_TITLE,
  CLASS_SECTION_CONTENT,
  DownloadAppProps,
  KEY_MAIN_TITLE,
  KEY_SECONDARY_TITLE,
  TRANSLATION_KEY_PREFIX,
  POLICY_URL,
  AppStoreName,
} from "./constants";
import DownloadAppContainer from "./styled";
import { handleTextWithNewLines } from "../../../utils/translations";
import { logEvent } from "../../../tracking/logger";
import EventName from "../../../tracking/mixpanel/EventName";
import { TrackerPropertyName } from "../../../tracking/mixpanel/PropertyNames";

const handleClickDownloadApp = (appStoreName: string) => () => {
  logEvent(EventName.PressedAppDownload, {
    [TrackerPropertyName.AppStore]: appStoreName,
  });
};

const DownloadApp: React.FC<DownloadAppProps> = () => {
  const { pathname } = useLocation();

  const { t } = useTranslation(TRANSLATION, {
    keyPrefix: TRANSLATION_KEY_PREFIX,
  });

  if (pathname !== POLICY_URL) return null;

  return (
    <DownloadAppContainer>
      <Box className={CLASS_SECTION_CONTENT}>
        <Typography className={CLASS_MAIN_TITLE} component={"h3"}>
          {handleTextWithNewLines(t(KEY_MAIN_TITLE))}
        </Typography>
        <Typography component="h4" className={CLASS_SECONDARY_TITLE}>
          {handleTextWithNewLines(t(KEY_SECONDARY_TITLE))}
        </Typography>
        <Box className={CLASS_DOWNLOAD_APP}>
          {!isAndroid && (
            <Button
              onClick={handleClickDownloadApp(AppStoreName.Apple)}
              href={"https://assets.withfaye.com/ios"}
              target={"_blank"}
              className={classnames(CLASS_DOWNLOAD_APP_LINK, CLASS_LINK_IOS)}
            >
              <AppStore />
            </Button>
          )}
          {!isIOS && (
            <Button
              onClick={handleClickDownloadApp(AppStoreName.Google)}
              href={"https://assets.withfaye.com/android"}
              target={"_blank"}
              className={classnames(
                CLASS_DOWNLOAD_APP_LINK,
                CLASS_LINK_ANDROID
              )}
            >
              <GooglePlay />
            </Button>
          )}
        </Box>

        <Box className={CLASS_APP_SUGGESTIONS}>
          <AppSuggestions />
        </Box>
      </Box>
    </DownloadAppContainer>
  );
};

export default DownloadApp;
