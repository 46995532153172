import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Box from "@mui/material/Box";

import { ReactComponent as Lock } from "assets/svg/Lock.svg";

import FormField from "../../../../../../common-components/display-utils/FormField/FormField";
import { ReactComponent as PoweredByStripe } from "../../../../../../assets/svg/powered-by-stripe.svg";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import Button from "@mui/material/Button";
import { LinearProgress, useTheme } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../hooks/redux-hooks";
import {
  selectQuotePaymentProcess,
  selectSavedQuoteId,
  selectTotalPrice,
} from "../../../../slice/selectors";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "../../../../../../i18n";
import { payWithCreditCard, saveQuote } from "../../../../slice/thunks/quote";
import { TypeFayeTheme } from "../../../../../../theme";
import stripeJs from "@stripe/stripe-js";
import {
  CARD_CVC_KEY,
  CARD_EXPIRATION_DATE_KEY,
  CARD_NUMBER_KEY,
  ContentFormProps,
  getPaymentElementStyle,
  STRIPE_TOKEN_ERROR,
  TRANSLATION_KEY_PREFIX,
} from "./constants";
import PaymentFormContainer from "./styled";
import CheckoutPaymentRequestButton from "./CheckoutPaymentRequestButton";
import { setPaymentErrorMessage } from "../../../../slice";
import { trackMadePayment } from "../../../../slice/thunks/payments";
import { PAYMENT_TYPE_CREDIT_CARD } from "../../../../../../constants";

const PaymentForm: React.FC<ContentFormProps> = () => {
  const [payButtonClicked, setPayButtonClicked] = useState<boolean>(false);
  const [cardNumberComplete, setCardNumberComplete] = useState<boolean>(false);
  const [cardExpiryComplete, setCardExpiryComplete] = useState<boolean>(false);
  const [cardCvcComplete, setCardCvcComplete] = useState<boolean>(false);

  const theme: TypeFayeTheme = useTheme();
  const payButtonRef = useRef<HTMLButtonElement>();
  const [token, setToken] = useState<string>("");
  const dispatch = useAppDispatch();
  const { t } = useTranslation(TRANSLATION, {
    keyPrefix: TRANSLATION_KEY_PREFIX,
  });
  const totalPrice = useAppSelector(selectTotalPrice);
  const paymentProcess = useAppSelector(selectQuotePaymentProcess);
  const savedQuoteId = useAppSelector(selectSavedQuoteId);

  const elements = useElements();
  const stripe = useStripe();

  const paymentInProgress = useMemo(
    () => Boolean(paymentProcess.inProcess) || Boolean(paymentProcess.success),
    [paymentProcess]
  );

  const formIsFull = useMemo(
    () => cardNumberComplete && cardExpiryComplete && cardCvcComplete,
    [cardNumberComplete, cardExpiryComplete, cardCvcComplete]
  );

  const isPaymentButtonDisabled = useMemo(
    () => !formIsFull || paymentInProgress || payButtonClicked,
    [formIsFull, paymentInProgress, payButtonClicked]
  );

  const handlePayClick = useCallback(
    async (event) => {
      event.preventDefault();
      setPayButtonClicked(true);
      if (stripe && elements) {
        const card = elements.getElement(CardNumberElement);
        if (card) {
          const token = await stripe.createToken(card);

          if (token && token?.token) {
            setToken(token.token!.id);
          } else {
            setToken("");
            dispatch(setPaymentErrorMessage(t(STRIPE_TOKEN_ERROR)));
            dispatch(
              trackMadePayment({
                paymentType: PAYMENT_TYPE_CREDIT_CARD,
                errorMessage: t(STRIPE_TOKEN_ERROR),
              })
            );
          }
        }
      }
    },
    [elements, stripe, dispatch, t]
  );

  const handleCardNumberReady = useCallback(() => {
    if (elements) {
      const cardNumberElement = elements.getElement(CardNumberElement);
      cardNumberElement?.focus();
    }
  }, [elements]);
  const handleCardNumberChange = useCallback(
    (event: stripeJs.StripeCardNumberElementChangeEvent) => {
      if (event.complete && elements) {
        const cardExpiryElement = elements.getElement(CardExpiryElement);
        cardExpiryElement?.focus();
        setCardNumberComplete(true);
      } else {
        setToken("");
        setCardNumberComplete(false);
      }
    },
    [elements]
  );

  const handleCardExpiryElementChange = useCallback(
    (event: stripeJs.StripeCardExpiryElementChangeEvent) => {
      if (event.complete && elements) {
        const cardCvcElement = elements.getElement(CardCvcElement);
        cardCvcElement?.focus();
        setCardExpiryComplete(true);
      } else {
        setCardExpiryComplete(false);
      }
    },
    [elements]
  );
  const handleCardCvcElementChange = useCallback(
    (event: stripeJs.StripeCardCvcElementChangeEvent) => {
      if (event.complete && elements) {
        setCardCvcComplete(true);
      } else {
        setCardCvcComplete(false);
      }
    },
    [elements]
  );

  useEffect(() => {
    if (Boolean(token) && !savedQuoteId) {
      dispatch(saveQuote());
    }
  }, [token, savedQuoteId, dispatch]);

  useEffect(() => {
    if (Boolean(token) && savedQuoteId) {
      dispatch(payWithCreditCard({ creditCardToken: token }));
    }
  }, [token, savedQuoteId, dispatch]);

  useLayoutEffect(() => {
    if (!isPaymentButtonDisabled) {
      payButtonRef.current?.focus();
    }
  }, [isPaymentButtonDisabled]);

  return (
    <PaymentFormContainer className="content-form">
      <CheckoutPaymentRequestButton />
      <Box className="payment-card-number">
        <FormField
          hint={t(CARD_NUMBER_KEY)}
          additionalHint={
            <a href="https://stripe.com/" target="_blank" rel="noreferrer">
              <PoweredByStripe />
            </a>
          }
        >
          <Box className="card-number-element-wrapper">
            <CardNumberElement
              options={{
                style: getPaymentElementStyle(theme),
                placeholder: "0000 0000 0000 0000",
              }}
              onReady={handleCardNumberReady}
              onChange={handleCardNumberChange}
              className="payment-field-element"
            />
            <Lock className="lock-icon" />
          </Box>
        </FormField>
      </Box>
      <Box className="payment-card-expiry">
        <FormField
          hint={t(CARD_EXPIRATION_DATE_KEY)}
          className="payment-card-expiry"
        >
          <CardExpiryElement
            options={{
              style: getPaymentElementStyle(theme),
            }}
            onChange={handleCardExpiryElementChange}
            className="payment-field-element"
          />
        </FormField>
      </Box>
      <Box className="payment-card-cvc">
        <FormField hint={t(CARD_CVC_KEY)}>
          <CardCvcElement
            options={{
              style: getPaymentElementStyle(theme),
              placeholder: "000",
            }}
            onChange={handleCardCvcElementChange}
            className="payment-field-element"
          />
        </FormField>
      </Box>
      <Button
        ref={payButtonRef as React.RefObject<HTMLButtonElement>}
        variant="contained"
        className="payment-button"
        disabled={isPaymentButtonDisabled}
        onClick={handlePayClick}
      >
        {paymentInProgress && (
          <LinearProgress className="paying-progress-bar" color="secondary" />
        )}
        Pay ${totalPrice}
      </Button>
    </PaymentFormContainer>
  );
};

export default PaymentForm;
