import React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";

import { WithThemeProps } from "types/general";
import { ThemeType } from "theme";

import {
  CLASS_BUBBLE_COMMENT,
  CLASS_BUBBLE_CONTAINER,
  CLASS_BUBBLE_CONTENT,
  CLASS_BUBBLE_TITLE,
} from "./constants";

const BubbleContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box {...props} />
))`
  &.${CLASS_BUBBLE_CONTAINER} {
    background-color: ${({ theme: { palette } }: ThemeType) =>
      palette.secondary.light};
    color: ${({ theme: { palette } }: ThemeType) => palette.primary.main};
    display: flex;
    flex-direction: column;
    align-items: center;
    aspect-ratio: 1 / 1;

    justify-content: center;
    border-radius: 50%;
    transform: none;
    transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    // ${({ theme }: ThemeType) => theme.breakpoints.down("lg")} {
    //  transform: scale(0);
    //}

    & > .${CLASS_BUBBLE_CONTENT} {
      & > .${CLASS_BUBBLE_TITLE}, .${CLASS_BUBBLE_COMMENT} {
        text-align: center;
        font-weight: 700;
        line-height: 134%;
      }

      & > .${CLASS_BUBBLE_TITLE} {
        font-size: clamp(12px, 1.9vw, 24px);
      }

      & > .${CLASS_BUBBLE_COMMENT} {
        font-size: clamp(8px, 1vw, 12px);
      }
    }
  }
`;

export default BubbleContainer;
