import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import DriverChip from "../DriverChip";
import classnames from "classnames";
import {
  CLASS_DRIVERS_CHIPS_CONTAINER,
  CLASS_DRIVERS_CONTAINER,
  CLASS_DRIVERS_PICKER_TITLE,
  DriversPickerProps,
} from "./constants";
import DriversPickerContainer from "./styled";

const DriversPicker: React.FC<DriversPickerProps> = ({
  className,
  travelers,
}) => {
  const driversChips = travelers.map((traveler, index) => (
    <DriverChip
      key={`traveler-${index}-${traveler?.firstName}-${traveler?.lastName}`}
      traveler={traveler!}
      travelerIndex={index}
    />
  ));

  return (
    <DriversPickerContainer
      className={classnames(CLASS_DRIVERS_CONTAINER, className)}
    >
      <Typography className={CLASS_DRIVERS_PICKER_TITLE} component="label">
        Select drivers to cover:
      </Typography>
      <Box className={CLASS_DRIVERS_CHIPS_CONTAINER}>{driversChips}</Box>
    </DriversPickerContainer>
  );
};

export default DriversPicker;
