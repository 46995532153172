import React from "react";

import { useTranslation } from "react-i18next";
import { TRANSLATION } from "i18n";

import {
  CLASS_DISCLAIMER_CONTAINER,
  DisclaimerProps,
  KEY_DISCLAIMER,
  TRANSLATION_KEY_PREFIX,
} from "./constants";
import DisclaimerContainer from "./styled";

const Disclaimer: React.FC<DisclaimerProps> = () => {
  const { t } = useTranslation(TRANSLATION, {
    keyPrefix: TRANSLATION_KEY_PREFIX,
  });

  return (
    <DisclaimerContainer className={CLASS_DISCLAIMER_CONTAINER}>
      {t(KEY_DISCLAIMER)}
    </DisclaimerContainer>
  );
};

export default Disclaimer;
