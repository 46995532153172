export const NAME_URL = `/name`;
export const DESTINATION = `/destination`;
export const DATES = `/dates`;
export const TRAVELERS_COUNT_URL = `/travelers-count`;
export const SOLO_TRAVELER_INFO_URL = `/solo-traveler-info`;
export const PERSONAL_INFO_URL = `/personal-info`;
export const RESIDENCY_URL = `/residency`;
export const ADDITIONAL_TRAVELER_INFO_URL = `/additional-traveler-info`;
export const COMING_SOON_URL = `/coming-soon`;
export const QUOTE_OFFER_URL = `/offer`;
export const QUOTE_PAYMENT_URL = `/payment`;
export const QUOTE_CONFIRMED_URL = `/confirmation`;

export type AppRouteItem =
  | typeof NAME_URL
  | typeof DESTINATION
  | typeof DATES
  | typeof TRAVELERS_COUNT_URL
  | typeof SOLO_TRAVELER_INFO_URL
  | typeof PERSONAL_INFO_URL
  | typeof RESIDENCY_URL
  | typeof ADDITIONAL_TRAVELER_INFO_URL
  | typeof COMING_SOON_URL
  | typeof QUOTE_OFFER_URL
  | typeof QUOTE_PAYMENT_URL
  | typeof QUOTE_CONFIRMED_URL;
