import React from "react";
import { Typography } from "@mui/material";

import { useTranslation } from "react-i18next";
import { TRANSLATION } from "i18n";

import {
  COVID_BADGE_TEXT,
  TRANSLATION_KEY_PREFIX,
} from "../../BaseCoverageSection/constants";
import { CLASS_COVID_BADGE } from "../constants";
import CovidBadgeContainer from "./styled";
import { CovidBadgeProps } from "./constants";

const CovidBadge: React.FC<CovidBadgeProps> = () => {
  const { t } = useTranslation(TRANSLATION, {
    keyPrefix: TRANSLATION_KEY_PREFIX,
  });
  return (
    <CovidBadgeContainer className={CLASS_COVID_BADGE}>
      <Typography>{t(COVID_BADGE_TEXT)}</Typography>
    </CovidBadgeContainer>
  );
};

export default CovidBadge;
