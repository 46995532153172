import { SearchRestrictions } from "types/general";
import { getSupportedCountriesList } from "./utils/destinations";
import { QUOTE_URL } from "./routes/routes-collection";
import { STEP_TO_URL_MAP, StepName } from "./features/quote/routes/url-map";

export const PAYMENT_SUCCESS_STATUS_CODE = 200;

export const HEADER_UNITS_HEIGHT_DESKTOP = 9.75;
export const HEADER_UNITS_HEIGHT_MOBILE = 7.75;
export const FORM_FIELD_UNITS_HEIGHT = 6.5;

export const CLASS_FORM_FIELDS_SECTION = "form-fields-section";
export const CLASS_FORM_FIELDS_SECTION_UNITS_WIDTH = 52;

export const COMPONENT_TYPE_STREET_NUMBER = "street_number";
export const COMPONENT_TYPE_STREET = "route";
export const COMPONENT_TYPE_ZIP_CODE = "postal_code";
export const COMPONENT_TYPE_COUNTRY = "country";
export const COMPONENT_TYPE_LOCALITY = "locality";
export const COMPONENT_TYPE_SUB_LOCALITY = "sublocality";
export const COMPONENT_TYPE_AREA_LEVEL1 = "administrative_area_level_1";
export const COMPONENT_TYPE_STREET_ADDRESS = "street_address";
export const COMPONENT_TYPE_PREMISE = "premise";

export const APARTMENT_STRING_MAXIMUM_CHARACTERS = 7;

export const UNITED_STATES = "us";
const PLACES_SCOPE_ADDRESS = "address";
const PLACES_SCOPE_REGIONS = "(regions)";

export const PAYMENT_TYPE_CREDIT_CARD = "Credit Card";
export const PAYMENT_LABEL = "Faye Travel Insurance";

export const UNSUPPORTED_STATES = [
  "HI",
  "IN",
  "WA",
  "MO",
  "MT",
  "NY",
  "PA",
  "DC",
];

export const UNSUPPORTED_COUNTRIES = [
  "IRAN",
  "IRAQ",
  "SYRIA",
  "NORTH KOREA",
  "UKRAINE",
  "RUSSIA",
];

export const MIN_DATE = new Date(2022, 3, 4, 0, 0, 0, 0);

export const GOOGLE_PLACES_RESIDENCY_ADDRESS_SEARCH_FILTER: SearchRestrictions =
  {
    componentRestrictions: { country: UNITED_STATES },
    types: [PLACES_SCOPE_ADDRESS],
  };

export const GOOGLE_PLACES_DESTINATION_SEARCH_FILTER: SearchRestrictions = {
  types: [PLACES_SCOPE_REGIONS],
  filterCallBack: getSupportedCountriesList,
};

export const TRIP_COVERAGE_COST_STEP = 500;
export const TRIP_COVERAGE_MIN_COST_STEP = TRIP_COVERAGE_COST_STEP;
export const TRIP_COVERAGE_MAX_COST_STEP = 40000;

export const GATSBY_URL =
  process.env.REACT_APP_GATSBY_URL ||
  `${QUOTE_URL}${STEP_TO_URL_MAP[StepName.Name]}`;
