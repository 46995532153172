import React from "react";
import { NOT_FOUND_404_URL } from "../../../routes/routes-collection";
import { useLocation } from "react-router-dom";
import FooterContainer from "./styled";

const Footer: React.FC = ({ children }) => {
  const location = useLocation();

  if (location.pathname === NOT_FOUND_404_URL) {
    return null;
  }

  return <FooterContainer>{children}</FooterContainer>;
};

export default Footer;
