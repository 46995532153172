import { Dict } from "types/general";

const REGEX_2_LETTERS_MINIMUM = /[\p{Letter}\p{Mark}]{2,}/gu;
const REGEX_EMAIL =
  // eslint-disable-next-line
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const ZIP_CODE_REGEX = /\d{5}/g;

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const LettersToWeightDict: Dict<string, number> = {
  a: 1,
  b: 2,
  c: 3,
  d: 4,
  e: 5,
  f: 6,
  g: 7,
  h: 8,
  i: 9,
  j: 10,
  k: 20,
  l: 30,
  m: 40,
  n: 50,
  o: 60,
  p: 70,
  q: 80,
  r: 90,
  s: 100,
  t: 200,
  u: 300,
  v: 400,
  w: 500,
  x: 600,
  y: 700,
  z: 800,
  "1": 1,
  "2": 2,
  "3": 3,
  "4": 4,
  "5": 5,
  "6": 6,
  "7": 7,
  "8": 8,
  "9": 9,
  "0": 0,
};

export const isNameValid = (name: string): boolean =>
  Boolean(name.match(REGEX_2_LETTERS_MINIMUM)?.length);

export const isMinimum2Characters = (text: string): boolean => text.length >= 2;

export const isEmailTextValid = (email: string): boolean =>
  Boolean(email.trim().match(REGEX_EMAIL)?.length);

export const isZipCodeValid = (zipCode: string): boolean =>
  Boolean(zipCode.match(ZIP_CODE_REGEX)?.length);

export const isNonEmptyText = (text: string): boolean => text.length >= 1;

export const capitalize = (word: string) =>
  word.toLowerCase().replace(/\w/, (firstLetter) => firstLetter.toUpperCase());

export const getGeometryValue = (text: string | undefined = "") =>
  text
    .toLowerCase()
    .split("")
    .reduce((sum, currChar) => sum + (LettersToWeightDict[currChar] ?? 0), 0);

export const limitStringLength = (maxLength: number) => (text: string) =>
  text.slice(0, maxLength);

export const formattedPrice = (price: number | string) =>
  currencyFormatter.format(typeof price === "number" ? price : Number(price));

const isOverflown = ({
  clientWidth,
  clientHeight,
  scrollWidth,
  scrollHeight,
}: Element) => scrollWidth > clientWidth || scrollHeight > clientHeight;

export const resizeText = ({
  element,
  minSize,
  maxSize,
  step = 1,
  unit = "px",
}: {
  element: Element;
  unit?: string;
  step?: number;
  minSize: number;
  maxSize: number;
}) => {
  let size = minSize;
  let overflow = false;

  const parent = element.parentNode;

  while (!overflow && size <= maxSize) {
    (element as any).style.fontSize = `${size}${unit}`;
    overflow = isOverflown(parent as Element);

    if (!overflow) size += step;
  }

  // revert to last state where no overflow happened
  (element as any).style.fontSize = `${size - step}${unit}`;
  (element as any).style.lineHeight = `${(size - step) * 1.2}${unit}`;
};
