export const TRANSLATION_KEY_PREFIX = "quote.steps.offer.bottom panel";
export const TRAVELLERS_COUNT_KEY = "travellers_count";
export const COVERAGES_WITH_ADDONS_KEY = "coverages_with_addons";
export const BASE_COVERAGE_ADDONS_KEY = "base coverage";
export const BOTTOM_PANEL_PAYMENT_BUTTON_TRANSLATION_KEY = "payment button";

export const CLASS_QUOTE_PANEL = "quote-panel";
export const CLASS_PANEL_COLLAPSE = "panel-collapse";
export const CLASS_TRIP_DESTINATIONS_AND_DATES = "trip-destinations-and-dates";
export const CLASS_TRAVELERS = "travelers";
export const CLASS_COVERAGES = "coverages";
export const CLASS_PAY_BUTTON = "pay-button";

export interface QuotePanelProps {}
