export const TRANSLATION_KEY_PREFIX = "quote.steps.destination";
export const TITLE_TRANSLATION_KEY = "title";
export const SECONDARY_TITLE_TRANSLATION_KEY = "secondary title";
export const INPUT_PLACEHOLDER_TRANSLATION_KEY = "input placeholder";
export const BUBBLE_MESSAGE_TRANSLATION_KEY = "bubble message";

export const CLASS_DESTINATIONS_STEP_CONTAINER = "destinations-step-container";

export const PAGE_NAME_DESTINATIONS = "Destinations"

export interface StartProps {}
