import React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";

import { WithThemeProps } from "types/general";
import { ThemeType } from "theme";

const BaseCoverageSectionContainer = styled(
  (props: BoxProps & WithThemeProps) => <Box {...props} />
)`
  &.base-coverages {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(6)};

    ${({ theme }: ThemeType) => theme.breakpoints.down("tablet")} {
      gap: ${({ theme }) => theme.spacing(7)};
    }

    & > .base-coverages-title {
      text-align: center;
      max-width: unset;
    }

    & > .base-coverages-secondary-title {
      max-width: clamp(
        ${({ theme: { spacing } }: ThemeType) =>
          `${spacing(20)}, 90vw, ${spacing(100)}`}
      );
      padding: ${({ theme: { spacing } }: ThemeType) => spacing(0, 1)};
      text-align: center;
      font-size: 24px;
      line-height: 163.5%;
    }

    & > .coverages-container {
      display: flex;
      gap: ${({ theme }) => theme.spacing(5)};
    }

    & > .coverages-expanded-details-container {
      display: flex;
      align-items: flex-start;
      gap: ${({ theme }) => theme.spacing(5)};
    }
  }
`;

export default BaseCoverageSectionContainer;
