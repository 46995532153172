import { Coverage } from "./quote.dto";
import { Dict, Nullable } from "../general";
import { PAYMENT_SUCCESS_STATUS_CODE } from "../../constants";

export * from "./Coverage";
export * from "./DateRangeChip";
export * from "./Locations";
export * from "./PaymentScreen";
export * from "./quote.dto";
export * from "./QuoteProcesses";

export enum PricingTypeEnum {
  general = "general",
  age = "age",
  origin = "origin",
  destination = "destination",
}

export type AutoCompleteOption = {
  label: string;
  value: string;
};

export type Name = {
  firstName: string;
  lastName: string;
};

export type Traveler = Name & {
  dateOfBirth: string;
  mainTraveller: boolean;
  email: string;
  isDriver: boolean;
};

export type FlightInfo = {
  flightNumber: string;
  date: Date;
};

export type Route = {
  flightNumber: string;
  date: Date;
};

export type PersonalPricing = {
  name: string;
  value: number;
  date: string;
};

export type PricingObjectResponseDTO = {
  coveragesPrice: Dict<string, number>;
  prices: PersonalPricing[];
  isTest?: boolean;
};

export type CoverageItem = { coverage: Coverage; coverageIndex: number };

export type SaveQuoteResponse = {
  policies: number[];
  token: {
    error: Nullable<any>;
    token: string;
  };
};

export interface CarDateDto {
  startDate: string;
  endDate: string;
}

export enum DatePart {
  Month = "Month",
  Day = "Day",
  Year = "Year",
}

export type QuotePaymentResponse =
  | typeof PAYMENT_SUCCESS_STATUS_CODE
  | {
      statusCode: number;
      message: string;
    };
