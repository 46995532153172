import React from "react";
import { styled } from "@mui/system";
import { Typography, TypographyProps } from "@mui/material";
import { WithThemeProps } from "types/general";
import { ThemeType } from "theme";

const ErrorMessage = styled((props: TypographyProps & WithThemeProps) => (
  <Typography {...props} />
))`
  color: ${({
    theme: {
      palette: { error },
    },
  }: ThemeType) => error.main};
  padding-top: ${({ theme: { spacing } }: ThemeType) => spacing(0.125)};
  font-size: 14px;
`;

export default ErrorMessage;
