import {
  DestinationGoogleData,
  GooglePlace,
  PlaceDetails,
  RegionProperties,
} from "../types/quote-types";
import { getGoogleData } from "./Places";
import i18next from "i18next";
import { GooglePlaceDetailsResponse, Nullable } from "../types";
import {
  COMPONENT_TYPE_AREA_LEVEL1,
  COMPONENT_TYPE_COUNTRY,
  COMPONENT_TYPE_LOCALITY,
  UNSUPPORTED_COUNTRIES,
} from "../constants";
import { GeocodeResult, Suggestion } from "use-places-autocomplete";

export const getLimitedMultipleDestinationsList = (
  destinations: DestinationGoogleData[]
) => {
  const firstDestination: DestinationGoogleData = destinations[0];
  const extraDestinations: DestinationGoogleData[] = destinations.slice(1);

  const extraDestinationsText = extraDestinations.length
    ? i18next.t("quote.steps.offer.bottom panel.additional destinations", {
        count: extraDestinations.length,
      })
    : "";

  return `${destinationNameForDisplay(
    firstDestination
  )}${extraDestinationsText}`;
};

const getDestinationDetailsFromGooglePlace = (
  placeDetailsResponse: GooglePlaceDetailsResponse,
  id: string,
  label: string
): PlaceDetails => {
  const addressComponentsInitValue: RegionProperties = {
    country: "",
    areaLevel1: "",
    locality: "",
  };

  const addressComponents: RegionProperties =
    placeDetailsResponse.address_components?.reduce(
      (details, currentComponent) => {
        const componentTypes = currentComponent.types;
        switch (true) {
          case componentTypes.indexOf(COMPONENT_TYPE_COUNTRY) !== -1:
            details.country = currentComponent.short_name;
            break;
          case componentTypes.indexOf(COMPONENT_TYPE_LOCALITY) !== -1:
            details.locality = currentComponent.short_name;
            break;
          case componentTypes.indexOf(COMPONENT_TYPE_AREA_LEVEL1) !== -1:
            details.areaLevel1 = currentComponent.short_name;
            break;
        }
        return details;
      },
      { ...addressComponentsInitValue }
    ) ?? addressComponentsInitValue;
  return { ...addressComponents, googleId: id, label };
};

export const getDestinationsPlaceDetails = (
  googlePlaceResults: GooglePlaceDetailsResponse[],
  destinations: GooglePlace[]
): PlaceDetails[] =>
  googlePlaceResults.map((googlePlaceResult, index) =>
    getDestinationDetailsFromGooglePlace(
      googlePlaceResult,
      destinations[index].googleId,
      `${destinations[index].label || googlePlaceResult.formatted_address}`
    )
  );

export const addressComponentReduceCallback = (
  result: string,
  curr: GeocodeResult["address_components"][0]
) => {
  let nextResult = result;
  if (nextResult) return nextResult;

  switch (true) {
    case curr.types.includes(COMPONENT_TYPE_LOCALITY): {
      nextResult = curr.long_name;
      break;
    }
    case curr.types.includes(COMPONENT_TYPE_AREA_LEVEL1) ||
      curr.types.includes(COMPONENT_TYPE_COUNTRY): {
      nextResult = curr.long_name;
    }
  }

  return nextResult ?? "";
};

export const destinationNameForDisplay = (
  destinationDetails: DestinationGoogleData
): string => {
  let result: string;

  if (destinationDetails.terms) {
    result = destinationDetails.terms[0].value;
  } else if (destinationDetails?.address_components) {
    result = destinationDetails.address_components.reduce(
      addressComponentReduceCallback,
      ""
    );
  } else {
    result = "";
  }

  return result;
};

export const getDestinationInitialValues = (
  places: Nullable<GooglePlace>[],
  destinationsGoogleData: DestinationGoogleData[]
): GooglePlace[] =>
  places.map((place) => {
    const placeTerms = destinationsGoogleData.find((destinationData) => {
      return destinationData.id === place?.googleId;
    });
    return {
      ...(placeTerms?.terms ? { terms: placeTerms.terms } : undefined),
      ...place!,
    };
  });

export const getDestinationPlacesGoogleData = async (
  places: GooglePlace[]
): Promise<DestinationGoogleData[]> => {
  const details: DestinationGoogleData[] = [];
  for (const place of places) {
    const googleResult: GooglePlaceDetailsResponse = await getGoogleData(place);
    details.push({ ...googleResult, id: place.googleId, terms: place.terms! });
  }
  return details;
};

export const getSupportedCountriesList = (
  results: Suggestion[] // Suggestion = google.maps.places.AutocompletePrediction
): Suggestion[] => {
  return results.filter(({ terms }) => {
    const termsLastIndex = terms.length - 1;
    const countryName = terms[termsLastIndex].value;
    return !UNSUPPORTED_COUNTRIES.includes(countryName.toUpperCase());
  });
};
