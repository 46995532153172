import React, { useCallback, useLayoutEffect } from "react";
import { TextFieldProps } from "@mui/material/TextField";
import { hasPressedEnter } from "../../../utils";
import TextFieldContainer from "./styled";
import { CLASS_TEXT_FIELD_CONTAINER, TextFieldWrapperProps } from "./constants";

const TextFieldWrapper = React.forwardRef<
  HTMLDivElement,
  TextFieldWrapperProps & TextFieldProps
>(({ autoFocus, submitCallback, topLabel, ...otherProps }, ref) => {
  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent) => {
      if (hasPressedEnter(event) && submitCallback) {
        submitCallback();
      }
    },
    [submitCallback]
  );

  useLayoutEffect(() => {
    if (autoFocus) {
      (
        ref as React.MutableRefObject<HTMLInputElement | undefined>
      )?.current?.focus();
    }
  }, [autoFocus, ref]);

  return (
    <TextFieldContainer
      className={CLASS_TEXT_FIELD_CONTAINER}
      inputRef={ref}
      onKeyPress={handleKeyPress}
      hint={topLabel}
      {...otherProps}
      autoComplete="off"
    />
  );
});

export default TextFieldWrapper;
