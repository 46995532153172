import React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";

import { WithThemeProps } from "types";
import { ThemeType } from "theme";

import {
  CLASS_POLICY_ID,
  CLASS_POLICY_ID_TEXT,
  CLASS_POLICY_LABEL,
} from "./constants";

const PolicyIdContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box {...props} />
))`
  &.${CLASS_POLICY_ID} {
    display: flex;
    justify-content: center;
    gap: ${({ theme: { spacing } }: ThemeType) => spacing(1)};
    margin: ${({ theme: { spacing } }: ThemeType) => spacing(1.5, 0)};

    & > .${CLASS_POLICY_ID_TEXT} {
      display: flex;
      flex-direction: column;

      & > .${CLASS_POLICY_LABEL} {
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;

        &:first-of-type {
          margin-right: ${({ theme: { spacing } }: ThemeType) => spacing(1)};
          font-size: 14px;
          line-height: 20px;
          color: ${({ theme: { palette } }: ThemeType) =>
            palette.p_grey.textDark};
        }

        &:last-of-type {
          font-weight: 600;
          font-size: 24px;
          line-height: 40px;
        }
      }
    }
  }
`;

export default PolicyIdContainer;
