import React from "react";

import classnames from "classnames";

import { CARD_CONTAINER, CardProps } from "./constants";
import CardContainer from "./styled";

const Card: React.FC<CardProps> = ({ children, className }) => {
  return (
    <CardContainer className={classnames(CARD_CONTAINER, className)}>
      {children}
    </CardContainer>
  );
};

export default Card;
