import { Coverage } from "types/quote-types/quote.dto";

export const CLASS_BASE_COVERAGE = "base-coverage";
export const CLASS_COVERAGE_IMAGE = "coverage-image-container";
export const CLASS_COVERAGE_TITLE = "coverage-title";
export const CLASS_COVERAGE_DESCRIPTION = "coverage-description";
export const CLASS_COVERAGE_DETAILS_CONTAINER = "coverage-details-container";
export const CLASS_COVERAGE_DETAILS_CONTENT = "coverage-details-content";
export const CLASS_COVID_BADGE = "covid-badge"

export interface BaseCoverageProps {
  coverage: Coverage;
}
