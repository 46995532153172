export interface MainTravelerPersonalProps {}

export const TRANSLATION_KEY_PREFIX = "quote.steps.personal info";
export const TITLE_KEY = "multiple travelers info.title";
export const SECONDARY_TITLE_KEY =
  "multiple travelers info.secondary title first traveler";
export const ASK_EMAIL_PLACEHOLDER = "ask email placeholder";

export const PAGE_NAME_MAIN_TRAVELER_DOB_AND_EMAIL =
  "Main Traveler DOB & Email";
