import { createAsyncThunk } from "@reduxjs/toolkit";

import * as Sentry from "@sentry/react";
import {
  PaymentIntent,
  PaymentRequestPaymentMethodEvent,
  Stripe,
} from "@stripe/stripe-js";

import QuoteDTO from "types/quote-types/quote.dto";
import { RootState } from "types/redux-types";
import {
  DestinationGoogleData,
  GooglePlace,
  PlaceDetails,
  PricingObjectResponseDTO,
  QuotePaymentResponse,
  SaveQuoteResponse,
} from "types/quote-types";

import ApiWrapper from "utils/ApiWrapper";
import { signInUsingCustomToken } from "features/authentication/slice/thunks/auth";
import {
  selectIsDomesticTraveling,
  selectSavedQuoteIds,
  selectTotalPrice,
} from "../selectors";
import { getDestinationPlacesGoogleData } from "../../../../utils/destinations";
import { sendPurchaseDetailsToGtm } from "../../../../tracking/google-tag-manager";
import {
  PAYMENT_SUCCESS_STATUS_CODE,
  PAYMENT_TYPE_CREDIT_CARD,
} from "../../../../constants";
import { getCustomError } from "../../../../utils";
import { trackMadePayment } from "./payments";
import loadGoogleMapsMaps from "../../../../utils/dynamic-scripts/google-maps";

const QUOTE_URL = "/quote";
const SAVE_QUOTE_URL = `${QUOTE_URL}/save`;
const CALCULATE_QUOTE_PRICE_URL = `${QUOTE_URL}/calculate`;
const CHARGE_QUOTE_URL = `${QUOTE_URL}/payment`;
const GET_POLICY_PDF_URL_PREFIX = `${QUOTE_URL}/policy`;
const GET_POLICY_RECEIPT_URL_PREFIX = `${QUOTE_URL}/receipt/policy`;
const PAYMENT_INTENT_URL = `${QUOTE_URL}/payment/intent/`;

const getQuoteStructureByParams = ({
  destination,
  tripStartDate,
  tripEndDate,
  noTravellers,
  residency,
}: {
  destination: string;
  residency: string;
  tripStartDate: string;
  tripEndDate: string;
  noTravellers: number;
}) =>
  `${QUOTE_URL}/destination/${destination}/date/${tripStartDate}/no-travellers/${noTravellers}/residency/${residency}/endDate/${tripEndDate}`;

const getUrlQuoteByHash = ({ hash, email }: { hash: string; email: string }) =>
  `${QUOTE_URL}/get/offer/${hash}/email/${email}`;

const getPolicyPdfUrl = (policyId: number) =>
  `${GET_POLICY_PDF_URL_PREFIX}/${policyId.toString()}`;

const getPolicyReceiptUrl = (policyId: number) =>
  `${GET_POLICY_RECEIPT_URL_PREFIX}/${policyId.toString()}`;

const getPolicyDetailsUrl = (policyId: number) =>
  `/policy/userPolicy/${policyId}/user`;

export const getQuote = createAsyncThunk<any, undefined, { state: RootState }>(
  "quote/get",
  async (_, { getState }) => {
    const { destinations, tripStartDate, tripEndDate, travellers, residency } =
      getState().quote;

    return await ApiWrapper.request(
      getQuoteStructureByParams({
        destination: (destinations as PlaceDetails[])
          .map((destination) => destination.country)
          .join(","),
        residency: residency!.areaLevel1 as string,
        tripStartDate: tripStartDate!,
        tripEndDate: tripEndDate!,
        noTravellers: travellers.length,
      }),
      "GET"
    );
  }
);

export const getQuoteFromHash = createAsyncThunk<
  { quote: QuoteDTO; destinationsGoogleData: DestinationGoogleData[] },
  undefined,
  { state: RootState }
>("quote/getQuoteFromHash", async (_, { getState }) => {
  const hash = getState().quote.hash;
  const email = getState().quote.email;
  const quoteDtoResponse = await ApiWrapper.request<QuoteDTO>(
    getUrlQuoteByHash({ hash, email }),
    "GET"
  );

  await loadGoogleMapsMaps(() => {});

  const destinationsGoogleData: DestinationGoogleData[] =
    await getDestinationPlacesGoogleData(
      quoteDtoResponse.destinations as GooglePlace[]
    );

  return {
    quote: quoteDtoResponse,
    destinationsGoogleData,
  };
});

export const saveQuote = createAsyncThunk<
  number[],
  undefined,
  { state: RootState }
>("quote/save", async (_, { getState, dispatch }) => {
  const {
    quoteDTO,
    calculationProcess,
    paymentProcess,
    datesAndOrDestinationAreKnown,
    destinationsGoogleData,
    offerLoaderShown,
    isTestUser,
    ...quoteUserData
  } = getState().quote;
  const dto = {
    ...quoteDTO,
    ...quoteUserData,
  };
  const result = await ApiWrapper.request<SaveQuoteResponse>(
    SAVE_QUOTE_URL,
    "POST",
    {
      data: dto,
    }
  );
  dispatch(signInUsingCustomToken(result.token.token));
  return result.policies;
});

export const calcQuotePrice = createAsyncThunk<
  PricingObjectResponseDTO,
  undefined,
  { state: RootState }
>("quote/calculatePrice", async (_, { getState }) => {
  const {
    quoteDTO,
    calculationProcess,
    paymentProcess,
    datesAndOrDestinationAreKnown,
    destinationsGoogleData,
    offerLoaderShown,
    isTestUser,
    ...quoteUserData
  } = getState().quote;
  const dto = {
    ...quoteDTO,
    ...quoteUserData,
  };

  const pricing = await ApiWrapper.request<PricingObjectResponseDTO>(
    CALCULATE_QUOTE_PRICE_URL,
    "POST",
    {
      data: dto,
    }
  );

  return pricing;
});

export const payWithCreditCard = createAsyncThunk<
  QuotePaymentResponse,
  {
    creditCardToken?: string;
  },
  { state: RootState }
>("quote/payment", async ({ creditCardToken }, { getState, dispatch }) => {
  const { savedQuoteIds } = getState().quote;
  const paymentData = {
    paymentToken: creditCardToken,
    quoteId: Number(savedQuoteIds![0]),
  };

  const response = await ApiWrapper.request<QuotePaymentResponse>(
    CHARGE_QUOTE_URL,
    "POST",
    {
      data: paymentData,
    }
  );

  const errorMessage =
    response !== PAYMENT_SUCCESS_STATUS_CODE ? response.message : undefined;

  dispatch(
    trackMadePayment({
      paymentType: PAYMENT_TYPE_CREDIT_CARD,
      errorMessage,
    })
  );

  if (errorMessage) {
    throw new Error(errorMessage);
  }

  dispatch(getPolicyDetails());
  dispatch(getPolicyPDFs());
  dispatch(getPolicyReceipts());

  return response;
});

export const getPolicyPDFs = createAsyncThunk<
  string[],
  undefined,
  { state: RootState }
>("quote/getPolicyPdf", async (_, { getState }) => {
  const savedQuoteIds = selectSavedQuoteIds(getState());
  const policyPdfUrls = [];
  for (let quoteIndex = 0; quoteIndex < savedQuoteIds!.length; quoteIndex++) {
    const blob = await ApiWrapper.request<any>(
      getPolicyPdfUrl(savedQuoteIds![quoteIndex]),
      "GET",
      {
        headers: {
          "Content-Type": "application/pdf",
        },
        responseType: "arraybuffer",
      }
    );
    const file = new Blob([blob], { type: "application/pdf" });
    const url = URL.createObjectURL(file);
    policyPdfUrls.push(url);
  }
  return policyPdfUrls;
});

type ReceiptResponse = { description: string; receipt: string }[];

export const getPolicyReceipts = createAsyncThunk<
  ReceiptResponse,
  undefined,
  { state: RootState }
>("quote/getPolicyReceipts", async (_, { getState }) => {
  const savedQuoteIds = selectSavedQuoteIds(getState());
  return await ApiWrapper.request<ReceiptResponse>(
    getPolicyReceiptUrl(savedQuoteIds![0]),
    "GET",
    {}
  );
});

export const getPolicyDetails = createAsyncThunk<
  { quoteDTO: QuoteDTO },
  undefined,
  { state: RootState }
>("quote/getPolicyDetails", async (_, { getState }) => {
  const savedQuoteIds = selectSavedQuoteIds(getState());
  const totalPrice = selectTotalPrice(getState());
  const isDomestic = selectIsDomesticTraveling(getState());

  const quoteDTO = await ApiWrapper.request<QuoteDTO>(
    getPolicyDetailsUrl(savedQuoteIds![0]),
    "GET"
  );

  sendPurchaseDetailsToGtm({
    policyId: quoteDTO.userPolicyUniqueUUID,
    orderValue: Number(totalPrice),
    coverages: quoteDTO.coverages,
    isDomestic,
    travelersAmount: quoteDTO.travellers.length,
  });

  return { quoteDTO };
});

export const testUserPay = createAsyncThunk<void, void, { state: RootState }>(
  "quote/testUserPay",
  async (_, { dispatch }) => {
    await dispatch(saveQuote());
    dispatch(payWithCreditCard({ creditCardToken: "tok_dummy" }));
  }
);

export const payWithPaymentMethod = createAsyncThunk<
  QuotePaymentResponse,
  {
    paymentRequestPaymentMethodEvent: PaymentRequestPaymentMethodEvent;
    stripeInstance: Stripe;
  },
  { state: RootState }
>(
  "quote/payment",
  async (
    { paymentRequestPaymentMethodEvent, stripeInstance },
    { getState, dispatch }
  ) => {
    const { savedQuoteIds } = getState().quote;

    const {
      quoteDTO,
      calculationProcess,
      paymentProcess,
      datesAndOrDestinationAreKnown,
      destinationsGoogleData,
      offerLoaderShown,
      ...quoteUserData
    } = getState().quote;

    const dto = {
      ...quoteDTO,
      ...quoteUserData,
    };
    try {
      const {
        id: paymentIntentId,
        client_secret: clientSecret,
        status,
      } = await ApiWrapper.request<PaymentIntent>(PAYMENT_INTENT_URL, "POST", {
        data: dto,
      });

      const paymentData = {
        paymentIntent: paymentIntentId,
        paymentMethod: paymentRequestPaymentMethodEvent.paymentMethod.id,
        quoteId: Number(savedQuoteIds![0]),
      };

      const response = await ApiWrapper.request<QuotePaymentResponse>(
        CHARGE_QUOTE_URL,
        "POST",
        {
          data: paymentData,
        }
      );

      paymentRequestPaymentMethodEvent.complete("success");

      if (status === "requires_action") {
        const { error } = await stripeInstance.confirmCardPayment(
          clientSecret as string
        );
        if (error) {
          return { statusCode: -1, message: error.message as string };
        }
      }

      dispatch(getPolicyDetails());
      dispatch(getPolicyPDFs());
      dispatch(getPolicyReceipts());

      dispatch(
        trackMadePayment({
          paymentType: paymentRequestPaymentMethodEvent.walletName,
        })
      );

      return response;
    } catch (e) {
      paymentRequestPaymentMethodEvent.complete("fail");

      const paymentError = getCustomError({
        name: "Payment_failed",
        message: (e as Error).message as string,
      });

      dispatch(
        trackMadePayment({
          errorMessage: paymentError.message,
          paymentType: paymentRequestPaymentMethodEvent.walletName,
        })
      );

      Sentry.captureException(paymentError);
      throw paymentError;
    }
  }
);
