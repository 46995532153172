import React from "react";
import { styled, css } from "@mui/system";
import TextField from "@mui/material/TextField";

import FayeTheme, { ThemeType, TypeFayeTheme } from "theme";

import { TextFieldContainerProps, textFieldValueLength } from "./constants";

const theme = FayeTheme as TypeFayeTheme;

const transformInputUp = css`
  transform: translateY(${theme.spacing(1)});
`;

const transformInput0 = css`
  transform: translateY(0);
`;

const transformInputShrink = css`
  transform: translate(${theme.spacing(1.75)}, ${theme.spacing(1)}) scale(0.75);
`;

const TextFieldContainer = styled(({ ...props }: TextFieldContainerProps) => (
  <TextField {...props} />
))`
  background-color: ${({ theme }: ThemeType) => theme.palette.p_white.full};

  position: relative;

  overflow: visible;

  &::before {
    content: "${({ hint }) => hint ?? ""}";
    position: absolute;
    top: ${({ theme: { spacing } }: ThemeType) => spacing(-3.5)};
    font-size: 14px;
  }

  & .MuiOutlinedInput-root {
    & input {
      transition: transform 0.2s cubic-bezier(0, 0, 0.2, 1);
      ${({ value, inputProps, label }) =>
        label && textFieldValueLength(value, inputProps)
          ? transformInputUp
          : transformInput0};
    }

    &.Mui-focused {
      & input {
        transform: ${({ value, inputProps, label }) =>
          label && textFieldValueLength(value, inputProps)
            ? transformInput0
            : ""};
      }
    }
  }

  & .MuiInputLabel-root {
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    opacity: 1;
    color: ${({ theme: { palette } }: ThemeType) => palette.p_grey.border};
    transform: translate(
        ${({ theme: { spacing } }: ThemeType) =>
          `${spacing(1.5)}, ${spacing(1.5)}`}
      )
      scale(1);

    &.MuiInputLabel-shrink {
      color: ${({ theme: { palette } }: ThemeType) => palette.p_grey.border};

      ${({ value, inputProps }) =>
        textFieldValueLength(value, inputProps) ? transformInputShrink : ``};
    }
  }
`;

export default TextFieldContainer;
