import React from "react";
import { styled } from "@mui/system";
import FormControl, { FormControlProps } from "@mui/material/FormControl";

import { WithThemeProps } from "types/general";
import { ThemeType } from "theme";

import { FORM_FIELD_UNITS_HEIGHT } from "../../../constants";

const FieldWithLabelContainer = styled(
  (props: FormControlProps & WithThemeProps) => <FormControl {...props} />
)`
  &.details-input {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    font-weight: 500;
    line-height: 28px;
    border: ${({ error, theme: { spacing, palette } }) =>
      `${spacing(0.125)} solid ${
        error ? palette.error.main : palette.p_grey.border
      }`};
    box-sizing: border-box;
    border-radius: 4px;
    height: fit-content;

    & .MuiTextField-root {
      border: none;
      border-radius: 4px;
    }

    & .hint-container {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
    }

    & .date-range-picker {
      width: 100%;
    }

    & .MuiOutlinedInput-root {
      font-weight: inherit;
      color: inherit;
      height: ${({ theme }) => theme.spacing(FORM_FIELD_UNITS_HEIGHT)};
      font-size: 14px;

      border-radius: ${({ theme }) => theme.spacing(0.5)};

      & input {
        padding: ${({ theme: { spacing } }: ThemeType) => spacing(2, 1.75, 2)};
        line-height: ${({ theme: { spacing } }: ThemeType) => spacing(2.5)};
        height: ${({ theme }) => theme.spacing(FORM_FIELD_UNITS_HEIGHT)};
        box-sizing: border-box;

        &::placeholder {
          font-weight: 500;
          font-size: 14px;
          line-height: 28px;
          color: ${({ theme: { palette } }: ThemeType) =>
            palette.p_grey.border};
          opacity: 1;
        }

        &.Mui-focused {
          border-color: ${({ error, theme }) =>
            error ? theme.palette.error.main : theme.palette.primary.main};
        }
      }

      & .MuiIconButton-root {
        margin: 0;
      }

      & .MuiAutocomplete-input {
        padding-top: 0;
        padding-bottom: 0;
      }

      & .MuiOutlinedInput-notchedOutline {
        display: none;
      }
    }

    & .MuiAutocomplete-popper {
      & .MuiPaper-root {
        width: 100%;
      }
    }
  }
`;

export default FieldWithLabelContainer;
