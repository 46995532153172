import { throttle } from "lodash-es";
import { Nullable, RootState } from "../types";

const LOCAL_STORAGE_STATE_KEY = "state";
type SessionPersistedState = Partial<RootState>;

export const loadState = (): undefined | SessionPersistedState => {
  try {
    const serializedState: Nullable<string> = sessionStorage.getItem(
      LOCAL_STORAGE_STATE_KEY
    );
    return serializedState !== null
      ? (JSON.parse(serializedState) as SessionPersistedState)
      : undefined;
  } catch (err) {
    return undefined;
  }
};

export const saveState = throttle((state: SessionPersistedState) => {
  try {
    const serializedQuoteState = JSON.stringify(state);
    sessionStorage.setItem(LOCAL_STORAGE_STATE_KEY, serializedQuoteState);
  } catch {}
}, 1000);
