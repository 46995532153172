import { styled } from "@mui/system";
import { WithThemeProps } from "../../../types/general";
import Box, { BoxProps } from "@mui/material/Box";
import { ThemeType } from "../../../theme";
import React from "react";
import { CLASS_STEP_HINT_MESSAGE } from "./constants";

const StepHintMessageContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box {...props} />
))`
  &.${CLASS_STEP_HINT_MESSAGE} {
    margin-top: ${({ theme: { spacing } }: ThemeType) => spacing(1)};
    max-width: ${({ theme: { spacing } }: ThemeType) => spacing(38)};
    font-size: 14px;
    line-height: 19px;
  }
`;

export default StepHintMessageContainer;
