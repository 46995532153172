import React from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";

import { useTranslation } from "react-i18next";
import { TRANSLATION } from "i18n";
import { useAppSelector } from "hooks/redux-hooks";
import { selectQuoteDTO } from "features/quote/slice/selectors";
import PolicyIdContainer from "./styled";
import {
  CLASS_POLICY_ID,
  CLASS_POLICY_ID_TEXT,
  CLASS_POLICY_LABEL,
  PolicyIdProps,
} from "./constants";
import {
  POLICY_NUMBER_TRANSLATION_KEY,
  TRANSLATION_KEY_PREFIX,
} from "../../constants";

import { ReactComponent as PolicySVG } from "assets/svg/Policy.svg";

const PolicyId: React.FC<PolicyIdProps> = () => {
  const { t } = useTranslation(TRANSLATION, {
    keyPrefix: TRANSLATION_KEY_PREFIX,
  });

  const quoteDto = useAppSelector(selectQuoteDTO);

  return (
    <PolicyIdContainer className={CLASS_POLICY_ID}>
      <PolicySVG />
      <Box className={CLASS_POLICY_ID_TEXT}>
        <Typography component="span" className={CLASS_POLICY_LABEL}>
          {t(POLICY_NUMBER_TRANSLATION_KEY)}
        </Typography>
        <Typography component="span" className={CLASS_POLICY_LABEL}>
          {quoteDto?.userPolicyUniqueUUID}
        </Typography>
      </Box>
    </PolicyIdContainer>
  );
};

export default PolicyId;
