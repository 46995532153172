import React, { useCallback } from "react";
import CheckIcon from "@mui/icons-material/Check";
import { useAppDispatch } from "../../../../../../../../hooks/redux-hooks";
import { toggleTravelerAsDriver } from "../../../../../../slice";
import { DriverChipProps } from "./constants";
import DriverChipContainer from "./styled";

const DriverChip: React.FC<DriverChipProps> = ({ travelerIndex, traveler }) => {
  const dispatch = useAppDispatch();

  const handleToggleDriverChip = useCallback(() => {
    dispatch(toggleTravelerAsDriver(travelerIndex));
  }, [dispatch, travelerIndex]);

  return (
    <DriverChipContainer
      onClick={handleToggleDriverChip}
      icon={traveler?.isDriver ? <CheckIcon /> : undefined}
      isDriver={Boolean(traveler?.isDriver)}
      label={`${traveler?.firstName} ${traveler?.lastName}`}
    />
  );
};

export default DriverChip;
