import React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";

import { WithThemeProps } from "types/general";

import {
  CLASS_POLICY_CONTAINER,
  CLASS_POLICY_CONTENT,
  CLASS_SECTION_IMAGE,
  CLASS_SUMMARY_PAGE_TITLE,
  CLASS_SUMMARY_PAGE_TOP_NOTE,
} from "./constants";
import { ThemeType } from "../../../../../theme";

const PolicyContainer = styled((props: BoxProps & WithThemeProps) => (
  <Box {...props} />
))`
  &.${CLASS_POLICY_CONTAINER} {
    height: 100%;
    padding-bottom: ${({ theme: { spacing } }) => spacing(8)};
    margin-top: clamp(${({ theme: { spacing } }: ThemeType) =>
      `${spacing(2.5)}, 6vw, ${spacing(9)}`});

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    max-width: unset;

    & .${CLASS_POLICY_CONTENT} {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      & > .${CLASS_SUMMARY_PAGE_TITLE} {
        max-width: unset;
        font-size: clamp(52px, 10vw, 80px);
        line-height: 125%;
        text-align: center;
      }

      & > .${CLASS_SUMMARY_PAGE_TOP_NOTE} {
        margin-top: ${({ theme: { spacing } }) => spacing(1.25)};
        text-align: center;
        color: ${({ theme }: ThemeType) => theme.palette.p_grey.textDark};
        font-weight: 600;
        font-size: 18px;
        max-width: clamp(${({ theme: { spacing } }: ThemeType) =>
          `${spacing(34)}, 50vw, ${spacing(76)}`});


        ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
          font-size: 16px;
        }
      }

      & > .${CLASS_SECTION_IMAGE} {
        & > img {
          aspect-ratio: 613 / 492;
          object-fit: cover;
          width: 90%;
        }

        width: clamp(${({ theme: { spacing } }: ThemeType) =>
          `${spacing(40)}, 61vw, ${spacing(76)}`});
        height: clamp(${({ theme: { spacing } }: ThemeType) =>
          `${spacing(26.5)}, 40.5vw, ${spacing(50)}`});
        z-index: 1;
      }
    }
  }
}
`;

export default PolicyContainer;
