import { axiosInstance, axiosRefreshTokenInstance } from "./index";
import TokenService from "../TokenService";
import { AppDispatch } from "../../types/redux-types";
import { AppUserAuthData } from "../../types/AuthTypes";
import { refreshUserTokenState } from "../../features/authentication/slice";

const refreshUserToken = async (dispatch: AppDispatch) => {
  const refreshToken = TokenService.getLocalRefreshToken();
  const response = await axiosRefreshTokenInstance.post("", {
    grant_type: "refresh_token",
    refresh_token: refreshToken,
  });
  const { data } = response;
  const { access_token, refresh_token, expires_in } = data as any;
  const result: AppUserAuthData = {
    accessToken: access_token,
    refreshToken: refresh_token,
    expirationTime: Date.now() + expires_in,
  };
  TokenService.updateLocalAccessToken(result);
  dispatch(refreshUserTokenState({ authData: result }));
};

const setupInterceptors = (store: any) => {
  axiosInstance.interceptors.request.use(
    (config: any) => {
      const token = TokenService.getLocalAccessToken();
      if (token) {
        config.headers["Authorization"] = "Bearer " + token; // for Spring Boot back-end
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const { dispatch } = store;
  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      const errorToThrow = new Error(err.response.data.message);

      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        // @ts-ignore
        // @ts-ignore
        try {
          await refreshUserToken(dispatch);
          return axiosInstance(originalConfig);
        } catch (
          //@ts-ignore next line
          _error: AxiosError
        ) {
          return Promise.reject(errorToThrow);
        }
      }

      return Promise.reject(errorToThrow);
    }
  );
};

export default setupInterceptors;
