import React from "react";

import { CoverageType } from "types/quote-types";
import { Dict, Nullable } from "types";
import YourHealthExpandedDetails from "./YourHealthExpandedDetails";
import YourStuffExpandedDetails from "./YourStuffExpandedDetails";
import YourTripExpandedDetails from "./YourTripExpandedDetails";

export interface CardMoreDetailsProps {
  coverageType: CoverageType;
}

export interface ExpandedDetailsComponentProps {
  className: string;
  coverageName: string;
}

export const TRANSLATION_KEY_PREFIX = "quote.steps.offer.section fine prints";

export const PER_PERSON = "per person";
export const BASE_COVERAGE = "base coverage";

export const TITLE = "title";

export const YOUR_HEALTH = "your health";
export const YOUR_STUFF = "your stuff";
export const YOUR_TRIP = "your trip";

export const TRIP_CANCELLATION = "trip cancellation";
export const NON_MEDICAL_EMERGENCY_EVACUATION =
  "non medical emergency evacuation";

export const CARD_MORE_DETAILS_CONTAINER = "card-more-details-container";

export const CLASS_CARD_TITLE = "card-title";
export const CLASS_PER_PERSON = "per-person";
export const CLASS_ITEMS_CONTAINER = "items-container";

export const coverageNameByType: Dict<CoverageType, Nullable<string>> = {
  [CoverageType.medicalCoverage]: YOUR_HEALTH,
  [CoverageType.baggageExtraCare]: YOUR_STUFF,
  [CoverageType.tripCancellation]: YOUR_TRIP,
  [CoverageType.tripInterruption]: YOUR_TRIP,
  [CoverageType.car]: null,
  [CoverageType.cancelForAnyReason]: null,
  [CoverageType.pet]: null,
};

export const coverageExpandedDetailsComponentByType: Dict<
  CoverageType,
  Nullable<React.FC<ExpandedDetailsComponentProps>>
> = {
  [CoverageType.medicalCoverage]: YourHealthExpandedDetails,
  [CoverageType.baggageExtraCare]: YourStuffExpandedDetails,
  [CoverageType.tripCancellation]: YourTripExpandedDetails,
  [CoverageType.tripInterruption]: YourTripExpandedDetails,
  [CoverageType.car]: null,
  [CoverageType.cancelForAnyReason]: null,
  [CoverageType.pet]: null,
};
