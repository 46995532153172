import React, { useMemo } from "react";
import StepTabsContainer from "./styled";
import { StepTabsProps } from "./constants";
import Tab from "./Tab/styled";

const StepTabs: React.FC<StepTabsProps> = ({ tabsCount, selectedIndex }) => {
  const tabs = useMemo(
    () =>
      new Array(tabsCount)
        .fill("")
        .map((item, index) => (
          <Tab key={index} selected={selectedIndex === index} />
        )),
    [tabsCount, selectedIndex]
  );
  return <StepTabsContainer>{tabs}</StepTabsContainer>;
};

export default StepTabs;
