export interface AddOnPriceProps {
  price: string;
  priceSuffix: string;
}

export const CLASS_ADD_ON_PRICE = "add-on-price-styled";
export const CLASS_CURRENCY = "currency";
export const CLASS_DOLLARS = "dollars";
export const CLASS_CENTS = "cents";
export const CLASS_PRICE_DOT = "price-dot";
export const CLASS_PRICE_SUFFIX = "price-suffix";
