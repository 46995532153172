export interface PolicyProps {}

export const TRANSLATION_KEY_PREFIX = "quote.steps.policy";
export const TITLE_TRANSLATION_KEY = "title";
export const TOP_NOTE_TRANSLATION_KEY = "top note";
export const POLICY_NUMBER_TRANSLATION_KEY = "policy number";

export const CLASS_POLICY_CONTAINER = "policy-container";
export const CLASS_POLICY_CONTENT = "policy-content";
export const CLASS_SUMMARY_PAGE_TITLE = "summary-page-title";
export const CLASS_SUMMARY_PAGE_TOP_NOTE = "summary-page-top-note";
export const CLASS_SECTION_IMAGE = "section-image";

export const PAGE_NAME_SUMMARY = "Summary";
