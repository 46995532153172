import React, { useContext } from "react";

import {
  coverageExpandedDetailsComponentByType,
  CARD_MORE_DETAILS_CONTAINER,
  CardMoreDetailsProps,
  coverageNameByType,
  TRANSLATION_KEY_PREFIX,
  CLASS_CARD_TITLE,
  TITLE,
  PER_PERSON,
  CLASS_PER_PERSON,
  CLASS_ITEMS_CONTAINER,
} from "./constants";
import MoreDetailsContainer from "./styled";
import ShowBaseCoverageExpandedDetails from "../../ShowBaseCoverageExpandedDetails";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "../../../../../../../i18n";
import { Box, Typography } from "@mui/material";

const CardMoreDetails: React.FC<CardMoreDetailsProps> = ({ coverageType }) => {
  const { t } = useTranslation(TRANSLATION, {
    keyPrefix: TRANSLATION_KEY_PREFIX,
  });

  const ExpandedDetailsComponent =
    coverageExpandedDetailsComponentByType[coverageType];

  const { expanded } = useContext(ShowBaseCoverageExpandedDetails);

  const coverageName = coverageNameByType[coverageType];
  if (!coverageName || !ExpandedDetailsComponent) return null;

  return (
    <MoreDetailsContainer className={CARD_MORE_DETAILS_CONTAINER} in={expanded}>
      <Box>
        <Typography component="h3" className={CLASS_CARD_TITLE}>
          {t(`${coverageName}.${TITLE}`)}
        </Typography>
        <Typography component="h3" className={CLASS_PER_PERSON}>
          {t(PER_PERSON)}
        </Typography>
      </Box>
      <ExpandedDetailsComponent
        className={CLASS_ITEMS_CONTAINER}
        coverageName={coverageName}
      />
    </MoreDetailsContainer>
  );
};

export default CardMoreDetails;
