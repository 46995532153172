import React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";

import { WithThemeProps } from "types/general";
import { ThemeType } from "theme";
import {
  CLASS_APP_SUGGESTIONS,
  CLASS_DOWNLOAD_APP,
  CLASS_DOWNLOAD_APP_LINK,
  CLASS_MAIN_TITLE,
  CLASS_SECONDARY_TITLE,
  CLASS_SECTION_CONTENT,
} from "./constants";

const DownloadAppContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box {...props} />
))`
  background-color: ${({ theme: { palette } }: ThemeType) =>
    palette.background.default};

  & > .${CLASS_SECTION_CONTENT} {
    overflow: hidden;
    height: fit-content;
    max-width: ${({ theme: { spacing } }: ThemeType) => spacing(180)};
    margin: 0 auto;
    padding-top: ${({ theme: { spacing } }: ThemeType) => spacing(14)};
    padding-bottom: ${({ theme: { spacing } }: ThemeType) => spacing(5)};
    padding-right: ${({ theme: { spacing } }) =>
      `clamp(${spacing(2)}, 5vw, ${spacing(10)})`};
    padding-left: ${({ theme: { spacing } }) =>
      `clamp(${spacing(2)}, 5vw, ${spacing(10)})`};

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, auto) 1fr;
    grid-template-areas:
      " ${CLASS_MAIN_TITLE}                 ${CLASS_APP_SUGGESTIONS}"
      " ${CLASS_SECONDARY_TITLE}    ${CLASS_APP_SUGGESTIONS}"
      " ${CLASS_DOWNLOAD_APP}    ${CLASS_APP_SUGGESTIONS}"
      "           .                         ${CLASS_APP_SUGGESTIONS}";

    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto 1fr auto;
      padding-top: 0;
      padding-bottom: 0;

      grid-template-areas:
        "${CLASS_MAIN_TITLE}"
        "${CLASS_SECONDARY_TITLE}"
        "${CLASS_APP_SUGGESTIONS}"
        "${CLASS_DOWNLOAD_APP}";
    }

    & > .${CLASS_APP_SUGGESTIONS} {
      grid-area: ${CLASS_APP_SUGGESTIONS};
      display: flex;
    }

    & .${CLASS_DOWNLOAD_APP} {
      transition: transform 0.2s cubic-bezier(0, 0, 0.2, 1);
      transform: none;

      width: 100%;
      max-width: ${({ theme: { spacing } }: ThemeType) => spacing(51)};
      margin-top: ${({ theme: { spacing } }: ThemeType) => spacing(3)};

      display: flex;
      gap: ${({ theme: { spacing } }: ThemeType) => spacing(2.25)};
      flex-wrap: wrap;

      ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
        margin-bottom: ${({ theme: { spacing } }: ThemeType) => spacing(10)};
        margin-top: unset;
        justify-self: center;
        justify-content: center;
        align-items: baseline;
      }
    }

    & > .${CLASS_MAIN_TITLE} {
      grid-area: ${CLASS_MAIN_TITLE};
      font-size: 42px;
      line-height: 50px;
      font-weight: 700;

      ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
        font-size: 32px;
        line-height: 34px;
        text-align: center;
      }
    }

    & .${CLASS_SECONDARY_TITLE} {
      padding-right: ${({ theme: { spacing } }: ThemeType) => spacing(15)};
      margin-top: ${({ theme: { spacing } }: ThemeType) => spacing(4)};
      font-size: 24px;
      line-height: 40px;
      font-weight: 600;

      ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
        padding-right: 0;
        font-size: 18px;
        line-height: 18px;
        text-align: center;
        max-width: ${({ theme: { spacing } }: ThemeType) => spacing(27.5)};
        justify-self: center;
        margin-bottom: max(0vw, 320px - 100vw);
      }
    }

    & .${CLASS_DOWNLOAD_APP_LINK} {
      padding: 0;
      margin: 0;

      width: ${({ theme: { spacing } }: ThemeType) => spacing(20)};
      height: ${({ theme: { spacing } }: ThemeType) => spacing(6.5)};
    }
  }
`;

export default DownloadAppContainer;
