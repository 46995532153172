import { styled } from "@mui/system";
import { WithThemeProps } from "../../../types/general";
import Box, { BoxProps } from "@mui/material/Box";
import { ThemeType } from "../../../theme";
import React from "react";

const StepTabsContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box {...props} />
))`
  margin-top: ${({ theme: { spacing } }: ThemeType) => spacing(5)};
  display: flex;
  justify-content: center;
  gap: ${({ theme: { spacing } }: ThemeType) => spacing(2)};
`;

export default StepTabsContainer;
