import React, { useCallback, useRef } from "react";
import { Box, Popper, Typography } from "@mui/material";
import CakeOutlinedIcon from "@mui/icons-material/CakeOutlined";

import classnames from "classnames";
import { useTranslation } from "react-i18next";

import { TRANSLATION } from "i18n";
import { DatePart } from "types/quote-types";
import {
  isDayInMonth,
  isDayValid,
  isMonthValid,
  isYearValid,
} from "utils/dates";
import ErrorMessage from "common-components/indicators/ErrorMessage";

import DateField from "./DateField";

import {
  CLASS_FAYE_DATE_PICKER,
  CLASS_INVALID_DATE_ERROR_MESSAGE,
  KEY_INVALID_DATE_MESSAGE,
  MyFayeDatePickerProps,
  TRANSLATION_KEY_PREFIX,
  KEY_DATE_OF_BIRTH,
  KEY_DAY_PLACEHOLDER,
  KEY_YEAR_PLACEHOLDER,
  KEY_MONTH_PLACEHOLDER,
  KEY_MONTH_LABEL,
  KEY_DAY_LABEL,
  KEY_YEAR_LABEL,
} from "./constants";
import FayeBirthDateFieldContainer from "./styled";

const FayeBirthDateField: React.FC<MyFayeDatePickerProps> = ({
  onFieldFocus,
  onFieldBlur,
  onDateFieldChange,
  month,
  year,
  day,
  isFocused,
  showError,
  autoFocus,
}) => {
  const container = useRef<HTMLDivElement>();

  const { t } = useTranslation(TRANSLATION, {
    keyPrefix: TRANSLATION_KEY_PREFIX,
  });
  const monthRef = useRef<HTMLInputElement>();
  const dayRef = useRef<HTMLInputElement>();
  const yearRef = useRef<HTMLInputElement>();

  const handleChangeMonth = useCallback(
    (newValue: string) => {
      onDateFieldChange(DatePart.Month, newValue);
    },
    [onDateFieldChange]
  );
  const handleChangeDay = useCallback(
    (newValue: string) => {
      onDateFieldChange(DatePart.Day, newValue);
    },
    [onDateFieldChange]
  );
  const handleChangeYear = useCallback(
    (newValue: string) => {
      onDateFieldChange(DatePart.Year, newValue);
    },
    [onDateFieldChange]
  );

  const handleLeftContainerClick = useCallback(() => {
    onFieldFocus(DatePart.Month);
    monthRef?.current?.focus();
  }, [onFieldFocus]);

  return (
    <FayeBirthDateFieldContainer className={CLASS_FAYE_DATE_PICKER}>
      <Box
        className={classnames("left-container", {
          "date-picker-focused": isFocused,
        })}
        onClick={handleLeftContainerClick}
      >
        <CakeOutlinedIcon />
        <Typography component="span" className="label">
          {t(KEY_DATE_OF_BIRTH)}
        </Typography>
      </Box>
      <Box ref={container}>
        <DateField
          key="field-month"
          className={classnames("date-field", "field-month")}
          inputRef={monthRef}
          fieldValue={month}
          placeholder={t(KEY_MONTH_PLACEHOLDER)}
          validationCallback={isMonthValid}
          nextFieldRef={dayRef}
          length={2}
          handleChangeValue={handleChangeMonth}
          datePart={DatePart.Month}
          onFieldFocus={onFieldFocus}
          onFieldBlur={onFieldBlur}
          autoFocus={autoFocus}
          fieldLabel={t(KEY_MONTH_LABEL)}
        />
        <DateField
          key="field-day"
          className={classnames("date-field", "field-day")}
          inputRef={dayRef}
          fieldValue={day}
          placeholder={t(KEY_DAY_PLACEHOLDER)}
          validationCallback={isDayValid(isDayInMonth(month, year))}
          nextFieldRef={yearRef}
          previousFieldRef={monthRef}
          length={2}
          handleChangeValue={handleChangeDay}
          datePart={DatePart.Day}
          onFieldFocus={onFieldFocus}
          onFieldBlur={onFieldBlur}
          fieldLabel={t(KEY_DAY_LABEL)}
        />

        <DateField
          key="field-year"
          className={classnames("date-field", "field-year")}
          inputRef={yearRef}
          fieldValue={year}
          placeholder={t(KEY_YEAR_PLACEHOLDER)}
          validationCallback={isYearValid}
          previousFieldRef={dayRef}
          length={4}
          handleChangeValue={handleChangeYear}
          datePart={DatePart.Year}
          onFieldFocus={onFieldFocus}
          onFieldBlur={onFieldBlur}
          fieldLabel={t(KEY_YEAR_LABEL)}
        />
      </Box>
      <Popper open={showError} anchorEl={container.current}>
        <ErrorMessage
          className={classnames(CLASS_INVALID_DATE_ERROR_MESSAGE, "Mui-error")}
        >
          {t(KEY_INVALID_DATE_MESSAGE)}
        </ErrorMessage>
      </Popper>
    </FayeBirthDateFieldContainer>
  );
};

export default FayeBirthDateField;
