import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";
import { WithThemeProps } from "../../../../../../../../types/general";
import { ThemeType } from "../../../../../../../../theme";
import React from "react";
import { CLASS_DATES_PICKER_TITLE, CLASS_DRIVERS_CONTAINER } from "./constants";

const DateRangeChipsCollectionContainer = styled(
  (props: BoxProps & WithThemeProps) => <Box {...props} />
)`
  &.${CLASS_DRIVERS_CONTAINER} {
    & .${CLASS_DATES_PICKER_TITLE} {
      color: ${({ theme }: ThemeType) => theme.palette.p_grey.textDark};
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
    }
  }
`;
export default DateRangeChipsCollectionContainer;
