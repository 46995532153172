import React from "react";
import { styled } from "@mui/system";
import { Box, BoxProps } from "@mui/material";

import { WithThemeProps } from "types";

import { CLASS_CONTAINER } from "./constants";

const TestUserPaymentModalContainer = styled(
  (props: WithThemeProps & BoxProps) => <Box {...props} />
)`
  &.${CLASS_CONTAINER} {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default TestUserPaymentModalContainer;
