import { Traveler } from "../../../../../../../../types/quote-types";

export const CLASS_DRIVERS_CONTAINER = "drivers-picker-container";
export const CLASS_DRIVERS_PICKER_TITLE = "drivers-picker-title";
export const CLASS_DRIVERS_CHIPS_CONTAINER = "drivers-chips-container";

export interface DriversPickerProps {
  className: string;
  travelers: Traveler[];
}
