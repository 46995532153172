import React, { useCallback } from "react";

import { Collapse } from "@mui/material";
import Button from "@mui/material/Button";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { useTranslation } from "react-i18next";

import EventName from "tracking/mixpanel/EventName";

import { TRANSLATION } from "i18n";
import { useAppSelector } from "hooks/redux-hooks";

import {
  selectDestinationGoogleData,
  selectPolicyPdfUrls,
  selectPolicyReceiptUrls,
  selectQuoteDTO,
} from "features/quote/slice/selectors";
import { DownloadDocumentsProps } from "./constants";
import DownloadDocumentsContainer from "./styled";
import {
  CLASS_DOWNLOAD_DOCUMENTS,
  DOWNLOAD_POLICY_TRANSLATION_KEY,
  DOWNLOAD_RECEIPT_TRANSLATION_KEY,
  TRANSLATION_KEY_PREFIX,
} from "../constants";
import { logEvent } from "tracking/logger";
import { destinationNameForDisplay } from "../../../../../../../utils/destinations";

const DownloadDocuments: React.FC<DownloadDocumentsProps> = () => {
  const { t } = useTranslation(TRANSLATION, {
    keyPrefix: TRANSLATION_KEY_PREFIX,
  });

  const policyPdfUrls = useAppSelector(selectPolicyPdfUrls);
  const policyReceiptUrls = useAppSelector(selectPolicyReceiptUrls);
  const quoteDto = useAppSelector(selectQuoteDTO);
  const destinationGooglePlaceResults = useAppSelector(
    selectDestinationGoogleData
  );
  const destinationName =
    destinationGooglePlaceResults && destinationGooglePlaceResults?.[0]
      ? destinationNameForDisplay(destinationGooglePlaceResults[0])
      : "";

  const handleDownloadPoliciesLinkClick = useCallback(() => {
    policyPdfUrls!.forEach((url, _) => {
      logEvent(EventName.SummaryScreenPressedPolicyPDF);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Policy${
          quoteDto?.userPolicyUniqueUUID
            ? `-${quoteDto?.userPolicyUniqueUUID}-${destinationName}`
            : ""
        }.pdf`
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode?.removeChild(link);
    });
  }, [policyPdfUrls, quoteDto?.userPolicyUniqueUUID, destinationName]);

  const handleDownloadReceiptsClick = useCallback(() => {
    logEvent(EventName.SummaryScreenPressedReceipt);

    const url = policyReceiptUrls![0].receipt;
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.rel = "noreferrer";

    // Append to html link element page
    document.body.appendChild(link);
    // Start download
    link.click();
    // Clean up and remove the link
    link.parentNode?.removeChild(link);
  }, [policyReceiptUrls]);

  return (
    <DownloadDocumentsContainer className={CLASS_DOWNLOAD_DOCUMENTS}>
      <Collapse orientation="horizontal" in={Boolean(policyPdfUrls?.length)}>
        <Button onClick={handleDownloadPoliciesLinkClick}>
          <FileDownloadOutlinedIcon />
          {t(DOWNLOAD_POLICY_TRANSLATION_KEY, {
            count: policyPdfUrls?.length,
          })}
        </Button>
      </Collapse>
      <Collapse
        orientation="horizontal"
        in={
          Boolean(policyReceiptUrls?.length) &&
          Boolean(policyReceiptUrls![0].receipt)
        }
      >
        <Button onClick={handleDownloadReceiptsClick}>
          <VisibilityIcon /> {t(DOWNLOAD_RECEIPT_TRANSLATION_KEY)}
        </Button>
      </Collapse>
    </DownloadDocumentsContainer>
  );
};

export default DownloadDocuments;
