import { DatePart } from "types/quote-types";

export const CLASS_FAYE_DATE_PICKER = "faye-date-picker";
export const CLASS_INVALID_DATE_ERROR_MESSAGE = "invalid-date-error-message";

export const TRANSLATION_KEY_PREFIX = "birthday date element";
export const KEY_INVALID_DATE_MESSAGE = "invalid error message";
export const KEY_DATE_OF_BIRTH = "date of birth";
export const KEY_MONTH_PLACEHOLDER = "month placeholder";
export const KEY_MONTH_LABEL = "month label";
export const KEY_DAY_PLACEHOLDER = "day placeholder";
export const KEY_DAY_LABEL = "day label";
export const KEY_YEAR_PLACEHOLDER = "year placeholder";
export const KEY_YEAR_LABEL = "year label";

export interface MyFayeDatePickerProps {
  month: string;
  year: string;
  day: string;
  autoFocus?: boolean;
  isFocused: boolean;
  showError: boolean;

  onFieldBlur(fieldBlurred: DatePart): void;

  onFieldFocus(fieldBlurred: DatePart): void;

  onDateFieldChange(datePart: DatePart, value: string): void;
}
