import { styled } from "@mui/system";
import Avatar, { AvatarProps } from "@mui/material/Avatar";
import { AgentAvatarProps, getAvatarSize } from "./constants";
import { WithThemeProps } from "../../../types/general";
import React from "react";

const AgentAvatarContainer = styled(
  (props: AvatarProps & AgentAvatarProps & WithThemeProps) => (
    <Avatar {...props} />
  )
)`
  width: ${({ size, theme }) => getAvatarSize({ size: size!, theme })};
  height: ${({ size, theme }) => getAvatarSize({ size: size!, theme })};
`;

export default AgentAvatarContainer;
