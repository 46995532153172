import React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";

import { WithThemeProps } from "types/general";
import { ThemeType } from "../../../../../../../../theme";

const PriceContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box {...props} />
))`
  &.price-styled {
    display: flex;
    align-items: baseline;

    & > * {
      font-weight: 600;
      line-height: normal;
    }

    & > .dollars {
      font-size: 80px;

      ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
        font-size: 48px;
      }
    }

    & > .currency {
      font-size: 24px;
      font-weight: 400;
      transform: translateY(
        ${({ theme: { spacing } }: ThemeType) => spacing(-0.5)}
      );
    }

    & > .dot,
    .cents {
      font-size: 36px;

      ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
        font-size: 24px;
      }
    }
  }
`;

export default PriceContainer;
