import React from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "../../../../i18n";
import Bubble from "../Bubble";
import AppSuggestionsContainer from "./styled";
import {
  AppSuggestionsProps,
  CLASS_APP_IMAGES_CONTAINER,
  CLASS_BUBBLE_ALERTS,
  CLASS_BUBBLE_ASSISTANCE,
  CLASS_BUBBLE_CLAIMS_PROCESSING,
  CLASS_MAIN_APP_IMAGE,
  CLASS_SECONDARY_APP_IMAGE,
  KEY_ASSISTANCE_COMMENT,
  KEY_ASSISTANCE_TITLE,
  KEY_CLAIMS_PROCESSING,
  KEY_REAL_TIME_ALERTS,
  TRANSLATION_KEY_PREFIX,
} from "./constants";

const AppSuggestions: React.FC<AppSuggestionsProps> = () => {
  const { t } = useTranslation(TRANSLATION, {
    keyPrefix: TRANSLATION_KEY_PREFIX,
  });

  return (
    <AppSuggestionsContainer>
      <Box className={CLASS_APP_IMAGES_CONTAINER}>
        <Box className={CLASS_MAIN_APP_IMAGE}>
          <Bubble
            title={t(KEY_REAL_TIME_ALERTS)}
            className={CLASS_BUBBLE_ALERTS}
          />
        </Box>
        <Box className={CLASS_SECONDARY_APP_IMAGE}>
          <Bubble
            title={t(KEY_CLAIMS_PROCESSING)}
            className={CLASS_BUBBLE_CLAIMS_PROCESSING}
          />
          <Bubble
            title={t(KEY_ASSISTANCE_TITLE)}
            comment={t(KEY_ASSISTANCE_COMMENT)}
            className={CLASS_BUBBLE_ASSISTANCE}
          />
        </Box>
      </Box>
    </AppSuggestionsContainer>
  );
};

export default AppSuggestions;
