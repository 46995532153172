import EventType from "./mParticle/EventType";
import mParticle, {
  IdentityApiData,
  IdentityResult,
  SDKEventAttrs,
  UserAttributesValue,
} from "@mparticle/web-sdk";
import {
  getStorageCustomerId,
  setStorageCustomerId,
} from "../utils/customerId";
import { nanoid } from "nanoid";
import { isUndefined, omitBy } from "lodash-es";
import * as Sentry from "@sentry/react";
import { getCustomError } from "../utils";

export const logEvent = (eventName: string, eventInfo?: SDKEventAttrs) =>
  mParticle.logEvent(eventName, EventType.Other, eventInfo);

export const setUserAttributes = (
  attributes: Record<string, UserAttributesValue>
) => {
  try {
    const currentUser = mParticle.Identity.getCurrentUser();
    currentUser.setUserAttributes(attributes);
  } catch (e) {
    const errorForLogs = getCustomError({
      name: "mParticle_setUserAttributes_Error",
      message: (e as Error)?.message,
    });

    Sentry.captureException(errorForLogs);
  }
};

export const setUserAttributeList = (
  key: string,
  value: UserAttributesValue[]
) => {
  try {
    const currentUser = mParticle.Identity.getCurrentUser();
    currentUser.setUserAttributeList(key, value);
  } catch (e) {
    const errorForLogs = getCustomError({
      name: "mParticle_setUserAttributeList_Error",
      message: (e as Error)?.message,
    });

    Sentry.captureException(errorForLogs);
  }
};

const logCustomerIdInvalid = (customerId?: string) => {
  if (customerId !== undefined) {
    Sentry.captureException(
      `CustomerID inValid, value = "", will be replaced with a valid new one instead`,
      {
        extra: {
          customerId,
        },
      }
    );
  } else {
    Sentry.captureMessage(
      "CustomerID is value = undefined, will be replaced with a valid new one instead"
    );
  }
};

export const getCustomerId = (queryParamCustomerId?: string) => {
  let customerId = queryParamCustomerId;

  if (!customerId) {
    logCustomerIdInvalid(customerId);
    customerId = (getStorageCustomerId() || nanoid()) as string;
  }
  return customerId;
};

/**
 * Error handling for IDSync request failures
 * As described here:
 * https://docs.mparticle.com/developers/sdk/web/idsync/#error-handling
 *
 * @param result - the result of the failed IDSync request
 */
const handleIdSyncRequestFailed = (result: IdentityResult) => {
  const idSyncRequest = result.getUser;
  const codes = window.mParticle.Identity.HTTPCodes;

  let errorForLogs: Error;

  switch (result.httpCode) {
    case codes.noHttpCoverage:
      // retry the IDSync request
      idSyncRequest();
      errorForLogs = getCustomError({
        name: "mParticle_IDSync_Request_Error",
        message: "mParticle noHttpCoverage",
      });
      break;
    case codes.activeIdentityRequest:
    case 429:
      //inspect your implementation if this occurs frequency
      //otherwise retry the IDSync request
      errorForLogs = getCustomError({
        name: "mParticle_IDSync_Request_Error",
        message: "mParticle activeIdentityRequest HTTP 429",
      });
      Sentry.captureException(errorForLogs);
      idSyncRequest();
      break;
    case codes.validationIssue:
    case 400:
      // inspect result.body to determine why the request failed
      // this typically means an implementation issue
      errorForLogs = getCustomError({
        name: "mParticle_IDSync_Request_Error",
        message: "mParticle validationIssue HTTP 400",
      });
      Sentry.captureException(errorForLogs);
      break;
    default:
      errorForLogs = getCustomError({
        name: "mParticle_IDSync_Request_Error",
        message:
          "mParticle IDSync Request failed - default case, probably user's add-blocker cause this issue",
      });
      Sentry.captureException(errorForLogs);
  }
};

const getIdentityRequestAndCallback = (userIdentityArgs: {
  customerId?: string;
  email?: string;
}) => {
  const userIdentities = omitBy(
    {
      customerid: userIdentityArgs.customerId as string,
      email: userIdentityArgs.email,
    },
    isUndefined
  );
  const identityRequest: IdentityApiData = {
    userIdentities,
  };
  const identityCallback = function (result: IdentityResult) {
    const idSyncRequest = result.getUser;

    if (idSyncRequest()) {
      //Proceed with login
      //IDSync request succeeded, mutate attributes or query for the MPID as needed
      Sentry.captureMessage(`mParticle IDSync request succeeded`);
      return;
    }

    handleIdSyncRequestFailed(result);
  };

  return { identityCallback, identityRequest };
};

export const loggerUserLogin = (loginArgs: {
  customerId?: string;
  email?: string;
}) => {
  try {
    if (loginArgs.customerId) {
      setStorageCustomerId(loginArgs.customerId as string);
    }

    const { identityRequest, identityCallback } =
      getIdentityRequestAndCallback(loginArgs);

    mParticle.Identity.login(identityRequest, identityCallback);
  } catch (e) {
    const errorForLogs = getCustomError({
      name: "mParticle_user_login_Error",
      message: (e as Error)?.message,
    });
    Sentry.captureException(errorForLogs);
  }
};

export const loggerUserModify = (modifyArgs: {
  customerId?: string;
  email?: string;
}) => {
  try {
    if (!mParticle.Identity.getCurrentUser()) {
      return;
    }

    const { identityRequest, identityCallback } = getIdentityRequestAndCallback(
      {
        email: modifyArgs?.email,
      }
    );

    mParticle.Identity.modify(identityRequest, identityCallback);
  } catch (e) {
    const errorForLogs = getCustomError({
      name: "mParticle_user_modify_Error",
      message: (e as Error)?.message,
    });
    Sentry.captureException(errorForLogs);
  }
};
