import { QUOTE_URL } from "../../../routes/routes-collection";
import {
  STEP_TO_URL_MAP,
  StepName,
} from "../../../features/quote/routes/url-map";

export const TRANSLATION_KEY_PREFIX = "download-app";
export const KEY_MAIN_TITLE = "main title";
export const KEY_SECONDARY_TITLE = "secondary title";

export enum AppStoreName {
  Apple = "Apple",
  Google = "Google",
}

export const CLASS_SECTION_CONTENT = "section-content";
export const CLASS_DOWNLOAD_APP = "download-app";
export const CLASS_APP_SUGGESTIONS = "app-suggestions";
export const CLASS_MAIN_TITLE = "main-title";
export const CLASS_SECONDARY_TITLE = "secondary-title";
export const CLASS_DOWNLOAD_APP_LINK = "download-app-link";
export const CLASS_LINK_ANDROID = "link-android";
export const CLASS_LINK_IOS = "link-ios";

export const POLICY_URL = `${QUOTE_URL}${STEP_TO_URL_MAP[StepName.Policy]}`;

export interface DownloadAppProps {}
