import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import Autocomplete from "@mui/material/Autocomplete";

import { isAndroid } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "i18n";

import { Nullable } from "types/general";
import {
  GooglePlace,
  PlaceDetails,
  DestinationGoogleData,
} from "types/quote-types/Locations";

import FillDetails from "common-components/display-utils/FillDetailsContainer";
import FormField from "common-components/display-utils/FormField/FormField";
import TextFieldWrapper from "common-components/controllers/TextFieldWrapper";
import AutoCompletePopper from "common-components/display-utils/AutoCompletePopper";

import MultiDestinationTag from "./MultiDestinationTag";

import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import useGooglePlacesMuiAutoComplete from "hooks/controllers/useGooglePlacesMuiAutoComplete";
import usePageViewEvent from "hooks/tracking/usePageViewEvent";
import useNextQuoteStep from "hooks/flow/useNextQuoteStep";
import { capitalize } from "utils/texts";
import { googlePlaceCollectionsAreEqual } from "utils/Places";
import {
  getDestinationInitialValues,
  getDestinationPlacesGoogleData,
  getDestinationsPlaceDetails,
} from "utils/destinations";

import {
  selectDestinationGoogleData,
  selectDestinations,
  selectName,
} from "features/quote/slice/selectors";
import { setDestinationThunk } from "features/quote/slice/thunks/steps";

import { StepName } from "../../../routes/url-map";

import {
  BUBBLE_MESSAGE_TRANSLATION_KEY,
  CLASS_DESTINATIONS_STEP_CONTAINER,
  INPUT_PLACEHOLDER_TRANSLATION_KEY,
  PAGE_NAME_DESTINATIONS,
  SECONDARY_TITLE_TRANSLATION_KEY,
  StartProps,
  TITLE_TRANSLATION_KEY,
  TRANSLATION_KEY_PREFIX,
} from "./constants";
import Container from "./styled";
import {
  CLASS_FORM_FIELDS_SECTION,
  GOOGLE_PLACES_DESTINATION_SEARCH_FILTER,
} from "../../../../../constants";
import AndroidInputPlacingHelper from "./AndroidInputPlacingHelper/AndroidInputPlacingHelper";
import loadGoogleMapsMaps from "../../../../../utils/dynamic-scripts/google-maps";

const multipleDestinations = true;

const Destination: React.FC<StartProps> = () => {
  usePageViewEvent({ pageName: PAGE_NAME_DESTINATIONS });

  const [googleMapsScriptLoaded, setGoogleMapsScriptLoaded] =
    useState<boolean>(false);
  const [rendered, setRendered] = useState<boolean>(false);

  const autoCompleteRef = useRef<HTMLInputElement>();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(TRANSLATION, {
    keyPrefix: TRANSLATION_KEY_PREFIX,
  });

  const nextQuoteStep: StepName = useNextQuoteStep();
  const { firstName } = useAppSelector(selectName);
  const destinations = useAppSelector(selectDestinations);
  const reduxDestinationGooglePlaceResults = useAppSelector(
    selectDestinationGoogleData
  );

  const {
    selectedOption: chosenDestinations,
    valid: isDestinationValid,
    onOptionChange: handleOnSelectDestination,
    onBlur: onDestinationBlur,
    onFocus: onDestinationFocus,
    hasError: destinationHasError,
    options: placesCollection,
    onTypedTextChange,
    isAutoCompleteOpen,
  } = useGooglePlacesMuiAutoComplete(
    getDestinationInitialValues(
      destinations,
      reduxDestinationGooglePlaceResults!
    ),
    GOOGLE_PLACES_DESTINATION_SEARCH_FILTER,
    multipleDestinations
  );

  const handleStepsButtonClick = useCallback(async () => {
    if (isDestinationValid) {
      let destinationsDetails: PlaceDetails[];
      let destinationsGoogleData: DestinationGoogleData[];

      if (
        destinations &&
        googlePlaceCollectionsAreEqual(
          destinations,
          chosenDestinations as Nullable<GooglePlace>[]
        )
      ) {
        destinationsDetails = destinations as PlaceDetails[];
        destinationsGoogleData = reduxDestinationGooglePlaceResults!;
      } else {
        destinationsGoogleData = await getDestinationPlacesGoogleData(
          chosenDestinations! as GooglePlace[]
        );

        destinationsDetails = getDestinationsPlaceDetails(
          destinationsGoogleData,
          chosenDestinations as GooglePlace[]
        );
      }

      dispatch(
        setDestinationThunk({
          destinationGooglePlaceResult: destinationsGoogleData!,
          nextQuoteStep,
          destinations: destinationsDetails,
        })
      );
    }
  }, [
    dispatch,
    destinations,
    nextQuoteStep,
    chosenDestinations,
    isDestinationValid,
    reduxDestinationGooglePlaceResults,
  ]);

  useLayoutEffect(() => {
    if (autoCompleteRef.current && rendered && googleMapsScriptLoaded) {
      autoCompleteRef.current?.focus();
    }
  }, [rendered, googleMapsScriptLoaded]);

  useEffect(() => {
    setRendered(true);
    loadGoogleMapsMaps(() => setGoogleMapsScriptLoaded(true));

    return () => {
      setRendered(false);
    };
  }, []);

  return (
    <Container className={CLASS_DESTINATIONS_STEP_CONTAINER}>
      <FillDetails
        title={`${t(TITLE_TRANSLATION_KEY, {
          name: capitalize(firstName),
        })}\n${t(SECONDARY_TITLE_TRANSLATION_KEY)}`}
        onStepsButtonClick={handleStepsButtonClick}
        isStepsButtonDisabled={!isDestinationValid}
        hint={t(BUBBLE_MESSAGE_TRANSLATION_KEY)}
      >
        <FormField
          hasError={destinationHasError}
          onBlur={onDestinationBlur}
          onFocus={onDestinationFocus}
          className={CLASS_FORM_FIELDS_SECTION}
        >
          <Autocomplete
            disabled={!googleMapsScriptLoaded}
            multiple={multipleDestinations}
            open={isAutoCompleteOpen}
            fullWidth
            value={chosenDestinations as GooglePlace[]}
            PopperComponent={AutoCompletePopper}
            options={placesCollection}
            onChange={handleOnSelectDestination}
            filterOptions={(options, state) =>
              options.filter((option) =>
                option.label
                  .toLowerCase()
                  .includes(state.inputValue.toLowerCase().trim())
              )
            }
            renderTags={(tags, getTagProps) =>
              tags.map((option, index) => (
                <MultiDestinationTag
                  option={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            isOptionEqualToValue={(option, value) =>
              option.googleId === value.googleId
            }
            renderInput={(params) => (
              <TextFieldWrapper
                inputRef={autoCompleteRef}
                onChange={onTypedTextChange}
                submitCallback={handleStepsButtonClick}
                placeholder={
                  (chosenDestinations as GooglePlace[]).length
                    ? undefined
                    : t(INPUT_PLACEHOLDER_TRANSLATION_KEY)
                }
                {...params}
              />
            )}
          />
        </FormField>
      </FillDetails>
      {isAndroid && (
        <AndroidInputPlacingHelper
          inputRef={autoCompleteRef}
          inputShouldBeUp={
            (autoCompleteRef?.current?.value as string)?.length > 0
          }
        />
      )}
    </Container>
  );
};

export default React.memo(Destination);
