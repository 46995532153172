import React from "react";
import { styled } from "@mui/system";
import { Typography, TypographyProps } from "@mui/material";

import { WithThemeProps } from "types/general";
import { ThemeType } from "theme";

const StepMessageContainer = styled(
  (props: WithThemeProps & TypographyProps<"h2">) => (
    <Typography component="h2" {...props} />
  )
)`
  color: ${({ theme }) => theme.palette.primary.main};
  max-width: ${({ theme }) => theme.spacing(52)};
  font-family: "utopia-std-display";
  font-weight: 400;
  font-size: 42px;
  line-height: 50px;
  margin: 0;
  text-align: start;

  ${({ theme }: ThemeType) => theme.breakpoints.down("sm")} {
    font-size: 36px;
    line-height: 36px;
  }
`;
export default StepMessageContainer;
