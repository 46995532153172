import React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";

import classnames from "classnames";

import { WithThemeProps } from "types/general";
import { ThemeType } from "theme";

import { CLASS_PAYMENT_MODAL_CONTENT } from "../constants";

const PaymentFormContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box
    {...props}
    className={classnames(CLASS_PAYMENT_MODAL_CONTENT, props.className)}
  />
))`
  &.content-form {
    height: 100%;
    display: grid;
    grid-template-columns: ${({ theme: { spacing } }: ThemeType) =>
      `1fr ${spacing(1.75)} 1fr`};
    grid-template-rows: auto 1fr 24px 1fr 33px 50px;
    grid-template-areas:
      "wallet-pay-container wallet-pay-container wallet-pay-container"
      "card-number card-number card-number"
      ". . ."
      "expiration . cvc"
      ". . ."
      "payment-button payment-button payment-button";

    & .wallet-pay-container {
      grid-area: wallet-pay-container;
    }
    
    & .payment-card-number {
      grid-area: card-number;

      & .card-number-element-wrapper {
        position: relative;

        & > .lock-icon {
          position: absolute;
          right: ${({ theme: { spacing } }: ThemeType) => spacing(1.5)};
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    & .payment-card-expiry {
      grid-area: expiration;
    }

    & .payment-card-cvc {
      grid-area: cvc;
    }

    & .payment-button {
      grid-area: payment-button;
      text-transform: none;
      font-size: 18px;
      line-height: 28px;
      font-weight: 700;
      overflow: hidden;

      & > .paying-progress-bar {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;

        & .MuiLinearProgress-bar {
          background-color: ${({ theme }: ThemeType) =>
            theme.palette.primary.main};
        }
      }
    }

    & .description-label-container {
      margin-bottom: ${({ theme: { spacing } }: ThemeType) => spacing(0.25)};
    }

    & .description-label {
      font-weight: 500;
      font-size: 14px;
      line-height: 28px;
    }

    & .details-input {
      width: unset;
      border: none;
    }

    & .payment-field-element {
      padding: 16px 0 16px 20px;
      height: ${({ theme: { spacing } }) => spacing(6.25)};
      font-weight: 500;
      font-size: 14px;
      line-height: 28px;
      box-sizing: border-box;
      border-radius: ${({ theme: { spacing } }) => spacing(0.5)};
      border: 1px solid ${({ theme: { palette } }) => palette.divider};
    }
  }
`;
export default PaymentFormContainer;
