import { DatePart } from "../../../../types/quote-types";

export const notDigitRegex = /\D/g;

export interface DateFieldProps {
  fieldValue: string;
  length: number;
  nextFieldRef?: React.MutableRefObject<HTMLInputElement | undefined>;
  previousFieldRef?: React.MutableRefObject<HTMLInputElement | undefined>;
  datePart: DatePart;
  autoFocus?: boolean;
  fieldLabel: string

  onFieldFocus(fieldBlurred: DatePart): void;

  onFieldBlur(fieldBlurred: DatePart): void;

  validationCallback(value: string): boolean;

  handleChangeValue(newValue: string): void;
}
