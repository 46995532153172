import React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";

import { WithThemeProps } from "types/general";
import { ThemeType } from "theme";

import {
  CLASS_BASE_COVERAGE,
  CLASS_COVERAGE_DESCRIPTION,
  CLASS_COVERAGE_TITLE,
  CLASS_COVERAGE_DETAILS_CONTAINER,
  CLASS_COVERAGE_DETAILS_CONTENT,
} from "./constants";

const BaseCoverageContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box {...props} />
))`
  &.${CLASS_BASE_COVERAGE} {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-template-columns: 1fr;
    width: ${({ theme: { spacing } }) =>
      `clamp(${spacing(39)}, 30vw, ${spacing(45)})`};
    min-height: ${({ theme: { spacing } }: ThemeType) => spacing(55)};

    gap: ${({ theme: { spacing } }: ThemeType) => spacing(0.5)};
    padding: ${({ theme: { spacing } }: ThemeType) => spacing(0, 1)};

    text-align: center;
    justify-content: space-between;
    box-sizing: border-box;

    & > .${CLASS_COVERAGE_DETAILS_CONTAINER} {
      min-height: ${({ theme: { spacing } }: ThemeType) => spacing(45)};
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      & > .${CLASS_COVERAGE_DETAILS_CONTENT} {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        & > .${CLASS_COVERAGE_TITLE} {
          font-weight: 400;
          font-size: 36px;
          line-height: 50px;
          font-family: "utopia-std-display";
        }

        & > .${CLASS_COVERAGE_DESCRIPTION} {
          color: ${({ theme: { palette } }: ThemeType) =>
            palette.p_grey.textDark};
          max-width: ${({ theme: { spacing } }: ThemeType) => spacing(39.25)};

          &.MuiTypography-root {
            font-family: "TWK Lausanne";
            font-weight: 300;
            font-size: 18px;
            line-height: 20px;
          }
        }
      }
    }
  }
`;

export default BaseCoverageContainer;
