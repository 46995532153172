enum EventType {
  Unknown = 0,
  Navigation = 1,
  Location = 2,
  Search = 3,
  Transaction = 4,
  UserContent = 5,
  UserPreference = 6,
  Social = 7,
  Other = 8,
  Media = 9,
}

export default EventType;
