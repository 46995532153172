enum EventName {
  OfferCallToActionPressed = "offer_cta_pressed",
  MadePayment = "made_payment",
  PageView = "page_view",
  SettingName = "setting_name",
  SettingDestination = "setting_destination",
  SettingDates = "setting_dates",
  SettingTravelersCount = "setting_travelers_count",
  SettingMainTravelersDetails = "setting_main_traveler_details",
  SettingAdditionalTravelersDetails = "setting_additional_traveler_details",
  PressedAppDownload = "pressed_app_download",
  SummaryScreenPressedReceipt = "summary_screen_pressed_receipt",
  SummaryScreenPressedPolicyPDF = "summary_screen_pressed_policy_PDF",
  LeadAdded = "lead_added",
}

export default EventName;
