import { createAsyncThunk } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";

import {
  getDestinationPlacesGoogleData,
  getDestinationsPlaceDetails,
} from "utils/destinations";

import { GooglePlace, PlaceDetails } from "types/quote-types/Locations";
import { GooglePlaceDetailsResponse } from "types/general";
import { RootState } from "types/redux-types";

import {
  setDates,
  setDatesAndDestinationAreKnown,
  setDestinationGooglePlaceResults,
  setDestinations,
} from "../index";
import { selectName, selectTravelers } from "../selectors";
import { logEvent, setUserAttributes } from "../../../../tracking/logger";
import EventName from "../../../../tracking/mixpanel/EventName";
import { TrackerPropertyName } from "../../../../tracking/mixpanel/PropertyNames";
import {
  calcAgeByDateOfBirth,
  nonAdultTravelersCount,
} from "../../../../utils/QuoteTravelers";
import { getGTMInstanceDataLayer } from "../../../../tracking/google-tag-manager";
import ApiWrapper from "../../../../utils/ApiWrapper";
import { UtmDto, UtmTypes } from "../../../../types/utm";
import { UtmTypeToNameDict } from "../../../../utils/utm";
import { getCustomError } from "../../../../utils";

const getAdvisorEmailUrl = (advisorId: string) => `/advisor/${advisorId}`;

export const datesAndDestinationGivenThunk = createAsyncThunk<
  void,
  {
    destinationGoogleId?: string;
    dates?: { tripStartDate: string; tripEndDate: string };
  },
  { state: RootState }
>(
  "quote/datesAndDestinationGivenThunk",
  async ({ destinationGoogleId, dates }, { dispatch }) => {
    if (dates && destinationGoogleId) {
      dispatch(setDatesAndDestinationAreKnown(true));
    }

    if (dates) {
      dispatch(setDates({ dates }));
    }

    if (destinationGoogleId) {
      const chosenDestinations: GooglePlace = {
        googleId: destinationGoogleId.toString(),
        label: "",
      };

      const destinationGooglePlaceResults: GooglePlaceDetailsResponse[] =
        await getDestinationPlacesGoogleData([chosenDestinations]);

      const destinationDetails: PlaceDetails[] = getDestinationsPlaceDetails(
        destinationGooglePlaceResults,
        [chosenDestinations] as GooglePlace[]
      );

      dispatch(setDestinationGooglePlaceResults(destinationGooglePlaceResults));
      dispatch(setDestinations({ destinations: destinationDetails }));
    }
    return;
  }
);

export const logPageViewEvent = createAsyncThunk<
  void,
  {
    pageName: string;
    additionalParams?: Record<string, any>;
  },
  { state: RootState }
>(
  "quote/logging/pageViewEvent",
  async ({ pageName, additionalParams }, { getState }) => {
    const store = getState();

    const { firstName, lastName } = selectName(store);
    const travelers = selectTravelers(store);

    logEvent(EventName.PageView, {
      [TrackerPropertyName.NameOfPage]: pageName,
      [TrackerPropertyName.FirstName]: firstName || null,
      [TrackerPropertyName.LastName]: lastName || null,
      [TrackerPropertyName.TotalTravelersCount]: travelers.length,
      [TrackerPropertyName.MainTravelerAge]: travelers[0]?.dateOfBirth
        ? calcAgeByDateOfBirth(travelers[0].dateOfBirth)
        : null,
      [TrackerPropertyName.NonAdultTravelersCount]: nonAdultTravelersCount(
        travelers
          .filter((traveler) => traveler !== null)
          .map((traveler) => traveler!.dateOfBirth)
      ),
      ...additionalParams,
    });

    const dataLayer = getGTMInstanceDataLayer();
    dataLayer.push({
      event: EventName.PageView,
      page_location: window.location.pathname,
      page_title: pageName,
    });
  }
);

export const getAdvisorDetails = createAsyncThunk<
  string,
  { utms: UtmDto[] },
  { state: RootState }
>("advisors/getAdvisorEmail", async ({ utms }) => {
  const advisorId = utms.find(
    (utm) => utm.key === UtmTypeToNameDict[UtmTypes.UtmCampaign]
  );
  const advisorCompany = utms.find(
    (utm) => utm.key === UtmTypeToNameDict[UtmTypes.UtmSource]
  );
  try {
    const url = getAdvisorEmailUrl(advisorId?.value!);
    const advisorEmail = await ApiWrapper.request<string>(url, "GET");

    setUserAttributes({
      advisor_email: advisorEmail,
      advisor_id: advisorId?.value ?? null,
      advisor_company: advisorCompany?.value ?? null,
    });

    return advisorEmail;
  } catch (e) {
    const errorForLogs = getCustomError({
      name: "Advisor_id_not_found",
      message: (e as Error)?.message,
    });
    Sentry.captureException(errorForLogs);
    return "";
  }
});
