import React from "react";
import PriceContainer from "./styled";
import { Typography } from "@mui/material";
import {
  AddOnPriceProps,
  CLASS_ADD_ON_PRICE,
  CLASS_CENTS,
  CLASS_CURRENCY,
  CLASS_DOLLARS,
  CLASS_PRICE_DOT,
  CLASS_PRICE_SUFFIX,
} from "./constants";

const Price: React.FC<AddOnPriceProps> = ({ price, priceSuffix }) => {
  const [dollars, cents] = Number(price).toFixed(2).toString().split(".");
  return (
    <PriceContainer className={CLASS_ADD_ON_PRICE}>
      <Typography className={CLASS_CURRENCY}>+ $</Typography>
      <Typography className={CLASS_DOLLARS}>{dollars}</Typography>
      {Boolean(cents) && <Typography className={CLASS_PRICE_DOT}>.</Typography>}
      {Boolean(cents) && (
        <Typography className={CLASS_CENTS}>{cents}</Typography>
      )}
      {Boolean(priceSuffix) && (
        <Typography className={CLASS_PRICE_SUFFIX}>{priceSuffix}</Typography>
      )}
    </PriceContainer>
  );
};

export default Price;
