import React from "react";

interface IShowQuotePanelContext {
  showPanelToggleRef: (node?: Element | null) => void;
  shouldShowPanel: boolean;
  showPriceToggleRef: (node?: Element | null) => void;
  shouldShowPrice: boolean;
}

const ShowQuotePanelContext = React.createContext<IShowQuotePanelContext>({
  shouldShowPanel: false,
  showPanelToggleRef: () => {},
  shouldShowPrice: false,
  showPriceToggleRef: () => {},
});

export default ShowQuotePanelContext;
