import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";
import { WithThemeProps } from "../../../../../types/general";
import { ThemeType } from "../../../../../theme";
import React from "react";

const TravelersCountContainer = styled((props: BoxProps & WithThemeProps) => (
  <Box {...props} />
))`
  & .MuiFormGroup-root {
    display: flex;
    flex-direction: column;
    gap: ${({ theme: { spacing } }: ThemeType) => spacing(2.25)};
    outline: none;

    & > .MuiFormControlLabel-root {
      background-color: ${({ theme: { palette } }: ThemeType) =>
        palette.p_white.full};
      border: ${({ theme: { spacing, palette } }: ThemeType) =>
        `${spacing(0.125)} solid ${palette.p_grey.border}`};
      box-sizing: border-box;
      border-radius: ${({ theme: { spacing } }: ThemeType) => spacing(0.5)};
      margin: 0;

      & > .MuiRadio-root {
        padding: ${({ theme: { spacing } }: ThemeType) => spacing(1.5)};

        ${({ theme }: ThemeType) => theme.breakpoints.down("sm")} {
          padding: ${({ theme: { spacing } }: ThemeType) => spacing(1, 1.5)};
        }

        & .MuiSvgIcon-root {
          width: ${({ theme: { spacing } }: ThemeType) => spacing(3.5)};
          height: ${({ theme: { spacing } }: ThemeType) => spacing(3.5)};

          ${({ theme }: ThemeType) => theme.breakpoints.down("sm")} {
            width: ${({ theme: { spacing } }: ThemeType) => spacing(4.5)};
            height: ${({ theme: { spacing } }: ThemeType) => spacing(4.5)};
          }
        }
      }

      & > .Mui-checked {
        & + .MuiTypography-root {
          color: ${({ theme: { palette } }: ThemeType) => palette.primary.main};
        }
      }

      & > .MuiTypography-root {
        color: ${({ theme: { palette } }: ThemeType) => palette.p_grey.text};
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
`;

export default TravelersCountContainer;
