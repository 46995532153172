import React from "react";
import { Typography } from "@mui/material";

import { ExpandedDetailsItem } from "utils/coverage-expanded-details";
import {
  CLASS_ITEM_DESCRIPTION,
  CLASS_ITEM_TITLE,
  CLASS_MORE_DETAILS_ITEM_ELEMENT,
} from "./constants";
import MoreDetailsItemElementContainer from "./styled";

interface MoreDetailsItemElementProps {
  item: ExpandedDetailsItem;
}

const MoreDetailsItemElement: React.FC<MoreDetailsItemElementProps> = ({
  item: { title, description },
}) => {
  return (
    <MoreDetailsItemElementContainer
      className={CLASS_MORE_DETAILS_ITEM_ELEMENT}
    >
      <Typography component="strong" className={CLASS_ITEM_TITLE}>
        {title}
      </Typography>
      <Typography className={CLASS_ITEM_DESCRIPTION}>{description}</Typography>
    </MoreDetailsItemElementContainer>
  );
};

export default MoreDetailsItemElement;
