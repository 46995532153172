import React from "react";
import { formatISO } from "date-fns";

export const convertDateToISO = (date: Date) => formatISO(date);
export const isoDateWithoutHour = (isoDate: string) => isoDate.split("T")[0];

export const getStepUrlFromPathname = (pathname: string) => {
  const urlRegex = /\/quote(\/[\p{Letter}\p{Mark}-]+)\/?[0-9]*/gu;
  const match = urlRegex.exec(pathname);
  return match?.[1];
};

export const hasPressedEnter = (event: React.KeyboardEvent) =>
  event.code === "Enter" || event.code === "NumpadEnter";

export const getCustomError = ({
  message,
  name,
}: {
  message: string;
  name: string;
}) => {
  const error = new Error(message);
  error.name = name;
  return error;
};
