import React from "react";
import { styled } from "@mui/system";
import Typography, { TypographyProps } from "@mui/material/Typography";

import { WithThemeProps } from "types";
import { ThemeType } from "theme";

import { CLASS_DISCLAIMER_CONTAINER } from "./constants";

const DisclaimerContainer = styled(
  (props: WithThemeProps & TypographyProps) => <Typography {...props} />
)`
  &.${CLASS_DISCLAIMER_CONTAINER} {
    margin-top: ${({ theme: { spacing } }: ThemeType) => spacing(2)};
    color: ${({ theme: { palette } }: ThemeType) => palette.p_grey.textDark};

    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 126%;

    & a {
      color: ${({ theme: { palette } }: ThemeType) => palette.primary.hovered};
      text-decoration: none;
    }
  }
`;

export default DisclaimerContainer;
