import React from "react";
import { FallbackProps } from "react-error-boundary";
import Button from "@mui/material/Button";

const ErrorFallback: React.FC<FallbackProps> = ({
  error,
  resetErrorBoundary,
}) => {
  console.warn(error?.message);
  return (
    <div role="alert">
      <h1>Oops...Something wrong happened</h1>
      {error && error.message && (
        <>
          <p>Here's why:</p>
          <pre>{error?.message}</pre>
        </>
      )}
      <Button variant="contained" onClick={resetErrorBoundary}>
        Let's try again
      </Button>
    </div>
  );
};

export default ErrorFallback;
