import React from "react";
import Box, { BoxProps } from "@mui/material/Box";
import { styled } from "@mui/system";

import { WithThemeProps } from "types/general";
import { ThemeType } from "theme";

import { DATE_RANGE_BOXES_CLASS, DATES_CONTAINER_CLASS } from "./constants";

const DatesContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box {...props} />
))`
  &.${DATES_CONTAINER_CLASS} {
    & .${DATE_RANGE_BOXES_CLASS} {
      width: 100%;
      display: flex;
      justify-content: space-between;

      & > .MuiFormControl-root {
        width: ${({ theme: { spacing } }: ThemeType) => spacing(26)};
        flex: 1;

        & input {
          padding: ${({ theme: { spacing } }: ThemeType) => spacing(2, 1.75)};
        }

        &:first-of-type {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-right: ${({ theme: { spacing, palette } }) =>
            `${spacing(0.125)} solid ${palette.p_grey.border}`};
        }

        &.MuiTextField-root {
          background-color: ${({ theme: { palette } }: ThemeType) =>
            palette.p_white.full};
        }
      }
    }
  }
`;

export default DatesContainer;
