import React, { useMemo } from "react";
import DriversPicker from "../Drivers/DriversPicker";
import CarDrivingRangesPicker from "../DateRanges/CarDrivingRangesPicker";
import { Typography } from "@mui/material";
import { DateRange } from "@mui/lab/DateRangePicker";
import { useAppSelector } from "../../../../../../../hooks/redux-hooks";
import { selectQuoteDTO } from "../../../../../slice/selectors";
import useDateRangeCollection from "../../../../../../../hooks/controllers/useDateRangeCollection";
import DateRangeContext from "../DateRanges/DateRangeContext/DateRangeContext";
import DateRangeChipsCollection from "../DateRanges/DateRangeChipsCollection/DateRangeChipsCollection";
import {
  CAR_DATE_QUESTION,
  CarCollapseProps,
  CLASS_CAR_COLLAPSE_CONTAINER,
  DATE_CHIPS_CONTAINER,
  DATE_RANGE_PICKER,
  DRIVERS_SELECTOR,
} from "./constants";
import CarCollapseContainer from "./styled";
import { getAdultTravelers } from "../../../../../../../utils/QuoteTravelers";

const CarCollapse: React.FC<CarCollapseProps> = ({ isCarAdditionOn }) => {
  const [range, setRange] = React.useState<DateRange<Date>>([null, null]);
  const quoteDto = useAppSelector(selectQuoteDTO);
  const adultTravelers = getAdultTravelers(quoteDto?.travellers ?? []);

  const isSoloTravelerDriver = useMemo(
    () => adultTravelers.length === 1 && isCarAdditionOn,
    [adultTravelers, isCarAdditionOn]
  );

  const { chipCollection, addChip, onDeleteChip, hasOverlappingRanges } =
    useDateRangeCollection(quoteDto?.carDatesDto ?? []);

  if (adultTravelers.length === 0) return null;

  return (
    <CarCollapseContainer
      in={isCarAdditionOn}
      className={CLASS_CAR_COLLAPSE_CONTAINER}
      isSoloTravelerDriver={isSoloTravelerDriver}
    >
      <DateRangeContext.Provider
        value={{
          range,
          setRange,
          chipCollection: chipCollection ?? [],
          addChip,
          onDeleteChip,
          hasOverlappingRanges,
        }}
      >
        <Typography component="div" className={CAR_DATE_QUESTION}>
          When will you be using a rental car?
        </Typography>
        <CarDrivingRangesPicker
          isCarAdditionOn={isCarAdditionOn}
          className={DATE_RANGE_PICKER}
        />
        <DateRangeChipsCollection className={DATE_CHIPS_CONTAINER} />
        {!isSoloTravelerDriver && (
          <DriversPicker
            className={DRIVERS_SELECTOR}
            travelers={adultTravelers}
          />
        )}
      </DateRangeContext.Provider>
    </CarCollapseContainer>
  );
};

export default CarCollapse;
