import React from "react";
import { PopperProps } from "@mui/material/Popper";
import AutoCompletePopperContainer from "./styled";

const AutoCompletePopper: React.FC<PopperProps> = ({ ...props }) => {
  return (
    <AutoCompletePopperContainer
      {...props}
      placement="bottom"
      disablePortal={false}
      modifiers={[
        {
          name: "flip",
          enabled: false,
          options: {
            altBoundary: true,
            rootBoundary: "document",
            padding: 8,
          },
        },
      ]}
    />
  );
};

export default AutoCompletePopper;
