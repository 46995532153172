import React from "react";
import { styled } from "@mui/system";
import MuiBox, { BoxProps } from "@mui/material/Box";

import { WithThemeProps } from "types/general";
import { ThemeType } from "theme";

import {
  HEADER_UNITS_HEIGHT_DESKTOP,
  HEADER_UNITS_HEIGHT_MOBILE,
} from "../constants";

const AppContainer = styled((props: BoxProps & WithThemeProps) => (
  <MuiBox {...props} />
))`
  -webkit-overflow-scrolling: unset;
  padding-top: ${({ theme }) => theme.spacing(HEADER_UNITS_HEIGHT_DESKTOP)};
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;

  ${({ theme }: ThemeType) => theme.breakpoints.down("sm")} {
    padding-top: ${({ theme }) => theme.spacing(HEADER_UNITS_HEIGHT_MOBILE)};
  }

  & main {
    flex-grow: 1;
    width: 100%;

    & > * {
      max-width: ${({ theme }) => theme.spacing(180)};
      margin: 0 auto;
      padding-right: ${({ theme: { spacing } }) =>
        `clamp(${spacing(2)}, 5vw, ${spacing(10)})`};
      padding-left: ${({ theme: { spacing } }) =>
        `clamp(${spacing(2)}, 5vw, ${spacing(10)})`};

      width: 100%;
      height: 100%;
    }
  }
`;

export default AppContainer;
