import { GATSBY_URL } from "../../../../../../constants";

export interface DisclaimerProps {}

export interface DisclaimerLinkProps {
  name: string;
  href: string;
}

export const TRANSLATION_KEY_PREFIX = "quote.steps.offer.disclaimer";
export const KEY_DISCLAIMER = "main text";
export const KEY_FAQ_LINK = "faq link name";
export const KEY_POLICY_WORDING_LINK = "policy wording link name";

export const FAQ_URL = `${GATSBY_URL}/faq`;
export const POLICY_WORDING_URL_INTERNATIONAL =
  "https://assets.withfaye.com/downloads/Faye%20Travel%20Protection%20International%20Plan%20(28.2.22).pdf";
export const POLICY_WORDING_URL_DOMESTIC =
  "https://assets.withfaye.com/downloads/Faye%20Travel%20Protection%20Domestic%20Plan%20(28.2.22).pdf";

export const CLASS_DISCLAIMER_CONTAINER = "disclaimer-container";

export type StringOrElement = string | JSX.Element;

export const injectElementToParagraph = ({
  sourceString,
  linkName,
  element,
}: {
  sourceString: string;
  linkName: string;
  element: StringOrElement;
}): StringOrElement[] => {
  const splitParagraph: StringOrElement[] = sourceString.split(linkName);

  return splitParagraph.reduce(
    (
      paragraphParts: StringOrElement[],
      nonLinkPart: StringOrElement,
      nonLinkPartIndex
    ) => {
      if (nonLinkPartIndex > 0) {
        return [...paragraphParts, element, nonLinkPart];
      } else {
        return [...paragraphParts, nonLinkPart];
      }
    },
    []
  );
};

export const injectElementsToArray = ({
  sourceArray,
  linkName,
  element,
}: {
  sourceArray: StringOrElement[];
  linkName: string;
  element: StringOrElement;
}): StringOrElement[] =>
  sourceArray.reduce((paragraphParts: StringOrElement[], paragraphPart) => {
    if (typeof paragraphPart === "string") {
      const convertedParagraphPart: StringOrElement[] = injectElementToParagraph({
        sourceString: paragraphPart,
        element,
        linkName,
      });
      return [...paragraphParts, ...convertedParagraphPart];
    }
    return [...paragraphParts, paragraphPart];
  }, []);
