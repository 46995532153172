import React, { useState } from "react";

import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";

import ComingSoon from "common-components/indicators/ComingSoon";
import useRedirectToQuoteSteps from "hooks/flow/useRedirectToSteps";

import { StepName, STEP_TO_URL_MAP } from "./url-map";
import Name from "../components/steps/Name";
import Payment from "../components/steps/Payment";
import TravelersCount from "../components/steps/Travelers";
import SoloTraveler from "../components/steps/TravelerInformation/SoloTraveler";
import Offer from "../components/steps/Offer";
import PolicyPage from "../components/steps/Policy";
import Destination from "../components/steps/Destination";
import Dates from "../components/steps/Dates";
import MainTravelerPersonal from "../components/steps/TravelerInformation/MainTraveler/Presonal";
import MainTravelerResidency from "../components/steps/TravelerInformation/MainTraveler/Residency";
import AdditionalTravelerInfo from "../components/steps/TravelerInformation/AdditionalTraveler";
import StripeElementsContainer from "../components/steps/Payment/StripeElementsContainer";
import PaymentRequestContext from "../components/contexts/PaymentRequestContext";
import { PaymentRequest } from "@stripe/stripe-js/types/stripe-js/payment-request";
import { Nullable } from "../../../types";

const QuoteRoutes = () => {
  useRedirectToQuoteSteps();
  const [paymentRequestInstance, setPaymentRequestInstance] =
    useState<Nullable<PaymentRequest>>(null);

  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}${STEP_TO_URL_MAP[StepName.Name].toString()}`}>
        <Name />
      </Route>
      <Route
        path={`${path}${STEP_TO_URL_MAP[StepName.Destination].toString()}`}
      >
        <Destination />
      </Route>
      <Route path={`${path}${STEP_TO_URL_MAP[StepName.Dates].toString()}`}>
        <Dates />
      </Route>
      <Route
        path={`${path}${STEP_TO_URL_MAP[StepName.TravelersCount].toString()}`}
      >
        <TravelersCount />
      </Route>
      <Route
        path={`${path}${STEP_TO_URL_MAP[StepName.SoloTravelerInfo].toString()}`}
      >
        <SoloTraveler />
      </Route>
      <Route
        path={`${path}${STEP_TO_URL_MAP[
          StepName.MainTravelerPersonalInfo
        ].toString()}`}
      >
        <MainTravelerPersonal />
      </Route>
      <Route
        path={`${path}${STEP_TO_URL_MAP[
          StepName.MainTravelerResidency
        ].toString()}`}
      >
        <MainTravelerResidency />
      </Route>
      <Route
        path={`${path}${STEP_TO_URL_MAP[
          StepName.AdditionalTravelerInfo
        ].toString()}/:index`}
      >
        <AdditionalTravelerInfo />
      </Route>
      <Route path={`${path}${STEP_TO_URL_MAP[StepName.ComingSoon].toString()}`}>
        <ComingSoon />
      </Route>
      <Route path={`${path}${STEP_TO_URL_MAP[StepName.Offer].toString()}`}>
        <PaymentRequestContext.Provider
          value={{ paymentRequestInstance, setPaymentRequestInstance }}
        >
          <StripeElementsContainer>
            <Offer />
          </StripeElementsContainer>
        </PaymentRequestContext.Provider>
      </Route>
      <Route path={`${path}${STEP_TO_URL_MAP[StepName.Payment].toString()}`}>
        <PaymentRequestContext.Provider
          value={{ paymentRequestInstance, setPaymentRequestInstance }}
        >
          <StripeElementsContainer>
            <Payment />
          </StripeElementsContainer>
        </PaymentRequestContext.Provider>
      </Route>
      <Route path={`${path}${STEP_TO_URL_MAP[StepName.Policy].toString()}`}>
        <PolicyPage />
      </Route>
      <Redirect to={`${path}${STEP_TO_URL_MAP[StepName.Name].toString()}`} />
    </Switch>
  );
};
export default React.memo(QuoteRoutes);
