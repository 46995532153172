import React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";

import { WithThemeProps } from "types/general";
import { ThemeType } from "theme";

import { CLASS_DESTINATIONS_STEP_CONTAINER } from "./constants";

const Container = styled((props: BoxProps & WithThemeProps) => (
  <Box {...props} />
))`
  &.${CLASS_DESTINATIONS_STEP_CONTAINER} {
    & .details-input {
      & .MuiOutlinedInput-root {
        &.MuiAutocomplete-inputRoot {
          height: unset;
          min-height: ${({ theme }: ThemeType) => theme.spacing(6.5)};
          justify-content: flex-start !important;
          padding: ${({ theme }: ThemeType) =>
            theme.spacing(1.25, 4, 1.25, 1.25)} !important;
        }

        & .MuiAutocomplete-input {
          min-width: ${({ theme }: ThemeType) => theme.spacing(7.5)};
          height: ${({ theme }: ThemeType) => theme.spacing(4)};
          transition: height 0.2s cubic-bezier(0, 0, 0.2, 1);
        }

        &.Mui-focused {
          padding: ${({ theme }: ThemeType) =>
            theme.spacing(1.25, 4, 1.25, 1.25)};

          & .MuiAutocomplete-input {
            height: ${({ theme }: ThemeType) => theme.spacing(4)};
          }
        }
      }
    }
  }
`;

export default Container;
