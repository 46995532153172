export interface TripCoverageProps {}

export const CLASSNAME_TRIP_COST_CONTROLLER = "trip-cost-controller";
export const CLASSNAME_TRIP_COST_CONTROLLER_CONTAINER =
  "trip-cost-controller-container";
export const CLASS_ESTIMATED_TRIP_COST = "estimated-trip-cost";
export const CLASS_TRIP_COST_DESCRIPTION = "trip-cost-description";
export const CLASS_PRICE = "price";
export const CLASS_BUTTON_CHANGE_COST = "button-change-cost";

export const TRIP_COST_CONTROLLER_TITLE = "trip cost controller title";
export const TRIP_COST_CONTROLLER_DESCRIPTION =
  "trip cost controller description";
export const TRANSLATION_KEY_PREFIX = "quote.steps.offer.section base coverage";

export const UPDATE_REDUX_TRIP_COST_DEBOUNCE = 500;
