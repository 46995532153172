import React, { useContext } from "react";
import {
  CircularProgress,
  Typography,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { TRANSLATION } from "i18n";

import { ProcessStatus } from "types/quote-types";
import { useAppSelector } from "hooks/redux-hooks";

import {
  selectCalculationProcess,
  selectTotalPrice,
} from "features/quote/slice/selectors";
import TripShortDescription from "../../../../../../../common-components/trip-related/TripShortDescription";
import Price from "./Price";

import TripDetailsContainer from "./styled";
import {
  CLASS_MOBILE_TRIP_COST_CARD,
  PRICE,
  PRICE_FOR_TRAVELERS,
  SUGGESTION_LABEL,
  TRIP_DETAILS_CARD,
  TRIP_DETAILS_CONTAINER,
  TripDetailsProps,
} from "./constants";
import {
  SHOW_PANEL_INTERSECTION_TARGET,
  SHOW_PRICE_INTERSECTION_TARGET,
  TOP_SECTION_SUGGESTION_TRANSLATION_KEY,
  TRANSLATION_KEY_PREFIX,
} from "../constants";
import Card from "../../../../../../../common-components/display-utils/Card";
import TripCoverage from "../../TripCostController";
import ShowQuotePanelContext from "../../ShowQuotePanelContext";

const TripDetails: React.FC<TripDetailsProps> = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { showPanelToggleRef, showPriceToggleRef } = useContext(
    ShowQuotePanelContext
  );

  const { t } = useTranslation(TRANSLATION, {
    keyPrefix: TRANSLATION_KEY_PREFIX,
  });
  const calculationProcess = useAppSelector(selectCalculationProcess);
  const totalPrice = useAppSelector(selectTotalPrice);

  return (
    <TripDetailsContainer className={TRIP_DETAILS_CONTAINER}>
      <Card className={TRIP_DETAILS_CARD}>
        <TripShortDescription />
      </Card>
      {!isMobile && (
        <Card>
          <TripCoverage />
        </Card>
      )}
      <Card>
        <Box
          ref={showPanelToggleRef}
          className={SHOW_PANEL_INTERSECTION_TARGET}
        />
        <Box
          ref={showPriceToggleRef}
          className={SHOW_PRICE_INTERSECTION_TARGET}
        />
        <Typography className={SUGGESTION_LABEL}>
          {t(TOP_SECTION_SUGGESTION_TRANSLATION_KEY)}
        </Typography>
        <Box className={PRICE_FOR_TRAVELERS}>
          {calculationProcess.status === ProcessStatus.IN_PROCESS ||
          !Number(totalPrice) ? (
            <CircularProgress color="secondary" />
          ) : (
            <Typography component="div" className={PRICE}>
              <Price price={Number(totalPrice)} />
            </Typography>
          )}
        </Box>
      </Card>
      {isMobile && (
        <Card className={CLASS_MOBILE_TRIP_COST_CARD}>
          <TripCoverage />
        </Card>
      )}
    </TripDetailsContainer>
  );
};

export default TripDetails;
