import React, { useEffect } from "react";
import { CircularProgress } from "@mui/material";

import { testUserPay } from "features/quote/slice/thunks/quote";
import { useAppDispatch } from "hooks/redux-hooks";

import { CLASS_CONTAINER, TestUserPaymentModalProps } from "./constants";
import TestUserPaymentModalContainer from "./styled";

const TestUserPaymentModal: React.FC<TestUserPaymentModalProps> = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(testUserPay());
  }, [dispatch]);

  return (
    <TestUserPaymentModalContainer className={CLASS_CONTAINER}>
      <CircularProgress />
    </TestUserPaymentModalContainer>
  );
};

export default TestUserPaymentModal;
