import { Window } from "types";
import { Coverage } from "../types/quote-types";
import { GtmPurchaseItem } from "../types/GTM";

const DOMESTIC_BASE = "domesticBase";
const INTERNATIONAL_BASE = "internationalBase";
const ADDON_ITEM_COUNT_PER_POLICY = 1;

export const getGTMInstanceDataLayer = () => {
  (window as Window).dataLayer = (window as Window).dataLayer || [];
  return (window as Window).dataLayer;
};

export const sendPurchaseDetailsToGtm = ({
  orderValue,
  policyId,
  coverages,
  isDomestic,
  travelersAmount,
}: {
  orderValue: number;
  policyId: string;
  coverages: Coverage[];
  isDomestic: boolean;
  travelersAmount: number;
}) => {
  const filteredCoverages: GtmPurchaseItem[] = coverages
    .filter(({ isDefault }) => !isDefault)
    .filter(({ isSelected }) => isSelected)
    .map(({ coverageType, isBase }) => ({
      item_name: coverageType,
      quantity: isBase ? travelersAmount : ADDON_ITEM_COUNT_PER_POLICY,
    }));

  const items: GtmPurchaseItem[] = [
    {
      item_name: isDomestic ? DOMESTIC_BASE : INTERNATIONAL_BASE,
      quantity: travelersAmount,
    },
    ...filteredCoverages,
  ];

  const dataLayer = getGTMInstanceDataLayer();
  dataLayer.push({
    event: "purchase",
    ecommerce: {
      currency: "USD",
      value: orderValue,
      transaction_id: policyId,
      items,
    },
  });
};
