import React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";

import { WithThemeProps } from "types/general";
import { ThemeType } from "theme";

import { CLASS_POLICY_DETAILS } from "./constants";
import { CARD_CONTAINER } from "../../../../../../common-components/display-utils/Card/constants";

const PolicyDetailsContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box {...props} />
))`
  &.${CLASS_POLICY_DETAILS} {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: ${({ theme: { spacing } }: ThemeType) => spacing(1)};

    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      flex-direction: column;
    }

    & > .${CARD_CONTAINER} {
      transition: width 0.2s cubic-bezier(0, 0, 0.2, 1),
        margin-top 0.2s cubic-bezier(0, 0, 0.2, 1);
      flex-direction: column;
      justify-content: flex-start;
    }
  }
`;

export default PolicyDetailsContainer;
