import React from "react";
import CoverageImageContainer from "./styled";
import { coverageImages } from "../../../utils/coverage-types";
import { CoverageType } from "../../../types/quote-types";

interface CoverageImageProps {
  className: string;
  coverageType: CoverageType;
}

const CoverageImage: React.FC<CoverageImageProps> = ({
  className,
  coverageType,
}) => {
  const image = coverageImages[coverageType];

  return image ? (
    <CoverageImageContainer className={className} src={image} />
  ) : null;
};

export default CoverageImage;
