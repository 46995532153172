import React, { useContext } from "react";

import DateRangeChip from "../DateRangeChip";
import DateRangeChipType from "../../../../../../../../types/quote-types/DateRangeChip";
import DateRangeContext from "../DateRangeContext/DateRangeContext";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import classnames from "classnames";
import DateRangeChipsCollectionContainer from "./styled";
import {
  CLASS_DATES_PICKER_TITLE,
  CLASS_DRIVERS_CONTAINER,
  DateRangeChipsCollectionProps,
} from "./constants";

const DateRangeChipsCollection: React.FC<DateRangeChipsCollectionProps> = ({
  className,
}) => {
  const {
    chipCollection: chips,
    onDeleteChip,
    hasOverlappingRanges,
  } = useContext(DateRangeContext);
  return (
    <DateRangeChipsCollectionContainer
      className={classnames(CLASS_DRIVERS_CONTAINER, className)}
    >
      <Typography component="h3" className={CLASS_DATES_PICKER_TITLE}>
        Selected date ranges:
      </Typography>
      {hasOverlappingRanges && (
        <Box>
          <strong style={{ color: "red" }}>
            NOTE: There are some overlapping ranges, please remove them
          </strong>
        </Box>
      )}
      {chips!.map((chip: DateRangeChipType, index) => (
        <DateRangeChip
          key={`${chip.startDate!}, ${chip.endDate!}`}
          onDeleteChip={onDeleteChip!}
          chipIndex={index}
          range={chip}
        />
      ))}
    </DateRangeChipsCollectionContainer>
  );
};

export default DateRangeChipsCollection;
