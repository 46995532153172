import { styled } from "@mui/system";
import { WithThemeProps } from "../../../../../../../types/general";
import Collapse, { CollapseProps } from "@mui/material/Collapse";
import { ThemeType } from "../../../../../../../theme";
import React from "react";
import {
  CAR_DATE_QUESTION,
  CLASS_CAR_COLLAPSE_CONTAINER,
  DATE_CHIPS_CONTAINER,
  DATE_RANGE_PICKER,
  DRIVERS_SELECTOR,
} from "./constants";

const CarCollapseContainer = styled(
  ({
    isSoloTravelerDriver,
    ...props
  }: { isSoloTravelerDriver: boolean } & WithThemeProps & CollapseProps) => (
    <Collapse {...props} />
  )
)`
  &.${CLASS_CAR_COLLAPSE_CONTAINER} {
    width: 100%;

    & .MuiCollapse-wrapperInner {
      margin-bottom: ${({ theme: { spacing } }: ThemeType) => spacing(4)};
      padding: 0 ${({ theme: { spacing } }: ThemeType) => spacing(5.5)};
      height: 100%;
      grid-template-columns: ${({ theme: { spacing } }: ThemeType) =>
          spacing(36)} 1fr 2fr;
      grid-template-rows: ${({ theme: { spacing } }: ThemeType) => spacing(3)} 1fr ${({
          theme: { spacing },
        }: ThemeType) => spacing(3)} 1fr;
      display: grid;
      grid-template-areas: ${({
        isSoloTravelerDriver,
      }: {
        isSoloTravelerDriver: boolean;
      }) =>
        isSoloTravelerDriver
          ? `
"${CAR_DATE_QUESTION}  .             .           "
"${DATE_RANGE_PICKER}  .  ${DATE_CHIPS_CONTAINER}"
"${DATE_RANGE_PICKER}  .  ${DATE_CHIPS_CONTAINER}"
"${DATE_RANGE_PICKER}  .  ${DATE_CHIPS_CONTAINER}"`
          : `
"${CAR_DATE_QUESTION}  .             .           "
"${DATE_RANGE_PICKER}  .  ${DATE_CHIPS_CONTAINER}"
"${DATE_RANGE_PICKER}  .             .           "
"${DATE_RANGE_PICKER}  .      ${DRIVERS_SELECTOR}"`};

      ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
        overflow-x: hidden;
        padding: ${({ theme: { spacing } }) => spacing(0, 2.75)};
        grid-template-columns: 1fr;
        grid-template-rows: ${({ theme: { spacing } }: ThemeType) => spacing(5)} 1fr auto auto;
        grid-template-areas: ${({
          isSoloTravelerDriver,
        }: {
          isSoloTravelerDriver: boolean;
        }) =>
          isSoloTravelerDriver
            ? `
"${CAR_DATE_QUESTION}"
"${DATE_RANGE_PICKER}"
"${DATE_CHIPS_CONTAINER}"
"${DATE_CHIPS_CONTAINER}"`
            : `
"${CAR_DATE_QUESTION}"
"${DATE_RANGE_PICKER}"
"${DATE_CHIPS_CONTAINER}"
"${DRIVERS_SELECTOR}"`};
      }

      & .${CAR_DATE_QUESTION} {
        grid-area: ${CAR_DATE_QUESTION};
        font-weight: 500;
        font-size: 18px;
        color: ${({ theme: { palette } }: ThemeType) =>
          palette.p_grey.textDark};

        ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
          align-self: center;
          font-weight: 500;
          font-size: 16px;
          line-height: 14px;
        }
      }

      & .${DATE_RANGE_PICKER} {
        grid-area: ${DATE_RANGE_PICKER};
      }

      & .${DATE_CHIPS_CONTAINER} {
        grid-area: ${DATE_CHIPS_CONTAINER};

        ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
          margin-bottom: ${({ theme: { spacing } }: ThemeType) => spacing(4.5)};
        }
      }

      & .${DRIVERS_SELECTOR} {
        grid-area: ${DRIVERS_SELECTOR};

        ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
          margin-bottom: ${({ theme: { spacing } }: ThemeType) => spacing(4.5)};
        }
      }
    }
  }
`;

export default CarCollapseContainer;
