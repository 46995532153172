export interface OfferTopContainerProps {}

export const TRANSLATION_KEY_PREFIX = "quote.steps.offer.section quote";
export const TITLE_TRANSLATION_KEY = "title";
export const TOP_SECTION_SUGGESTION_TRANSLATION_KEY =
  "top section.suggestion_general";

export const QUOTE_SECTION = "quote-section";
export const QUOTE_SECTION_TITLE = "quote-section-title";
export const BUTTON_CONTAINER = "button-container";
export const SHOW_PANEL_INTERSECTION_TARGET = "show-panel-intersection-target";
export const SHOW_PRICE_INTERSECTION_TARGET = "show-price-intersection-target";
export const SECTION_IMAGE = "section-image";

export interface PriceProps {
  price: number;
}
