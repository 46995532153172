import { AutoCompleteOption } from "../types/quote-types";
import { Nullable } from "../types/general";
import { Coverage } from "../types/quote-types/quote.dto";
import { GooglePlace } from "../types/quote-types/Locations";

export const findItemCollection = (
  collection: AutoCompleteOption[],
  valueToFind: Nullable<string>
): AutoCompleteOption | null =>
  collection.find((collectionItem) => collectionItem.value === valueToFind) ??
  null;

export const findPlaceInCollection = (
  collection: GooglePlace[],
  idToFind: Nullable<string>
): GooglePlace | null =>
  collection.find((collectionItem) => collectionItem.googleId === idToFind) ??
  null;

export const mapToIndexedCoverages = (coverages: Coverage[]) =>
  coverages.map((coverage, index) => ({
    coverage,
    coverageIndex: index,
  }));
