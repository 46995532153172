import React from "react";
import { styled } from "@mui/system";
import { Box, BoxProps } from "@mui/material";

import { WithThemeProps } from "types";
import { ThemeType } from "theme";

import {
  CLASS_CALCULATING_POLICY,
  CLASS_LOADER_CONTENT,
  CLASS_MESSAGE_WE_BUILD_YOUR_POLICY,
  LOADER_CONTAINER,
  LOADER_HIDDEN,
  LOADER_SHOWN,
} from "./constants";
import {
  HEADER_UNITS_HEIGHT_DESKTOP,
  HEADER_UNITS_HEIGHT_MOBILE,
} from "../../../../../../constants";

const LoadingContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box {...props} />
))`
  &.${LOADER_CONTAINER} {
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    max-width: unset;
    overflow: hidden;

    background-color: ${({ theme }: ThemeType) =>
      theme.palette.background.default};
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &.${LOADER_SHOWN} {
      height: 100vh;
    }

    &.${LOADER_HIDDEN} {
      height: 0;
    }

    padding-top: ${({ theme }) => theme.spacing(HEADER_UNITS_HEIGHT_DESKTOP)};
    padding-right: ${({ theme: { spacing } }) =>
      `clamp(${spacing(2)}, 5vw, ${spacing(10)})`};
    padding-left: ${({ theme: { spacing } }) =>
      `clamp(${spacing(2)}, 5vw, ${spacing(10)})`};

    ${({ theme }: ThemeType) => theme.breakpoints.down("sm")} {
      padding-top: ${({ theme }) => theme.spacing(HEADER_UNITS_HEIGHT_MOBILE)};
    }

    & .${CLASS_LOADER_CONTENT} {
      margin: ${({ theme }: ThemeType) => theme.spacing(6.25)} auto 0;
      max-width: ${({ theme }: ThemeType) => theme.spacing(54)};

      ${({ theme }: ThemeType) => theme.breakpoints.down("sm")} {
        max-width: ${({ theme }: ThemeType) => theme.spacing(43)};
      }

      & .${CLASS_MESSAGE_WE_BUILD_YOUR_POLICY} {
        margin-top: ${({ theme }) => theme.spacing(2.5)};
        max-width: unset;
      }

      & .${CLASS_CALCULATING_POLICY} {
        margin-top: ${({ theme }) => theme.spacing(5.5)};
        display: block;
        text-align: center;

        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
      }

      & > .MuiLinearProgress-root {
        border-radius: ${({ theme }) => theme.spacing(0.5)};
        margin: ${({ theme }) => theme.spacing(4)} auto 0;
        width: ${({ theme }) => theme.spacing(20)};

        & > .MuiLinearProgress-bar {
          border-radius: inherit;
        }
      }
    }
  }
`;

export default LoadingContainer;
