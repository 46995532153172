import { styled } from "@mui/system";
import { WithThemeProps } from "../../../types/general";
import Popper, { PopperProps } from "@mui/material/Popper";
import React from "react";
import { ThemeType } from "../../../theme";

const AutoCompletePopperContainer = styled(
  ({
    theme,
    placement,
    disablePortal,
    modifiers,
    ...props
  }: WithThemeProps & PopperProps) => (
    <Popper
      {...props}
      placement={placement}
      disablePortal={disablePortal}
      modifiers={modifiers}
    />
  )
)`
  inset: ${({ theme }: ThemeType) => theme.spacing(0.25)} auto auto 0 !important;
`;

export default AutoCompletePopperContainer;
