import {
  PaymentRequest,
  PaymentRequestWallet,
} from "@stripe/stripe-js/types/stripe-js/payment-request";
import { RootState } from "types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { selectTotalPrice, selectTripCost } from "../selectors";
import { TrackerPropertyName } from "../../../../tracking/mixpanel/PropertyNames";
import { format } from "date-fns";
import {
  FORMAT_LAST_PURCHASE_DATE,
  STATUS_SUCCESS,
} from "../../components/steps/Payment/constants";
import { logEvent, setUserAttributes } from "../../../../tracking/logger";
import EventName from "../../../../tracking/mixpanel/EventName";
import { PAYMENT_TYPE_CREDIT_CARD } from "../../../../constants";

export let paymentRequestInstance: PaymentRequest;

export const trackMadePayment = createAsyncThunk<
  void,
  {
    errorMessage?: string;
    paymentType:
      | PaymentRequestWallet
      | typeof PAYMENT_TYPE_CREDIT_CARD
      | string;
  },
  { state: RootState }
>(
  "tracking/trackMadePayment",
  ({ errorMessage, paymentType }, { getState }) => {
    const totalPrice = selectTotalPrice(getState());
    const tripCost = selectTripCost(getState());
    const { defaultTripCost, travellers } = getState().quote;

    const lastPolicyDateProperty = {
      [TrackerPropertyName.LastPolicyDate]: format(
        Date.now(),
        FORMAT_LAST_PURCHASE_DATE
      ),
    };

    setUserAttributes(lastPolicyDateProperty);

    logEvent(EventName.MadePayment, {
      [TrackerPropertyName.PaymentType]: paymentType,
      [TrackerPropertyName.Status]: errorMessage ?? STATUS_SUCCESS,
      [TrackerPropertyName.TotalPrice]: Number(totalPrice),
      [TrackerPropertyName.TotalTravelersCount]: travellers.length,
      [TrackerPropertyName.TripCost]: tripCost,
      [TrackerPropertyName.DefaultTripCost]: defaultTripCost,
      ...lastPolicyDateProperty,
    });
  }
);
