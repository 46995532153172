import { styled } from "@mui/system";
import { WithThemeProps } from "../../../../../../../../types/general";
import Box, { BoxProps } from "@mui/material/Box";
import { ThemeType } from "../../../../../../../../theme";
import React from "react";
import {
  CLASS_ADD_ON_PRICE,
  CLASS_CENTS,
  CLASS_PRICE_DOT,
  CLASS_PRICE_SUFFIX,
} from "./constants";

const PriceContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box {...props} />
))`
  &.${CLASS_ADD_ON_PRICE} {
    display: flex;
    align-items: baseline;

    & > * {
      font-weight: 600;
      font-size: 18px;
    }

    & > .${CLASS_PRICE_DOT}, .${CLASS_CENTS}, .${CLASS_PRICE_SUFFIX} {
      font-size: 14px;
    }

    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      & > * {
        font-weight: 400;
        font-size: 15px;
      }

      & > .${CLASS_PRICE_DOT}, .${CLASS_CENTS}, .${CLASS_PRICE_SUFFIX} {
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
`;

export default PriceContainer;
