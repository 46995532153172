import { createSlice } from "@reduxjs/toolkit";
import { Nullable } from "../../../types/general";
import { signInUsingCustomToken } from "./thunks/auth";
import { AppUserData, OtpStatus } from "../../../types/AuthTypes";
import TokenService from "../../../utils/TokenService";

interface IAuthenticationState {
  user: Nullable<AppUserData>;
  emailOtpStatus: OtpStatus;
  otpIncorrectError?: any;
}

const initialState: IAuthenticationState = {
  user: TokenService.getUser() as Nullable<AppUserData>,
  emailOtpStatus: OtpStatus.INITIAL,
};

const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    refreshUserTokenState: (
      state: IAuthenticationState,
      { payload: { authData } }
    ) => {
      state.user = { authData };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      signInUsingCustomToken.fulfilled,
      (state: IAuthenticationState, { payload: { authData } }) => {
        state.user = { authData };
      }
    );
  },
});

export const { refreshUserTokenState } = authenticationSlice.actions;

export default authenticationSlice.reducer;
