import React, { useEffect } from "react";
import Box from "@mui/material/Box";

import { useTranslation } from "react-i18next";
import { TRANSLATION } from "i18n";

import HereWeGo from "assets/images/summary-page/HereWeGo.png";

import usePageViewEvent from "hooks/tracking/usePageViewEvent";
import StepMessage from "common-components/display-utils/StepMessage";
import PolicyDetails from "./PolicyDetails";

import PolicyContainer from "./styled";
import {
  CLASS_POLICY_CONTAINER,
  CLASS_POLICY_CONTENT,
  CLASS_SECTION_IMAGE,
  CLASS_SUMMARY_PAGE_TITLE,
  CLASS_SUMMARY_PAGE_TOP_NOTE,
  PAGE_NAME_SUMMARY,
  PolicyProps,
  TITLE_TRANSLATION_KEY,
  TOP_NOTE_TRANSLATION_KEY,
  TRANSLATION_KEY_PREFIX,
} from "./constants";
import { Typography } from "@mui/material";

const Policy: React.FC<PolicyProps> = () => {
  usePageViewEvent({ pageName: PAGE_NAME_SUMMARY });

  const { t } = useTranslation(TRANSLATION, {
    keyPrefix: TRANSLATION_KEY_PREFIX,
  });

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <PolicyContainer className={CLASS_POLICY_CONTAINER}>
      <Box className={CLASS_POLICY_CONTENT}>
        <StepMessage
          message={t(TITLE_TRANSLATION_KEY)}
          className={CLASS_SUMMARY_PAGE_TITLE}
        />
        <Typography className={CLASS_SUMMARY_PAGE_TOP_NOTE}>
          {t(TOP_NOTE_TRANSLATION_KEY)}
        </Typography>
        <Box className={CLASS_SECTION_IMAGE}>
          <img src={HereWeGo} alt="here-we-go" />
        </Box>
        <PolicyDetails />
      </Box>
    </PolicyContainer>
  );
};

export default Policy;
