import { useCallback } from "react";

import { Nullable } from "types";

import { useAppSelector } from "hooks/redux-hooks";
import {
  selectQuoteDTO,
  selectTotalPrice,
} from "features/quote/slice/selectors";

import EventName from "tracking/mixpanel/EventName";
import { TrackerPropertyName } from "tracking/mixpanel/PropertyNames";
import { logEvent, setUserAttributeList } from "tracking/logger";

export enum PaymentButtonCTA {
  TopCTA = "Top CTA",
  BottomCTA = "Bottom CTA",
}

const useAcceptOfferEvent = () => {
  const quoteDto = useAppSelector(selectQuoteDTO);
  const totalPrice = useAppSelector(selectTotalPrice);

  const coverages = quoteDto?.coverages;
  const tripCost: number | undefined = quoteDto?.tripCost;

  const selectedAddOns: string[] | undefined = coverages
    ?.filter((coverage) => !coverage.isBase && coverage.isSelected)
    ?.map((coverage) => coverage.title);

  const quotePrice: Nullable<number> = Number(totalPrice) || null;

  const fireAcceptOfferEvent = useCallback(
    (paymentButtonCta: PaymentButtonCTA) => {
      setUserAttributeList(TrackerPropertyName.SelectedAddOns, selectedAddOns!);
      logEvent(EventName.OfferCallToActionPressed, {
        [TrackerPropertyName.SelectedAddOns]: selectedAddOns?.join(" | "),
        [TrackerPropertyName.TripCost]: tripCost,
        [TrackerPropertyName.QuotePrice]: quotePrice,
        [TrackerPropertyName.PressedPaymentCTA]: paymentButtonCta,
      });
    },
    [quotePrice, selectedAddOns, tripCost]
  );
  return { fireAcceptOfferEvent };
};

export default useAcceptOfferEvent;
