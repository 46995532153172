export type AppUserAuthData = {
  accessToken?: string;
  refreshToken?: string;
  expirationTime?: number;
};

export type AppUserData = {
  authData: AppUserAuthData;
};

export enum OtpStatus {
  INITIAL = "INITIAL",
  REQUESTING = "REQUESTING",
  SENT = "SENT",
  CONFIRMED = "CONFIRMED",
}
