import React, { useCallback, useEffect, useLayoutEffect, useRef } from "react";
import Box from "@mui/material/Box";
import FillDetailsContainer from "../../../../../../../common-components/display-utils/FillDetailsContainer";
import FormField from "../../../../../../../common-components/display-utils/FormField/FormField";
import useInput from "../../../../../../../hooks/controllers/useInput";
import { isEmailTextValid } from "../../../../../../../utils/texts";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../hooks/redux-hooks";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "../../../../../../../i18n";
import { StepName } from "../../../../../routes/url-map";
import useNextQuoteStep from "../../../../../../../hooks/flow/useNextQuoteStep";
import {
  selectDateOfBirth,
  selectEmail,
  selectName,
} from "../../../../../slice/selectors";
import { setMainTravelerPersonalInfoThunk } from "../../../../../slice/thunks/steps";
import { convertDateToISO } from "../../../../../../../utils";
import TextFieldWrapper from "../../../../../../../common-components/controllers/TextFieldWrapper";
import { CLASS_FORM_FIELDS_SECTION } from "../../../../../../../constants";
import StepTabs from "../../../../../../../common-components/indicators/StepTabs";
import useBirthDateField from "../../../../../../../hooks/controllers/useBirthDateField";
import FayeBirthDateField from "../../../../../../../common-components/controllers/FayeBirthDateField";
import { setCurrentTravelerIndex } from "../../../../../../travelers/slice";
import {
  ASK_EMAIL_PLACEHOLDER,
  MainTravelerPersonalProps,
  PAGE_NAME_MAIN_TRAVELER_DOB_AND_EMAIL,
  SECONDARY_TITLE_KEY,
  TITLE_KEY,
  TRANSLATION_KEY_PREFIX,
} from "./constants";
import MainTravelerPersonalContainer from "./styled";
import usePageViewEvent from "../../../../../../../hooks/tracking/usePageViewEvent";

const MainTravelerPersonal: React.FC<MainTravelerPersonalProps> = () => {
  usePageViewEvent({ pageName: PAGE_NAME_MAIN_TRAVELER_DOB_AND_EMAIL });

  const dobRef = useRef<HTMLInputElement>();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(TRANSLATION, {
    keyPrefix: TRANSLATION_KEY_PREFIX,
  });
  const nextQuoteStep: StepName = useNextQuoteStep();
  const { firstName } = useAppSelector(selectName);
  const dateOfBirth = useAppSelector(selectDateOfBirth);
  const email = useAppSelector(selectEmail);

  const {
    value: userEmail,
    valid: isUserEmailValid,
    onChange: onUserEmailChange,
    onFocus: onUserEmailFocus,
    onBlur: onUserEmailBlur,
    hasError: userEmailHasError,
  } = useInput<string>(email, isEmailTextValid);

  const {
    value: selectedDateOfBirth,
    valid: isDateOfBirthValid,
    focused: isDateOfBirthFocused,
    onFieldFocus: onFieldDateFocus,
    onDateFieldChange: onDateOfBirthFieldChange,
    hasError: dateHasError,
    onDateFieldBlur,
    month,
    day,
    year,
  } = useBirthDateField(dateOfBirth);

  const handleStepsButtonClick = useCallback(async () => {
    if (isDateOfBirthValid && isUserEmailValid) {
      dispatch(
        setMainTravelerPersonalInfoThunk({
          nextQuoteStep,
          dateOfBirth: convertDateToISO(selectedDateOfBirth!),
          email: userEmail.trim(),
        })
      );
    }
  }, [
    dispatch,
    userEmail,
    selectedDateOfBirth,
    isDateOfBirthValid,
    isUserEmailValid,
    nextQuoteStep,
  ]);

  useEffect(() => {
    dispatch(setCurrentTravelerIndex(0));
  }, [dispatch]);

  useLayoutEffect(
    () => {
      if (dobRef.current) {
        dobRef.current?.focus();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dobRef.current]
  );

  return (
    <MainTravelerPersonalContainer>
      <FillDetailsContainer
        title={`${t(TITLE_KEY)}`}
        secondaryTitle={t(SECONDARY_TITLE_KEY, { name: firstName })}
        subtitleWidget={<StepTabs tabsCount={2} selectedIndex={0} />}
        isStepsButtonDisabled={!isDateOfBirthValid || !isUserEmailValid}
        onStepsButtonClick={handleStepsButtonClick}
      >
        <Box className={CLASS_FORM_FIELDS_SECTION}>
          <FormField hasError={dateHasError}>
            <FayeBirthDateField
              showError={dateHasError}
              isFocused={isDateOfBirthFocused}
              autoFocus
              onFieldFocus={onFieldDateFocus}
              onFieldBlur={onDateFieldBlur}
              onDateFieldChange={onDateOfBirthFieldChange}
              month={month}
              day={day}
              year={year}
            />
          </FormField>
          <FormField
            onBlur={onUserEmailBlur}
            onFocus={onUserEmailFocus}
            hasError={userEmailHasError}
          >
            <TextFieldWrapper
              className="details-input"
              label={t(ASK_EMAIL_PLACEHOLDER)}
              value={userEmail}
              onChange={onUserEmailChange}
              submitCallback={handleStepsButtonClick}
              type="email"
            />
          </FormField>
        </Box>
      </FillDetailsContainer>
    </MainTravelerPersonalContainer>
  );
};

export default MainTravelerPersonal;
