import { styled } from "@mui/system";
import { WithThemeProps } from "../../../../../../../types/general";
import Box, { BoxProps } from "@mui/material/Box";
import { CLASS_FORM_FIELDS_SECTION } from "../../../../../../../constants";
import { ThemeType } from "../../../../../../../theme";
import React from "react";

const MainTravelerPersonalContainer = styled(
  (props: WithThemeProps & BoxProps) => <Box {...props} />
)`
  & .${CLASS_FORM_FIELDS_SECTION} {
    display: flex;
    flex-direction: column;
    gap: ${({ theme: { spacing } }: ThemeType) => spacing(3)};
  }
`;

export default MainTravelerPersonalContainer;
