import React, { useCallback, useContext } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

import { useTranslation } from "react-i18next";
import { TRANSLATION } from "i18n";

import {
  selectCalculationProcess,
  selectDestinationGoogleData,
  selectTotalPrice,
  selectTravelers,
  selectTripEndDate,
  selectTripStartDate,
} from "features/quote/slice/selectors";
import { goToPaymentThunk } from "features/quote/slice/thunks/steps";

import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import StepsButton from "common-components/display-utils/StepsButton";
import { ProcessStatus } from "types/quote-types/QuoteProcesses";

import ShowQuotePanelContext from "../ShowQuotePanelContext";
import useAcceptOfferEvent, { PaymentButtonCTA } from "../useAcceptOfferEvent";

import SittingOnSuitcase from "assets/images/offer-page/SittingOnSuitcase.png";
import {
  OfferTopContainerProps,
  TITLE_TRANSLATION_KEY,
  TRANSLATION_KEY_PREFIX,
  SECTION_IMAGE,
  QUOTE_SECTION,
  QUOTE_SECTION_TITLE,
  BUTTON_CONTAINER,
  SHOW_PANEL_INTERSECTION_TARGET,
} from "./constants";
import QuoteSectionContainer from "./styled";
import TripDetails from "./TripDetails";
import { destinationNameForDisplay } from "../../../../../../utils/destinations";

const QuoteSection: React.FC<OfferTopContainerProps> = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { fireAcceptOfferEvent } = useAcceptOfferEvent();

  const { showPanelToggleRef } = useContext(ShowQuotePanelContext);
  const dispatch = useAppDispatch();
  const { t } = useTranslation(TRANSLATION, {
    keyPrefix: TRANSLATION_KEY_PREFIX,
  });
  const travellers = useAppSelector(selectTravelers);
  const startDate = useAppSelector(selectTripStartDate);
  const endDate = useAppSelector(selectTripEndDate);
  const destinationGooglePlaceResults = useAppSelector(
    selectDestinationGoogleData
  );
  const destinationName =
    destinationGooglePlaceResults && destinationGooglePlaceResults?.[0]
      ? destinationNameForDisplay(destinationGooglePlaceResults[0])
      : "";
  const totalPrice = useAppSelector(selectTotalPrice);
  const calculationProcess = useAppSelector(selectCalculationProcess);

  const handleClickPay = useCallback(() => {
    fireAcceptOfferEvent(PaymentButtonCTA.TopCTA);
    dispatch(goToPaymentThunk());
  }, [dispatch, fireAcceptOfferEvent]);

  if (!travellers || !startDate || !endDate || !destinationName) {
    return null;
  }

  return (
    <QuoteSectionContainer className={QUOTE_SECTION}>
      <Typography component="h2" className={QUOTE_SECTION_TITLE}>
        {t(TITLE_TRANSLATION_KEY)}
      </Typography>
      <Box className={SECTION_IMAGE}>
        <img src={SittingOnSuitcase} alt="sitting-on-suitcase" />
      </Box>
      <TripDetails />
      {!isMobile && (
        <Box className={BUTTON_CONTAINER}>
          <Box
            ref={showPanelToggleRef}
            className={SHOW_PANEL_INTERSECTION_TARGET}
          />
          <StepsButton
            onClick={handleClickPay}
            loading={
              calculationProcess.status === ProcessStatus.IN_PROCESS ||
              !Number(totalPrice)
            }
          />
        </Box>
      )}
    </QuoteSectionContainer>
  );
};

export default React.memo(QuoteSection);
