import { Dict } from "types/general";
import { CoverageType } from "types/quote-types/Coverage";
import { Coverage } from "types/quote-types/quote.dto";

type CarouselCoverage =
  | CoverageType.medicalCoverage
  | CoverageType.tripCancellation
  | CoverageType.tripInterruption
  | CoverageType.baggageExtraCare;

const coverageTypeIndexesDict: Dict<CoverageType, number> = {
  [CoverageType.medicalCoverage]: 0,
  [CoverageType.tripCancellation]: 1,
  [CoverageType.tripInterruption]: 1,
  [CoverageType.baggageExtraCare]: 2,
  [CoverageType.car]: 3,
  [CoverageType.cancelForAnyReason]: 4,
  [CoverageType.pet]: 5,
};
const carouselCoverageIndexes: Dict<CarouselCoverage, number> = {
  [CoverageType.medicalCoverage]: 1,
  [CoverageType.tripCancellation]: 0,
  [CoverageType.tripInterruption]: 0,
  [CoverageType.baggageExtraCare]: 2,
};

export const getCoveragesArrayByIndexes = (coverages: Coverage[]): Coverage[] =>
  coverages
    .reduce((accumulator: Coverage[], current) => {
      accumulator[coverageTypeIndexesDict[current.coverageType]] = current;
      return accumulator;
    }, [])
    .filter((_) => _ !== undefined);

export const getCarouselCoveragesArrayByIndexes = (
  coverages: Coverage[]
): Coverage[] =>
  coverages.reduce((accumulator: Coverage[], current) => {
    accumulator[
      carouselCoverageIndexes[current.coverageType as CarouselCoverage]
    ] = current;
    return accumulator;
  }, []);

export const nonDefaultCoveragesFilter = (coverage: Coverage): boolean =>
  !Boolean(coverage.isDefault);
