import React from "react";
import { CLASS_RESIDENCY_FIELDS_CONTAINER } from "./constants";
import { ResidencyFieldsContainer } from "./styled";

const ResidencyFields: React.FC = ({ children }) => {
  return (
    <ResidencyFieldsContainer className={CLASS_RESIDENCY_FIELDS_CONTAINER}>
      {children}
    </ResidencyFieldsContainer>
  );
};

export default ResidencyFields;
