import React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";

import { useTranslation } from "react-i18next";
import { TRANSLATION } from "i18n";

import { WithThemeProps } from "types/general";

import {
  BASE_COVERAGE,
  ExpandedDetailsComponentProps,
  TRANSLATION_KEY_PREFIX,
} from "./constants";
import { parseExpandedDetails } from "../../../../../../../utils/coverage-expanded-details";
import MoreDetailsItemElement from "./MoreDetailsItemElement";
import { useAppSelector } from "../../../../../../../hooks/redux-hooks";
import { selectIsDomesticTraveling } from "../../../../../slice/selectors";

const COVID = "covid";
const DOMESTIC_TRAVEL = "domestic";
const INTERNATIONAL_TRAVEL = "international";

const YourHealthExpandedDetailsContainer = styled(
  (props: WithThemeProps & BoxProps) => <Box {...props} />
)``;

const YourHealthExpandedDetails: React.FC<ExpandedDetailsComponentProps> = ({
  coverageName,
  className,
}) => {
  const { t } = useTranslation(TRANSLATION, {
    keyPrefix: TRANSLATION_KEY_PREFIX,
  });

  const isDomestic = useAppSelector(selectIsDomesticTraveling);
  if (!coverageName) return null;

  const expandedDetailsItemsCovid = parseExpandedDetails(
    t(`${coverageName}.${COVID}`)
  );

  const expandedDetailsItemsTravelDest = parseExpandedDetails(
    t(`${coverageName}.${isDomestic ? DOMESTIC_TRAVEL : INTERNATIONAL_TRAVEL}`)
  );

  const expandedDetailsItemsBase = parseExpandedDetails(
    t(`${coverageName}.${BASE_COVERAGE}`)
  );

  return (
    <YourHealthExpandedDetailsContainer className={className}>
      {expandedDetailsItemsCovid.map((item) => (
        <MoreDetailsItemElement key={item.title} item={item} />
      ))}
      {expandedDetailsItemsTravelDest.map((item) => (
        <MoreDetailsItemElement key={item.title} item={item} />
      ))}
      {expandedDetailsItemsBase.map((item) => (
        <MoreDetailsItemElement key={item.title} item={item} />
      ))}
    </YourHealthExpandedDetailsContainer>
  );
};

export default YourHealthExpandedDetails;
