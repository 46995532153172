import React, { useState } from "react";

import { getCarouselCoveragesArrayByIndexes } from "utils/coverage-collections";
import BaseCoverage from "../BaseCoverage";

import { Controller } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/free-mode";

import { BaseCoverageCarouselProps } from "./constants";
import BaseCoverageCarouselContainer from "./styled";
import { Swiper as SwiperClass } from "swiper/types";
import CardMoreDetails from "../CardMoreDetails";

const BaseCoverageCarousel: React.FC<BaseCoverageCarouselProps> = ({
  coverages,
}) => {
  const [firstSwiper, setFirstSwiper] = useState<SwiperClass>();
  const [secondSwiper, setSecondSwiper] = useState<SwiperClass>();

  const carouselCoverages = getCarouselCoveragesArrayByIndexes(coverages);

  const carouselBaseCoverageElements = carouselCoverages.map((coverage) => (
    <SwiperSlide key={`coverage-${coverage.title}`}>
      <BaseCoverage coverage={coverage} />
    </SwiperSlide>
  ));

  const carouselExpandedDetailsElements = carouselCoverages.map((coverage) => (
    <SwiperSlide key={`coverage-${coverage.title}`}>
      <CardMoreDetails coverageType={coverage.coverageType} />
    </SwiperSlide>
  ));

  return (
    <>
      <BaseCoverageCarouselContainer>
        <Swiper
          modules={[Controller]}
          centeredSlides={true}
          spaceBetween={20}
          slidesPerView="auto"
          controller={{ control: secondSwiper }}
          onSwiper={setFirstSwiper}
        >
          {carouselBaseCoverageElements}
        </Swiper>
        <Swiper
          modules={[Controller]}
          centeredSlides={true}
          spaceBetween={20}
          slidesPerView="auto"
          simulateTouch={false}
          onSwiper={setSecondSwiper}
          controller={{ control: firstSwiper }}
          allowTouchMove={false}
          grabCursor={false}
          enabled={false}
        >
          {carouselExpandedDetailsElements}
        </Swiper>
      </BaseCoverageCarouselContainer>
    </>
  );
};

export default BaseCoverageCarousel;
