import React, { useContext } from "react";

import { useTranslation } from "react-i18next";
import { TRANSLATION } from "i18n";

import {
  FINE_PRINT_SHOW_LESS,
  FINE_PRINT_SHOW_MORE,
  TRANSLATION_KEY_PREFIX,
} from "../../BaseCoverageSection/constants";
import { FinePrintButtonProps } from "./constants";
import FinePrintButtonContainer from "./styled";
import ShowBaseCoverageExpandedDetails from "../../../ShowBaseCoverageExpandedDetails";

const FinePrintExpandToggle: React.FC<FinePrintButtonProps> = () => {
  const { t } = useTranslation(TRANSLATION, {
    keyPrefix: TRANSLATION_KEY_PREFIX,
  });

  const { setExpanded, expanded } = useContext(ShowBaseCoverageExpandedDetails);

  return (
    <FinePrintButtonContainer
      onClick={() => setExpanded((expanded) => !expanded)}
    >
      {t(expanded ? FINE_PRINT_SHOW_LESS : FINE_PRINT_SHOW_MORE)}
    </FinePrintButtonContainer>
  );
};

export default FinePrintExpandToggle;
