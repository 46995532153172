export enum TrackerPropertyName {
  /* Personal */
  FirstName = "$first_name",
  LastName = "$last_name",
  Email = "$email",
  DateOfBirth = "date_of_birth",
  Age = "age",
  Address = "address",
  MainTravelerAge = "main_traveler_age",

  /* Trip */
  StartDate = "start_date",
  EndDate = "end_date",
  Days = "days",
  Destinations = "destinations",
  IsMultipleDestinations = "is_multiple_destinations",
  TripCost = "trip_cost",
  DefaultTripCost = "default_trip_cost",
  QuotePrice = "quote_price",

  /* Travelers */
  TotalTravelersCount = "total_travelers_count" /* Travelers count */,
  TravelersCount = "travelers_count" /* Solo / Couple / Bunch */,
  NonAdultTravelersCount = "non_adult_travelers_count",
  Ages = "ages",
  Emails = "emails",

  /* Payment */
  PressedPaymentCTA = "pressed_payment_CTA",
  PaymentType = "payment_type",
  Status = "status",
  TotalPrice = "total_price",

  /* Indicators */
  LastPolicyDate = "last_purchase_date",
  UsedFullAddressForm = "user_used_full_address_form",
  SelectedAddOns = "selected_add_ons",
  NameOfPage = "name_of_page",
  AppStore = "app_store",

  /* Tracking */
  QuoteHash = "quote_hash",

  /* Lead */
  Location = "location",
}
