import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";
import { WithThemeProps } from "../../../../../../types/general";
import { CLASS_ADDITIONAL_TRAVELER_CONTAINER } from "./constants";
import { ThemeType } from "../../../../../../theme";
import { CLASS_FORM_FIELDS_SECTION } from "../../../../../../constants";
import React from "react";
import {
  CLASS_ADD_TRAVELLER_BUTTON,
  CLASS_TRAVELER_ORDINAL,
  SECTION_DATE_OF_BIRTH,
  SECTION_EMAIL,
  SECTION_FIRST_NAME,
  SECTION_LAST_NAME,
} from "./AdditionalTravelerForm/constants";

const AdditionalTravelerContainer = styled(
  (props: BoxProps & WithThemeProps) => <Box {...props} />
)`
  &.${CLASS_ADDITIONAL_TRAVELER_CONTAINER} {
    & .${CLASS_TRAVELER_ORDINAL} {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: ${({ theme: { spacing } }: ThemeType) => spacing(3)};
      width: 100%;
      height: ${({ theme: { spacing } }: ThemeType) => spacing(3.5)};

      & span {
        &:nth-of-type(2) {
          font-weight: 500;
          font-size: 20px;
          line-height: 28px;
        }

        &:last-of-type {
          width: ${({ theme: { spacing } }: ThemeType) => spacing(5)};
        }
      }
    }

    & .${CLASS_ADD_TRAVELLER_BUTTON} {
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      width: ${({ theme: { spacing } }: ThemeType) => spacing(25)};
      text-transform: none;
      margin-top: ${({ theme: { spacing } }: ThemeType) => spacing(2.5)};
      padding: ${({ theme: { spacing } }: ThemeType) => spacing(0.625, 1)};
    }

    & .${CLASS_FORM_FIELDS_SECTION} {
      display: grid;
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: ${({ theme: { spacing } }: ThemeType) => spacing(2)};
      grid-row-gap: ${({ theme: { spacing } }: ThemeType) => spacing(3)};
      grid-template-areas:
        "${SECTION_FIRST_NAME}         ${SECTION_LAST_NAME}"
        "${SECTION_DATE_OF_BIRTH}  ${SECTION_DATE_OF_BIRTH}"
        "${SECTION_EMAIL}                  ${SECTION_EMAIL}";

      & > .${SECTION_FIRST_NAME} {
        grid-area: ${SECTION_FIRST_NAME};
      }

      & > .${SECTION_LAST_NAME} {
        grid-area: ${SECTION_LAST_NAME};
      }

      & > .${SECTION_DATE_OF_BIRTH} {
        grid-area: ${SECTION_DATE_OF_BIRTH};
      }

      & > .${SECTION_EMAIL} {
        grid-area: ${SECTION_EMAIL};
      }
    }
  }
`;

export default AdditionalTravelerContainer;
