import { styled } from "@mui/system";
import { WithThemeProps } from "../../../../types/general";
import Box, { BoxProps } from "@mui/material/Box";
import { ThemeType } from "../../../../theme";
import React from "react";
import { TabProps } from "./constants";

const Tab = styled((props: WithThemeProps & BoxProps & TabProps) => (
  <Box {...props} />
))`
  width: ${({ theme: { spacing } }: ThemeType) => spacing(10.5)};
  background-color: ${({
    selected,
    theme: { palette },
  }: ThemeType & TabProps) =>
    selected ? palette.primary.main : palette.p_grey.background};
  height: ${({ theme: { spacing } }: ThemeType) => spacing(0.5)};
  border-radius: ${({ theme: { spacing } }: ThemeType) => spacing(0.25)};
`;

export default Tab;
