import React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";

import { ThemeType } from "theme";
import { WithThemeProps } from "types/general";

import {
  CLASS_CONTAINER,
  CLASS_DATE,
  CLASS_DESTINATION,
  CLASS_DESTINATION_CONTAINER,
  CLASS_FOR_TRAVELLERS,
  CLASS_TRIP_DESCRIPTION,
} from "./constants";

const OfferTripShortDescriptionContainer = styled(
  (props: WithThemeProps & BoxProps) => <Box {...props} />
)`
  &.${CLASS_CONTAINER} {
    position: relative;
    max-width: ${({ theme: { spacing } }: ThemeType) => spacing(105.75)};
    height: 100%;

    & > .${CLASS_TRIP_DESCRIPTION} {
      height: 100%;
      position: relative;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      gap: ${({ theme: { spacing } }: ThemeType) => spacing(0.75)};

      ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
        align-items: center;
      }

      & .${CLASS_DESTINATION_CONTAINER} {
        max-height: ${({ theme: { spacing } }: ThemeType) => spacing(11)};
      }

      & .${CLASS_DESTINATION} {
        font-family: "utopia-std-display";
        font-weight: 600;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      & > .${CLASS_DATE}, .${CLASS_FOR_TRAVELLERS} {
        font-size: 18px;

        ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
          font-size: 14px;
        }
      }

      & > .${CLASS_DATE} {
        font-weight: 500;
      }
    }
  }
`;

export default OfferTripShortDescriptionContainer;
