import { useCallback, useEffect, useMemo, useState } from "react";
import { DateRange } from "@mui/lab/DateRangePicker";

import { isAfter } from "date-fns";

import { getMaxEndDateAccordingToStartDate, getMinDate } from "utils/dates";
import { DateRangeError, getErrorType, valueValidation } from "./constants";

const useDateRangePicker = (initialValue: DateRange<Date>) => {
  const [value, setValue] = useState<DateRange<Date>>(initialValue);
  const [valid, setValid] = useState<boolean>();
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const maxEndDate = useMemo(
    () => getMaxEndDateAccordingToStartDate(value[0]),
    [value]
  );

  const hasError = useMemo(
    () => valid === false && !isFocused,
    [valid, isFocused]
  );

  const errorType = useMemo(() => {
    return valid === false ? getErrorType(value) : DateRangeError.None;
  }, [valid, value]);

  const isEndDateBeyondLimit = useMemo(
    () =>
      Boolean(
        value[0] &&
          value[1] &&
          isAfter(value[1], getMaxEndDateAccordingToStartDate(value[0]))
      ),
    [value]
  );

  const validate = useCallback((newValue) => {
    setValid(valueValidation(newValue));
  }, []);

  const handleDateRangeChange = useCallback(
    (dateRange: DateRange<Date>) => {
      setValue(dateRange);
      validate(dateRange);
    },
    [validate]
  );

  const onFocus = useCallback(() => {
    if (!isFocused) {
      setIsFocused(true);
    }
  }, [isFocused]);

  const onBlur = useCallback(() => {
    if (isFocused) {
      setIsFocused(false);
    }
    validate(value);
  }, [isFocused, validate, value]);

  useEffect(() => {
    if (initialValue[0] !== null && initialValue[1] !== null) {
      validate(initialValue);
    }
  }, [validate, initialValue]);

  return {
    value,
    onChange: handleDateRangeChange,
    validate,
    valid,
    onFocus,
    onBlur,
    hasError,
    isEndDateBeyondLimit,
    maxEndDate,
    minStartDate: getMinDate(),
    errorType,
  };
};

export default useDateRangePicker;
