import React from "react";
import { styled } from "@mui/system";
import FormControl, { FormControlProps } from "@mui/material/FormControl";

import { WithThemeProps } from "types/general";
import { ThemeType } from "theme";

import { CLASS_FAYE_DATE_PICKER } from "./constants";

const FayeBirthDateFieldContainer = styled(
  (props: FormControlProps & WithThemeProps) => <FormControl {...props} />
)`
  &.${CLASS_FAYE_DATE_PICKER} {
    display: flex;
    border-radius: 4px;
    font-size: 14px;
    line-height: 28px;
    flex-direction: row;
    background-color: ${({ theme }: ThemeType) => theme.palette.p_white.full};
    height: 100%;
    box-sizing: border-box;

    & .date-field {
      border-left: ${({ theme: { spacing, palette } }) =>
        `${spacing(0.125)} solid ${palette.p_grey.border}`} !important;
      border-radius: 0 !important;

      & .MuiOutlinedInput-root {
        border-color: transparent;
      }
    }

    & .Mui-error {
      color: ${({
        theme: {
          palette: { error },
        },
      }: ThemeType) => error.main};
    }

    & .left-container {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-grow: 1;
      padding-left: ${({ theme: { spacing } }: ThemeType) => spacing(1.75)};

      &.date-picker-focused {
        & .label {
          color: ${({ theme: { palette } }: ThemeType) => palette.primary.main};
        }
      }

      & .label {
        flex-grow: 1;
        font-size: 14px;
        line-height: 10px;
        color: ${({ theme: { palette } }: ThemeType) => palette.p_grey.border};
      }

      & > .MuiSvgIcon-root {
        flex-shrink: 0;
        margin-right: ${({ theme }: ThemeType) => theme.spacing(1.5)};
        margin-bottom: ${({ theme }: ThemeType) => theme.spacing(0.75)};
        width: 0.8em;
        height: 0.8em;

        ${({ theme }: ThemeType) => theme.breakpoints.down("sm")} {
          margin-right: ${({ theme }: ThemeType) => theme.spacing(1)};
        }
      }
    }

    & .field-month,
    .field-day {
      width: 52px;
    }

    & .field-year {
      width: 92px;
    }

    & .MuiFormControl-root {
      font-weight: 500;
      flex-shrink: 0;
      border-radius: 0;
      outline: none;
    }
  }
`;
export default FayeBirthDateFieldContainer;
