import React from "react";

import Card from "common-components/display-utils/Card";
import TripShortDescription from "common-components/trip-related/TripShortDescription";

import DownloadDocuments from "./DownloadDocuments";
import PolicyId from "./PolicyId";

import PolicyDetailsContainer from "./styled";
import { CLASS_POLICY_DETAILS, PolicyDetailsProps } from "./constants";

const PolicyDetails: React.FC<PolicyDetailsProps> = () => {
  return (
    <PolicyDetailsContainer className={CLASS_POLICY_DETAILS}>
      <Card>
        <TripShortDescription />
      </Card>
      <Card>
        <PolicyId />
      </Card>
      <Card>
        <DownloadDocuments />
      </Card>
    </PolicyDetailsContainer>
  );
};

export default PolicyDetails;
