import React, { Dispatch, SetStateAction } from "react";

interface IShowBaseCoverageExpandedDetails {
  expanded: boolean;
  setExpanded: Dispatch<SetStateAction<boolean>>;
}

const ShowBaseCoverageExpandedDetails =
  React.createContext<IShowBaseCoverageExpandedDetails>({
    expanded: false,
    setExpanded: () => {},
  });

export default ShowBaseCoverageExpandedDetails;
