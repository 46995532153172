import React, { useEffect, useState } from "react";
import Routes from "../routes";
import Header from "../common-components/layout/Header";
import Footer from "../common-components/layout/Footer";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../common-components/indicators/ErrorFallback";
import {
  getAnalyticsInstance,
  getAuthInstance,
  getFirebaseInstance,
} from "../integrations/firebase";
import { FirebaseApp } from "firebase/app";
import { Auth } from "firebase/auth";
import { Nullable } from "types/general";
import { Analytics } from "firebase/analytics";
import { CssBaseline, Typography } from "@mui/material";
import DownloadApp from "../common-components/sections/DownloadApp";
import AppContainer from "./styled";
import { AppProps } from "./constants";
import useWindowSize from "../hooks/util/useWindowSize";

const App: React.FC<AppProps> = () => {
  const [firebaseInstance, setFirebaseInstance] =
    useState<Nullable<FirebaseApp>>();
  const [firebaseAnalyticsInstance, setFirebaseAnalyticsInstance] =
    useState<Nullable<Analytics>>();
  const [firebaseAuthInstance, setFirebaseAuthInstance] =
    useState<Nullable<Auth>>();
  const { height } = useWindowSize();

  useEffect(() => {
    if (!firebaseInstance) {
      const instance = getFirebaseInstance();
      setFirebaseInstance(instance);
    }
  }, [firebaseInstance]);

  useEffect(() => {
    if (firebaseInstance && !firebaseAnalyticsInstance) {
      const instance = getAnalyticsInstance();
      setFirebaseAnalyticsInstance(instance);
    }
  }, [firebaseInstance, firebaseAnalyticsInstance]);

  useEffect(() => {
    if (firebaseInstance && !firebaseAuthInstance) {
      const instance = getAuthInstance();
      setFirebaseAuthInstance(instance);
    }
  }, [firebaseInstance, firebaseAuthInstance]);

  return (
    <AppContainer height={height}>
      <CssBaseline />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Header />
        <Typography component="main">
          <Routes />
        </Typography>
        <Footer>
          <DownloadApp />
        </Footer>
      </ErrorBoundary>
    </AppContainer>
  );
};

export default App;
