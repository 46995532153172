import React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";

import { WithThemeProps } from "types";
import { ThemeType } from "theme";

import { CLASS_COVID_BADGE } from "../constants";

const CovidBadgeContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box {...props} />
))`
  &.${CLASS_COVID_BADGE} {
    width: ${({ theme: { spacing } }: ThemeType) => spacing(16)};
    height: ${({ theme: { spacing } }: ThemeType) => spacing(16)};
    border-radius: 50%;

    background-color: ${({ theme: { palette } }: ThemeType) =>
      palette.primary.main};
    color: ${({ theme: { palette } }: ThemeType) => palette.p_white.full};

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transform: rotate(-10deg);

    position: absolute;
    left: -10%;
    top: -10%;

    font-size: 18px;

    & > .MuiTypography-root {
      line-height: 112%;
      font-weight: 600;
      font-size: inherit;
    }

    ${({ theme }: ThemeType) => theme.breakpoints.down("tablet")} {
      width: ${({ theme: { spacing } }: ThemeType) => spacing(13)};
      height: ${({ theme: { spacing } }: ThemeType) => spacing(13)};

      font-size: 16px;

      left: -10%;
      top: -10%;
    }
  }
`;

export default CovidBadgeContainer;
