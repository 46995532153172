import { useEffect } from "react";

import { useAppDispatch } from "hooks/redux-hooks";
import { logPageViewEvent } from "../../features/quote/slice/thunks/general";

const usePageViewEvent = ({
  pageName,
  disable,
  additionalParams,
}: {
  pageName: string;
  disable?: boolean;
  additionalParams?: Record<string, any>;
}) => {
  const dispatch = useAppDispatch();

  useEffect(
    () => {
      !disable &&
        dispatch(
          logPageViewEvent({
            pageName,
            additionalParams,
          })
        );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, disable, additionalParams]
  );
};

export default usePageViewEvent;
