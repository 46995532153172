import React, { useEffect, useRef, useState } from "react";
import { MINIMUM_LOADER_WORD_READING_TIME_MS } from "./constants";

const useDummyProgress = (randomMaxValue: number) => {
  const [progress, setProgress] = React.useState<number>(0);
  const [progressFinished, setProgressFinished] = useState(false);

  const progressInterval = useRef<NodeJS.Timer>();

  useEffect(() => {
    if (progress < 20 && progressInterval.current) {
      clearInterval(progressInterval.current);
    } else {
      progressInterval.current = setTimeout(() => {
        setProgress((oldProgress) => {
          if (oldProgress === 100) {
            setProgressFinished(true);
            return 0;
          }
          const diff = Math.random() * randomMaxValue;
          return Math.min(oldProgress + diff, 100);
        });
      }, MINIMUM_LOADER_WORD_READING_TIME_MS);
    }

    return () => {
      if (progressInterval.current) {
        clearInterval(progressInterval.current);
      }
    };
  }, [progress, randomMaxValue]);

  return {
    progress,
    setProgress,
    progressFinished,
  };
};

export default useDummyProgress;
