import React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";

import { WithThemeProps } from "types/general";
import { ThemeType } from "theme";

const BaseCoverageCarouselContainer = styled(
  (props: WithThemeProps & BoxProps) => <Box {...props} />
)`
  width: 100vw;
  overflow-x: hidden;

  & .swiper {
    z-index: 0;

    & .swiper-pagination-bullet-active {
      background-color: ${({ theme: { palette } }: ThemeType) =>
        palette.primary.main};
    }

    & .swiper-wrapper {
      & .swiper-slide {
        cursor: grab;
        margin: ${({ theme: { spacing } }: ThemeType) => spacing(5, 0, 6, 0)};
        width: ${({ theme: { spacing } }) =>
          `clamp(${spacing(33.75)}, 5vw, ${spacing(40)})`} !important;
        display: flex;
        justify-content: center;
      }

      & .base-coverage {
        padding: ${({ theme: { spacing } }: ThemeType) => spacing(0, 1)};
        background-color: ${({ theme: { palette } }: ThemeType) =>
          palette.p_white.full};
        box-shadow: none;
      }
    }

    :nth-of-type(2) {
      & .swiper-wrapper {
        & .swiper-slide {
          cursor: auto;
          margin-bottom: 0;
          margin-top: 0;
        }
      }
    }

    :nth-of-type(2) {
      & .swiper-wrapper {
        & .swiper-slide {
          cursor: auto;
          margin-bottom: 0;
          margin-top: 0;
        }
      }
    }
  }
`;

export default BaseCoverageCarouselContainer;
