import React from "react";
import { styled } from "@mui/system";
import FormControl, { FormControlProps } from "@mui/material/FormControl";
import { WithThemeProps } from "../../../../../../types/general";
import { ThemeType } from "../../../../../../theme";

const Container = styled((props: WithThemeProps & FormControlProps) => (
  <FormControl fullWidth {...props} />
))`
  display: flex;
  flex-direction: row;
  gap: ${({ theme: { spacing } }: ThemeType) => spacing(2)};

  & > * {
    flex: 1;
  }
`;

const StateZipCodeFields: React.FC = ({ children }) => {
  return <Container>{children}</Container>;
};

export default StateZipCodeFields;
