import React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";
import { WithThemeProps } from "../../../../../../types/general";
import { ThemeType } from "../../../../../../theme";
import { GooglePlace } from "../../../../../../types/quote-types/Locations";

interface MultiDestinationTagProps {
  option: GooglePlace;

  //AutocompleteRenderGetTagProps
  key: number;
  className: string;
  disabled: boolean;
  "data-tag-index": number;
  tabIndex: -1;
  onDelete: (event: any) => void;
}

const Container = styled((props: WithThemeProps & BoxProps) => (
  <Box {...props} />
))`
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  height: ${({ theme: { spacing } }: ThemeType) => spacing(3)};

  background-color: ${({ theme: { palette } }: ThemeType) =>
    palette.primary.light};
  padding: ${({ theme: { spacing } }: ThemeType) => spacing(0.25, 1.5)};
  margin: ${({ theme: { spacing } }: ThemeType) =>
    spacing(0.5, 0.5)} !important;
  border-radius: ${({ theme: { spacing } }: ThemeType) => spacing(3)};
`;

const MultiDestinationTag: React.FC<MultiDestinationTagProps> = (props) => {
  const {
    option: { label, terms },
    onDelete,
    ...restProps
  } = props;
  return <Container {...restProps}>{terms?.[0]?.value ?? label}</Container>;
};

export default MultiDestinationTag;
