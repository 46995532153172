import { styled } from "@mui/system";
import { WithThemeProps } from "../../../../../../../types";
import Box, { BoxProps } from "@mui/material/Box";
import React from "react";
import { PAYMENT_OPTIONS_DIVIDER, WALLET_PAY_CONTAINER } from "./constants";
import { ThemeType } from "../../../../../../../theme";

const CheckoutPaymentRequestButtonContainer = styled(
  (props: WithThemeProps & BoxProps) => <Box {...props} />
)`
  &.${WALLET_PAY_CONTAINER} {
    & .${PAYMENT_OPTIONS_DIVIDER} {
      color: ${({ theme: { palette } }: ThemeType) => palette.p_grey.border};

      & .MuiDivider-wrapper {
        padding: 0 ${({ theme: { spacing } }: ThemeType) => spacing(3)};
        margin: ${({ theme: { spacing } }: ThemeType) => spacing(1.75)} 0;

        &:before,
        :after {
          border-width: ${({ theme: { spacing } }: ThemeType) => spacing(0.25)};
        }

        &:before {
          margin-left: ${({ theme: { spacing } }: ThemeType) => spacing(4)};
        }

        &:after {
          margin-right: ${({ theme: { spacing } }: ThemeType) => spacing(4)};
        }
      }
    }
  }
`;

export default CheckoutPaymentRequestButtonContainer;
