import { DateRange } from "@mui/lab/DateRangePicker";
import {
  differenceInDays,
  format,
  isAfter,
  isBefore,
  isValid as isDateInstanceValid,
} from "date-fns";

import { Dict, Nullable } from "types";

import {
  getMaxEndDateAccordingToStartDate,
  getMinDate,
  getTodayMidnight,
  isDateStringValid,
  MAX_DAYS_FROM_TRIP_START_DATE,
} from "utils/dates";

export const TRANSLATION_KEY_PREFIX = "date range picker errors";

export enum DateRangeError {
  None = "None",
  StartDateInvalid = "Start Date Invalid",
  EndDateInvalid = "End Date Invalid",
  BothDatesInvalid = "Both Dates Invalid",
  StartDateBeforeToday = "Start Date Before Today",
  StartDateBeforeMinDate = "Start Date Before Min Date",
  StartDateAfterEndDate = "Start Date After End Date",
  StartDateAfterMaxDate = "Start Date After Max Date",
  TripExceedsMaxDuration = "Trip Exceeds Max Duration",
  EndDateAfterMaxDate = "End Date After Max Date",
}

/**
 * Mapping logical enum values to dictionary key names
 * Protection for future changes
 */
export const DateRangeErrorDictionaryKeyByType: Dict<DateRangeError, string> = {
  [DateRangeError.StartDateInvalid]: "start date invalid",
  [DateRangeError.EndDateInvalid]: "end date invalid",
  [DateRangeError.BothDatesInvalid]: "both dates invalid",
  [DateRangeError.StartDateBeforeToday]: "start date before today",
  [DateRangeError.StartDateBeforeMinDate]: "start date before min date",
  [DateRangeError.StartDateAfterEndDate]: "start date after end date",
  [DateRangeError.StartDateAfterMaxDate]: "start date after max date",
  [DateRangeError.TripExceedsMaxDuration]: "trip exceeds max duration",
  [DateRangeError.EndDateAfterMaxDate]: "end date after max date",
  [DateRangeError.None]: "",
};

const dateRangeBetweenMinAndMax = ([
  startDate,
  endDate,
]: DateRange<Date>): boolean => {
  const maxSate = getMaxEndDateAccordingToStartDate(startDate!);

  return (
    !isBefore(startDate!, getMinDate()) &&
    !isBefore(endDate!, startDate!) &&
    !isBefore(maxSate, endDate!)
  );
};

export const valueValidation = ([startDate, endDate]: DateRange<Date>):
  | boolean
  | undefined =>
  startDate === null && endDate === null
    ? undefined
    : isDateInstanceValid(startDate) &&
      isDateInstanceValid(endDate) &&
      isDateStringValid(format(startDate!, "MM/dd/yyyy")) &&
      isDateStringValid(format(endDate!, "MM/dd/yyyy")) &&
      !isAfter(startDate!, endDate!) &&
      dateRangeBetweenMinAndMax([startDate, endDate]);

const isDateValidAsDate = (date: Nullable<Date>) =>
  date !== null &&
  isDateInstanceValid(date) &&
  isDateStringValid(format(date, "MM/dd/yyyy"));

export const getErrorType = ([
  startDate,
  endDate,
]: DateRange<Date>): DateRangeError => {
  const startDateValidAsDate = isDateValidAsDate(startDate);
  const endDateValidAsDate = isDateValidAsDate(endDate);

  const maxSate = getMaxEndDateAccordingToStartDate(startDate);
  const todayMidnight: Date = getTodayMidnight();
  const minDate: Date = getMinDate();

  switch (true) {
    case !startDateValidAsDate && endDateValidAsDate:
      return DateRangeError.StartDateInvalid;
    case startDateValidAsDate && !endDateValidAsDate:
      return DateRangeError.EndDateInvalid;
    case !startDateValidAsDate && !endDateValidAsDate:
      return DateRangeError.BothDatesInvalid;
    case isBefore(startDate!, todayMidnight):
      return DateRangeError.StartDateBeforeToday;
    case isBefore(startDate!, minDate):
      return DateRangeError.StartDateBeforeMinDate;
    case isAfter(startDate!, endDate!):
      return DateRangeError.StartDateAfterEndDate;
    case isAfter(startDate!, maxSate):
      return DateRangeError.StartDateAfterMaxDate;
    case differenceInDays(endDate!, startDate!) > MAX_DAYS_FROM_TRIP_START_DATE:
      return DateRangeError.TripExceedsMaxDuration;
    case isAfter(endDate!, maxSate):
      return DateRangeError.EndDateAfterMaxDate;
    default:
      return DateRangeError.None;
  }
};
