import { configureStore, Reducer } from "@reduxjs/toolkit";
import quoteReducer from "../features/quote/slice";
import authenticationReducer from "../features/authentication/slice";
import travelersReducer from "../features/travelers/slice";
import { connectRouter, routerMiddleware } from "connected-react-router";
import history from "../utils/history";
import { saveState } from "./session-persisted-state";

export const store = configureStore({
  reducer: {
    quote: quoteReducer,
    authentication: authenticationReducer,
    travelers: travelersReducer,
    router: connectRouter(history) as Reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(routerMiddleware(history)),
});

store.subscribe(() =>
  saveState({
    quote: store.getState().quote,
    travelers: store.getState().travelers,
  })
);
