import React from "react";
import { Typography } from "@mui/material";

import { PriceProps } from "../../constants";
import PriceContainer from "./styled";

const Price: React.FC<PriceProps> = ({ price }) => {
  const [dollars, cents] = price.toFixed(2).toString().split(".");

  return (
    <PriceContainer className="price-styled">
      <Typography className="currency">$</Typography>
      <Typography className="dollars">{dollars}</Typography>
      {Boolean(cents) && <Typography className="dot">.</Typography>}
      {Boolean(cents) && <Typography className="cents">{cents}</Typography>}
    </PriceContainer>
  );
};

export default Price;
