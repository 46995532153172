import React, { useCallback, useEffect, useMemo, useState } from "react";

type InputType = string | number;

const useInput = <T extends InputType>(
  initialValue: T,
  validationCallback?: (...args: any[]) => boolean,
  refactorInputCallBack?: (...args: any[]) => T
) => {
  const [value, setValue] = useState<T>(initialValue);
  const [valid, setValid] = useState<boolean>();
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const hasError = useMemo(
    () => valid === false && !isFocused,
    [valid, isFocused]
  );

  const validate = useCallback(
    (newValue) => {
      if (validationCallback) {
        const isValid = validationCallback(newValue);
        setValid(isValid);
      }
    },
    [validationCallback]
  );

  const handleChange = useCallback(
    (e: React.BaseSyntheticEvent) => {
      let newValue: InputType = e.target.value;
      if (typeof initialValue === "number") {
        newValue = Number.parseFloat(e.target.value || 0);
      }
      if (refactorInputCallBack) {
        newValue = refactorInputCallBack(newValue);
      }
      setValue(newValue as T);
      validate(newValue);
    },
    [validate, initialValue, refactorInputCallBack]
  );

  const onFocus = useCallback(() => {
    if (!isFocused) {
      setIsFocused(true);
    }
    if (!valid) {
      setValid(undefined);
    }
  }, [isFocused, valid]);

  const onBlur = useCallback(() => {
    if (isFocused) {
      setIsFocused(false);
    }
  }, [isFocused]);

  useEffect(() => {
    if (initialValue) {
      validate(initialValue);
    }
  }, [validate, initialValue]);

  return {
    value,
    onChange: handleChange,
    validate,
    valid,
    onBlur,
    onFocus,
    hasError,
  };
};

export default useInput;
