import React from "react";

export const handleTextWithNewLines = (text: string) =>
  text.split("\n").map((text, index) =>
    index !== 0 ? (
      <React.Fragment key={index}>
        <br />
        {text}
      </React.Fragment>
    ) : (
      text
    )
  );
