export const TRANSLATION_KEY_PREFIX = "quote.steps.offer.loading page";
export const TITLE_KEY = "title";
export const TITLE_RETRIEVING_OFFER_KEY = "title retrieving offer";
export const CALCULATING_KEY = "calculating";

export const LOADER_CONTAINER = "loader-container";
export const LOADER_SHOWN = "loader-shown";
export const LOADER_HIDDEN = "loader-hidden";

export const CLASS_MESSAGE_WE_BUILD_YOUR_POLICY =
  "message-we-build-your-policy";
export const CLASS_CALCULATING_POLICY = "message-calculating-policy";
export const CLASS_LOADER_CONTENT = "loader-content";

export const PAGE_NAME_OB_LOADER = "OB Loader";

const MINIMUM_LOADER_TIME_MS = 2500;
export const MINIMUM_LOADER_WORD_READING_TIME_MS = 250;

/**
 * @param text - according to the amount of words, the random process step
 *               will be calculated
 *
 * Example:
 * Interval = 0.25s, RandomMaxStep = 20 ----> TotalTime =~ 2.5s
 * Interval = 0.25s, RandomMaxStep = 10 ----> TotalTime =~ 5s
 * Interval = 0.25s, RandomMaxStep = 5 ----> TotalTime =~ 10s
 *
 * NOTE: Usually the average step will be half from the RandomMaxStep
 * So the calculation should be:
 *
 * 100 (stands for 100% of progress)
 * ---------------------------------  * 2 (because the NOTE above) =  RandomMaxStep
 * Amount of words in the text
 */
export const getRandomTimeRangeMaximumProgressPerWord = (text: string) =>
  200 /
  Math.max(
    MINIMUM_LOADER_TIME_MS / MINIMUM_LOADER_WORD_READING_TIME_MS,
    text.replaceAll("\n", " ").split(" ").length
  );

export interface LoadingProps {}
